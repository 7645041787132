import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EventDefinition from './EventDefinition';
import EventDefinitionDifference from './EventDefinitionDifference';
import TimelineCoverageStyle from './css/TimelineCoverage.module.scss';

const EventCoverage = (props) => {
    const { coverages, isDifference } = props;
    const classCoverageDifference = classNames(TimelineCoverageStyle.gwTimelineCoverageDiff, {
        [TimelineCoverageStyle.gwTimelineCoverageArray]: !isDifference
    });
    const classCoverageDiffCoverage = TimelineCoverageStyle.gwTimelineCoverageDiff__coverage;
    const classCoverageDiffHeading = TimelineCoverageStyle.gwTimelineCoverageDiff__heading;

    return (
        <div className={classCoverageDifference}>
            <div className={classCoverageDiffCoverage}>
                {coverages.map((coverage) => (
                    <Fragment>
                        <div className={classCoverageDiffHeading}>{coverage.coverage}</div>
                        {coverage.covTerms.map((term) => (isDifference ? (
                            <EventDefinitionDifference
                                key={`eventdefinitiondifference${term.name}`}
                                term={term.name}
                                newValue={term.value}
                                oldValue={term.oldValue}
                            />
                        ) : (
                            <EventDefinition
                                key={`eventdefinition${term.name}`}
                                term={term.name}
                                value={term.value}
                            />
                        )))}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};
EventCoverage.propTypes = {
    coverages: PropTypes.shape([]).isRequired,
    isDifference: PropTypes.bool.isRequired
};

export default EventCoverage;
