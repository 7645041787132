import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { Chevron } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './RatesAndCostsComponent.metadata.json5';
import styles from './RatesAndCostsComponent.module.scss';
import message from './RatesAndCostsComponent.messages';

class RatesAndCostsComponent extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        value: PropTypes.shape({}).isRequired,
        goToEmployeesAndLocations: PropTypes.func.isRequired,
        showButton: PropTypes.bool
    };

    static defaultProps = {
        showButton: true
    };

    renderAccordionHeader = (state, index) => {
        const translator = this.context;

        return (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} className={styles.chevronStyle} />
                <Grid
                    id={`gridAccordion_${index}`}
                    columns={['3fr', '3fr', '3fr', '3fr', '3fr']}
                    alignContent="top"
                >
                    <span className={styles.accordionStyle}>
                        {translator({
                            id: `typekey.Jurisdiction.${state.jurisdiction}`,
                            defaultMessage: state.jurisdiction
                        })}
                    </span>
                    <span id={`accordionLocationData_${index}`} />
                    <CurrencyField
                        id={`totalPremium_${index}`}
                        value={state.totalPremium}
                        labelPosition="left"
                        className={styles.tax}
                        readOnly
                    />
                    <CurrencyField
                        id={`taxesAndSurcharges_${index}`}
                        value={state.taxesAndSurcharges}
                        labelPosition="left"
                        className={styles.tax}
                        readOnly
                    />
                    <CurrencyField
                        id={`totalCost_${index}`}
                        value={state.totalCost}
                        labelPosition="left"
                        className={styles.tax}
                        readOnly
                    />
                </Grid>
            </React.Fragment>
        );
    };

    getCurrency = (amount, index) => {
        return (
            <div className={styles.currencyContainer}>
                <CurrencyField
                    id={`currency_${index}`}
                    value={amount}
                    readOnly
                />
            </div>
        );
    };

    getAccordionHeaders = () => {
        const { value: submissionValue } = this.props;

        const availableStates = _.get(submissionValue, 'lobData.wc7WorkersComp.coverables.states') || [];

        const updatedHeaders = availableStates
            .sort((firstState, secondState) => firstState
                .jurisdiction.localeCompare(secondState.jurisdiction))
            .map((state, index) => {
                return {
                    [`employeeClassCodeDetailsAccordionCard${index}`]: {
                        renderHeader: this.renderAccordionHeader(state, index)
                    }
                };
            });

        return Object.assign({}, ...updatedHeaders);
    };

    getLocationAddress = ({ location }) => {
        const translator = this.context;
        const { address } = location;

        const postalCode = address.postalCode || '';

        const addressDetails = {
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            addressLine3: address.addressLine3,
            city: address.city,
            stateWithPostalCode: _.trim(
                `${translator({
                    id: `typekey.State.${address.state}`,
                    defaultMessage: address.state
                })} ${postalCode}`
            ),
            country: translator({
                id: `typekey.Country.${address.country}`,
                defaultMessage: address.country
            })
        };

        const formattedAddress = Object.values(addressDetails)
            .filter((data) => data)
            .join(', ');

        const employeeAddressPrefix = translator(message.employeesClasses);

        return `${employeeAddressPrefix} - ${formattedAddress}`;
    };

    getEmployeesAndLocationsTableData = ({ coveredEmployees }) => {
        return (
            coveredEmployees
            && coveredEmployees.map((employee, index) => {
                return {
                    code: employee.classCode.code,
                    classification: employee.classCode.classification,
                    basisAmount: employee.basisAmount,
                    rate: employee.rate,
                    amount: this.getCurrency(employee.amount, index)
                };
            })
        );
    };

    sortByState = (locationsAndEmployees) => {
        const path = 'location.address.state';

        return locationsAndEmployees.sort((firstEmployee, secondEmployee) => {
            return _.get(firstEmployee, path).localeCompare(_.get(secondEmployee, path));
        });
    };

    getEmployeesAndLocationsDetails = () => {
        const { value: submissionValue, showButton } = this.props;

        const locationsAndEmployees = _.get(submissionValue, 'lobData.wc7WorkersComp.coverables.locationsAndEmployees') || [];

        const locationsAndEmployeesDetails = this.sortByState(locationsAndEmployees).map(
            (location, index) => {
                return {
                    [`employeeAddressSection${index}`]: {
                        content: this.getLocationAddress(location)
                    },
                    [`employeeTableContainer${index}`]: {
                        data: this.getEmployeesAndLocationsTableData(location)
                    },
                    [`changeOrAddEmployeeButtonId${index}`]: {
                        visible: showButton
                    }
                };
            }
        );

        return Object.assign({}, ...locationsAndEmployeesDetails);
    };

    getPremiumTableData = (allPremiums) => {
        return (
            allPremiums
            && allPremiums.map((cost) => {
                return {
                    code: cost.code,
                    description: cost.description,
                    basis: cost.basis,
                    rate: cost.rate,
                    amount: this.getCurrency(cost.amount)
                };
            })
        );
    };

    getPremiumDetails = () => {
        const { value: submissionValue } = this.props;

        const stateDetails = _.get(submissionValue, 'lobData.wc7WorkersComp.coverables.states') || [];

        const allPremiumDetails = stateDetails
            .sort((firstState, secondState) => firstState
                .jurisdiction.localeCompare(secondState.jurisdiction))
            .map((state, index) => {
                const allPremiums = [
                    ...state.standardPremiums,
                    ...state.otherPremiumsAndSurcharges
                ];
                return {
                    [`otherPremiumTableContainer${index}`]: {
                        data: this.getPremiumTableData(allPremiums)
                    }
                };
            });

        return Object.assign({}, ...allPremiumDetails);
    };

    getCell = (item, index, { id: property }) => {
        return item[property];
    };

    render() {
        const { goToEmployeesAndLocations, showButton, value: submissionValue } = this.props;
        const overrideProps = {
            changeOrAddEmployeeButtonId: {
                visible: showButton
            },
            addOrChange: {
                visible: showButton
            },
            ...this.getAccordionHeaders(),
            ...this.getEmployeesAndLocationsDetails(),
            ...this.getPremiumDetails()
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                goToEmployeesAndLocations: goToEmployeesAndLocations
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={submissionValue}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

export default RatesAndCostsComponent;
