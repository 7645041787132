import { defineMessages } from 'react-intl';

export default defineMessages({
    userID: {
        id: 'auth.components.User.SignUp.User ID',
        defaultMessage: 'User ID:'
    },
    userFirstName: {
        id: 'auth.components.User.SignUp.First Name:',
        defaultMessage: 'First Name:'
    },
    userCreateProfile: {
        id: 'auth.components.User.SignUp.Create Profile',
        defaultMessage: 'Create New Account'
    },
    accountType: {
        id: 'auth.components.User.SignUp.Account Type',
        defaultMessage: 'Account Type'
    },
    agencyCode: {
        id: 'auth.components.User.SignUp.Agency Code',
        defaultMessage: 'Agency Code'
    },
    company: {
        id: 'auth.components.SignUp.Company',
        defaultMessage: 'Company'
    },
    soleProprietor: {
        id: 'auth.components.SignUp.Sole Proprietor',
        defaultMessage: 'Sole Proprietor'
    },
    ssn: {
        id: 'auth.components.User.SignUp.SSN',
        defaultMessage: 'SSN:'
    },
    policyNumber: {
        id: 'auth.components.User.SignUp.Policy Number',
        defaultMessage: 'Policy Number:'
    },
    userLastName: {
        id: 'auth.components.User.SignUp.Last Name:',
        defaultMessage: 'Last Name:'
    },
    userEmailAddress: {
        id: 'auth.components.User.SignUp.Email:',
        defaultMessage: 'Email:'
    },
    userInvalidEmail: {
        id: 'auth.components.User.SignUp.Please enter a valid email address.',
        defaultMessage: 'Please enter a valid email address'
    },
    userDoYouHaveAccount: {
        id: 'auth.components.User.SignUp.Already have an account?',
        defaultMessage: 'Already have an account?'
    },
    userFein: {
        id: 'auth.components.User.SignUp.FEIN',
        defaultMessage: 'FEIN:'
    },
    userTelephone: {
        id: 'auth.components.User.SignUp.telephone',
        defaultMessage: 'Telephone:'
    },
    userType: {
        id: 'auth.components.User.SignUp.userType',
        defaultMessage: 'User Type:'
    },
    userCreation: {
        id: 'auth.components.User.Creation.Submit Request',
        defaultMessage: 'Submit Request'
    },
    userFirstNameIsEmpty: {
        id: 'auth.components.User.Creation.First Name Is Empty',
        defaultMessage: 'First name is empty'
    },
    userLastNameIsEmpty: {
        id: 'auth.components.User.Creation.Last Name Is Empty',
        defaultMessage: 'Last name is empty'
    },
    userTypeError: {
        id: 'auth.components.User.Creation.User Type is Empty',
        defaultMessage: 'User type is empty'
    },
    telephoneError: {
        id: 'auth.components.User.Creation.Telephone Number is Empty',
        defaultMessage: 'Telephone number is empty'
    },
    feinIsEmptyError: {
        id: 'auth.components.User.Creation.FEIN is Empty',
        defaultMessage: 'You must enter 1 ID field for your organization (FEIN or SSN)'
    },
    userIdError: {
        id: 'auth.components.User.Creation.User Id Format is Wrong',
        defaultMessage: 'User Id format is wrong'
    },
    feinFormatError: {
        id: 'auth.components.User.Creation.FEIN format is wrong',
        defaultMessage: 'FEIN should be of 9 digits'
    },
    ssnFormatError: {
        id: 'auth.components.User.Creation.SSN format is wrong',
        defaultMessage: 'SSN should be 9 digits'
    },
    backToRegistration: {
        id: 'auth.components.User.Creation.Back to Registration',
        defaultMessage: 'Back to Registration'
    },
    termsError: {
        id: 'auth.components.User.Creation.Terms and Conditions',
        defaultMessage: 'Please agree SWIF terms and condition'
    },
    captchaCheckedError: {
        id: 'auth.components.User.Creation.Captcha is not checked',
        defaultMessage: 'Captcha is not checked'
    },
    returnToLoginScreen: {
        id: 'auth.components.User.Registration.Return to Sign In and enter your password.',
        defaultMessage: 'Return to Sign In and enter your password.\n test2"'
    },
    userSuccessfullyRegisterUnderReview: {
        id: 'auth.components.User.Registration.Under Review',
        defaultMessage: 'Your request for access to the application is currently under review. You will receive another email once access is granted.'
    },
    userSuccessfullyRegisterQuestions: {
        id: 'auth.components.User.Registration.Questions or Issues',
        defaultMessage: 'If you have any questions or issues, please contact Customer Service at 570-963-4635, Monday through Friday 8 a.m. – 4 p.m.'
    },
    userSuccessfullyRegister: {
        id: 'auth.components.User.Registration.User Successfully Registered',
        defaultMessage: 'Congratulations. You have completed the Producer Portal Sign Up Registration request. '
    },
    userRegisterEmptyLink: {
        id: 'auth.components.User.Registration.Empty Link',
        defaultMessage: ' '
    },
    userFailedRegister: {
        id: 'auth.components.User.Registration.User Registration Failed',
        defaultMessage: 'FEIN not found in GW'
    },
    userFailedRegisterHeader: {
        id: 'auth.components.User.Registration.Header.User Registration Failed',
        defaultMessage: 'User Registration Failed'
    },
    userIDnotFound: {
        id: 'auth.components.User.Registration.User id not found.',
        defaultMessage: 'User ID not found. Please register as new user'
    },
    agencyCodeError: {
        id: 'auth.components.User.Creation.AgencyCode format is wrong',
        defaultMessage: 'Agency code should be 6 digits'
    }

});
