import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { Loader, withModalContext } from '@jutro/components';
import { JobUtil } from 'gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { PolicyChangeService, UserService } from 'gw-capability-gateway';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import styles from './Summary.module.scss';

class EndorsementSummary extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadPolicyChangeSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    state = {
        showContinuePolicyChangeButton: false,
        canWithdraw: false,
        isLoading: true
    };

    componentDidMount() {
        this.canWithdraw();
        this.getShowContinuePolicyChange();
    }

    getShowContinuePolicyChange = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'createpolchange'
        };
        const showContinuePolicyChangeButton = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ showContinuePolicyChangeButton, isLoading: false });
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            }
        } = this.props;

        if (!_.isEmpty(policyChange)) {
            const translator = this.context;
            if (
                policyChange.status
                    !== translator({
                        id: 'typekey.PolicyPeriodStatus.Bound',
                        defaultMessage: 'Bound'
                    })
                && !policyChange.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    updateWithDrawPolicyChange = (jobStatusCode, policyChange) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });
        _.set(policyChange, 'status', status);
        _.set(policyChange, 'statusCode', jobStatusCode);
        this.updateJobSummary(policyChange);
    };

    onContinueTransaction = () => {
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            },
            history
        } = this.props;

        const productCode = _.get(policyChange, 'productCode');
        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], {
                policyNumber: policyChange.policy.policyNumber
            });
        } else {
            JobUtil.openJobInXCenter(policyChange.jobNumber);
        }
    };

    onWithdrawTransaction = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            },
            authHeader,
            modalContext
        } = this.props;
        modalContext.showConfirm({
            title: messages.withdrawPolicyChange,
            message: messages.sureWithdrawPolicyChange,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: messages.withdraw
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            PolicyChangeService.withdrawPolicyChangeByPolicyChangeNumber(
                policyChange.jobNumber,
                authHeader
            ).then(
                () => {
                    this.updateWithDrawPolicyChange('Withdrawn', policyChange);
                },
                () => {
                    modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedToWithdrawPolicyChange,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    hasUwIssues = (uwIssues) => {
        return uwIssues.length > 0;
    };

    getNotificationContent = () => {
        const translator = this.context;
        const { showContinuePolicyChangeButton } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            }
        } = this.props;
        const content = {};
        if (
            policyChange
            && !['Bound', 'Quoted', 'Withdrawn'].includes(policyChange.statusCode)
            && !this.hasUwIssues(policyChange.underwritingIssues)
        ) {
            content.infoMessageTitle = translator(messages.policyChangeStarted);
            content.infoMessageDescription = translator(messages.mayWithdrawOrContinue);
        }
        if (
            policyChange
            && policyChange.statusCode === 'Quoted'
            && !this.hasUwIssues(policyChange.underwritingIssues)
        ) {
            content.infoMessageTitle = translator(messages.policyChangePremiusCalculated);
            content.infoMessageDescription = showContinuePolicyChangeButton
                ? translator(messages.mayWithdrawOrContinue)
                : translator(messages.mayWithDrawPolicyChange);
        }
        if (policyChange && policyChange.statusCode === 'Bound') {
            content.infoMessageTitle = translator(messages.policyChangeBound);
            content.infoMessageDescription = '';
        }
        if (policyChange && policyChange.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(messages.policyWithdrawn);
            content.infoMessageDescription = '';
        }
        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            withDrawContent: translator(messages.withdrawPolicyChange),
            continueContent: translator(messages.continuePolicyChange)
        };
    };

    getPolicyChangeToProceed = () => {
        const { canWithdraw, showContinuePolicyChangeButton } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            }
        } = this.props;

        if (
            policyChange
            && (policyChange.statusCode === 'Withdrawn' || policyChange.statusCode === 'Bound')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false
            };
        }
        return {
            isContinueTransaction:
                showContinuePolicyChangeButton && canWithdraw && !policyChange.isEditLocked,
            isWithdrawTransaction: canWithdraw
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            }
        } = this.props;

        const policyNumber = _.get(policyChange, 'policy.latestPeriod.policyNumber', 'Unassigned');
        return policyNumber !== 'Unassigned';
    };

    redirectToPolicy = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            }
        } = this.props;

        const policyNumber = _.get(policyChange, 'policy.latestPeriod.policyNumber');
        return (
            <Link to={`/policies/${policyNumber}/summary`} className={styles.removeLinkStyle}>
                {policyNumber}
            </Link>
        );
    };

    updateJobSummary = (policyChange) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(policyChange);
        }
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadPolicyChangeSummary: policyChange }
            }
        } = this.props;
        const { isLoading } = this.state;
        if (_.isEmpty(policyChange)) {
            return <Loader loaded={!isLoading} />;
        }
        const overrides = {
            '@field': {
                readOnly: true
            },
            quoteNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getPolicyChangeToProceed()
            },
            underwritingTable: {
                job: policyChange,
                continueJob: this.getPolicyChangeToProceed(),
                jobService: PolicyChangeService,
                onUpdateJobSummary: this.updateJobSummary
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible(),
                value: this.redirectToPolicy()
            },
            quotedBoundContainer: {
                visible: policyChange.statusCode === 'Quoted' || policyChange.statusCode === 'Bound'
            },
            producerCodeOfRecordOrgId: {
                value: `${_.get(
                    policyChange,
                    'policy.latestPeriod.producerCodeOfRecordOrg'
                )} (${_.get(policyChange, 'policy.latestPeriod.producerCodeOfRecord')})`
            },
            producerOfServiceId: {
                value: `${_.get(
                    policyChange,
                    'policy.latestPeriod.producerCodeOfServiceOrg'
                )} (${_.get(policyChange, 'policy.latestPeriod.producerCodeOfService')})`
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent
            },
            resolveCallbackMap: {
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, policyChange, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChange}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

export default withAuthenticationContext(withModalContext(EndorsementSummary));
