import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class AccountService {
    static accountExists(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayaccountAccount'),
            'accountExists',
            [accountNumber],
            additionalHeaders
        );
    }

    static createNewUser(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('userEnrollment'), 'createNewUser', [data], additionalHeaders);
    }
}
