import React from 'react';
import { FormattedDate } from '@jutro/locale';
import NameFormatter from './NameFormatter';
import Address from './address';
import Utils from '../util/timelineUtils';

const functionMap = {
    date: (value) => {
        const eventDate = new Date(value);
        return (<FormattedDate value={new Date(eventDate)} />);
    },
    gwName: (value) => {
        return NameFormatter(value);
    },
    gwAddress: (value, translator) => {
        return Address(value, translator);
    },
    gwTypeKey: (key, type, translator) => {
        const displayKey = `${type}.${key}`;
        const translatedKey = translator({
            id: displayKey,
            defaultMessage: key
        });

        return translatedKey;
    }
};

const EventTypeResolver = (type, value, translator) => {
    if (!type.includes(':')) {
        return functionMap[type](value, translator);
    }
    const [typeName, typeArgs] = Utils.getTypeBySplit(type);
    if (typeof typeArgs === 'object') {
        if (typeArgs.key) {
            return functionMap[typeName](value, typeArgs.key);
        }
        if (typeArgs.type) {
            return functionMap[typeName](value, typeArgs.type, translator);
        }
        return functionMap[typeName](typeArgs);
    }
    return value;
};

export default EventTypeResolver;
