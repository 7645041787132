import { defineMessages } from 'react-intl';

export default defineMessages({
    quote: {
        id: 'endorsement.common.views.actions.Quote',
        defaultMessage: 'Quote'
    },
    next: {
        id: 'integration.quoteandbind.directives.custom-quote.CP.Quote.Next',
        defaultMessage: 'Next'
    },
    finish: {
        id: 'integration.quoteandbind.directives.custom-quote.CP.Quote.Finish',
        defaultMessage: 'Finish'
    }
});
