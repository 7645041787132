import { setComponentMapOverrides } from '@jutro/uiconfig';
import PolicyChangeCPPolicyDetailsPage from './pages/PolicyChangeCPPolicyDetails/PolicyChangeCPPolicyDetailsPage';
import PolicyChangeCPQuotePage from './pages/PolicyChangeCPQuote/PolicyChangeCPQuotePage';
import 'gw-capability-policychange-common-react';
import 'gw-capability-gateway-quoteandbind-cp-react';
import './CPPolicyChange.messages';

setComponentMapOverrides(
    {
        PolicyChangeCPPolicyDetailsPage: { component: 'PolicyChangeCPPolicyDetailsPage' },
        PolicyChangeCPQuotePage: { component: 'PolicyChangeCPQuotePage' }
    },
    {
        PolicyChangeCPPolicyDetailsPage,
        PolicyChangeCPQuotePage
    }
);
