import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteRequest: {
        id: 'gw-portals-policytransaction-common-angular.baseTransaction.baseTransactionWizard.Quote Request',
        defaultMessage: 'Quote Request'
    },
    coveragesAndExclusionsTitle: {
        id: 'quoteandbind.wc7.views.coverages-and-exclusions.Coverages and Exclusions',
        defaultMessage: 'Coverages and Exclusions'
    },
    coveragesAndExclusionsCoverages: {
        id: 'quoteandbind.wc7.views.coveragesandexclusions.Coverages',
        defaultMessage: 'Coverages'
    },
    coveragesAndExclusionsExclusions: {
        id: 'quoteandbind.wc7.views.coveragesandexclusions.Exclusions',
        defaultMessage: 'Exclusions'
    },
    coveragesAndExclusionsConditions: {
        id: 'quoteandbind.wc7.views.coveragesandexclusions.Conditions',
        defaultMessage: 'Conditions'
    },
    coveragesAndExclusionsConditionsDisclaimer: {
        id: 'gw-portals-wc7-components-angular.clauses.ScheduledClause.Do you want to see conditions?',
        defaultMessage: 'Do you want to see conditions?'
    },
    changeCoverages: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.Change Coverages',
        defaultMessage: 'Add or Change'
    },
    sameAddress: {
        id: 'quoteandbind.wc7.views.finalquote.Is the billing address the same as the above address?',
        defaultMessage: 'Is the billing address the same as the above address?'
    },
    yes: {
        id: 'quoteandbind.wc7.views.additional-info.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.wc7.views.additional-info.No',
        defaultMessage: 'No'
    },
    policyInformation: {
        id: 'quoteandbind.wc7.views.finalquote.Policy Information',
        defaultMessage: 'Policy Information'
    },
    phoneNumber: {
        id: 'quoteandbind.wc7.views.finalquote.Phone Number',
        defaultMessage: 'Phone Number'
    },
    email: {
        id: 'quoteandbind.wc7.views.finalquote.Email',
        defaultMessage: 'Email'
    },
    additionalInformationTitle: {
        id: 'quoteandbind.wc7.wizard.step.Additional Information',
        defaultMessage: 'Additional Information'
    },
    wc7PolicyDetailsTitle: {
        id: 'quoteandbind.wc7.views.policydetails.Policy Details',
        defaultMessage: 'Policy Details'
    },
    wc7PolicyDetailsIndustryCode: {
        id: 'quoteandbind.wc7.views.policydetails.Industry Code',
        defaultMessage: 'Industry Code'
    },
    wc7PolicyDetailsOrganizationType: {
        id: 'quoteandbind.wc7.views.policydetails.Organization Type',
        defaultMessage: 'Organization Type'
    },
    wc7PolicyDetailsSSN: {
        id: 'quoteandbind.wc7.views.policydetails.SSN',
        defaultMessage: 'SSN'
    },
    wc7PolicyDetailsFEIN: {
        id: 'quoteandbind.wc7.views.policydetails.FEIN',
        defaultMessage: 'FEIN'
    },
    wc7PolicyDetailsCoverageStartDate: {
        id: 'quoteandbind.wc7.views.policydetails.Coverage Start Date',
        defaultMessage: 'Coverage Start Date'
    },
    employeesAndLocations: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor.Employees and Locations',
        defaultMessage: 'Employees and Locations'
    },
    coveragesAndExclusions: {
        id: 'quoteandbind.wc7.wizard.step.Coverages and Exclusions',
        defaultMessage: 'Coverages and Exclusions'
    },
    modalError: {
        id: 'gw-portals-wc7-components-angular.coverages.lineCoverages.Error',
        defaultMessage: 'Error'
    },
    modalErrorMessage: {
        id: 'gw-portals-wc7-components-angular.coverages.lineCoverages.Something went wrong. Please try again.',
        defaultMessage: 'Something went wrong. Please try again.'
    },
    finalQuote: {
        id: 'quoteandbind.wc7.wizard.step.Final Quote',
        defaultMessage: 'Final Quote'
    },
    wc7WizardQualificationTitle: {
        id: 'quoteandbind.wc7.wizard.step.Qualification',
        defaultMessage: 'Qualification'
    },
    wc7WizardEmployeesAndLocationTitle: {
        id: 'quoteandbind.wc7.wizard.step.Employees and Locations',
        defaultMessage: 'Employees and Locations'
    },
    wc7WizardPolicyDetailsTitle: {
        id: 'quoteandbind.wc7.wizard.step.Policy Details',
        defaultMessage: 'Policy Details'
    },
    wc7SearchIndustryCodes: {
        id: 'quoteandbind.wc7.views.policydetails.Search industry codes',
        defaultMessage: 'Search industry codes'
    },
    wc7PlaceHolderOrganizationType: {
        id: 'quoteandbind.wc7.views.policydetails.-- Organization Type --',
        defaultMessage: '-- Organization Type --'
    },
    wc7Yes: {
        id: 'quoteandbind.wc7.views.qualification.Yes',
        defaultMessage: 'Yes'
    },
    wc7No: {
        id: 'quoteandbind.wc7.views.qualification.No',
        defaultMessage: 'No'
    },
    wc7EmployeeLocationInfoLable: {
        id: 'quoteandbind.wc7.views.employees-and-locations.You can add manually 20 employees at the most. Use a spreadsheet to add more than 20.',
        defaultMessage: 'You can add manually 20 employees at the most. Use a spreadsheet to add more than 20.'
    },
    primaryInsured: {
        id: 'quoteandbind.wc7.views.confirmation.Primary Insured',
        defaultMessage: 'Primary Insured'
    },
    companyName: {
        id: 'quoteandbind.wc7.views.finalquote.Company Name',
        defaultMessage: 'Company Name'
    },
    address: {
        id: 'quoteandbind.wc7.views.finalquote.Address',
        defaultMessage: 'Address'
    },
    anErrorOccurred: {
        id: 'quoteandbind.wc7.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.wc7.wizard.error.cancel.title',
        defaultMessage: 'Error'
    },
    Location: {
        id: 'quoteandbind.wc7.views.employees-classes-locations.Location',
        defaultMessage: 'Location'
    },
    LocName: {
        id: 'quoteandbind.wc7.views.employees-classes-locations.Loc Name',
        defaultMessage: 'Loc Name'
    },
    workClassification: {
        id: 'quoteandbind.wc7.views.employees-classes-locations.Work Classification',
        defaultMessage: 'Work Classification'
    },
    saveQuoteError: {
        id: 'quoteandbind.wc7.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'quoteandbind.wc7.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    }

});
