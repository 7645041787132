import { defineMessages } from 'react-intl';

export default defineMessages({
    activitieslandingAssignment: {
        id: 'gateway.views.activities-landing.Assignment',
        defaultMessage: 'Assignment'
    },
    activitieslandingComplete: {
        id: 'gateway.views.activities-landing.Complete',
        defaultMessage: 'Complete'
    },
    activitieslandingCanceled: {
        id: 'gateway.views.activities-landing.Canceled',
        defaultMessage: 'Canceled'
    },
    activitieslandingCreatedBy: {
        id: 'gateway.views.activities-landing.Created By',
        defaultMessage: 'Created By'
    },
    activitieslandingLoadingActivities: {
        id: 'gateway.views.activities-landing.Loading Activities',
        defaultMessage: 'Loading Activities'
    },
    activitieslandingMe: {
        id: 'gateway.views.activities-landing.Me',
        defaultMessage: 'Me'
    },
    activitieslandingOpen: {
        id: 'gateway.views.activities-landing.Open',
        defaultMessage: 'Open'
    },
    activitieslandingOptions: {
        id: 'gateway.views.activities-landing.Options',
        defaultMessage: 'Options'
    },
    activitieslandingOthers: {
        id: 'gateway.views.activities-landing.Others',
        defaultMessage: 'Others'
    },
    activitieslandingPriority: {
        id: 'gateway.views.activities-landing.Priority',
        defaultMessage: 'Priority'
    },
    activitieslandingQuickFilters: {
        id: 'gateway.views.activities-landing.Quick Filters',
        defaultMessage: 'Quick Filters'
    },
    activitieslandingSkipped: {
        id: 'gateway.views.activities-landing.Skipped',
        defaultMessage: 'Skipped'
    },
    activitieslandingStatus: {
        id: 'gateway.views.activities-landing.Status',
        defaultMessage: 'Status'
    },
    activitieslandingSwitchto: {
        id: 'gateway.views.activities-landing.Switch to',
        defaultMessage: 'Switch to'
    },
    activitieslandingToMe: {
        id: 'gateway.views.activities-landing.To Me',
        defaultMessage: 'To Me'
    },
    activitieslandingToOthers: {
        id: 'gateway.views.activities-landing.To Others',
        defaultMessage: 'To Others'
    },
    activitieslandingType: {
        id: 'gateway.views.activities-landing.Type',
        defaultMessage: 'Type'
    },
    activitieslandingClearAll: {
        id: 'gateway.views.filters.Clear All',
        defaultMessage: 'Clear All'
    },
    activitieslandingClearAllFilters: {
        id: 'gateway.views.filters.Clear All Filters',
        defaultMessage: 'Clear All Filters'
    },
    activitieslandingSelectAll: {
        id: 'gateway.views.filters.Select All',
        defaultMessage: 'Select All'
    }
});
