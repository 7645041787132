import { defineMessages } from 'react-intl';

export default defineMessages({
    mtdCommission: {
        id: 'portal.infocenter.tab.MTD and YTD Commission',
        defaultMessage: 'MTD & YTD Commission'
    },
    statements: {
        id: 'portal.infocenter.tab.Statements',
        defaultMessage: 'Statements'
    },
    mtdCommissions: {
        id: 'gateway.views.reports.commissions.MTD Commissions',
        defaultMessage: 'MTD Commissions'
    },
    earned: {
        id: 'gateway.views.reports.commissions.EARNED',
        defaultMessage: 'EARNED'
    },
    writeOff: {
        id: 'gateway.views.reports.commissions.Write off',
        defaultMessage: 'Write off'
    },
    totalCommission: {
        id: 'gateway.views.reports.commissions.Total Commission',
        defaultMessage: 'Total Commission'
    },
    ytdCommissions: {
        id: 'gateway.views.reports.commissions.YTD Commissions',
        defaultMessage: 'YTD Commissions'
    },
    bookedPolicies: {
        id: 'gateway.views.reports.commissions.Booked Policies',
        defaultMessage: 'Booked Policies'
    },
    selectStatement: {
        id: 'gateway.directives.ReportStatementsDirective.Select a statement:',
        defaultMessage: 'Select a statement:'
    },
    statementNumber: {
        id: 'gateway.directives.ReportStatementsDirective.Statement statement',
        defaultMessage: 'Statement {num}'
    },
    statementTotalBalance: {
        id: 'gateway.directives.ReportStatementsDirective.Total',
        defaultMessage: 'Total'
    },
    statementsPolicyNumber: {
        id: 'gateway.directives.ReportStatementsDirective.Policy Number',
        defaultMessage: 'Policy Number'
    },
    statementsAccount: {
        id: 'gateway.directives.ReportStatementsDirective.Account',
        defaultMessage: 'Account'
    },
    statementsEarnedItems: {
        id: 'gateway.directives.ReportStatementsDirective.Earned Items',
        defaultMessage: 'Earned Items'
    },
    statementsCommission: {
        id: 'gateway.directives.ReportStatementsDirective.Commission',
        defaultMessage: 'Commission'
    },
    noPoliciesFound: {
        id: 'gateway.directives.ReportStatementsDirective.None of the policies has earned commission in this time period.',
        defaultMessage: 'None of the policies has earned commission in this time period.'
    },
    commissionPolicyNumber: {
        id: 'gateway.views.reports.commissions.POLICY NUMBER',
        defaultMessage: 'POLICY NUMBER'
    },
    commissionAccount: {
        id: 'gateway.views.reports.commissions.ACCOUNT',
        defaultMessage: 'ACCOUNT'
    },
    commissionEffectiveDate: {
        id: 'gateway.views.reports.commissions.EFFECTIVE DATE',
        defaultMessage: 'EFFECTIVE DATE'
    },
    commissionBasis: {
        id: 'gateway.views.reports.commissions.BASIS',
        defaultMessage: 'BASIS'
    },
    commissionRate: {
        id: 'gateway.views.reports.commissions.RATE',
        defaultMessage: 'RATE'
    },
    commissionTotal: {
        id: 'gateway.views.reports.commissions.TOTAL',
        defaultMessage: 'TOTAL'
    },
    commissionEarned: {
        id: 'gateway.views.reports.commissions.Earned',
        defaultMessage: 'Earned'
    },
    commissionPaid: {
        id: 'gateway.views.reports.commissions.PAID',
        defaultMessage: 'PAID'
    },
    commissionReserved: {
        id: 'gateway.views.reports.commissions.RESERVED',
        defaultMessage: 'RESERVED'
    }
});
