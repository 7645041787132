import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from 'gw-portals-wizard-react';
import { GatewayRenewalService } from 'gw-capability-gateway-policyrenewal';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { messages as commonMessages } from 'gw-platform-translations';
import { summaryMessages } from 'gw-capability-gateway-react';
import { policyRenewalMessages } from 'gw-capability-policyrenewal-common-react';
import messages from './CPPolicyRenewalWizard.messages';
import wizardConfig from './config/cp-wizard-config.json5';
import styles from './CPPolicyRenewalWizard.module.scss';

function CPPolicyRenewalWizard(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;
    const landingFrom = _.get(location, 'state.landingFrom');
    const toSkip = landingFrom === 'quoteSummary';

    useEffect(() => {
        if (!location.state) {
            history.push('/');
            return;
        }
        const { state: { jobNumber } } = location;
        if (!jobNumber) {
            return;
        }

        const errorModalBox = (errorMessage) => {
            showConfirm(errorMessage).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                setIsLoading(false);
                return history.push(`/contactAgent/${jobNumber}`);
            }, _.noop);
        };
        GatewayRenewalService.loadRenewal(jobNumber, authHeader)
            .then((responseData) => {
                ClausesUtil.getDependentClausesFromServer(
                    ClauseService,
                    _.get(responseData, 'jobID'),
                    authHeader
                );
                const submission = viewModelService.create(
                    responseData,
                    'pc',
                    'edge.capabilities.renewal.dto.RenewalDataDTO'
                );
                setInitialSubmission(submission);
                setIsLoading(false);
            }).catch(() => {
                errorModalBox({
                    title: policyRenewalMessages.unableToReferUW,
                    message: policyRenewalMessages.errorOccurWhileRenewal,
                    status: 'warning',
                    icon: 'mi-error-outline'
                });
            });
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardSnapshot, wizardData, param: nextPath = '', currentStepIndex, steps: totalSteps
        } = cancelParams;
        if ((currentStepIndex + 1 === totalSteps.length) || nextPath.startsWith('/renewal') || hasErrorOccurred) {
            return true;
        }
        return showConfirm({
            title: commonMessages.wantToCancel,
            message: commonMessages.infoWillBeSavedAsDraft,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            const isQuoted = _.get(wizardSnapshot.value, 'baseData.periodStatus') === 'Quoted';
            let serverCall = GatewayRenewalService.saveRenewal;
            let submission = wizardData.value;
            const { jobID } = submission;
            const redirectLobPath = nextPath || `/renewal/${jobID}/summary`;
            setIsLoading(true);
            let isArgumentUpdated = true;
            let renewalModel;
            if (isQuoted) {
                if (!_.isEqual(submission, wizardSnapshot.value)) {
                    submission = _.omit(submission, 'bindData.paymentDetails');
                    serverCall = GatewayRenewalService.updatePaymentPlan;
                    renewalModel = submission;
                } else {
                    isArgumentUpdated = false;
                }
            } else {
                const isValid = wizardData.aspects.valid
                && wizardData.aspects.subtreeValid;
                renewalModel = isValid ? submission : wizardSnapshot.value;
            }
            if (!isArgumentUpdated) {
                return history.push(redirectLobPath);
            }
            return serverCall([renewalModel], authHeader).then(() => {
                history.push(redirectLobPath);
            }).catch(() => {
                showAlert({
                    title: summaryMessages.error,
                    message: summaryMessages.details,
                    status: 'error',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
                history.push('/policies');
            });
        }, _.noop);
    }, [authHeader, hasErrorOccurred, history, showAlert, showConfirm]);

    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.jobNumber');
        const jobID = jobIDFromWizard || jobIDFromLocation;
        const redirectPath = `/renewal/${jobID}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        return showAlert({
            title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        }, _.noop);
    }, [location, showAlert]);

    if (isLoading) {
        return (
            <Loader loaded={!isLoading} />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.cpPolicyRenewalWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <DependencyProvider
                    value={{
                        LoadSaveService: GatewayRenewalService
                    }}
                >
                    <Wizard
                        initialSteps={steps}
                        wizardTitle={title}
                        initialData={initialSubmission}
                        onCancel={handleOnCancel}
                        skipCompletedSteps={toSkip}
                    />

                </DependencyProvider>
            </ErrorBoundary>
        </div>
    );
}

CPPolicyRenewalWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            jobNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withRouter(withAuthenticationContext(CPPolicyRenewalWizard));
