import { defineMessages } from 'react-intl';

export default defineMessages({
    documents: {
        id: 'document.component.document-component.Documents',
        defaultMessage: 'Documents'
    },
    uploadDocuments: {
        id: 'document.component.document-component.Upload Documents',
        defaultMessage: 'Upload Documents'
    },
    documentName: {
        id: 'document.component.document-component.File name',
        defaultMessage: 'File name'
    },
    searchDocuments: {
        id: 'document.component.document-component.Search documents',
        defaultMessage: 'Search Documents'
    },
    dateUploaded: {
        id: 'document.component.document-component.Date Modified',
        defaultMessage: 'Date Uploaded'
    },
    documentRemove: {
        id: 'document.component.document-component.Remove',
        defaultMessage: 'Remove'
    },
    dropaFilehere: {
        id: 'document.component.document-component.dropafilehere',
        defaultMessage: 'drag and drop a file here'
    },
    fileisUploaded: {
        id: 'document.component.document-component.fileisuploaded',
        defaultMessage: 'file is uploaded'
    },
    removeDocument: {
        id: 'document.component.document-component.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'document.component.document-component.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove'
    },
    documentRemoveConfirmYes: {
        id: 'document.component.document-component.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'document.component.document-component.No',
        defaultMessage: 'No'
    },
    noDocsAssociated: {
        id: 'document.component.document-component.There are no documents associated with this policy',
        defaultMessage: 'There are no documents associated with this policy'
    },
    incorrectFileTypeMessage: {
        id: 'document.component.document-component.Incorrect File Type Message',
        defaultMessage: 'File types .pdf, .docx, .png, .jpeg, .xlsx, .txt are accepted'
    },
    uploadToolTip: {
        id: 'document.component.document-component.upload tooltip',
        defaultMessage: 'Volunteer Fire Fighter and/or Volunteer Ambulance rosters only. Any other documents uploaded will not be viewed and should be submitted by way of resource email accounts to the SWIF Policy Support department. Please visit https://www.dli.pa.gov/Businesses/swif for more details.'
    },
    contactSWIFCustomerServiceMessage: {
        id: 'document.component.document-component.contactSWIFCustomerService',
        defaultMessage: 'Please contact the SWIF Customer Service department at 570-963-4635 to obtain a hard copy of a document listed.'

    },
    okText: {
        id: 'document.component.document-component.Okay Text',
        defaultMessage: 'Ok'
    }
});
