import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withValidation, validationPropTypes, validationDefaultProps } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { UserService, OrganizationService } from 'gw-capability-gateway';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { LocalDateUtil } from 'gw-portals-util-js';
import { Loader, withModalContext } from '@jutro/components';
import NewSubmissionPage from './NewSubmission/NewSubmissionPage';
import styles from './NewQuotePage.module.scss';
import metadata from './NewQuotePage.metadata.json5';
import messages from '../NewQuoteAccountSearch.messages';
import './NewQuotePage.messages';
import gatewayMessages from '../../gateway.messages';

class NewQuotePage extends Component {
    static contextType = TranslatorContext;

    state = {
        submissionVM: {},
        producerCode: [],
        isAnExistingAccount: false,
        accountHolderDetails: {},
        accountHolderViewVM: {},
        newAccountCreate: {},
        allOrganisationValue: [],
        selectedOrganisationValue: '',
        showErrors: false,
        isLoading: true
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                accountSearchCriteria: PropTypes.object
            })
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({}),
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        ...validationPropTypes,
        ...validationDefaultProps
    };

    static defaultProps = {
        authUserData: undefined
    }

    localeService = ServiceManager.getService('locale-service');

    componentDidUpdate(prevProps) {
        const { authUserData } = this.props;
        if (_.get(prevProps, 'authUserData.userType') !== _.get(authUserData, 'userType')) {
            this.getAccountHolderDetails();
        }
    }

    updateAccountHolderVM = (accountHolderViewVM) => {
        this.setState({ accountHolderViewVM });
    };

    userIsAgent = () => {
        const { authUserData } = this.props;
        return _.get(authUserData, 'userType') === 'producer';
    };

    getProducerCodes = () => {
        let { producerCode } = this.state;
        const { authHeader } = this.props;
        UserService.getAvailableProducerCodesForCurrentUser(authHeader).then(
            (producerCodeResponse) => {
                producerCode = _.sortBy(producerCodeResponse, 'code').map((value) => {
                    return {
                        code: value.code,
                        name: value.displayValue
                    };
                });
                this.setState({
                    producerCode
                });
            }
        );
    };

    getAccountHolderDetails = async () => {
        let isAnExistingAccount;
        const {
            match: {
                params: { accountNumber }
            }
        } = this.props;
        if (accountNumber) {
            await this.findAccount(accountNumber);
            isAnExistingAccount = true;
        } else {
            const { accountHolderDetails } = this.state;
            const { location, history } = this.props;
            const accountSearchCriteria = _.get(location, 'state.accountSearchCriteria');
            const accountHolder = {
                contactName: '',
                primaryAddress: {}
            };
            if (_.isNil(accountSearchCriteria)) {
                history.push('/');
                return;
            }
            isAnExistingAccount = false;
            const criteriaContactType = accountSearchCriteria.contactType;
            accountHolderDetails.contactType = _.upperFirst(criteriaContactType);
            if (accountHolderDetails.contactType === 'Person') {
                accountHolder.firstName = accountSearchCriteria.firstName;
                accountHolder.lastName = accountSearchCriteria.lastName;
                if (accountSearchCriteria.lastNameKanji) {
                    accountHolder.firstNameKanji = accountSearchCriteria.firstNameKanji;
                    accountHolder.lastNameKanji = accountSearchCriteria.lastNameKanji;
                }
            } else {
                accountHolder.contactName = accountSearchCriteria.contactName;
                if (accountSearchCriteria.contactNameKanji) {
                    accountHolder.contactNameKanji = accountSearchCriteria.contactNameKanji;
                }
            }
            accountHolder.primaryAddress.city = accountSearchCriteria.city;
            accountHolder.primaryAddress.state = accountSearchCriteria.state;
            accountHolder.primaryAddress.postalCode = accountSearchCriteria.postalCode;
            accountHolder.primaryAddress.country = accountSearchCriteria.country;
            if (accountSearchCriteria.cityKanji) {
                accountHolder.primaryAddress.cityKanji = accountSearchCriteria.cityKanji;
            }

            this.setState({
                accountHolderDetails
            }, () => {
                this.setAccountHolderViewVM(accountHolder);
            });

            accountSearchCriteria.contactName = '';
            accountSearchCriteria.lastName = '';
            accountSearchCriteria.firstName = '';
            accountSearchCriteria.city = '';
            accountSearchCriteria.state = '';
            accountSearchCriteria.postalCode = '';
        }
        if (this.userIsAgent()) {
            this.getProducerCodes();
        } else {
            this.getAllOrganisation();
        }
        this.setState({
            isLoading: false,
            isAnExistingAccount
        });
    };

    getAllOrganisation = () => {
        const { authHeader } = this.props;
        let { allOrganisationValue } = this.state;

        OrganizationService.getAvailableOrganizations(authHeader).then(
            (getOrganizationsResponse) => {
                allOrganisationValue = getOrganizationsResponse.map((value) => {
                    return {
                        code: `${value.publicID}`,
                        name: `${value.displayName}`
                    };
                });

                this.setState({
                    allOrganisationValue
                });
            }
        );
    };

    handleValueOrganisation = (value) => {
        let { selectedOrganisationValue } = this.state;
        selectedOrganisationValue = value;
        this.getProducerCodesForAnOrganization(value);
        this.setState({ selectedOrganisationValue });
    };

    getProducerCodesForAnOrganization = (selectedOrganisationValue) => {
        let { producerCode } = this.state;
        const { accountHolderViewVM, allOrganisationValue } = this.state;
        const { authHeader } = this.props;
        let organisationCode = {};

        const selectedOrganisation = selectedOrganisationValue;
        allOrganisationValue.forEach((value) => {
            if (value.code === selectedOrganisation) {
                organisationCode = {
                    displayName: value.name,
                    publicID: value.code
                };
            }
        });
        OrganizationService.getProducerCodeForOrganization(organisationCode, authHeader).then(
            (producerCodeResponse) => {
                producerCode = producerCodeResponse.map((value) => {
                    return {
                        code: value.code,
                        name: value.displayValue
                    };
                });
                _.set(accountHolderViewVM, 'producerCode', '');
                this.setState({
                    producerCode,
                    accountHolderViewVM
                });
            }
        );
    };

    componentDidMount() {
        let { submissionVM } = this.state;
        const { viewModelService } = this.props;
        const model = {
            country: this.localeService.getDefaultCountryCode()
        };
        submissionVM = viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.job.submission.dto.NewSubmissionDTO'
        );
        _.set(submissionVM, 'effectiveDate', LocalDateUtil.today());
        _.set(submissionVM, 'productCode', '');
        this.setState(
            {
                submissionVM
            },
            () => {
                this.getAccountHolderDetails();
            }
        );
    }

    findAccount = async (accountNumber) => {
        const { accountHolderDetails, submissionVM } = this.state;
        const { authHeader, modalContext } = this.props;
        const account = await AccountService.getAccountDetails(accountNumber, authHeader);

        if (account.accountHolder) {
            accountHolderDetails.contactType = account.accountHolder.subtype;
            accountHolderDetails.newAccount = account;
            _.set(submissionVM, 'state', account.accountHolder.primaryAddress.state);
            accountHolderDetails.displayAddressArray = account
                .accountHolder.primaryAddress.displayName
                .split(', ')
                .join('\n');
            _.set(submissionVM, 'accountNumber', account.accountNumber);
            _.set(submissionVM, 'accountPublicId', account.publicID);
        } else {
            modalContext.showConfirm({
                title: gatewayMessages.modelError,
                message: messages.accountTypeErrorMessage,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                return window.history.back();
            }, _.noop);
        }
        this.setState(
            {
                accountHolderDetails,
                submissionVM
            },
            () => {
                this.setAccountHolderViewVM(accountHolderDetails.newAccount);
            }
        );
    };

    setAccountHolderViewVM = (account) => {
        const { viewModelService } = this.props;
        let { accountHolderViewVM } = this.state;
        const { accountHolderDetails } = this.state;
        const viewModel = viewModelService.create(
            account,
            'pc',
            'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
            {
                ProducerCodeRequired: true
            }
        );
        _.set(viewModel, 'isAgentPortalContact', true);
        if (accountHolderDetails.contactType === 'Person') {
            _.set(viewModel, 'subtype.value', 'Person');
        } else {
            _.set(viewModel, 'subtype.value', 'Company');
        }
        accountHolderViewVM = viewModel;
        this.setState({
            accountHolderViewVM
        });
    };

    createNewAccount = async () => {
        const {
            accountHolderDetails,
            newAccountCreate,
            accountHolderViewVM,
            submissionVM,
            modalContext
        } = this.state;
        const { authHeader, history } = this.props;
        let { isAnExistingAccount } = this.state;
        const newAccount = {
            accountHolder: {
                primaryAddress: {
                    addressType: 'business'
                }
            }
        };
        newAccount.accountHolder = _.get(accountHolderViewVM, 'value');
        if (accountHolderDetails.contactType === 'Person') {
            newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            newAccount.accountHolder.primaryAddress.addressType = 'home';
        }
        newAccount.producerCodes = [
            {
                code: _.get(accountHolderViewVM, 'producerCode.value')
            }
        ];
        const newAccountResponse = await AccountService.getOrCreateAccount(newAccount, authHeader);
        if (newAccountResponse.accountNumber > 0) {
            newAccountCreate.newAccount = newAccountResponse;
            newAccountCreate.displayAddressArray = newAccountResponse
                .accountHolder.primaryAddress.displayName
                .split(', ')
                .join('\n');
            _.set(submissionVM, 'accountNumber', newAccountResponse.accountNumber);

            isAnExistingAccount = true;
        } else {
            modalContext.showConfirm({
                title: messages.accountTypeError,
                message: messages.newAccountCreateErrorMessage,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                return window.history.back();
            }, _.noop);
        }
        this.setState(
            {
                newAccountCreate,
                isAnExistingAccount,
                submissionVM
            },
            () => {
                return history.push(`/new-quote/${_.get(submissionVM, 'accountNumber.value')}`);
            }
        );
    };

    handleValidation = () => {
        this.setState({ showErrors: true });
    };

    handleCancel = () => {
        const { history, modalContext } = this.props;
        modalContext.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            return history.push('/accounts');
        }, _.noop);
    };

    updateSubmission = (submissionVM) => {
        this.setState({
            submissionVM
        });
    };

    render() {
        const {
            accountHolderViewVM,
            accountHolderDetails,
            isAnExistingAccount,
            submissionVM,
            newAccountCreate,
            allOrganisationValue,
            selectedOrganisationValue,
            showErrors,
            isLoading
        } = this.state;
        const isAgent = this.userIsAgent();
        const { producerCode } = this.state;
        const translator = this.context;
        const { authHeader, setComponentValidation, isComponentValid } = this.props;
        let contactTypeValue = '';
        let subTypeLabel = '';
        if (_.get(accountHolderViewVM, 'subtype.value') === 'Person') {
            contactTypeValue = translator(messages.contactTypePersonal);
            subTypeLabel = translator(messages.person);
        } else if (_.get(accountHolderViewVM, 'subtype.value') === 'Company') {
            contactTypeValue = translator(messages.contactTypeCommercial);
            subTypeLabel = translator(messages.company);
        }
        const accountType = !!(
            _.get(accountHolderViewVM, 'subtype')
            && _.get(accountHolderViewVM, 'subtype.value') === 'Person'
        );
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showOptional: true
            },
            newQuoteDetailsContainer: {
                visible: !isAnExistingAccount
            },
            policyDetailsContainer: {
                visible: isAnExistingAccount
            },
            commercialContainer: {
                visible: !accountType
            },
            personalContainer: {
                visible: accountType
            },
            producerCode: {
                availableValues: producerCode
            },
            contactType: {
                value: contactTypeValue
            },
            organisation: {
                visible: !isAgent,
                availableValues: allOrganisationValue,
                onValueChange: this.handleValueOrganisation,
                value: selectedOrganisationValue
            },
            addressDetails: {
                label: subTypeLabel,
                value: _.get(newAccountCreate, 'displayAddressArray')
                    ? `${_.get(newAccountCreate, 'newAccount.accountHolder.displayName')}\n${_.get(
                        newAccountCreate,
                        'displayAddressArray'
                    )}`
                    : `${_.get(
                        accountHolderDetails,
                        'newAccount.accountHolder.displayName'
                    )}\n${_.get(accountHolderDetails, 'displayAddressArray')}`
            },
            accountNumberDetails: {
                value: _.get(submissionVM, 'accountNumber.value')
            },
            contactTypeDetails: {
                value: contactTypeValue
            },
            newSubmission: {
                visible: true,
                isAnExistingAccount: isAnExistingAccount,
                submissionVM: submissionVM,
                postalCode:
                    _.get(newAccountCreate.newAccount, 'accountHolder.primaryAddress.postalCode')
                    || _.get(
                        accountHolderDetails.newAccount,
                        'accountHolder.primaryAddress.postalCode'
                    ),
                updateSubmissionVM: this.updateSubmission,
                producerCodeValue: isAgent ? producerCode : [],
                isAgent: isAgent,
                allOrganisationValue: allOrganisationValue,
                authHeader: authHeader
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                newsubmission: NewSubmissionPage
            },
            resolveCallbackMap: {
                submitCreateAccountForm: isComponentValid
                    ? this.createNewAccount
                    : this.handleValidation,
                onCancel: this.handleCancel
            }
        };
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                accountHolderViewVM,
                id,
                path,
                overrideProps
            );
        };

        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountHolderViewVM}
                    onModelChange={this.updateAccountHolderVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    showErrors={showErrors}
                />
            </div>
        );
    }
}
export const NewQuoteComponent = withModalContext(NewQuotePage);
export default withValidation(
    withRouter(withViewModelService(withAuthenticationContext(withModalContext(NewQuotePage))))
);
