import React, { Component } from 'react';
import { Icon, Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { Grid } from '@jutro/layout';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { AccountService } from 'gw-capability-gateway-policy';
import { UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { AccountClaims } from 'gw-capability-gateway-claim-react';
import { AccountCommission } from 'gw-capability-gateway-commission-react';
import { AccountTimeline } from 'gw-capability-journey-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import CommonRoutes from '../../Common/CommonRouting';
import metadata from './AccountDetails.metadata.json5';
import styles from './AccountDetails.module.scss';
import pageRouting from '../../Common/Accounts-config.json5';
import '../../Policies/Policies.messages';

const { capabilitiesConfig } = appConfig;

class AccountDetails extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                accountNumber: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]),
                type: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.string,
            pathname: PropTypes.string
        }).isRequired,

        isLoggedIn: PropTypes.bool.isRequired,
        authHeader: PropTypes.shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentView: undefined,
            journeyTurnedOn: false,
            commissionTurnedOn: false,
            accountData: {},
            accountBillingData: {},
            isLoading: true
        };
    }

    componentDidMount() {
        this.getAccountDetails();
    }

    componentDidUpdate(preProp, preState) {
        const {
            location: { pathname },
            match: {
                params: { accountNumber }
            }
        } = this.props;
        if (preProp.location.pathname.indexOf(accountNumber) === -1) {
            this.getAccountDetails();
        }
        const currentPage = _.last(pathname.split('/'));
        if (preState.currentView !== currentPage) {
            this.updateCurrentView(currentPage);
        }
    }

    getAccountDetails = () => {
        this.setState({
            isLoading: true
        });
        const {
            isLoggedIn,
            authHeader,
            match: {
                params: { accountNumber }
            },
            location: { state: redirectPath }
        } = this.props;
        if (isLoggedIn) {
            this.getJourneyTurnedOn(accountNumber, authHeader);
            this.getCommissionTurnedOn(authHeader);
            const getPathname = _.get(this.props, 'location.pathname') || '';
            const getActiveTile = getPathname.replace(/\/accounts\/\d+\//g, '');

            AccountService.getAccountDetails(accountNumber, authHeader)
                .then((accountDetailsResponse) => {
                    this.setState({
                        accountData: accountDetailsResponse,
                        currentView: redirectPath || getActiveTile || 'summary'
                    });
                    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
                        AccountService.getAccountBillingData(accountNumber, authHeader).then(
                            (accountBillingData) => {
                                this.setState({
                                    accountBillingData: accountBillingData,
                                    isLoading: false
                                });
                            }
                        ).finally(() => {
                            this.setState({
                                isLoading: false
                            });
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                });
            AccountService.addRecentlyViewedAccounts(accountNumber, authHeader);
        }
    };

    getJourneyTurnedOn = async (accountNumber, authHeader) => {
        const hasUserAccess = await UserService
            .hasUserAccessToAccountPolicies(accountNumber, authHeader);
        this.setState({ journeyTurnedOn: hasUserAccess });
    };

    getCommissionTurnedOn = async (authHeader) => {
        const permissionDTO = {
            permission: 'viewcommissions'
        };
        const commissionTurnedOn = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ commissionTurnedOn });
    };

    getAccountHolderNameandNumber = () => {
        const { accountData } = this.state;
        if (_.isEmpty(accountData)) {
            return null;
        }
        const iconName = accountData.accountHolder.subtype === 'Person' ? 'mi-account_circle' : 'mi-business';
        const accounHolderName = `${accountData.accountHolder.displayName} (${accountData.accountNumber})`;
        return (
            <Grid
                columns={['0.01fr', '1fr']}
                alignItems="middle"
                vgap="small"
                hgap="small"
            >
                <Icon className={styles.accountIcon} icon={iconName} />
                {accounHolderName}
            </Grid>
        );
    };

    getBillingPaymentIcon = () => {
        const { accountBillingData } = this.state;
        let isDelinquent = false;
        if (!_.isEmpty(accountBillingData) && !_.isEmpty(accountBillingData.ownedPolicies)) {
            isDelinquent = accountBillingData.ownedPolicies.length > 0
                && _.filter(accountBillingData.ownedPolicies, {
                    isDelinquent: true
                }).length > 0;
        }
        if (!_.isEmpty(accountBillingData.unownedPolicies)) {
            isDelinquent = accountBillingData.unownedPolicies.length > 0
                && _.filter(accountBillingData.unownedPolicies, {
                    isDelinquent: true
                }).length > 0;
        }
        return isDelinquent ? 'mi-warning' : 'mi-check';
    };

    getRoutesWithFeature() {
        const routes = [...pageRouting];
        this.commissionsTurnedOn(routes);
        this.journeyFeatureTurnedOn(routes);
        this.billingFeatureTurnedOn(routes);
        this.claimFeatureTurnedOn(routes);
        return routes;
    }

    journeyFeatureTurnedOn = (routes) => {
        const { journeyTurnedOn } = this.state;
        if (journeyTurnedOn && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'journey' })) {
            const timelineRoute = {
                path: '/timeline',
                component: AccountTimeline
            };
            routes.push(timelineRoute);
        }
    };

    commissionsTurnedOn = (routes) => {
        const { commissionTurnedOn } = this.state;
        if (
            commissionTurnedOn
            && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
        ) {
            const route = {
                path: '/commission',
                component: AccountCommission
            };
            routes.push(route);
        }
    };

    billingFeatureTurnedOn = (routes) => {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            const billingRoute = {
                path: '/billingAndPayment',
                componentPath: 'Accounts/BillingAndPayment/BillingAndPayment'
            };
            routes.push(billingRoute);
        }
    };

    claimFeatureTurnedOn = (routes) => {
        if (this.isClaimsTurnedOn()) {
            const claimRoute = {
                path: '/claims',
                component: AccountClaims
            };
            routes.push(claimRoute);
        }
    };

    updateCountInfo = (type) => {
        const { accountData } = this.state;
        const currentCount = _.get(accountData, `numberOf${type}`);
        _.set(accountData, `numberOf${type}`, currentCount + 1);
        this.setState({ accountData });
    };

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    getMetadataImplementation = () => {
        const { journeyTurnedOn, commissionTurnedOn, accountData } = this.state;
        const { currentView } = this.state;
        const overrides = {
            [currentView]: {
                active: true
            },
            timeline: {
                visible: journeyTurnedOn,
                active: currentView === 'timeline'
            },
            commission: {
                active: currentView === 'commission',
                visible: commissionTurnedOn && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
            },
            billingAndPayment: {
                active: currentView === 'billingAndPayment',
                tileIcon: this.getBillingPaymentIcon(),
                visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })
            },
            claims: {
                active: currentView === 'claims',
                visible: this.isClaimsTurnedOn()
            },
            accountHeaderTitle: {
                content: this.getAccountHolderNameandNumber()
            }
        };
        const resolvers = {
            resolveCallbackMap: {
                getCell: this.getCell,
                getAccount: this.getAccount,
                getPolicyLink: this.getPolicyLink,
                handleTilesOnClick: this.handleTilesOnClick,
                handleValueChange: this.handleValueChange,
                handleLandingTilesOnClick: this.handleLandingTilesOnClick
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={accountData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    };

    isClaimsTurnedOn = () => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' });
    };

    updateCurrentView(currentPage) {
        const excludePage = ['contactDetails'];
        if (excludePage.indexOf(currentPage) !== -1) {
            return;
        }
        this.setState({
            currentView: currentPage
        });
    }

    render() {
        const { accountData, isLoading, accountBillingData } = this.state;
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }
        const {
            match: { url }
        } = this.props;
        const accountActivitiesData = {
            accountData: accountData,
            getUpdateCountInfo: this.updateCountInfo
        };

        return (
            <>
                {this.getMetadataImplementation()}
                <CommonRoutes
                    steps={this.getRoutesWithFeature()}
                    basePath={url}
                    accountDetailsData={accountData}
                    accountActivitiesData={accountActivitiesData}
                    billingData={accountBillingData}
                />
            </>
        );
    }
}
export const AccountsSummary = AccountDetails;
export default withRouter(withAuthenticationContext(AccountDetails));
