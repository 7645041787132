import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class GatewayFNOLService {
    static submitClaim(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayFnol'),
            'submitClaim',
            [data],
            additionalHeaders
        );
    }

    static getFNOLDetails(policyNumber, data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayFnol'),
            'createClaim',
            [policyNumber, data],
            additionalHeaders
        );
    }

    static getClaim(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayFnol'),
            'getClaim',
            [claimNumber],
            additionalHeaders
        );
    }

    static saveFNOLDetails(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayFnol'),
            'saveClaim',
            [data],
            additionalHeaders
        );
    }

    static searchPolicies(policySearchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayFnolPolicy'), 'searchPolicies', [policySearchCriteria], additionalHeaders);
    }

    static getPolicySummaryRiskUnits(policyNumber, lossDate, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayFnolPolicy'), 'getPolicySummaryRiskUnits', [policyNumber, lossDate], additionalHeaders);
    }
}
