import { defineMessages } from 'react-intl';

export default defineMessages({
    policyContactDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Contacts associated with this policy only',
        defaultMessage: 'Contacts associated with this policy only.'
    },
    accountContactsDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Contacts associated with this account only',
        defaultMessage: 'Contacts associated with this account only.'
    },
    contact: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Contact',
        defaultMessage: 'Contact'
    },
    contactName: {
        id: 'gateway.contacts.common.components.Contacts.Contact name',
        defaultMessage: 'Contact name'
    },
    phoneNumber: {
        id: 'gateway.contacts.common.components.Contacts.Phone number',
        defaultMessage: 'Phone number'
    },
    emailAddress: {
        id: 'gateway.contacts.common.components.Contacts.Email address',
        defaultMessage: 'Email address'
    },
    role: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Roles',
        defaultMessage: 'Roles'
    },
    allRoles: {
        id: 'gateway.contacts.common.views.roles.All Roles',
        defaultMessage: 'All Roles'
    },
    accountHolder: {
        id: 'gateway.contacts.common.views.roles.AccountHolder',
        defaultMessage: 'Account Holder'
    },
    additionalInsured: {
        id: 'gateway.contacts.common.views.roles.AdditionalInsured',
        defaultMessage: 'Additional Insured'
    },
    additionalInterest: {
        id: 'gateway.contacts.common.views.roles.AdditionalInterest',
        defaultMessage: 'Additional Interest'
    },
    driver: {
        id: 'gateway.contacts.common.views.roles.Driver',
        defaultMessage: 'Driver'
    },
    laborClient: {
        id: 'gateway.contacts.common.views.roles.LaborClient',
        defaultMessage: 'Labor Client'
    },
    laborContractor: {
        id: 'gateway.contacts.common.views.roles.LaborContractor',
        defaultMessage: 'Labor Contractor'
    },
    namedInsured: {
        id: 'gateway.contacts.common.views.roles.NamedInsured',
        defaultMessage: 'Named Insured'
    },
    ownerOrOfficer: {
        id: 'gateway.contacts.common.views.roles.OwnerOfficer',
        defaultMessage: 'Owner/Officer'
    },
    brokerOfRecord: {
        id: 'gateway.contacts.common.views.roles.BrokerOfRecord_PAExt',
        defaultMessage: 'Broker of Record'
    },
    accountAlternativeEmployeer: {
        id: 'gateway.contacts.common.views.roles.AccountAlternativeEmployeer_PAExt',
        defaultMessage: 'Account Alternative Employeer'
    },
    auditContact: {
        id: 'gateway.contacts.common.views.roles.AuditContact_PAExt',
        defaultMessage: 'Audit Contact'
    },
    certificateHolder: {
        id: 'gateway.contacts.common.views.roles.CertificateHolder_Ext',
        defaultMessage: 'Certificate Holder'
    },
    DBARole: {
        id: 'gateway.contacts.common.views.roles.DBARole_SP',
        defaultMessage: 'DBA Role'
    },
    leasingGroupRole: {
        id: 'gateway.contacts.common.views.roles.LeasingGroupRole_PAExt',
        defaultMessage: 'Leasing Group Role'
    },
    leasingGroupClient: {
        id: 'gateway.contacts.common.views.roles.LeasingGroupClient_PAExt',
        defaultMessage: 'Leasing Group Client'
    },
    premiumFinanceCompany: {
        id: 'gateway.contacts.common.views.roles.PremiumFinanceCompany_PAExt',
        defaultMessage: 'Premium Finance Company'
    },
    policyAddlInsured: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyAddlInsured',
        defaultMessage: 'Additional Insured'
    },
    policyAddlInterest: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyAddlInterest',
        defaultMessage: 'Additional Interest'
    },
    policyAddlNamedInsured: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyAddlNamedInsured',
        defaultMessage: 'Additional Named Insured'
    },
    policyBillingContact: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyBillingContact',
        defaultMessage: 'Billing Contact'
    },
    policyDriver: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyDriver',
        defaultMessage: 'Driver'
    },
    policyLaborClient: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyLaborClient',
        defaultMessage: 'Labor Client'
    },
    policyLaborContractor: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyLaborContractor',
        defaultMessage: 'Labor Contractor'
    },
    policyOwnerOfficer: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyOwnerOfficer',
        defaultMessage: 'Owner/Officer'
    },
    policyPriNamedInsured: {
        id: 'gateway.contacts.common.views.policy.roles.PolicyPriNamedInsured',
        defaultMessage: 'Primary Named Insured'
    },
    policySecNamedInsured: {
        id: 'gateway.contacts.common.views.policy.roles.PolicySecNamedInsured',
        defaultMessage: 'Secondary Named Insured'
    },
    wC7PolicyContactRole: {
        id: 'gateway.contacts.common.views.policy.roles.WC7PolicyContactRole',
        defaultMessage: 'Policy Contact Role'
    },
    wC7PolicyLaborClient: {
        id: 'gateway.contacts.common.views.policy.roles.WC7PolicyLaborClient',
        defaultMessage: 'Policy Labor Client'
    },
    wC7PolicyLaborContractor: {
        id: 'gateway.contacts.common.views.policy.roles.WC7PolicyLaborContractor',
        defaultMessage: 'Policy Labor Contractor'
    },
    wC7ExcludedOwnerOfficer: {
        id: 'gateway.contacts.common.views.policy.roles.WC7ExcludedOwnerOfficer',
        defaultMessage: 'Excluded Owner Officer'
    },
    wC7IncludedOwnerOfficer: {
        id: 'gateway.contacts.common.views.policy.roles.WC7IncludedOwnerOfficer',
        defaultMessage: 'Included Owner Officer'
    },
    wC7LaborContact: {
        id: 'gateway.contacts.common.views.policy.roles.WC7LaborContact',
        defaultMessage: 'Labor Contact'
    },
    wC7PolicyOwnerOfficer: {
        id: 'gateway.contacts.common.views.policy.roles.WC7PolicyOwnerOfficer',
        defaultMessage: 'Policy Owner Officer'
    },
    involvedWith: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Involved With',
        defaultMessage: 'Involved With'
    },
    activePolicies: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Policies',
        defaultMessage: 'Active Policies'
    },
    activePolicy: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Policy',
        defaultMessage: 'Active Policy'
    },
    account: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Account',
        defaultMessage: 'Account'
    },
    relatedBy: {
        id: 'gateway.contacts.AccountRelationshipType.Related by',
        defaultMessage: 'Related by'
    },
    relationshipTypeAll: {
        id: 'gateway.contacts.AccountRelationshipType.All',
        defaultMessage: 'All'
    },
    child: {
        id: 'gateway.contacts.AccountRelationshipType.typekey.parent',
        defaultMessage: 'Parent'
    },
    parent: {
        id: 'gateway.contacts.AccountRelationshipType.typekey.child',
        defaultMessage: 'Child'
    },
    commonOwner: {
        id: 'gateway.contacts.AccountRelationshipType.typekey.commonowner',
        defaultMessage: 'Common Ownership'
    },
    commonContact: {
        id: 'gateway.contacts.AccountRelationshipType.Common Contact',
        defaultMessage: 'Common Contact'
    },
    allRelationshipDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.RelationshipDesc.$all',
        defaultMessage: 'These contacts are on accounts that are related in any way to the account: {accountName} {accountNumber}.'
    },
    parentRelationshipDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.RelationshipDesc.child',
        defaultMessage: 'These contacts are on accounts that are parents of the account: {accountName} {accountNumber}.'
    },
    childRelationshipDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.RelationshipDesc.parent',
        defaultMessage: 'These contacts are on accounts that are children of the account: {accountName} {accountNumber}.'
    },
    commonOwnerRelationshipDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.RelationshipDesc.commonowner',
        defaultMessage: 'These contacts are on accounts that share common ownership with the account: {accountName} {accountNumber}.'
    },
    commonContactsRelationshipDescription: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.RelationshipDesc.$common',
        defaultMessage: 'These contacts are on accounts that have a contact in common with the account: {accountName} {accountNumber}. Each contact in common is indicated as such.'
    }
});
