import React, {
    useContext, useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import metadata from './ProcessPaymentPopUp.metadata.json5';
import messages from './ProcessPaymentPopUp.messages';

function ProcessPaymentPopUp(props) {
    const {
        paymentInformation,
        onReject,
        onResolve,
        policyNumber,
        authHeader,
        accountNumber,
        billingData,
        history,
    } = props;
    const [isOpen, setViewModel] = useState(true);
    const translator = useContext(TranslatorContext);

    const handleReject = () => {
        GatewayBillingService.createPaymentReportRecord(policyNumber, 'PE', authHeader)
            .catch(_.noop);
        setTimeout(() => {
            onReject();
        }, 500);
        setTimeout(() => {
            setViewModel(false);
        }, 500);
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({
            pathname: nextPath,
            accountDetailsData: {
                accountNumber: accountNumber
            },
            billingDataFromMakePayment: billingData
        });
    };

    const handleSave = useCallback(() => {
        GatewayBillingService.createPaymentReportRecord(policyNumber, 'PE', authHeader)
            .catch(_.noop);
        setTimeout(() => {
            onResolve();
        }, 500);
        setTimeout(() => {
            setViewModel(false);
        }, 500);
    });

    return (
        <ModalNext isOpen={isOpen}>
            <form action={paymentInformation.url} method="post" target="payeezy_page" onSubmit={onResolve}>
                <ModalHeader title="Payment Confirmation" onClose={handleReject} />
                <ModalBody>
                    <>
                        <p>
                            {translator(messages.payeezyServiceFeeMessage)}
                        </p>
                        <p>
                            {translator(messages.payeezyAttentionMessage)}
                        </p>
                        <p>
                            {translator(messages.payeezyCreditCardPaymentAlternatives)}
                        </p>
                        <p>
                            {translator(messages.paymentAlternativesContactInformation)}
                        </p>
                        <input type="hidden" name="x_login" value={paymentInformation.xlogin} />
                        <input type="hidden" name="x_fp_sequence" value={paymentInformation.sequence} />
                        <input type="hidden" name="x_fp_timestamp" value={paymentInformation.timeStamp} />
                        <input type="hidden" name="x_amount" value={paymentInformation.totalAmountToPay} />
                        <input type="hidden" name="x_fee_amount" value={paymentInformation.convenienceFee.amount} />
                        <input type="hidden" name="x_fp_hash" value={paymentInformation.hash} size="40" />
                        <input type="hidden" name="x_show_form" value="PAYMENT_FORM" />
                        <input type="hidden" name="Customer_Ref" value={`E${paymentInformation.customerRef}`} />
                        <input type="hidden" name="x_invoice_num" value={`E${paymentInformation.customerRef}`} />
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleReject} type="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} actionType="submit">
                        Confirm
                    </Button>
                </ModalFooter>
            </form>
        </ModalNext>
    );
}

ProcessPaymentPopUp.propTypes = {
    title: PropTypes.string.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
};

export default ProcessPaymentPopUp;
