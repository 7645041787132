import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import { CancellationService } from 'gw-capability-gateway';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from 'gw-platform-translations';
import NotesComponent from '../../Components/NotesComponent/NotesComponent';
import metadata from './Notes.metadata.json5';
import messages from './Notes.messages';
import gatewayMessages from '../../gateway.messages';

class CancellationNotes extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string,
                getCancellationSummaryCount: PropTypes.func
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    state = {
        notesData: [],
        loaded: true
    };

    componentDidMount() {
        this.getNotes();
    }

    handleError = (title, message) => {
        const { modalContext } = this.props;
        return modalContext.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    };

    getNotes = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber, getCancellationSummaryCount }
            }
        } = this.props;
        this.setState({ loaded: false });
        try {
            const getAvailableNotesData = await CancellationService.getNotesForCancellation(
                [jobNumber],
                authHeader
            );
            this.setState({
                notesData: getAvailableNotesData,
                loaded: true
            });
            getCancellationSummaryCount();
        } catch (e) {
            this.handleError(gatewayMessages.modalError, gatewayMessages.errorLoadingNotes);
        }
    };

    createNote = async (data) => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber }
            }
        } = this.props;
        this.setState({ loaded: false });
        try {
            const cancellationNoteData = await CancellationService.createNoteForCancellation(
                [jobNumber, data],
                authHeader
            );
            if (!_.isEmpty(cancellationNoteData)) {
                this.getNotes();
            }
        } catch (e) {
            this.handleError(gatewayMessages.modalError, gatewayMessages.errorLoadingNotes);
        }
    };

    render() {
        const { notesData, loaded } = this.state;
        const translator = this.context;
        const overrides = {
            cancellationNotes: {
                initialNotesData: notesData,
                createNote: this.createNote,
                noDataMessage: translator(messages.noCancellationNotes),
                visible: loaded,
                openActivitiesNote: false
            },
            notesLoader: {
                loaded: loaded
            }
        };
        const resolvers = {
            resolveComponentMap: {
                notescomponent: NotesComponent
            }
        };
        // eslint-disable-next-line max-len
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

export default withAuthenticationContext(withModalContext(CancellationNotes));
