import React, { useCallback } from 'react';
import _ from 'lodash';

import { UnderwritingIssues } from 'gw-components-platform-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';

import { useValidation } from 'gw-portals-validation-react';

import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CostBoxComponent } from '../../components/CostBoxComponent/CostBoxComponent';
import RatesAndCostsComponent from '../../components/RatesAndCostsComponent/RatesAndCostsComponent';
import CoverageTermsTableComponent from '../../components/CoverageTermsTableComponent/CoverageTermsTableComponent';
import styles from './QuotePage.module.scss';

import metadata from './QuotePage.metadata.json5';

const BLOCKS_QUOTES = [UnderwritingIssues.BLOCKS_QUOTE, UnderwritingIssues.BLOCKS_QUOTE_RELEASE];

function QuotePage(props) {
    const {
        wizardData: submissionVM, steps, jumpTo, underwritingIssues
    } = props;
    const applications = ['producer', 'csr'];
    const showUWIssues = applications.includes(config.persona);
    const { onValidate, isComponentValid } = useValidation('QuotePage');

    const generateClauseOverrides = () => {
        const coveragesPath = 'lobData.wc7WorkersComp.clauses.coverages';
        const coverages = _.get(submissionVM.value, coveragesPath);
        const filteredCoverageData = coverages.filter((coverage) => coverage.terms.length > 0);
        // eslint-disable-next-line default-param-last
        const overrides = filteredCoverageData.map((cov = {}, index) => {
            const resultData = {
                [`coverageTableHeader${index}`]: {
                    visible: true
                },
                [`coverageHeader${index}`]: {
                    content: cov.description
                }
            };
            return resultData;
        });
        return Object.assign({}, ...overrides);
    };

    const editQuote = useCallback(
        (destinationPage) => {
            const indexOfDestinationPage = _.findIndex(
                steps,
                ({ path }) => path === destinationPage
            );
            jumpTo(indexOfDestinationPage);
        },
        [jumpTo, steps]
    );

    const findBlockingPoint = () => {
        return underwritingIssues.some((offeringIssue) => {
            return _.includes(BLOCKS_QUOTES, offeringIssue.currentBlockingPoint);
        });
    };
    const hasQuotes = !!_.get(submissionVM, 'quoteData.value');

    const overrideProps = {
        ratesAndCostsComponentDivContainer: {
            visible: hasQuotes
        },
        underWritingIssues: {
            underwritingIssues: underwritingIssues,
            blockQuote: findBlockingPoint(),
            quoteID: _.get(submissionVM, 'quoteID.value'),
            visible: showUWIssues
        },
        ratesAndCostsComponentContainer: {
            goToEmployeesAndLocations: () => editQuote('/employees-and-locations'),
            visible: hasQuotes
        },
        coverageTableGridContainer: {
            visible: hasQuotes
        },
        costBoxComponentContainer: {
            visible: hasQuotes
        },
        ...generateClauseOverrides()
    };

    const resolvers = {
        resolveComponentMap: {
            costboxcomponent: CostBoxComponent,
            ratesandcostscomponent: RatesAndCostsComponent,
            coveragetermstablecomponent: CoverageTermsTableComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            goToCoverages: () => editQuote('/coverages-and-exclusions')
        }
    };

    return (
        <WizardPage
            disableNext={!isComponentValid}
            showPrevious={hasQuotes}
            showNext={hasQuotes}
            showCancel={hasQuotes}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;

export default QuotePage;
