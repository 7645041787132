import React, {
    useCallback, useContext, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { PolicyChangeDetailsComponent } from 'gw-capability-policyjob-react';
import {
    ConfirmationQuoteDetailsPage,
    CPBOPPaymentPage
} from 'gw-capability-policychange-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './QuotePage.metadata.json5';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';

const paymentDetails = {
    id: 'paymentDetails',
    path: '/payment-details',
    component: CPBOPPaymentPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.paymentDetails
};

const confirmation = {
    path: '/confirmation',
    component: ConfirmationQuoteDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    }
};

function QuotePage(props) {
    const {
        wizardData: submissionVM, updateWizardData, changeNextSteps, authHeader
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const [isLoading, updateLoadingState] = useState(false);

    const setPolicyData = useMemo(() => {
        return {
            effectiveDate: _.get(submissionVM.value, 'baseData.effectiveDate'),
            policyPeriod: {
                startDate: _.get(submissionVM.value, 'baseData.periodStartDate'),
                endDate: _.get(submissionVM.value, 'baseData.periodEndDate')
            },
            previousPremium: _.get(submissionVM.value, 'previousTotalCost'),
            changeInCost: _.get(submissionVM.value, 'transactionCost'),
            newPremium: _.get(submissionVM.value, 'totalCost'),
            taxes: _.get(submissionVM.value, 'taxes'),
            newPremiumBeforeTaxes: {
                amount:
                    _.get(submissionVM.value, 'totalCost.amount')
                    - _.get(submissionVM.value, 'taxes.amount'),
                currency: _.get(submissionVM.value, 'taxes.currency')
            }
        };
    }, [submissionVM]);

    useEffect(() => {
        const changeInCost = _.get(submissionVM.value, 'transactionCost.amount');

        if (changeInCost > 0) {
            const newSteps = [paymentDetails, confirmation];
            changeNextSteps(newSteps);
        } else {
            const newSteps = [confirmation];
            changeNextSteps(newSteps);
        }
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNext = useCallback(async () => {
        updateLoadingState(true);
        const changeInCost = _.get(submissionVM.value, 'transactionCost.amount');
        if (changeInCost <= 0) {
            const jobID = _.get(submissionVM.value, 'jobID');
            submissionVM.value = await EndorsementService.bind(
                [jobID, { paymentMethod: 'redistribute' }],
                authHeader
            );
            updateWizardData(submissionVM);
        }
        updateLoadingState(false);
        return submissionVM;
    }, [EndorsementService, authHeader, submissionVM, updateWizardData]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            policychangedetailscomponent: PolicyChangeDetailsComponent
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            required: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        bopPolicyChangeDetails: {
            policyDetails: setPolicyData,
        }
    };

    return (
        <WizardPage
            disableNext={isLoading}
            onNext={handleNext}
            nextLabel={
                _.get(submissionVM.value, 'transactionCost.amount') > 0
                    ? translator(messages.next)
                    : translator(messages.finish)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default withAuthenticationContext(QuotePage);
