import { defineMessages } from 'react-intl';

export default defineMessages({
    wc7Qualification: {
        id: 'quoteandbind.wc7.views.qualification.Qualification',
        defaultMessage: 'Qualification'
    },
    wc7AdditionalName: {
        id: 'quoteandbind.wc7.views.qualification.Additional Named Insured',
        defaultMessage: 'Additional Named Insured'
    },
    wc7AddNameInsured: {
        id: 'quoteandbind.wc7.views.qualification.Add Named Insured',
        defaultMessage: 'Add Named Insured'
    },
    wc7AddAnotherNameInsured: {
        id: 'quoteandbind.wc7.views.qualification.Add Another Additional Named Insured?',
        defaultMessage: 'Add Another Additional Named Insured?'
    },
    wc7NamedInsured: {
        id: 'quoteandbind.wc7.views.qualification.Named Insured',
        defaultMessage: 'Named Insured'
    },
    wc7NameInsuredType: {
        id: 'quoteandbind.wc7.views.qualification.Named Insured Type',
        defaultMessage: 'Named Insured Type'
    },
    wc7QualificationPerson: {
        id: 'quoteandbind.wc7.views.qualification.Person',
        defaultMessage: 'Person'
    },
    wc7QualificationCompany: {
        id: 'quoteandbind.wc7.views.qualification.Company',
        defaultMessage: 'Company'
    },
    wc7AdditionalInsuredPersonFirstName: {
        id: 'quoteandbind.wc7.views.qualification.First Name',
        defaultMessage: 'First Name'
    },
    wc7AdditionalInsuredPersonLastName: {
        id: 'quoteandbind.wc7.views.qualification.Last Name',
        defaultMessage: 'Last Name'
    },
    wc7AdditionalInsuredCompanyName: {
        id: 'quoteandbind.wc7.views.qualification.Company Name',
        defaultMessage: 'Company Name'
    },
    wc7AdditionalInsuredCountry: {
        id: 'quoteandbind.wc7.views.qualification.Country',
        defaultMessage: 'Country'
    },
    wc7AdditionalInsuredAddress: {
        id: 'quoteandbind.wc7.views.qualification.Address',
        defaultMessage: 'Address'
    },
    wc7AdditionalInsuredCity: {
        id: 'quoteandbind.wc7.views.qualification.City',
        defaultMessage: 'City'
    },
    wc7AdditionalInsuredZipCode: {
        id: 'quoteandbind.wc7.views.qualification.ZIP Code',
        defaultMessage: 'ZIP Code'
    },
    wc7AdditionalInsuredAddressType: {
        id: 'quoteandbind.wc7.views.qualification.Address Type',
        defaultMessage: 'Address Type'
    },
    wc7RemoveNamedInsured: {
        id: 'quoteandbind.wc7.views.qualification.Remove Named Insured?',
        defaultMessage: 'Remove Named Insured?'
    },
    wc7AddIntRemovalMsg: {
        id: 'quoteandbind.wc7.views.qualification.AddIntRemovalMsg',
        defaultMessage: 'Are you sure you want to remove this named insured from the list?'
    },
    wc7DoesHaveCompInsurance: {
        id: 'quoteandbind.wc7.views.qualification.Does business currently have Workers\' Compensation insurance?',
        defaultMessage: 'Does business currently have Workers\' Compensation insurance?'
    },
    wc7CarrierName: {
        id: 'quoteandbind.wc7.views.qualification.Who is the insurance company?',
        defaultMessage: 'Who is the insurance company?'
    },
    wc7EffectiveDate: {
        id: 'quoteandbind.wc7.views.qualification.Previous Insurance Effective Date',
        defaultMessage: 'Previous Insurance Effective Date'
    },
    wc7ExpirationDate: {
        id: 'quoteandbind.wc7.views.qualification.Previous Insurance Expiration Date',
        defaultMessage: 'Previous Insurance Expiration Date'
    }
});
