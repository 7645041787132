import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage, Preferences } from 'gw-pages-platform-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';
import { PEWC7Wizard } from 'gw-capability-gateway-quoteandbind-wc7-react';
import { PEPAWizard } from 'gw-capability-gateway-quoteandbind-pa-react';
import { PEHOWizard } from 'gw-capability-gateway-quoteandbind-ho-react';
import { PEBOPWizard } from 'gw-capability-gateway-quoteandbind-bop-react';
import { PECPWizard } from 'gw-capability-gateway-quoteandbind-cp-react';
import { PAPolicyChangeWizard } from 'gw-capability-policychange-pa-react';
import { HOPolicyChangeWizard } from 'gw-capability-policychange-ho-react';
import { CPPolicyChangeWizard } from 'gw-capability-gateway-policychange-cp-react';
import { BOPPolicyChangeWizard } from 'gw-capability-gateway-policychange-bop-react';
import { CPPolicyRenewalWizard } from 'gw-capability-gateway-policyrenewal-cp-react';
import { BOPPolicyRenewalWizard } from 'gw-capability-gateway-policyrenewal-bop-react';
import FaqPage from 'gw-capability-faq-react';
import { KnockOutPage, CapabilityRoute } from 'gw-components-platform-react';
import { ClaimDetails, CreateNote } from 'gw-capability-claim-react';
import { ClaimsLanding } from 'gw-capability-gateway-claim-react';
import { CommissionLanding } from 'gw-capability-gateway-commission-react';
import PELandingPage, {
    Accounts,
    Policies,
    Activities,
    Analytics,
    AccountDetails,
    PolicyDetails,
    NewQuoteAccountSearch,
    NewQuotePage,
    SearchResults,
    QuoteDetails,
    Renewal,
    Endorsement,
    Cancellation,
    AccountBillingAndPayment,
    MakePayment,
    ContactUnderwriter,
    PaymentConfirmation
} from 'gw-capability-gateway-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from 'gw-digital-auth-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

const { gatewayAnalytics } = appConfig;

function PageRouting() {
    return (
        <Switch>
            {/* <Route exact path="/" component={PELandingPage} /> */}
            <Route exact path="/" component={Accounts} />
            {/* <Route exact path="/accounts" component={Accounts} /> */}
            <Route exact path="/policies" component={Policies} />
            {/* <CapabilityRoute capability="claim" exact path="/claims" component={ClaimsLanding} /> */}
            {/* <Route path="/activities" component={Activities} /> */}
            {/* {gatewayAnalytics && <Route path="/analytics" component={Analytics} />} */}
            <CapabilityRoute
                capability="commission"
                exact
                path="/commission"
                component={CommissionLanding}
            />
            <Route path="/accounts/:accountNumber" component={AccountDetails} />
            <Route path="/policies/:policyNumber" component={PolicyDetails} />
            {/* <CapabilityRoute
                capability="claim"
                exact
                path="/claims/:claimNumber"
                component={ClaimDetails}
            />
            <CapabilityRoute
                capability="claim"
                exact
                path="/claims/:claimNumber/create-note"
                component={CreateNote}
            /> */}
            {/* <CapabilityRoute
                capability="quote"
                exact
                path="/new-quote-account-search"
                component={NewQuoteAccountSearch}
            />
            <CapabilityRoute capability="quote" exact path="/new-quote" component={NewQuotePage} />
            <CapabilityRoute
                capability="quote"
                exact
                path="/new-quote/:accountNumber"
                component={NewQuotePage}
            />
            <CapabilityRoute capability="quote" lob="cp" path="/quote-cp" component={PECPWizard} />
            <CapabilityRoute
                capability="quote"
                lob="wc7"
                path="/quote-wc7"
                component={PEWC7Wizard}
            />
            <CapabilityRoute capability="quote" lob="pa" path="/quote-pa" component={PEPAWizard} />
            <CapabilityRoute capability="quote" lob="ho" path="/quote-ho" component={PEHOWizard} />
            <CapabilityRoute
                capability="quote"
                lob="bop"
                path="/quote-bop"
                component={PEBOPWizard}
            /> */}
            <CapabilityRoute
                capability="policychange"
                lob="pa"
                path="/endorsement-pa"
                component={PAPolicyChangeWizard}
            />
            <CapabilityRoute
                capability="policychange"
                lob="ho"
                path="/endorsement-ho"
                component={HOPolicyChangeWizard}
            />
            <CapabilityRoute
                capability="policychange"
                lob="cp"
                path="/endorsement-cp"
                component={CPPolicyChangeWizard}
            />
            <CapabilityRoute
                capability="policychange"
                lob="bop"
                path="/endorsement-bop"
                component={BOPPolicyChangeWizard}
            />
            <CapabilityRoute
                capability="renewal"
                lob="cp"
                path="/renewal-cp"
                component={CPPolicyRenewalWizard}
            />
            <CapabilityRoute
                capability="renewal"
                lob="bop"
                path="/renewal-bop"
                component={BOPPolicyRenewalWizard}
            />
            <CapabilityRoute
                capability="fnol"
                path="/fnol-select-policy"
                component={BaseFNOLWizard}
            />
            <CapabilityRoute capability="fnol" lob="ho" path="/fnol-ho" component={FNOLHOWizard} />
            <CapabilityRoute capability="fnol" lob="pa" path="/fnol-pa" component={FNOLPAWizard} />
            <CapabilityRoute capability="fnol" lob="ca" path="/fnol-ca" component={FNOLCAWizard} />
            <CapabilityRoute capability="fnol" lob="cp" path="/fnol-cp" component={FNOLCPWizard} />
            <CapabilityRoute capability="fnol" lob="wc" path="/fnol-wc" component={FNOLWCWizard} />
            <CapabilityRoute
                capability="fnol"
                lob="bop"
                path="/fnol-bop"
                component={FNOLBOPWizard}
            />
            <CapabilityRoute
                capability="fnol"
                lob="gl"
                path="/fnol-gl"
                component={FNOLGeneralWizard}
            />
            <CapabilityRoute
                capability="fnol"
                lob="im"
                path="/fnol-im"
                component={FNOLGeneralWizard}
            />
            <CapabilityRoute
                capability="fnol"
                path="/confirmation/:claimNumber"
                component={ClaimsConfirmationPage}
            />
            <Route path="/contact-agent/:claimNumber" component={ContactAgentPage} />
            <Route path="/contact-agent" component={ContactAgentPage} />
            <Route exact path="/search/:searchText" component={SearchResults} />
            <CapabilityRoute capability="faq" path="/faq" component={FaqPage} />
            <Route exact path="/preferences" component={Preferences} />
            <CapabilityRoute
                capability="quote"
                path="/quotes/:jobNumber"
                component={QuoteDetails}
            />
            <CapabilityRoute capability="renewal" path="/renewal/:jobNumber" component={Renewal} />
            <CapabilityRoute
                capability="policychange"
                path="/change/:jobNumber"
                component={Endorsement}
            />
            <Route path="/cancellation/:jobNumber" component={Cancellation} />
            <Route
                exact
                path="/accounts/:accountNumber/billingAndPayment"
                component={AccountBillingAndPayment}
            />
            <Route exact path="/pay-invoices/billed/:accountNumber" component={MakePayment} />
            <Route exact path="/contactAgent/:policyNumber" component={ContactUnderwriter} />
            <Route
                exact
                path="/make-payment-confirmation/:accountNumber"
                component={PaymentConfirmation}
            />
            <Route exact path="/knockoutpage" component={KnockOutPage} />
            <CapabilityRoute
                capability="document"
                exact
                path="/PolicyDocumentError.html"
                component={PolicyDocumentError}
            />
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}

PageRouting.propTypes = {
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    })
};

PageRouting.defaultProps = {
    authUserData: PropTypes.shape({
        userType: ''
    })
};

export default withAuthenticationContext(PageRouting);
