import React, { Component } from 'react';
import _ from 'lodash';
import { Icon } from '@jutro/components';
import { Link, withRouter } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { SelectProducerCode } from 'gw-gateway-common-react';
import { GatewayClaimService } from 'gw-capability-gateway-claim';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { withIntl, TranslatorContext } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { DatatableUtil } from 'gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './ClaimsLanding.module.scss';
import metadata from './ClaimsLanding.metadata.json5';
import messages from '../../Claims.messages';

const { gatewayParamConfig = {} } = appConfig;

class ClaimsLanding extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired
    };

    state = {
        currentView: 'recentlyViewedTile',
        selectedProducerCode: 'all',
        selectedHeaderContent: messages.recentlyViewed
    };

    processResponseData = (responseData) => {
        if (!_.isEmpty(responseData)) {
            return responseData.map((claimInfo) => {
                const claims = {
                    type: claimInfo.isPersonalAccount ? 'Personal' : 'Commercial',
                    product: claimInfo.product,
                    account: claimInfo.insuredName,
                    claimNumber: claimInfo.claimNumber,
                    dateOfLoss: claimInfo.dateOfLoss,
                    status: claimInfo.state,
                    paid: claimInfo.totalPayments,
                    netIncurred: claimInfo.totalIncurredNet,
                    policyNumber: claimInfo.policyNumber,
                    accountNumber: claimInfo.accountNumber
                };
                return claims;
            });
        }
        return [];
    };

    getCell = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = {
            product: translator(messages.product),
            dateOfLoss: translator(messages.dateOfLoss),
            status: translator(messages.status)
        };

        return (
            <span title={toolTipMessage[property]}>
                {item[property]}
            </span>
        );
    };

    getCurrencyCell = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = {
            paid: translator(messages.totalPayments),
            netIncurred: translator(messages.totalIncurrednet)
        };
        return (
            <span title={toolTipMessage[property]}>
                <CurrencyField
                    id={`claimsCurrency_${index}`}
                    value={item[property]}
                    datatype="object"
                    readOnly
                    hideLabel
                />
            </span>
        );
    };

    getAccount = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.account);
        const icon = item.type === 'Personal' ? 'mi-account_circle' : 'mi-apartment';
        return (
            <div className={styles.linkAccount}>
                <Icon icon={icon} className={styles.gwClaimsAccountIcon} />
                <Link
                    to={`/accounts/${item.accountNumber}/summary`}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage}
                >
                    {item[property]}
                </Link>
            </div>
        );
    };

    handleOnClick = (id) => {
        this.setState({ currentView: id });
    };

    handleValueChange = (producerCode) => {
        this.setState({
            selectedProducerCode: producerCode
        });
    };

    getLink = (item, index, { id: property }) => {
        const translator = this.context;
        let toolTipMessage = translator(messages.claimNumber);
        if (property === 'policyNumber') {
            toolTipMessage = translator(messages.policyNumber);
            return (
                <Link
                    to={`/policies/${item[property]}/summary`}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage}
                >
                    {item[property]}
                </Link>
            );
        }
        if (
            item.status
            === translator({
                id: 'typekey.ClaimState.draft',
                defaultMessage: 'Draft'
            })
        ) {
            const redirectpath = {
                pathname: '/fnol-select-policy',
                state: {
                    claimNumber: item[property],
                    redirectPath: '/claims',
                    claimStatus: item.status
                }
            };
            return (
                <Link
                    to={redirectpath}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage}
                >
                    {item[property]}
                </Link>
            );
        }
        const redirectpath = {
            pathname: `/claims/${item[property]}`,
            state: {
                claimNumber: item[property],
                redirectPath: '/claims',
                claimStatus: item.status
            }
        };
        return (
            <Link to={redirectpath} className={styles.gwClaimsLinkContent} title={toolTipMessage}>
                {item[property]}
            </Link>
        );
    };

    recentView = (sorted) => {
        const { authHeader } = this.props;
        return GatewayClaimService.getRecentlyViewedClaims(authHeader).then((response) => {
            this.setState({
                selectedHeaderContent: messages.recentlyViewed
            });
            const data = this.processResponseData(response);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: sortedData.length
            };
        });
    };

    recentCreated = (paginationParams, paramProducerCode, sorted) => {
        const { authHeader } = this.props;
        let claimsPromise = null;
        claimsPromise = _.isNull(paramProducerCode)
            ? GatewayClaimService.getClaimsForCurrentUser(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            )
            : GatewayClaimService.getClaimsForProducerCode(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            );

        return claimsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.recentlyCreated
            });
            const data = this.processResponseData(response.claims);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    openClaims = (paginationParams, paramProducerCode, sorted) => {
        const { authHeader } = this.props;
        let claimsPromise = null;
        claimsPromise = _.isNull(paramProducerCode)
            ? GatewayClaimService.getOpenClaimsForCurrentUser(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            )
            : GatewayClaimService.getOpenClaimsForProducerCode(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            );

        return claimsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.openClaims
            });
            const data = this.processResponseData(response.claims);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    closedClaims = (paginationParams, paramProducerCode, sorted) => {
        const { authHeader } = this.props;
        let claimsPromise = null;
        claimsPromise = _.isNull(paramProducerCode)
            ? GatewayClaimService.getClosedClaimsForCurrentUser(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            )
            : GatewayClaimService.getClosedClaimsForProducerCode(
                [
                    gatewayParamConfig.claimsCreatedInLastXDays,
                    paginationParams,
                    paramProducerCode
                ],
                authHeader
            );

        return claimsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.closedClaims
            });
            const data = this.processResponseData(response.claims);
            const sortedData = _.orderBy(
                data,
                sorted.map(({ id }) => (row) => row[id]),
                sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
            );
            return {
                rows: sortedData,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    loadData = ({ sorted, pageSize, page }) => {
        const { currentView, selectedProducerCode } = this.state;
        const paramProducerCode = selectedProducerCode === 'all' ? null : selectedProducerCode;
        let tile = 'recentlyViewedTile';
        if (currentView) {
            tile = currentView;
        }

        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy: gatewayParamConfig.landingOrderBy,
            orderByDescending: gatewayParamConfig.landingOrderByDescending
        };
        if (tile === 'recentlyViewedTile') {
            return this.recentView(sorted);
        }
        if (tile === 'recentlyCreatedTile') {
            return this.recentCreated(paginationParams, paramProducerCode, sorted);
        }
        if (tile === 'openClaimsTile') {
            return this.openClaims(paginationParams, paramProducerCode, sorted);
        }
        if (tile === 'closedClaimsTile') {
            return this.closedClaims(paginationParams, paramProducerCode, sorted);
        }
        return { rows: [], numberOfRows: 0 };
    };

    render() {
        const { currentView, selectedProducerCode, selectedHeaderContent } = this.state;
        const translator = this.context;
        const tableKey = currentView + selectedProducerCode;
        const overrides = {
            recentlyViewedTableGrid: {
                onFetchData: this.loadData,
                key: tableKey
            },
            [currentView]: {
                active: true
            },
            recentlyViewedId: {
                content: translator(selectedHeaderContent)
            },
            paidColumnTitle: {
                visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
            },
            netIncurredColumnTitle: {
                visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getCurrencyCell: this.getCurrencyCell,
                getAccount: this.getAccount,
                getLink: this.getLink,
                handleOnClick: this.handleOnClick,
                handleValueChange: this.handleValueChange,
                sortCurrency: DatatableUtil.sortCurrency,
                sortString: DatatableUtil.sortString,
                sortDate: DatatableUtil.sortDate
            },
            resolveComponentMap: {
                selectproducercode: SelectProducerCode
            }
        };

        const claimsLandingpage = <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            {...resolvers}
        />;
        return <div>{claimsLandingpage}</div>;
    }
}

export default withRouter(withIntl(withAuthenticationContext(ClaimsLanding)));
