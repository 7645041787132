import { defineMessages } from 'react-intl';

export default defineMessages({
    policyPeriod: {
        id: 'quoteandbind.directives.QuoteDetailsBox.PolicyPeriod',
        defaultMessage: 'Policy Period',
    },
    estimatedAnnualPremium: {
        id: 'quoteandbind.wc7.views.quote-details-box.Estimated Annual Premium',
        defaultMessage: 'Estimated Annual Premium'
    },
    taxesAndSurcharges: {
        id: 'quoteandbind.wc7.views.quote-details-box.Taxes and Surcharges',
        defaultMessage: 'Taxes and Surcharges'
    },
    totalCost: {
        id: 'quoteandbind.wc7.views.quote-details-box.Total Cost',
        defaultMessage: 'Total Cost'
    },
});
