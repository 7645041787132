import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { CommissionPolicyService } from 'gw-capability-gateway-commission';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { UserService, PolicyService } from 'gw-capability-gateway';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyCommission.metadata.json5';
import styles from './PolicyCommission.module.scss';
import messages from './PolicyCommission.messages';

function PolicyCommission(props) {
    const translator = useTranslator();
    const [transactionTable, setTransactionTable] = useState([]);
    const [showCommissionSummary, setShowCommissionSummary] = useState(true);
    const [totalTransaction, setTotalTransaction] = useState({});
    const [policyPeriodValues, setPolicyPeriodValues] = useState([]);
    const [policyPeriodName, setPolicyPeriodName] = useState([]);
    const [commissionSummary, setCommissionSummary] = useState({ producersList: [] });
    const [policyPeriodJobType, setPolicyPeriodJobType] = useState(null);
    const [policyPeriodOptions, setPolicyPeriodOptions] = useState([]);
    const [policyTermNumber, setPolicyTermNumber] = useState(null);
    const { authHeader } = useAuthentication();
    const intl = useContext(IntlContext);

    const {
        fromAccountLanding: {
            policyDetailsData: {
                policyResponse: {
                    policyNumber,
                    account: { accountNumber }
                }
            }
        }
    } = props;

    const getCell = (items, index, property) => {
        return items[property.id];
    };

    const getFormattedDate = useCallback((item, index, property) => {
        return intl.formatDate(new Date(item[property.id]), { year: 'numeric', month: 'short', day: 'numeric' });
    }, [intl]);

    const getFormattedCurrency = (item, index, property) => {
        const currency = item[property.id] || {};
        const findNegativeAmount = currency.amount < 0 ? 'negative' : '';
        return (
            <CurrencyField
                id={`currency_${property.id}`}
                value={currency}
                datatype="object"
                title={translator(messages[property.id])}
                className={styles[findNegativeAmount]}
                readOnly
                hideLabel
            />
        );
    };

    const getRate = (item, index, property) => {
        return (item[property.id] && `${item[property.id]}%`) || '-';
    };

    const calculateTotalTransactions = (transactions) => {
        if (!transactions || transactions.length === 0) {
            return null;
        }
        const total = transactions.reduce((a, b) => a + b.amount.amount, 0);
        return {
            amount: total,
            currency: _.head(transactions).currency
        };
    };

    const updateCurrentPeriodDescription = (policyPeriods) => {
        const current = policyPeriods.find((period) => period.isInForce);
        const currentMsg = translator(messages.current);
        const policyPeriodAvailableValues = policyPeriods.map((policy) => {
            if (policy.isInForce) {
                return {
                    code: `${policy.description}`,
                    name: `${policy.description} ${currentMsg}`
                };
            }
            setPolicyTermNumber(policyPeriods[0].termNumber);
            return {
                code: policy.description,
                name: policy.description
            };
        });
        setPolicyPeriodValues(policyPeriodAvailableValues);

        if (current) {
            setPolicyPeriodName(current.description);
            setPolicyTermNumber(current.termNumber);
        } else {
            setPolicyPeriodName(_.head(policyPeriodAvailableValues).name);
            setPolicyTermNumber(_.head(policyPeriodAvailableValues).termNumber);
        }
    };

    useEffect(() => {
        const policyPeriodsData = async () => {
            let getPolicyPeriods = [];
            try {
                getPolicyPeriods = await CommissionPolicyService.getPolicyPeriods(
                    [policyNumber],
                    authHeader
                );
            } catch (e) {
                setShowCommissionSummary(false);
                return;
            }
            updateCurrentPeriodDescription(getPolicyPeriods);
            setPolicyPeriodOptions(getPolicyPeriods);
        };
        policyPeriodsData();
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!policyTermNumber) {
            return;
        }

        CommissionPolicyService.getCommissionsTransactionsByPolicyPeriod(
            [policyNumber, policyTermNumber],
            authHeader
        ).then((data) => {
            setTotalTransaction(calculateTotalTransactions(data));
            setTransactionTable(data);
        });

        PolicyService.getPolicyPeriodJobType(
            [policyNumber, policyTermNumber],
            authHeader
        ).then((data) => {
            setPolicyPeriodJobType(data);
        });

        const policyCommissionSummaryData = async () => {
            const getUserProducerCodes = await UserService.getAvailableProducerCodesForCurrentUser(
                authHeader
            );
            const userProducerCodes = getUserProducerCodes.map((producerCode) => producerCode.code);
            const summaryData = await CommissionPolicyService.getPolicyCommissionSummary(
                [policyNumber, policyTermNumber, userProducerCodes],
                authHeader
            );
            const { primaryProducer, referrerProducer, secondaryProducer } = summaryData;
            summaryData.producersList = [
                { ...primaryProducer, type: 'primaryProducer' },
                { ...secondaryProducer, type: 'secondaryProducer' },
                { ...referrerProducer, type: 'referrerInfo' }
            ];

            setCommissionSummary(() => {
                return summaryData;
            });
        };
        policyCommissionSummaryData();
    }, [authHeader, policyNumber, policyTermNumber]);

    const haveCommission = (commission) => {
        return !!(commission && commission.totalCommission && commission.totalCommission.amount);
    };

    const onValueChange = (value) => {
        setPolicyPeriodName(value);
        const [selectedPolicyPeriod] = _.filter(policyPeriodOptions, { description: value });
        const { termNumber } = selectedPolicyPeriod;
        setPolicyTermNumber(termNumber);
    };

    const producersListOverrideProps = useCallback(() => {
        const { producersList } = commissionSummary;
        const overrides = producersList.map((producer, index) => {
            return {
                [`title${index}`]: {
                    content: translator(messages[producer.type])
                },
                [`standardCommissionRateValue${index}`]: {
                    value: `${producer.standardCommissionRate}%`
                },
                [`producerCodeValue${index}`]: {
                    value: `${producer.producerCode} - ${producer.producerName}`
                },
                [`producerList${index}`]: {
                    visible: !!producer.producerCode
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [translator, commissionSummary]);

    const overrideProps = {
        transactionTable: {
            data: transactionTable
        },
        policyPeriodDropdown: {
            availableValues: policyPeriodValues,
            value: policyPeriodName
        },
        viewAccountCommission: {
            to: `/accounts/${accountNumber}/commission`
        },
        viewOverallCommission: {
            to: '/commission'
        },
        associatedPolicyTransactionRight: {
            content: policyPeriodJobType
        },
        totalPremiumRight: {
            content: (
                <CurrencyField
                    id="totalPremium"
                    hideLabel
                    readOnly
                    value={commissionSummary.totalPremium}
                />
            )
        },
        totalTransaction: {
            value: (
                <CurrencyField
                    id="totalCurrency"
                    hideLabel
                    readOnly
                    value={totalTransaction}
                    datatype="object"
                />
            )
        },
        problemAccessingCommission: {
            visible: !showCommissionSummary
        },
        policyCommissionsSummary: {
            visible: showCommissionSummary
        },
        totalWrapper: {
            visible: transactionTable.length > 0
        },
        noCommissionFound: {
            visible: !haveCommission(commissionSummary)
        },
        subtitleDescription: {
            visible: haveCommission(commissionSummary)
        },
        noTransactionFound: {
            visible: !transactionTable.length
        },
        commissionSummaryInfo: {
            visible: haveCommission(commissionSummary)
        },
        producersColumn: {
            visible: haveCommission(commissionSummary)
        },
        ...producersListOverrideProps()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: getCell,
            getFormattedCurrency: getFormattedCurrency,
            getRate: getRate,
            getFormattedDate: getFormattedDate,
            onValueChange: onValueChange
        }
    };

    const data = {
        policyPeriodJobType,
        commissionSummary
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, data, id, path, overrideProps);
        },
        [data, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={data}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PolicyCommission.propTypes = {
    fromAccountLanding: PropTypes.shape({
        policyDetailsData: PropTypes.shape({
            policyResponse: PropTypes.shape({
                policyNumber: PropTypes.string,
                account: PropTypes.shape({
                    accountNumber: PropTypes.string
                })
            })
        })
    }).isRequired
};
export default PolicyCommission;
