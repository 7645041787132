import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonField } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import FilterOptions from './FilterOptions';
import styles from './AdvancedFilter.module.scss';
import messages from './PolicyLanding.messages';

class AdvancedFilter extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        customFilter: PropTypes.shape([]).isRequired,
        onValueChange: PropTypes.func.isRequired,
        selectedCategory: PropTypes.string.isRequired,
        countInformation: PropTypes.shape({}).isRequired
    };

    state = {
        advancedFilterSelectedCategory: undefined
    };

    componentDidMount() {
        const { selectedCategory } = this.props;
        this.setState({ advancedFilterSelectedCategory: selectedCategory });
    }

    hasFilterOptions = (policy, category) => {
        const NestedOptions = policy.filters.filter((items) => items.options.length > 0);
        if (
            policy.filters.length > 0
            && category === policy.displayValue
            && NestedOptions.length > 0
        ) {
            return true;
        }
        return false;
    };

    onWriteValue = (value) => {
        this.setState({ advancedFilterSelectedCategory: value });
        this.eventCallBack(value);
    };

    onValueChange = () => {
        const { advancedFilterSelectedCategory } = this.state;
        this.eventCallBack(advancedFilterSelectedCategory);
    };

    eventCallBack = (category) => {
        const { onValueChange } = this.props;
        if (onValueChange) {
            onValueChange(category);
        }
    };

    render() {
        const translator = this.context;
        const { customFilter, countInformation } = this.props;
        const { advancedFilterSelectedCategory } = this.state;
        const writePolicyInfo = (displayValue, displayKey) => {
            const displayName = `${translator(displayKey)} (${countInformation[displayValue]})`;
            return [{ code: displayValue, name: displayName }];
        };
        return (
            <div className={styles.gw_filter_section}>
                <div className={styles.gw_filter_section_title}>
                    {translator(messages.policylandingCategory)}
                </div>
                {customFilter.length > 0
                    && customFilter.map((policy) => (
                        <Fragment>
                            <RadioButtonField
                                id={`policyType${policy.displayKey}`}
                                value={
                                    advancedFilterSelectedCategory === policy.displayValue
                                    && translator(policy.displayValue)
                                }
                                availableValues={writePolicyInfo(
                                    policy.displayValue,
                                    policy.displayKey
                                )}
                                showOptional={false}
                                onValueChange={this.onWriteValue}
                            />
                            {this.hasFilterOptions(policy, advancedFilterSelectedCategory)
                                && policy.filters.map((filter) => (
                                    <div
                                        className={styles.gw_filter_sub_option_container}
                                        key={`status${filter.displayKey}`}
                                    >
                                        <div className={styles.gw_sub_option_title}>
                                            {translator(filter.displayKey)}
                                        </div>
                                        <FilterOptions
                                            filters={filter}
                                            onValueChange={this.onValueChange}
                                        />
                                    </div>
                                ))}
                        </Fragment>
                    ))}
            </div>
        );
    }
}

export default AdvancedFilter;
