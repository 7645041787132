import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Icon,
} from '@jutro/components';
import EventCard from './EventCard';
import TimelineFilterPolicy from './TimelineFilterPolicy';
import styles from './css/Timeline.module.scss';
import transformEvents from './util/TimelineEventsFilter';
import PolicyFilterUtil from './util/PolicyFilter';

const TimelineComponent = (props) => {
    const { events, id } = props;
    const timelineEvents = transformEvents(events);
    const [filterEvents, setEvents] = useState(timelineEvents);
    const { options } = new PolicyFilterUtil(timelineEvents);
    const [filterOption, setFilterOptions] = useState({
        yearList: PolicyFilterUtil.getYearList(filterEvents),
        options
    });

    const filterByPolicyNumber = useCallback(
        (policyNumber) => {
            const filterByPolicyEvents = timelineEvents;
            if (policyNumber === 'all') {
                return filterByPolicyEvents;
            }
            return filterByPolicyEvents.filter((event) => event.policyNumber === policyNumber);
        },
        [timelineEvents]
    );

    const handleValueChange = useCallback(
        (option) => {
            const filterEventByPolicy = filterByPolicyNumber(option.code);
            setEvents(filterEventByPolicy);
            setFilterOptions({
                yearList: PolicyFilterUtil.getYearList(filterEventByPolicy),
                options
            });
        },
        [filterByPolicyNumber, options]
    );

    return (
        <Fragment>
            <TimelineFilterPolicy filterData={filterOption} onValueChange={handleValueChange} />
            <div id={id} className={styles.gwTimelineContainer}>
                <div className={styles.gwTimelineTopLine}>
                    <Icon icon="mi-calendar_today" className={styles.calendarIcon} />
                </div>
                {filterEvents.map((event, index) => (
                    <EventCard key={event.id} event={event} index={index} />
                ))}
                <div className={styles.gwTimelineBottomLine}>
                    <Icon icon="mi-calendar_today" className={styles.calendarIcon} />
                </div>
            </div>
        </Fragment>
    );
};

TimelineComponent.propTypes = {
    id: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({}))
};
TimelineComponent.defaultProps = {
    events: undefined
};
export default TimelineComponent;
