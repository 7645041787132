import { defineMessages } from 'react-intl';

export default defineMessages({
    showLess: {
        id: 'quoteandbind.cp.entryscreen.spreadsheetupload.report.errors.Show Less',
        defaultMessage: 'Show Less'
    },
    showAll: {
        id: 'quoteandbind.cp.entryscreen.spreadsheetupload.report.errors.Show All',
        defaultMessage: 'Show All'
    },
    somethingDataWasNotImported: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Something went wrong and your data was not imported.',
        defaultMessage: 'Something went wrong and your data was not imported.'
    },
    yourSpreadsheetWasEmpty: {
        id: 'quoteandbind.cp.views.spreadsheetprocessorresult.Yourspreadsheetwasempty.',
        defaultMessage: 'Your spreadsheet was empty.'
    },
    affectedRow: {
        id: 'quoteandbind.cp.entryscreen.spreadsheetuploadresultsummary.errors.AffectedRow:',
        defaultMessage: 'Affected Row: {count}'
    },
    number: {
        id: 'quoteandbind.cp.entryscreen.spreadsheetuploadresultsummary.number',
        defaultMessage: '{count}'
    },
    continueUpdatingtheSpreadsheet: {
        id: 'quoteandbind.cp.entryscreen.spreadsheetupload.Somethingwentwrong.ContinueUpdatingtheSpreadsheet',
        defaultMessage: 'Continue Updating the Spreadsheet'
    },
    buildingsWereNotImported: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Buildings were not imported.',
        defaultMessage: 'Buildings were not imported.'
    },
    uploadTheSpreadsheetAgain: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload.Upload the Spreadsheet Again',
        defaultMessage: 'Upload the Spreadsheet Again'
    },
    buildingsWereNotImportedCount: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Buildings were not imported.',
        defaultMessage: '{count} buildings were not imported.'
    },
    affectedRowError: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.errors.Affected Row:',
        defaultMessage: 'Affected Row: {count}'
    },
    fixIssuesToImportSuccessful: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Fix the issues below to successfully import the buildings.',
        defaultMessage: 'Fix the issues below to successfully import the buildings.'
    },
    buildingsWereAdded: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Buildings were added.',
        defaultMessage: '{count} Buildings were added.'
    },
    buildingsWereEdited: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Buildings were edited.',
        defaultMessage: '{count} Buildings were edited.'
    },
    buildingsWereRemoved: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Buildings were removed.',
        defaultMessage: '{count} Buildings were removed.'
    },
    successYouCanProceed: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Now you can:',
        defaultMessage: 'Now you can:'
    },
    editIndividualBuildingsListBelow: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Edit individual buildings from the list below.',
        defaultMessage: 'Edit individual buildings from the list below.'
    },
    singleBuildingsAddBuildingButton: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Add new single buildings to your list using the Add Building button.',
        defaultMessage: 'Add new single buildings to your list using the Add Building button.'
    },
    editBuildingDataOnceUploadSpreadsheet: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Edit all of your building data at once in your spreadsheet.',
        defaultMessage: 'Edit all of your building data at once in your spreadsheet. {downloadreminder}'
    },
    downloadUploadedSpreadsheetFirst: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.You will need to download the uploaded spreadsheet first.',
        defaultMessage: 'You will need to download the uploaded spreadsheet first.'
    },
    continueUpdatingSpreadsheet: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload.Something went wrong.Continue Updating the Spreadsheet',
        defaultMessage: 'Continue Updating the Spreadsheet'
    },
    spreadsheetDataSuccessfullyImported: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Your spreadsheet data was successfully imported.',
        defaultMessage: 'Your spreadsheet data was successfully imported.'
    },
    editLocationEmployeeListBelow: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Edit individual location and employees from the list below.',
        defaultMessage: 'Edit individual location and employees from the list below.'
    },
    singleLocationEmployeeButton: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Add new single location and employee to your list using the Add Location and Employees button.',
        defaultMessage: 'Add new single location and employee to your list using the Add Location and Employees button.'
    },
    editLocationEmployeeDataOnceUploadSpreadsheet: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Spreadsheet data successfully imported.Edit all of your locations and empployees data at once in your spreadsheet.',
        defaultMessage: 'Edit all of your locations and empployees data at once in your spreadsheet. {downloadreminder}'
    },
    noDataImported: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.There was no data to be imported.',
        defaultMessage: 'There was no data to be imported.'
    },
    uploadSpreadsheet: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Upload Spreadsheet',
        defaultMessage: 'Upload Spreadsheet'
    },
    noChangeInSpreadsheet: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Your spreadsheet did not have any changes.',
        defaultMessage: 'Your spreadsheet did not have any changes.'
    },
    updatedSpreadsheetInformation: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Click the button below to upload a spreadsheet containing updated information about your buildings and locations.',
        defaultMessage: 'Click the button below to upload a spreadsheet containing updated information about your buildings and locations.'
    },
    spreadSheetFailEmpty: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Your spreadsheet was empty.',
        defaultMessage: 'Your spreadsheet was empty.'
    },
    spreadSheetNoInformation: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Click the button below to upload a spreadsheet containing information about your buildings and locations.',
        defaultMessage: 'Click the button below to upload a spreadsheet containing information about your buildings and locations.'
    },
    spreadSheetUpload: {
        id: 'quoteandbind.cp.views.spreadsheet processor result.Upload Spreadsheet',
        defaultMessage: 'Upload Spreadsheet'
    },
    wentWrongPartialUpload: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload result summary.Something went wrong and your data was only partially imported.',
        defaultMessage: 'Something went wrong and your data was only partially imported.'
    },
    addCorrectDataDownloadTemplate: {
        id: 'quoteandbind.cp.entryscreen.spreadsheet upload.Something went wrong.Click the button below to download your spreadsheet again and try adding the correct data.',
        defaultMessage: 'Click the button below to download your spreadsheet again and try adding the correct data.'
    }
});
