import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Loader } from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { PolicyService } from 'gw-capability-gateway';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { gatewayMessages } from 'gw-capability-gateway-react';
import { DatatableUtil } from 'gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import claimMessages from '../../Claims.messages';
import metadata from './PolicyClaims.metadata.json5';
import styles from './PolicyClaims.module.scss';

const { fnolLobs, fnolLobsConfig } = appConfig;
const getFnolLobs = _.reduce(
    fnolLobs,
    (namesLiterals, policyType) => namesLiterals.concat(fnolLobsConfig[policyType]),
    []
);

class PolicyClaims extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string,
                    account: PropTypes.shape({
                        policySummaries: PropTypes.array
                    }),
                    product: PropTypes.shape({
                        productCode: PropTypes.string
                    }),
                    latestPeriod: PropTypes.shape({
                        canceled: PropTypes.string
                    })
                })
            })
        }).isRequired,
        intl: PropTypes.func.isRequired
    };

    static contextType = TranslatorContext;

    state = {
        claimResponse: '',
        claimDataTable: '',
        searchFilter: '',
        isLoading: true
    };

    getSearchFilterValues = (claimsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(claimsArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getClaimDataTable = (claimsData, filter) => {
        let claimsArrayResult = claimsData;
        if (filter.type === 'SearchFilter') {
            claimsArrayResult = this.getSearchFilterValues(claimsArrayResult, filter);
        }
        claimsArrayResult = claimsArrayResult.map((claimInfo) => {
            const claims = {
                claimNumber: claimInfo.claimNumber,
                dateOfLoss: claimInfo.lossDate,
                status: claimInfo.status,
                paid: claimInfo.totalPayments,
                netIncurred: claimInfo.totalIncurredNet
            };
            return claims;
        });
        this.setState({
            claimDataTable: claimsArrayResult
        });
    };

    componentDidMount() {
        const { authHeader, fromAccountLanding } = this.props;
        const { policyNumber } = fromAccountLanding.policyDetailsData.policyResponse;
        PolicyService.getPolicyClaims(policyNumber, authHeader).then((response) => {
            const filter = {
                type: null,
                value: null
            };
            this.getClaimDataTable(response, filter);
            this.setState({
                claimResponse: response,
                isLoading: false
            });
        });
    }

    handleFilterChange = (value) => {
        const { claimResponse } = this.state;
        const filter = {
            type: 'SearchFilter',
            value: value
        };
        this.setState(
            {
                searchFilter: value
            },
            () => {
                this.getClaimDataTable(claimResponse, filter);
            }
        );
    };

    handleFileClick = () => {
        const {
            history,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const { policyNumber } = policyResponse;
        const redirectClaimsPath = `/policies/${policyNumber}/claims`;
        return history.push({
            pathname: '/fnol-select-policy',
            state: {
                policyNumber: policyNumber,
                redirectPath: redirectClaimsPath
            }
        });
    };

    getCell = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            dateOfLoss: translator(claimMessages.dateOfLoss),
            status: translator(claimMessages.status)
        };
        return <span title={toolTipMessage[property.id]}>{item[property.id]}</span>;
    };

    getCurrencyCell = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            paid: translator(claimMessages.paid),
            netIncurred: translator(claimMessages.netIncurred)
        };
        return (
            <span title={toolTipMessage[property.id]}>
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    datatype="object"
                    title={toolTipMessage}
                    readOnly
                    hideLabel
                />
            </span>
        );
    };

    getLink = (item, index, property) => {
        const translator = this.context;
        const { fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const toolTipMessage = {
            policyNumber: translator(claimMessages.policyNumber),
            claimNumber: translator(claimMessages.claimNumber)
        };
        if (
            item.status
            === translator({
                id: 'typekey.ClaimState.draft',
                defaultMessage: 'Draft'
            })
        ) {
            const redirectpath = {
                pathname: '/fnol-select-policy',
                state: {
                    claimNumber: item[property.id],
                    redirectPath: `/policies/${policyNumber}/claims`,
                    claimStatus: item.status
                }
            };
            return (
                <Link to={redirectpath} title={toolTipMessage[property.id]}>
                    {item[property.id]}
                </Link>
            );
        }
        const redirectRoute = property.id === 'policyNumber'
            ? `/policies/${item[property.id]}/summary`
            : `/claims/${item[property.id]}`;
        const redirectPath = {
            pathname: redirectRoute,
            state: {
                claimNumber: item[property.id],
                redirectPath: `/policies/${policyNumber}/claims`,
                claimStatus: item.status
            }
        };
        return (
            <Link to={redirectPath} title={toolTipMessage[property.id]}>
                {item[property.id]}
            </Link>
        );
    };

    checkIfPolicyIsInForceStatus = (latestPeriod) => {
        const translator = this.context;
        return (
            latestPeriod.status
                === translator({ id: 'typekey.PolicyPeriodStatus.Bound', defaultMessage: 'Bound' })
            && !moment().isBefore(moment(latestPeriod.effectiveDate))
        );
    };

    getFileClaimButtonVisible = () => {
        const {
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const policyType = _.get(policyResponse, 'product.productCode', '');
        const policyNumber = _.get(policyResponse, 'policyNumber', '');
        return (
            getFnolLobs.includes(policyType)
            && policyNumber
            && !policyResponse.latestPeriod.canceled
            && this.checkIfPolicyIsInForceStatus(policyResponse.latestPeriod)
        );
    };

    render() {
        const {
            claimDataTable, claimResponse, searchFilter, isLoading
        } = this.state;

        const translator = this.context;

        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        const overrides = {
            claimDataTable: {
                data: claimDataTable
            },
            fileClaimButton: {
                visible: this.getFileClaimButtonVisible()
            },
            searchFilter: {
                value: searchFilter,
                visible: !_.isEmpty(claimResponse)
            },
            noDataMessageContainer: {
                visible: _.isEmpty(claimResponse)
            },
            noDataMessage: {
                value: translator(gatewayMessages.noClaimsResult)
            },
            paidColumnTitle: {
                visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
            },
            netIncurredColumnTitle: {
                visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getCurrencyCell: this.getCurrencyCell,
                getAccount: this.getAccount,
                getLink: this.getLink,
                handleSearchValueChange: this.handleFilterChange,
                handleFileClick: this.handleFileClick,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                sortCurrency: DatatableUtil.sortCurrency
            }
        };
        const claimPage = <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            {...resolvers}
        />;
        return (
            <div>
                {claimPage}
            </div>
        );
    }
}

export const PolicyClaimsComponent = PolicyClaims;
export default withIntl(withAuthenticationContext(PolicyClaims));
