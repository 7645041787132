import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from 'gw-portals-wizard-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { GatewayRenewalService } from 'gw-capability-gateway-policyrenewal';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { summaryMessages } from 'gw-capability-gateway-react';
import { policyRenewalMessages } from 'gw-capability-policyrenewal-common-react';
import messages from './BOPPolicyRenewalWizard.messages';
import wizardConfig from './config/bop-wizard-config.json5';
import styles from './BOPPolicyRenewalWizard.module.scss';

function BOPPolicyRenewalWizard(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;
    const landingFrom = _.get(location, 'state.landingFrom');
    const toSkip = landingFrom === 'quoteSummary';
    useEffect(
        () => {
            if (!location.state) {
                history.push('/');
                return;
            }
            const { state: { jobNumber } } = location;
            if (!jobNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push(`/contactAgent/${jobNumber}`);
                }, _.noop);
            };
            GatewayRenewalService.loadRenewal(jobNumber, authHeader)
                .then((response) => {
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(response, 'jobID'),
                        authHeader
                    );
                    const submission = viewModelService.create(
                        response,
                        'pc',
                        'edge.capabilities.renewal.dto.RenewalDataDTO'
                    );
                    setInitialSubmission(submission);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: policyRenewalMessages.unableToReferUW,
                        message: policyRenewalMessages.errorOccurWhileRenewal,
                        status: 'warning',
                        icon: 'mi-error-outline'
                    });
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardSnapshot, wizardData, param: nextPath = '', currentStepIndex, steps: totalSteps
        } = cancelParams;
        if ((currentStepIndex + 1 === totalSteps.length) || nextPath.startsWith('/renewal') || hasErrorOccurred) {
            return true;
        }

        return showConfirm({
            title: commonMessages.wantToCancel,
            message: commonMessages.infoWillBeSavedAsDraft,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yes,
            cancelButtonText: commonMessages.no
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            setIsLoading(true);
            const isQuoted = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === 'Quoted';
            let gatewayRenewalServiceCall = GatewayRenewalService.saveRenewal;
            const updateCoveragesCall = GatewayRenewalService.updateCoverages;
            const coveragePath = 'lobData.businessOwners.coverages';
            let serverCall = null;
            let submission = wizardData.value;
            const { jobID, sessionUUID } = submission;
            const redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/renewal/${jobID}/summary`;
            const lobName = ClausesUtil.getLobNameFromPath(coveragePath);
            const clauseType = ClausesUtil.getLastSectionOfPath(coveragePath);
            const clauses = _.get(submission, `${coveragePath}`);
            const clausesToUpdate = ClausesUtil.structureClausesForServer(
                clauses,
                lobName,
                clauseType
            );
            const updateCoveragesCallArgs = [jobID, sessionUUID, clausesToUpdate, authHeader];
            let isServiceCallNeeded = false;
            let renewalModel;
            if (isQuoted) {
                if (!_.isNil(_.get(submission, 'bindData.paymentDetails'))) {
                    submission = _.omit(submission, 'bindData.paymentDetails');
                    gatewayRenewalServiceCall = GatewayRenewalService.updatePaymentPlan;
                    renewalModel = submission;
                    isServiceCallNeeded = true;
                }
            } else {
                const isValid = wizardData.aspects.valid
                && wizardData.aspects.subtreeValid;
                isServiceCallNeeded = true;
                renewalModel = isValid ? submission : wizardSnapshot.value;
            }

            if (!isServiceCallNeeded) {
                serverCall = updateCoveragesCall(
                    ...updateCoveragesCallArgs
                );
            } else {
                serverCall = Promise.all([
                    gatewayRenewalServiceCall([renewalModel], authHeader),
                    updateCoveragesCall(
                        ...updateCoveragesCallArgs
                    )]);
            }
            return serverCall.then(() => {
                history.push(redirectLobPath);
            }).catch(() => {
                showAlert({
                    title: summaryMessages.error,
                    message: summaryMessages.details,
                    status: 'error',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
                history.push('/policies');
            });
        }, _.noop);
    }, [authHeader, hasErrorOccurred, history, showAlert, showConfirm]);

    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.jobNumber');
        const jobID = jobIDFromWizard || jobIDFromLocation;
        const redirectPath = `/renewal/${jobID}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        return showAlert({
            title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        }, _.noop);
    }, [location, showAlert]);

    if (isLoading) {
        return (
            <Loader loaded={!isLoading} />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.bopPolicyRenewalWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <DependencyProvider
                    value={{
                        LoadSaveService: GatewayRenewalService
                    }}
                >
                    <Wizard
                        initialSteps={steps}
                        wizardTitle={title}
                        initialData={initialSubmission}
                        onCancel={handleOnCancel}
                        skipCompletedSteps={toSkip}
                    />
                </DependencyProvider>
            </ErrorBoundary>
        </div>
    );
}

BOPPolicyRenewalWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            jobNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired

};

export default withRouter(withAuthenticationContext(BOPPolicyRenewalWizard));
