import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DefinitionArrayStyle from './css/TimelineDefinitionArray.module.scss';

const EventDefinitionArray = (props) => {
    const { term, values } = props;
    const classDefinition = DefinitionArrayStyle.gwTimelineDefinitionArray;
    const classDefinitionTerm = DefinitionArrayStyle.gwTimelineDefinitionArray__term;
    const classDefinitionList = DefinitionArrayStyle.gwTimelineDefinitionArray__list;
    const classDefinitionDefinition = DefinitionArrayStyle.gwTimelineDefinitionArray__definition;

    return (
        <div className={classDefinition}>
            <h3 className={classDefinitionTerm}>{term}</h3>
            <div className={classDefinitionList}>
                <div className={classDefinitionDefinition}>
                    {values.map((value) => {
                        return (
                            <Fragment>
                                {value}
                                <br />
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
EventDefinitionArray.propTypes = {
    term: PropTypes.string.isRequired,
    values: PropTypes.shape([]).isRequired
};

export default EventDefinitionArray;
