import { defineMessages } from 'react-intl';

export default defineMessages({
    timelineFiltersJumpto: {
        id: 'journey.components.TimelineFilters.Jump to:',
        defaultMessage: 'Jump to:'
    },
    timelineFiltersNewest: {
        id: 'journey.components.TimelineFilters.Newest',
        defaultMessage: 'Newest'
    },
    timelineDetailsPolicy: {
        id: 'journey.directives.timeline.timeline-details.templates.timeline-details.Policy:',
        defaultMessage: 'Policy: #{policyNumber}'
    },
    timelineDetailsHomePolicy: {
        id: 'journey.directives.timeline.timeline-details.templates.timeline-details.Home Policy:',
        defaultMessage: 'Home Policy: #{policyNumber}'
    },
    timelineDetailsAutoPolicy: {
        id: 'journey.directives.timeline.timeline-details.templates.timeline-details.Auto Policy:',
        defaultMessage: 'Auto Policy: #{policyNumber}'
    },
});
