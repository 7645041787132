import { defineMessages } from 'react-intl';

export default defineMessages({
    coverages: {
        id: 'policy.ho.directives.policy-dwelling-info.Coverages For',
        defaultMessage: 'Coverages'
    },
    scheduledItems: {
        id: 'quoteandbind.ho.directives.ho-additional-coverages.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    }
});
