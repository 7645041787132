import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useValidation } from 'gw-portals-validation-react';

import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './StateIdComponent.metadata.json5';

function StateIdComponent(props) {
    const {
        data: stateIdVM, updateStateIds, id, onValidate
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const overrideLabelNames = useMemo(() => {
        const overrides = stateIdVM.value.officialIDs.map((officialID, index) => {
            return {
                [`stateId${index}`]: {
                    label: `${stateIdVM.value.jurisdiction} - ${officialID.officialIDType}`
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [stateIdVM]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ...overrideLabelNames
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={stateIdVM}
            overrideProps={overrideProps}
            onModelChange={updateStateIds}
            onValidationChange={setComponentValidation}
        />
    );
}

StateIdComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    updateStateIds: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default StateIdComponent;
