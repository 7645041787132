import { ServiceManager } from '@jutro/services';

function buildAddressLine(defaultDelimiter) {
    const fragments = [];

    return {
        append(fragment, delimiter = defaultDelimiter) {
            fragments.push({ fragment, delimiter });
            return this;
        },
        join() {
            return fragments
                .filter(({ fragment }) => fragment)
                .reduce((formatted, { fragment, delimiter }, index) => {
                    return formatted
                        + (index ? delimiter : '')
                        + fragment;
                }, '');
        }
    };
}

function formatJapaneseAddress(address, _delimiter, translator) {
    const LocaleService = ServiceManager.getService('locale-service');
    const state = address.state
        ? translator({
            id: `typekey.State.${address.state}`,
            defaultMessage: address.state
        })
        : null;
    const city = address.city || address.cityKanji;
    const addressLine1 = address.addressLine1 || address.addressLine1Kanji;
    const addressLine2 = address.addressLine2 || address.addressLine2Kanji;
    const locale = LocaleService.getCurrentLocale();
    const delimiter = locale === 'ja_JP' && _delimiter === ', ' ? '' : _delimiter;
    const postalCode = address.postalCode ? `〒${address.postalCode}` : null;
    return buildAddressLine(delimiter)
        .append(postalCode)
        .append(state)
        .append(city, '')
        .append(addressLine1)
        .append(addressLine2)
        .join();
}

function AddressFormatter(address, translator) {
    if (!address) return null;
    const delimiter = ', ';

    const {
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        county,
        postalCode
    } = address;

    // eslint-disable-next-line react/destructuring-assignment
    const countryCode = address.country;
    // eslint-disable-next-line react/destructuring-assignment
    const stateCode = address.state;
    const LocaleService = ServiceManager.getService('locale-service');
    const country = countryCode && LocaleService.getCountry()
        ? translator({
            id: `typekey.Country.${countryCode}`,
            defaultMessage: countryCode
        })
        : null;

    const state = stateCode ? translator({
        id: `typekey.State.${stateCode}`,
        defaultMessage: stateCode
    }) : null;

    const builder = buildAddressLine(delimiter);

    if (countryCode === 'JP') {
        return formatJapaneseAddress(address, delimiter, translator);
    }

    if (countryCode === 'AU') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(city)
            .append(state, ' ')
            .append(postalCode, ' ')
            .append(country)
            .join();
    }

    if (countryCode === 'CA') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(city)
            .append(state, ', ')
            .append(postalCode, '  ')
            .append(country)
            .join();
    }

    if (countryCode === 'DE') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(postalCode)
            .append(city, ' ')
            .append(country)
            .join();
    }

    if (countryCode === 'FR') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(postalCode)
            .append(city, ' ')
            .append(country)
            .join();
    }

    if (countryCode === 'GB') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(city)
            .append(postalCode)
            .append(country)
            .join();
    }

    if (countryCode === 'US') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(city)
            .append(state, ', ')
            .append(postalCode, ' ')
            .append(country)
            .append(county)
            .join();
    }

    if (countryCode === 'CH') {
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(postalCode)
            .append(city, ' ')
            .append(country)
            .join();
    }

    return builder
        .append(addressLine1)
        .append(addressLine2)
        .append(addressLine3)
        .append(city)
        .append(postalCode, ' ')
        .append(country)
        .join();
}

export default AddressFormatter;
