import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import JobService from './JobService';

export default class RenewalService {
    static findJobByJobNumber(renewalNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'findJobByJobNumber',
            [renewalNumber],
            additionalHeaders
        );
    }

    static getRenewalSummary(renewalNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'getJobSummaryCount',
            [renewalNumber],
            additionalHeaders
        );
    }

    static getNotesForRenewal(renewalNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'getNotesForRenewal',
            renewalNumber,
            additionalHeaders
        );
    }

    static createNoteForRenewal(params, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'createNoteForRenewal',
            params,
            additionalHeaders
        );
    }

    static getDocumentsForRenewal(renewalNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'getDocumentsForRenewal',
            [renewalNumber],
            additionalHeaders
        );
    }

    static withdrawRenewalByRenewalNumber(renewalNumber, additionalHeaders) {
        return JobService.withdrawJobByJobNumber(renewalNumber, additionalHeaders);
    }

    static markRenewalNotTaken(renewalNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'markRenewalNotTaken',
            [renewalNumber],
            additionalHeaders
        );
    }

    static markRenewalNonRenew(renewalNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'markRenewalNonRenew',
            [renewalNumber],
            additionalHeaders
        );
    }

    static referToUnderwriter(renewalNumber, noteBody, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewal'),
            'referToUnderwriter',
            [renewalNumber, noteBody],
            additionalHeaders
        );
    }
}
