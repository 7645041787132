import React, {
    useEffect, useContext, useCallback, useState, useMemo
} from 'react';
import {
    Link,
    InputNumberField,
    CheckboxField,
    Button,
    useModal
} from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ServiceManager } from '@jutro/services';
import { WizardPage, pageTemplateProps } from 'gw-portals-wizard-react';
import { DocumentComponent } from 'gw-capability-document-react';
import { SubmissionService } from 'gw-capability-gateway';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Wc7CoverablesService } from 'gw-capability-quoteandbind-wc7';
import { messages as commonMessages } from 'gw-platform-translations';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { DatatableUtil } from 'gw-portals-util-js';
import StateIdComponent from '../../components/StateIdComponent/StateIdComponent';
import messages from './AdditionalInformationPage.messages';
import metadata from './AdditionalInformationPage.metadata.json5';
import styles from './AdditionalInformationPage.module.scss';

const locationDTOName = 'edge.capabilities.policyjob.lob.wc7workerscomp.coverables.dto.Wc7LocationsAndEmployeesDTO';

const getCell = (item, index, property) => {
    return item[property.path];
};

const displayName = (item, index, property) => {
    return (
        <div className={styles.displayNameColumn}>
            <Link href="/" className={styles.displayName}>
                {item[property.path]}
            </Link>
        </div>
    );
};

function AdditionalInformationPage(props) {
    const {
        showAlert
    } = useModal();

    const translator = useTranslator();
    const {
        wizardData: submissionVM, updateWizardData, authHeader, jumpTo, steps
    } = props;
    const [documentData, setDocumentData] = useState([]);
    const [uploadTokenID, setUploadTokenID] = useState('');
    const [showlocationAndEmployees, setShowlocationAndEmployees] = useState(false);
    const [showEmployeeAndClassesContainer, setShowEmployeeAndClassesContainer] = useState(true);
    const [selectedLocationAndEmployeesVM, updateSelectedLocationAndEmployeesVM] = useState(null);
    const [selectedState, updateSelectedState] = useState('');
    const [showEditLocationForm, setEditLocationFormVisible] = useState(false);
    const [showAddEmployeesClasses, setAddEmployeesClasses] = useState(false);
    const [locationAndCoveredEmployeeData, setLocationAndCoveredEmployeeData] = useState([]);
    const [showUpdatedLocationTable, setUpdatedLocationTable] = useState(false);
    const [isContinue, setContinue] = useState(false);
    const localeService = ServiceManager.getService('locale-service');
    const viewModelService = useContext(ViewModelServiceContext);
    const handleError = useCallback((title, message) => {
        return showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);
    const { onValidate, isComponentValid, registerInitialComponentValidation } = useValidation(
        'AdditionalInformationPage'
    );

    const addressType = useMemo(
        () => _.get(submissionVM.value, 'baseData.policyAddress.addressType'),
        [submissionVM]
    );
    const quoteID = _.get(submissionVM.value, 'quoteID');

    const getDocuments = useCallback(async () => {
        try {
            const availableDocuments = await SubmissionService.getDocumentsForSubmission(
                quoteID,
                authHeader
            );
            setDocumentData(availableDocuments);
        } catch (e) {
            handleError(messages.modalError, messages.errorLoadingDocument);
        }
    }, [authHeader, handleError, quoteID]);

    useEffect(() => {
        const docUploadToken = async () => {
            try {
                const documentUploadTokenID = await GatewayDocumentService
                    .generateUploadToken(authHeader);
                return setUploadTokenID(documentUploadTokenID);
            } catch (e) {
                return handleError(
                    commonMessages.errorUploadTitle,
                    commonMessages.errorGenerateUploadToken
                );
            }
        };

        docUploadToken();
        getDocuments();

        if (!_.get(submissionVM, '_context.Binding', false)) {
            const vm = viewModelService.changeContext(submissionVM, {
                Binding: true
            });
            updateWizardData(vm);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUploadDocument = useCallback(
        async (file) => {
            const documentMetaDataTemplate = {
                docUID: '001',
                documentType: 'loss_history',
                securityType: 'unrestricted',
                status: 'approved',
                jobNumber: quoteID,
                name: file.name,
                mimeType: file.type,
                sessionID: uploadTokenID
            };
            try {
                const documentUploadData = await GatewayDocumentService.uploadDocument(
                    file,
                    documentMetaDataTemplate,
                    authHeader
                );
                setDocumentData([...documentData, documentUploadData]);
            } catch (error) {
                handleError(commonMessages.errorUploadTitle, commonMessages.uploadFailedMessage);
            }
        },
        [quoteID, uploadTokenID, authHeader, documentData, handleError]
    );

    const deleteDocument = useCallback(
        async (publicID) => {
            const isDeleteItem = await GatewayDocumentService.removeDocument(
                [publicID],
                authHeader
            );
            if (isDeleteItem) {
                const updatedData = documentData.filter((val) => val.publicID !== publicID);
                setDocumentData(updatedData);
            }
        },
        [documentData, authHeader]
    );

    const downloadDocument = useCallback(
        (publicID, sessionID) => {
            return GatewayDocumentService.downloadDocument(publicID, sessionID);
        },
        []
    );

    const onNext = useCallback(async () => {
        const updatedQuoteRequest = _.omit(submissionVM.value, 'bindData.paymentDetails');
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            updatedQuoteRequest,
            authHeader
        );
        return submissionVM;
    }, [submissionVM, authHeader]);

    const updatePolicyAddress = useCallback(
        (path, value) => {
            const policyAddressPath = 'submissionVM.baseData.policyAddress';
            if (path === policyAddressPath && !value.addressType) {
                return _.extend(value, { addressType: addressType });
            }
            return value;
        },
        [addressType]
    );

    const writeValue = useCallback(
        (value, path) => {
            // eslint-disable-next-line no-param-reassign
            value = updatePolicyAddress(path, value);
            // This is for updating values in the - coverables.dto.Wc7LocationsAndEmployeesDTO.
            if (/^selectedLocationAndEmployeesVM/gi.test(path)) {
                _.set(
                    selectedLocationAndEmployeesVM,
                    path.replace(/selectedLocationAndEmployeesVM./, ''),
                    value
                );
                const newSubmission = viewModelService.clone(selectedLocationAndEmployeesVM);
                updateSelectedLocationAndEmployeesVM(newSubmission);
            } else {
                _.set(submissionVM.value, path.replace(/submissionVM./, ''), value);
                updateWizardData(submissionVM);
            }
        },
        [
            submissionVM,
            updateWizardData,
            selectedLocationAndEmployeesVM,
            viewModelService,
            updatePolicyAddress
        ]
    );

    const getFormattedMessage = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.toIssueThePolicy}
                values={{
                    employeesNumberAndWages: (
                        <strong>{translator(messages.assignTheExactNumber)}</strong>
                    )
                }}
            />
        );
    }, [translator]);

    const uploadOnClick = useCallback((e) => {
        e.preventDefault();
        document.getElementById('uploadHereId').click();
    }, []);

    const getUploadTextLink = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.attachItHere}
                values={{
                    link: (
                        <Link href="/" onClick={uploadOnClick} className={styles.removeLinkStyle}>
                            {translator(messages.here)}
                        </Link>
                    )
                }}
            />
        );
    }, [translator, uploadOnClick]);

    const navigateToEmployeesLocation = useCallback(() => {
        const indexOfEmployeeAndLocations = _.findIndex(
            steps,
            ({ path }) => path === '/employees-and-locations'
        );
        jumpTo(indexOfEmployeeAndLocations);
    }, [jumpTo, steps]);

    const getSpreadsheetLink = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.haveManyLocationsAndEmployees}
                values={{
                    spreadsheet: (
                        <Link
                            href="/"
                            onClick={navigateToEmployeesLocation}
                            className={styles.removeLinkStyle}
                        >
                            {translator(messages.spreadsheet)}
                        </Link>
                    )
                }}
            />
        );
    }, [translator, navigateToEmployeesLocation]);

    const getEmployeeClassesAndLocationsData = useCallback(() => {
        const locationsAndEmployees = _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.locationsAndEmployees'
        );

        const employeesClassesJurisdictions = locationsAndEmployees.children.reduce((acc, item) => {
            const jurisdiction = _.get(item, 'location.address.state.value');
            return [
                ...acc,
                ...item.coveredEmployees.value.map((cv) => ({
                    jurisdiction: { id: jurisdiction.name, defaultMessage: jurisdiction.code },
                    classification: `${cv.classCode.code} - ${cv.classCode.classification}`,
                    annualWages: cv.basisAmount,
                    numberOfEmployees: cv.numberOfEmployees,
                    exposureClearBasisAmount: cv.exposureClearBasisAmount
                        ? translator(messages.ifAny)
                        : null
                }))
            ];
        }, []);

        return employeesClassesJurisdictions;
    }, [submissionVM, translator]);

    const getEmployeeClassesAndLocationsDataByState = useCallback(() => {
        if (selectedState.length) {
            const employeesClassesJurisdictions = [selectedLocationAndEmployeesVM.value].reduce(
                (acc, item) => {
                    const jurisdiction = _.get(item, 'location.address.state.value.name');
                    return [
                        ...acc,
                        ...item.coveredEmployees.map((cv) => ({
                            jurisdiction,
                            classification: `${cv.classCode.classification} - ${cv.classCode.code}`,
                            annualWages: cv.basisAmount,
                            isNotEditable: false,
                            numberOfEmployees: cv.numberOfEmployees,
                            exposureClearBasisAmount: cv.exposureClearBasisAmount
                                ? translator(messages.ifAny)
                                : null
                        }))
                    ];
                },
                []
            );

            return employeesClassesJurisdictions;
        }
        return [];
    }, [selectedLocationAndEmployeesVM, selectedState, translator]);

    const getUpdatedEmployeeClassesAndLocationsData = useCallback(() => {
        const getLocation = (address) => {
            return [
                address.addressLine1,
                address.addressLine2,
                address.addressLine3,
                address.city,
                address.state,
                address.postalCode
            ]
                .filter(Boolean)
                .join(', ');
        };
        const employeesClassesJurisdictions = locationAndCoveredEmployeeData.reduce((acc, item) => {
            return [
                ...acc,
                ...item.coveredEmployees.map((employeesLocation) => ({
                    displayName: getLocation(item.location.address),
                    locName: item.location.locationName,
                    classification: `${employeesLocation.classCode.code} - ${employeesLocation.classCode.classification}`,
                    annualWages: employeesLocation.basisAmount,
                    numberOfEmployees: employeesLocation.numberOfEmployees,
                    exposureClearBasisAmount: employeesLocation.exposureClearBasisAmount
                        ? translator(messages.ifAny)
                        : ''
                }))
            ];
        }, []);

        return employeesClassesJurisdictions;
    }, [locationAndCoveredEmployeeData, translator]);

    const handleAssign = useCallback(() => {
        setShowlocationAndEmployees(true);
        setShowEmployeeAndClassesContainer(false);
    }, []);

    const updateStateIds = useCallback(() => {
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const statesOverrides = useCallback(() => {
        const statesPath = 'lobData.wc7WorkersComp.coverables.primaryInsuredIds.stateIDs.value';
        const statesArray = _.get(submissionVM, statesPath, []);

        const openAllStates = statesArray.map(
            (state, index) => `additionalAccordionDetails${index}`
        );
        const openAllAccordionCards = {
            additionalAccordion: {
                accordionStates: openAllStates
            }
        };
        const overrides = statesArray.map((state, index) => {
            return {
                [`additionalAccordionDetails${index}`]: {
                    title: `${state.jurisdiction} ${translator(messages.officialId)}`
                },
                [`stateIdComponent${index}`]: {
                    updateStateIds
                }
            };
        });
        return Object.assign({}, ...overrides, openAllAccordionCards);
    }, [submissionVM, updateStateIds]);

    const setStates = useCallback(() => {
        const statesPath = 'lobData.wc7WorkersComp.coverables.locationsAndEmployees';
        const statesArray = _.get(submissionVM.value, statesPath, []);
        const states = statesArray.map(({ location }) => {
            return {
                code: location.address.state,
                name: translator({
                    id: `typekey.State.${location.address.state}`,
                    defaultMessage: location.address.state
                })
            };
        });

        return states;
    }, [submissionVM.value, translator]);

    const interstateOverrides = useCallback(() => {
        const interstatePath = 'lobData.wc7WorkersComp.coverables.primaryInsuredIds.interstateIDs.value';
        const interstateArray = _.get(submissionVM, interstatePath, []);
        const overrides = interstateArray.map((officialID, index) => {
            return {
                [`interstateIDs${index}`]: {
                    label: officialID.officialIDType
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [submissionVM]);

    const setPrimaryAddress = useCallback(
        (locationsAndEmployees, state) => {
            const isPrimaryLocationPresent = locationsAndEmployees.value.some(
                ({ location }) => location.isPrimaryLocation
            );
            const primaryLocation = locationsAndEmployees.value.find(
                ({ location }) => location.isPrimaryLocation
            );
            const initialLocationContext = {
                address: {
                    state: state,
                    country: localeService.getDefaultCountryCode()
                },
                isNonSpecificLocation: true,
                isTemplateLocation: false
            };
            if (!isPrimaryLocationPresent) {
                return initialLocationContext;
            }
            return _.cloneDeep(primaryLocation.location);
        },
        [localeService]
    );

    const getSelectedLocationAndEmployees = useCallback(
        (state) => {
            const locationsAndEmployees = _.get(
                submissionVM,
                'lobData.wc7WorkersComp.coverables.locationsAndEmployees'
            );
            const selectedLocationAndEmployees = locationsAndEmployees.value.find(
                ({ location }) => {
                    return location.address.state === state;
                }
            );
            if (selectedLocationAndEmployees.location.isPrimaryLocation) {
                selectedLocationAndEmployees.location = setPrimaryAddress(
                    locationsAndEmployees,
                    state
                );
            }

            const selectedLocations = viewModelService.create(
                selectedLocationAndEmployees,
                'pc',
                locationDTOName
            );
            updateSelectedLocationAndEmployeesVM(() => selectedLocations);
            updateSelectedState(state);
            setEditLocationFormVisible(true);
            setAddEmployeesClasses(false);
            setUpdatedLocationTable(false);
        },
        [
            setPrimaryAddress,
            submissionVM,
            viewModelService,
            updateSelectedState,
            setEditLocationFormVisible
        ]
    );

    const getEmployeesClasses = useCallback((item) => {
        const employeeClassValue = item.classification;
        return <div>{employeeClassValue}</div>;
    }, []);

    const removeEmployee = useCallback(
        (index) => {
            const newSubmission = viewModelService.clone(selectedLocationAndEmployeesVM);
            newSubmission.coveredEmployees.value.splice(index, 1);
            updateSelectedLocationAndEmployeesVM(() => newSubmission);
        },
        [selectedLocationAndEmployeesVM, viewModelService]
    );
    const getRemoveButton = useCallback(
        (data, id) => {
            return (
                <div>
                    <Button
                        size="medium"
                        type="filled"
                        onClick={() => removeEmployee(id)}
                        className={styles.addEmployeeButton}
                    >
                        {translator(messages.remove)}
                    </Button>
                </div>
            );
        },
        [removeEmployee, translator]
    );

    const updateCoveredEmployeesValue = useCallback(
        (value, index, path) => {
            const newSubmission = viewModelService.clone(selectedLocationAndEmployeesVM);
            _.set(newSubmission.value, `coveredEmployees[${index}][${path}]`, value);
            updateSelectedLocationAndEmployeesVM(newSubmission);
        },
        [selectedLocationAndEmployeesVM, viewModelService]
    );

    const getAnnualWages = useCallback(
        (data, id, { path }) => {
            let annualWagesTag = false;
            if (data.exposureClearBasisAmount && !data.basisAmount) {
                annualWagesTag = true;
            }
            return data.isNotEditable ? (
                <div>{data.annualWages}</div>
            ) : (
                <div>
                    <InputNumberField
                        id="annualWagesField"
                        type="field"
                        onValueChange={(value) => updateCoveredEmployeesValue(value, id, path)}
                        value={data.annualWages}
                        disabled={annualWagesTag}
                        className={styles.annualWages}
                    />
                </div>
            );
        },
        [updateCoveredEmployeesValue]
    );

    const getNumberOfEmployees = useCallback(
        (data, index, { path }) => {
            return data.isNotEditable ? (
                <div>{data.numberOfEmployees}</div>
            ) : (
                <div>
                    <InputNumberField
                        id="noOfEmployeesField"
                        type="field"
                        onValueChange={(e) => updateCoveredEmployeesValue(e, index, path)}
                        value={data.numberOfEmployees}
                        className={styles.noOfEmployees}
                    />
                </div>
            );
        },
        [updateCoveredEmployeesValue]
    );

    const getIfAnyEmployee = useCallback(
        (item, id, { path }) => {
            const labelName = translator(messages.ifAny);
            const ifAnyLabel = true;
            return item.isNotEditable ? (
                <div>{item.exposureClearBasisAmount && labelName}</div>
            ) : (
                <div>
                    <CheckboxField
                        id="ifAnyEmployeeCheckBox"
                        onValueChange={(e) => updateCoveredEmployeesValue(e, id, path)}
                        value={!!item.exposureClearBasisAmount}
                        label={labelName}
                        labelClassName={styles.labelStyle}
                        showInlineLabel={ifAnyLabel}
                    />
                </div>
            );
        },
        [updateCoveredEmployeesValue, translator]
    );

    const saveSelectedEmployeesHandler = useCallback(() => {
        setAddEmployeesClasses(true);
    }, []);

    const continueButtonHandler = useCallback(() => {
        setUpdatedLocationTable(true);
        setContinue(true);
    }, []);

    const updateLocationAndCoveredEmployee = useCallback(() => {
        const sessionUUID = _.get(submissionVM.value, 'sessionUUID');
        const updatedCoveredEmployeesRequest = _.cloneDeep(selectedLocationAndEmployeesVM.value);

        if (updatedCoveredEmployeesRequest.location.isPrimaryLocation) {
            const removePublicIDs = updatedCoveredEmployeesRequest.coveredEmployees.map(
                (coveredEmployees) => {
                    return _.omit(coveredEmployees, ['publicID', 'address.publicID']);
                }
            );
            updatedCoveredEmployeesRequest.coveredEmployees = removePublicIDs;
        }
        Wc7CoverablesService.updateLocationWithCoveredEmployees(
            quoteID,
            sessionUUID,
            updatedCoveredEmployeesRequest,
            authHeader
        ).then((updatedCoveredEmployeesResponse) => {
            setLocationAndCoveredEmployeeData(() => {
                return [updatedCoveredEmployeesResponse];
            });
            setUpdatedLocationTable(true);
        });
    }, [submissionVM, selectedLocationAndEmployeesVM, quoteID, authHeader]);

    const cancelLocationAndCoveredEmployee = useCallback(() => {
        setAddEmployeesClasses(false);
        setEditLocationFormVisible(false);
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        assignBtn: {
            onClick: handleAssign
        },
        employeeClassesAndLocation: {
            data: getEmployeeClassesAndLocationsData()
        },
        locationAndEmployees: {
            visible: showlocationAndEmployees
        },
        employeeAndClassesContainer: {
            visible: showEmployeeAndClassesContainer
        },
        state: {
            availableValues: setStates(),
            onValueChange: getSelectedLocationAndEmployees,
            value: selectedState
        },
        spreadSheetContainer: {
            visible: !isContinue
        },
        selectEmployeesClassesContinue: {
            visible: !isContinue
        },
        editLocation: {
            visible: !isContinue
        },
        editLocationForm: {
            visible: showEditLocationForm && !showUpdatedLocationTable
        },
        addEmployeesClasses: {
            visible: showAddEmployeesClasses && !showUpdatedLocationTable
        },
        saveButton: {
            visible: showEditLocationForm,
            disabled: !(
                selectedLocationAndEmployeesVM
                && selectedLocationAndEmployeesVM.aspects.valid
                && selectedLocationAndEmployeesVM.aspects.subtreeValid
            )
        },
        lossDocuments: {
            initialDocumentsData: documentData,
            deleteDocument: deleteDocument,
            downloadDocument: downloadDocument
        },
        wc7AddLocationTable: {
            data: getEmployeeClassesAndLocationsDataByState()
        },
        wc7UpdatedLocationTableContainer: {
            visible: showUpdatedLocationTable
        },
        wc7UpdatedLocationTable: {
            data: getUpdatedEmployeeClassesAndLocationsData()
        },
        continueButton: {
            visible: !isContinue
        },
        ...interstateOverrides(),
        ...statesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            uploadOnClick: uploadOnClick,
            getCell: getCell,
            displayName: displayName,
            handleAssign: handleAssign,
            onUploadDocument: onUploadDocument,
            getEmployeesClasses: getEmployeesClasses,
            getAnnualWages: getAnnualWages,
            getNumberOfEmployees: getNumberOfEmployees,
            getIfAnyEmployee: getIfAnyEmployee,
            getRemoveButton: getRemoveButton,
            updateLocationAndCoveredEmployee: updateLocationAndCoveredEmployee,
            cancelLocationAndCoveredEmployee: cancelLocationAndCoveredEmployee,
            saveSelectedEmployeesHandler: saveSelectedEmployeesHandler,
            continueButtonHandler: continueButtonHandler,
            onValidate: onValidate,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
        },
        resolveComponentMap: {
            formattedmessage: getFormattedMessage,
            uploadtextwithlink: getUploadTextLink,
            spreadsheetlink: getSpreadsheetLink,
            documentscomponent: DocumentComponent,
            stateidcomponent: StateIdComponent
        }
    };

    const validateStates = useCallback(() => {
        return _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.primaryInsuredIds.stateIDs.aspects.subtreeValid'
        );
    }, [submissionVM]);

    const model = { submissionVM, selectedLocationAndEmployeesVM };

    useEffect(() => {
        registerInitialComponentValidation(validateStates);
    }, [validateStates, registerInitialComponentValidation]);

    return (
        <WizardPage
            disableNext={!isComponentValid}
            nextLabel={translator(messages.finalQuote)}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={model}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

AdditionalInformationPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired
};
AdditionalInformationPage.propTypes = pageTemplateProps;

export default withAuthenticationContext(AdditionalInformationPage);
