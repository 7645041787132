import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

// Default page size
const PAGE_SIZE = 10;

export default class ContactService {
    static getAccountContacts(
        accountNumber,
        additionalHeaders = {},
        roles = [],
        fromPage = 0,
        pageSize = PAGE_SIZE
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayContact'),
            'getMainAccountContacts',
            [
                accountNumber,
                roles,
                fromPage,
                pageSize
            ],
            additionalHeaders
        );
    }

    static getAccountContactDetails(contactId, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayContact'),
            'getAccountContactDetails',
            [contactId],
            additionalHeaders
        );
    }

    static getRelatedAccountContacts(
        accountNumber,
        additionalHeaders = {},
        relationships = [],
        fromPage = 0,
        pageSize = PAGE_SIZE
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayContact'),
            'getRelatedAccountContacts',
            [
                accountNumber,
                fromPage,
                pageSize,
                relationships
            ],
            additionalHeaders
        );
    }

    static getCommonAccountContacts(
        accountNumber,
        additionalHeaders = {},
        fromPage = 0,
        pageSize = PAGE_SIZE
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayContact'),
            'getCommonAccountContacts',
            [
                accountNumber,
                fromPage,
                pageSize
            ],
            additionalHeaders
        );
    }

    static getPolicyContactSummaries(
        policyNumber,
        additionalHeaders = {},
        roles = [],
        fromPage = 0,
        pageSize = PAGE_SIZE
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayContact'),
            'getPolicyContactSummaries',
            [
                policyNumber,
                roles,
                fromPage,
                pageSize
            ],
            additionalHeaders
        );
    }

    static getPolicyContactDetails(
        contactId,
        policyNumber,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayContact'),
            'getPolicyContactDetails',
            [
                contactId,
                policyNumber
            ],
            additionalHeaders
        );
    }
}
