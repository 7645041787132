import React, { Component } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { UserService } from 'gw-capability-gateway';
import metadata from './PageHeaderComponent.metadata.json5';

import pageHeaderStyles from './PageHeaderComponent.module.scss';
import './PageHeaderComponent.messages';

class PageHeaderComponent extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
            location: PropTypes.shape({
                pathname: PropTypes.string
            })
        }).isRequired,
        logout: PropTypes.func.isRequired,
        user_name: PropTypes.string,
        authUserData: PropTypes.shape({
            displayName: PropTypes.string,
            userType: PropTypes.string
        })
    };

    static defaultProps = {
        user_name: '',
        authUserData: {
            displayName: '',
            userType: ''
        }
    };

    state = {
        searchText: '',
        isLogout: false,
        showError: false,
        showStartNewButton: false,
        canCreateAccount: false
    };

    componentDidMount() {
        this.getShowStartNewQuoteButton();
        this.showNewQuoteButton();
    }

    getShowStartNewQuoteButton = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'createsubmission'
        };
        const showStartNewButton = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ showStartNewButton });
    };

    showNewQuoteButton = async () => {
        const { authHeader } = this.props;
        const accountPermCheckDTO = {
            permEntityType: 'Account',
            permEntityID: null,
            permission: 'create',
            isCheckPermEntity: false
        };

        const canCreateAccount = await UserService.hasUserPermission(
            accountPermCheckDTO,
            authHeader
        );
        this.setState({ canCreateAccount });
    };

    writeValue = (value) => {
        this.setState({ searchText: value });
    };

    goToFaq = () => {
        const { history } = this.props;
        history.push('/faq');
    };

    goToPreference = () => {
        const { history } = this.props;
        history.push('/preferences');
    };

    goToLogout = () => {
        const { isLogout } = this.state;
        const { logout } = this.props;
        if (!isLogout) {
            logout();
            this.setState({
                isLogout: true
            });
        }
    };

    goToNewQuote = () => {
        const { history } = this.props;
        const fromLocation = history.location.pathname;
        const { lobQuoteURL } = appConfig;
        const lobPaths = Object.values(lobQuoteURL) || [];
        const fromLOB = lobPaths.filter((value) => fromLocation.indexOf(value) !== -1);

        const previousLocation = {
            prevLocation: !!fromLOB.length
        };
        return history.push('/new-quote-account-search', previousLocation);
    };

    goToQuoteThisAccount = () => {
        const { history } = this.props;
        const {
            history: {
                location: { pathname }
            }
        } = this.props;
        const accountNumber = pathname.match('accounts/(.*)/(.*)');
        return history.push(`/new-quote/${accountNumber[1]}`);
    };

    render() {
        const {
            searchText, showError, canCreateAccount, showStartNewButton
        } = this.state;
        const {
            history,
            user_name: userName,
            authUserData
        } = this.props;
        const resolvers = {
            resolveClassNameMap: pageHeaderStyles,
            resolveCallbackMap: {
                goToFaq: this.goToFaq,
                goToPreference: this.goToPreference,
                goToLogout: this.goToLogout,
                goToNewQuote: this.goToNewQuote,
                goToQuoteThisAccount: this.goToQuoteThisAccount
            }
        };
        const overrideProps = {
            headerSearchBox: {
                onEnter: () => {
                    if (!searchText) {
                        this.setState({
                            showError: true
                        });
                        return false;
                    }
                    return history.push(`/search/${searchText}`);
                },
                onValueChange: this.writeValue,
                value: searchText,
                showErrors: showError
            },
            gwAuthStatus: {
                username: userName,
                title: authUserData.displayName,
                subtitle: _.capitalize(authUserData.userType)
            },
            startNewQuoteBtn: {
                visible: canCreateAccount && showStartNewButton
            }
        };

        return (
            <MetadataContent
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        );
    }
}

export default withRouter(withAuthenticationContext(PageHeaderComponent));
