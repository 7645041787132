import React from 'react';
import { Chevron } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import PolicyCoverage from '../PolicyCoverage/PolicyCoverage';
import metadata from './PolicyDwellingInfo.metadata.json5';
import styles from '../../Summary.module.scss';
import messages from './PolicyDwellingInfo.messages';

const getHeaderSection = (title) => {
    return (isOpen) => (
        <React.Fragment>
            <Chevron isOpen={isOpen} className={styles.chevronStyle} />
            <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>{title}</h2>
        </React.Fragment>
    );
};
function PolicyDwellingInfo(props) {
    const translator = useTranslator();
    const translateYesNo = (keyObject) => {
        const object = _.cloneDeep(keyObject);
        const appendingYear = (obj, key) => {
            let result = '';
            const mappingYear = {
                heatingUpgraded: 'heatingUpgradeYear',
                plumbingUpgraded: 'plumbingUpgradeYear',
                wiringUpgraded: 'wiringUpgradeYear',
                roofUpgraded: 'roofUpgradeYear'
            };
            const getDisplayYearKey = mappingYear[key];
            if (getDisplayYearKey && obj[key]) {
                result = `(${obj[getDisplayYearKey]})`;
            }
            return result;
        };
        if (!_.isEmpty(object)) {
            _.map(object, (value, key) => {
                if (typeof value === 'boolean') {
                    object[key] = `${translator(
                        value ? messages.infoYes : messages.infoNo
                    )} ${appendingYear(object, key)}`;
                }
            });
        }
        return object;
    };
    const updateCoverages = (homeOwnersData) => {
        const houseOwners = _.cloneDeep(homeOwnersData);
        const coverages = houseOwners.coverageDTOs.concat(houseOwners.dwellingDTO.coverageDTOs);
        const allCoverages = {
            sectionICoverages: [],
            sectionIICoverages: [],
            additionalLiabilityCoverages: [],
            additionalPropertyCoverages: [],
            scheduledItems: []
        };
        coverages.forEach((coverage) => {
            const cov = coverage;
            switch (cov.categoryCodeIdentifier) {
                case 'HOPSectionICovCat':
                case 'HODW_SectionI_HOE':
                    allCoverages.sectionICoverages.push(cov);
                    break;
                case 'HOPSectionIICovCat':
                case 'HODW_SectionII_HOE':
                    allCoverages.sectionIICoverages.push(cov);
                    break;
                case 'HOPAdditionalCovCat':
                case 'HOLI_Additional_HOE':
                    allCoverages.additionalLiabilityCoverages.push(cov);
                    break;
                case 'HOPPCAdditionalCovCat':
                case 'HODW_Additional_HOE':
                    allCoverages.additionalPropertyCoverages.push(cov);
                    break;
                case 'HOPScheduledItemCovCat':
                case 'HODW_SchedulesCov_HOE':
                    if (cov.specialLimits) {
                        cov.specialLimits.forEach((coverageItem) => {
                            allCoverages.scheduledItems.push(coverageItem);
                        });
                    } else if (cov.scheduledProperty) {
                        cov.scheduledProperty.forEach((coverageItem) => {
                            allCoverages.scheduledItems.push(coverageItem);
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        return allCoverages;
    };
    const getFormattedInfo = (message, key, value) => {
        return (
            <FormattedMessage
                {...message}
                values={{
                    [key]: value
                }}
            />
        );
    };
    const getHomeOwnersData = () => {
        // eslint-disable-next-line react/destructuring-assignment
        const homeOwnersData = _.cloneDeep(props.value);
        if (!_.isEmpty(homeOwnersData.dwellingDTO.homeDetails)) {
            homeOwnersData.dwellingDTO.homeDetails = translateYesNo(
                homeOwnersData.dwellingDTO.homeDetails
            );
            _.set(
                homeOwnersData,
                'dwellingDTO.homeDetails.distanceToFireHydrant',
                getFormattedInfo(
                    messages.FeetToFireHydrant,
                    'distance',
                    homeOwnersData.dwellingDTO.homeDetails.distanceToFireHydrant
                )
            );
            _.set(
                homeOwnersData,
                'dwellingDTO.homeDetails.distanceToFireStation',
                getFormattedInfo(
                    messages.MilesToFireStation,
                    'miles',
                    homeOwnersData.dwellingDTO.homeDetails.distanceToFireStation
                )
            );
        }
        if (!_.isEmpty(homeOwnersData.dwellingDTO.constructionDetails)) {
            homeOwnersData.dwellingDTO.constructionDetails = translateYesNo(
                homeOwnersData.dwellingDTO.constructionDetails
            );
        }
        if (!_.isEmpty(homeOwnersData.dwellingDTO.miscDetails)) {
            homeOwnersData.dwellingDTO.miscDetails = translateYesNo(
                homeOwnersData.dwellingDTO.miscDetails
            );
        }
        if (_.isEmpty(homeOwnersData.dwellingDTO.mortgageeDetailsDTO)) {
            homeOwnersData.mortgageeDetailsDTO = [];
        }
        homeOwnersData.allCoverages = updateCoverages(homeOwnersData);
        return homeOwnersData;
    };
    const homeOwnersData = getHomeOwnersData();

    if (_.isEmpty(homeOwnersData)) {
        return null;
    }

    const overrides = {
        homeDetailsCard: {
            renderHeader: getHeaderSection(translator(messages.HomeDetails))
        },
        constructionDetailsCard: {
            renderHeader: getHeaderSection(translator(messages.ConstructionDetails))
        },
        protectionDetailsCard: {
            renderHeader: getHeaderSection(translator(messages.ProtectionDetails))
        },
        addressDisplayName: {
            content: _.get(homeOwnersData, 'dwellingDTO.address.displayName', '')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            policycoveragecomponent: PolicyCoverage
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={homeOwnersData}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PolicyDwellingInfo.propTypes = {
    value: PropTypes.shape({}).isRequired
};
export default PolicyDwellingInfo;
