import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import styles from './ContactRoles.module.scss';
import messages from './ContactRoles.messages';

class ContactRoles extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        relatedRoles: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                subtype: PropTypes.string
            })
        ).isRequired,
        associatedContacts: PropTypes.string.isRequired,
        contactType: PropTypes.string.isRequired
    };

    contractRoleLabor = [
        'inclusion',
        'state',
        'contractEffectiveDate',
        'contractExpirationDate',
        'workLocation',
        'numberOfEmployees'
    ];

    contactRoles = {
        additionalInterestRoles: ['type', 'certificateRequired', 'contractNumber', 'interest'],
        driverRoles: [
            'dateCompletedTrainingClass',
            'trainingClassType',
            'goodStudent',
            'yearLicensed',
            'licenseNumber',
            'licenseState',
            'goodDriverDiscount',
            'applicableGoodDriverDiscount',
            'excluded',
            'accidents',
            'violations',
            'policyLevelNumberOfAccidents',
            'policyLevelNumberOfViolations'
        ],
        laborClientRoles: this.contractRoleLabor,
        laborContractorRoles: this.contractRoleLabor,
        namedInsuredRoles: [
            'orgType',
            'industryCode',
            'industryCodeClassification',
            'busOpDescription'
        ],
        ownerOfficerRoles: [
            'relationshipTitle',
            'policyRelationship',
            'inclusion',
            'state',
            'classCode',
            'ownershipPercentage'
        ]
    };

    renderRoleValue = (roleTitle, value, translator) => {
        let roleValue = '';

        switch (value) {
            case 'true':
                roleValue = translator(messages.yes);
                break;
            case 'false':
                roleValue = translator(messages.no);
                break;
            default:
                roleValue = value;
        }

        if (roleTitle === 'licenseState') {
            roleValue = translator({
                id: `typekey.State.${roleValue}`,
                defaultMessage: `typekey.State.${roleValue}`
            });
        }

        return roleValue;
    };

    getOrderedRoleOptions = (role) => {
        const { contactType } = this.props;
        let orderedRoleOptions = {};
        const roleType = _.camelCase(role.subtype);

        if (roleType === 'namedInsured' && contactType === 'Person') {
            return null;
        }

        if (this.contactRoles[`${roleType}Roles`]) {
            this.contactRoles[`${roleType}Roles`].forEach((contact) => {
                if (role[roleType] && contact in role[roleType]) {
                    orderedRoleOptions[contact] = role[roleType][contact];
                }
            });
        } else {
            orderedRoleOptions = role[roleType];
        }

        return orderedRoleOptions;
    };

    renderRole = (role, translator) => {
        const { relatedRoles } = this.props;
        const roleOptions = _.camelCase(role.subtype);

        const orderedRole = this.getOrderedRoleOptions(role);
        const contactRoles = orderedRole && Object.keys(orderedRole).map((contact) => {
            const roleTitle = roleOptions === 'driver'
                && (contact === 'accidents' || contact === 'violations')
                ? _.camelCase(`${roleOptions}_${relatedRoles}_${contact}`)
                : _.camelCase(`${roleOptions}_${contact}`);
            return (
                <div className={styles.gwContactRoleContainer}>
                    <div className={styles.gwContactRoleTitle}>
                        {translator(messages[roleTitle])}
                    </div>
                    <div className={styles.gwContactRoleValue}>
                        {this.renderRoleValue(
                            contact,
                            orderedRole[contact].toString(),
                            translator
                        )}
                    </div>
                </div>
            );
        });
        return contactRoles;
    };

    getPolicyRoles = (roles) => {
        return roles.map((role) => {
            const accountRoles = role
                .accountContactRole[_.camelCase(role.accountContactRole.subtype)];
            const policiesRoles = role[_.camelCase(role.subtype)];
            const modifiedRoles = { ...accountRoles, ...policiesRoles };
            const omitKey = ['accidents', 'violations'];

            omitKey.forEach((key, index) => {
                if (!modifiedRoles[key]) {
                    omitKey[index] = null;
                }
            });

            return {
                [_.camelCase(role.accountContactRole.subtype)]: _.omit(modifiedRoles, omitKey),
                subtype: role.accountContactRole.subtype
            };
        });
    };

    render() {
        const { roles, associatedContacts } = this.props;
        const translator = this.context;

        const filteredRoles = associatedContacts === 'policyContacts' ? this.getPolicyRoles(roles) : roles;

        const roleList = filteredRoles.map((role) => {
            return (
                <div>
                    <h4 className={styles.gwContactRoleHeader}>
                        {translator(messages[_.camelCase(role.subtype)])}
                    </h4>
                    <Grid columns={['1fr']} gap="none">
                        {this.renderRole(role, translator)}
                    </Grid>
                </div>
            );
        });

        return <div>{roleList}</div>;
    }
}

export default ContactRoles;
