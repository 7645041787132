import { defineMessages } from 'react-intl';

export default defineMessages({
    doNotChange: {
        id: 'gateway.directives.change-policy.Do Not Change',
        defaultMessage: 'Do Not Change'
    },
    startChange: {
        id: 'gateway.directives.change-policy.Start Change',
        defaultMessage: 'Start Change'
    },
    changePolicy: {
        id: 'gateway.directives.change-policy.Change Policy',
        defaultMessage: 'Change Policy'
    },
    effectiveDate: {
        id: 'gateway.directives.change-policy.Effective Date',
        defaultMessage: 'Effective Date'
    },
    description: {
        id: 'gateway.directives.change-policy.Description',
        defaultMessage: 'Description'
    }
});
