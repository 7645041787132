import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { DocumentUploadService } from 'gw-portals-document-js';

export default class GatewayClaimService {
    static getClaimDetail(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getClaimDetail',
            [claimNumber],
            additionalHeaders
        );
    }

    static getClaimNotes(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getClaimNotes',
            [claimNumber],
            additionalHeaders
        );
    }

    static createClaimNote(claimNumber, noteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'createClaimNote',
            [claimNumber, noteDTO],
            additionalHeaders
        );
    }

    static updateClaimNote(claimNumber, note, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'updateClaimNote',
            [claimNumber, note],
            additionalHeaders
        );
    }

    static deleteClaimNote(claimId, noteId, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'deleteClaimNote',
            [claimId, noteId],
            additionalHeaders
        );
    }

    static getRecentlyViewedClaims(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getRecentlyViewedClaims',
            [],
            additionalHeaders
        );
    }

    static addRecentlyViewedClaim(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'addRecentlyViewedClaim',
            [claimNumber],
            additionalHeaders
        );
    }

    static getClaimsForCurrentUser(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getClaimsForCurrentUser',
            data,
            additionalHeaders
        );
    }

    static getOpenClaimsForCurrentUser(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getOpenClaimsForCurrentUser',
            data,
            additionalHeaders
        );
    }

    static getClosedClaimsForCurrentUser(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getClosedClaimsForCurrentUser',
            data,
            additionalHeaders
        );
    }

    static getClaimsForProducerCode(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getClaimsForProducerCode',
            data,
            additionalHeaders
        );
    }

    static getOpenClaimsForProducerCode(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getOpenClaimsForProducerCode',
            data,
            additionalHeaders
        );
    }

    static getClosedClaimsForProducerCode(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaim'),
            'getClosedClaimsForProducerCode',
            data,
            additionalHeaders
        );
    }

    static claimsDocUploadToken(params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaimDocs'),
            'generateUploadToken',
            params,
            additionalHeaders
        );
    }

    static claimsRemoveDocument(params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayClaimDocs'),
            'removeDocument',
            params,
            additionalHeaders
        );
    }

    static uploadDocument(file, documentMetadata, additionalHeaders = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('uploadDocument'), file, documentMetadata, additionalHeaders);
    }
}
