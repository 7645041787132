import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { CustomizedHeaderComponent, Currency as CurrencyField } from 'gw-components-platform-react';
import { CommissionService } from 'gw-capability-gateway-commission';
import { SelectProducerCode, withProducerContext } from 'gw-gateway-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './CommissionLanding.metadata.json5';
import messages from './CommissionLanding.messages';
import styles from './CommissionLanding.module.scss';

class CommissionLanding extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        unSortedProducerCodeList: PropTypes.arrayOf(
            PropTypes.shape({}).isRequired
        ).isRequired,
        intl: PropTypes.func.isRequired,
    };

    state = {
        currentView: 'mtdAndYtdTile',
        selectedProducerCode: 'everything',
        mtdAndYtdTileVisible: true,
        statementTile: false,
        showCommission: false,
        statementsArray: [],
        selectedYearStatements: [],
        selectedYear: '',
        selectedStatement: {},
        selectedStatementPolicies: [],
        years: []
    };

    handleTilesOnClick = (id) => {
        const { showCommission } = this.state;
        this.setTile(id);
        this.setState({
            currentView: id,
            showCommission: !showCommission
        });
    };

    setTile = (id) => {
        let mtdTile = false;
        let statementTile = false;
        switch (id) {
            case 'mtdAndYtdTile':
                mtdTile = true;
                break;
            case 'statementTile':
                statementTile = true;
                break;
            default:
                break;
        }
        this.setState({
            mtdAndYtdTileVisible: mtdTile,
            statementTile: statementTile
        });
    };

    setYearForStatement = (statements) => {
        return statements.map((statement) => {
            return {
                date: statement.date,
                statement: statement.statement,
                payment: statement.payment,
                year: parseInt(statement.date, 10)
            };
        });
    };

    getStatementsForProducerCode = (producerCode) => {
        const { authHeader, unSortedProducerCodeList } = this.props;
        const [producerDetails] = unSortedProducerCodeList;

        CommissionService.getStatements(producerDetails.code, authHeader).then((response) => {
            if (response.length > 0) {
                const statementsResponse = this.setYearForStatement(response);
                const years = this.getAvailableYearValues(statementsResponse);
                const [year] = years;
                const initialYearStatements = statementsResponse
                    .filter((val) => val.year === year.code)
                    .reverse();
                const [initialYearStatement] = initialYearStatements;
                this.setState({
                    statementsArray: statementsResponse,
                    selectedYearStatements: initialYearStatements,
                    selectedYear: year.code,
                    selectedStatement: initialYearStatement,
                    years: years,
                    selectedProducerCode: producerCode
                });
                this.updateStatementPolicies(initialYearStatement);
            }
        });
    };

    getStatementNumber = (item) => {
        const translator = this.context;
        return translator(messages.statementNumber, {
            num: item.statement
        });
    };

    getLink = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            policyNumber: translator('gateway.directives.ReportStatementsDirective.Policy Number'),
            account: translator('gateway.directives.ReportStatementsDirective.Account')
        };
        const redirectpath = property.id === 'policyNumber'
            ? `/policies/${item[property.id]}/commission`
            : `/accounts/${item.accountNumber}/commission`;
        const redirectRoute = {
            pathname: redirectpath,
            state: 'commission'
        };
        return (
            <Link to={redirectRoute} title={toolTipMessage[property.id]}>
                {item[property.id]}
            </Link>
        );
    };

    getCell = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            earnedItems: translator('gateway.directives.ReportStatementsDirective.Earned Items')
        };
        return (
            <span title={toolTipMessage[property.id]}>
                {item[property.id]}
            </span>
        );
    };

    getCurrencyCell = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            commission: translator('gateway.directives.ReportStatementsDirective.Commission')
        };
        const negativeCommission = classNames({
            [styles.negativeCommission]: item[property.id].amount < 0
        });
        return (
            <span className={negativeCommission} title={toolTipMessage[property.id]}>
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    datatype="object"
                    title={toolTipMessage}
                    readOnly
                    hideLabel
                />
            </span>
        );
    };

    handleStatementSelection = (item) => {
        const { intl } = this.props;
        this.setState({
            selectedStatement: item
        });
        this.getStatementHeader(item, intl);
        this.updateStatementPolicies(item);
    };

    generateStatementOverrides = () => {
        const { selectedYearStatements, selectedStatement } = this.state;
        const setClassNames = (item) => {
            return classNames(styles.statementItemsContainer, {
                [styles.statementSelectedTile]: selectedStatement.statement === item.statement
            });
        };
        const overrides = selectedYearStatements.map((item, index) => {
            return {
                [`statementItemsContainer${index}`]: {
                    onClick: () => {
                        this.handleStatementSelection(item);
                    },
                    className: setClassNames(item)
                },
                [`statementItemSecondRow${index}`]: {
                    value: this.getStatementNumber(item)
                },
                [`statementDate${index}`]: {
                    value: new Date(item.date)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    getStatementHeader = (selectedStatement, intl) => {
        const { selectedYearStatements } = this.state;
        if (selectedYearStatements.length === 0) {
            return '';
        }
        if (_.isEmpty(selectedStatement)) {
            const [selectedYearStatement] = selectedYearStatements;
            const date = intl.formatDate(selectedYearStatement.date, { year: 'numeric', month: 'long', day: 'numeric' });
            const statementNumber = this.getStatementNumber(selectedYearStatement);
            const statementHeader = `${date} - ${statementNumber}`;
            return statementHeader;
        }
        const date = intl.formatDate(selectedStatement.date, { year: 'numeric', month: 'long', day: 'numeric' });
        const statementNumber = this.getStatementNumber(selectedStatement);
        const statementHeader = `${date} - ${statementNumber}`;
        return statementHeader;
    };

    getStatementBalance = (policies) => {
        const translator = this.context;
        if (!policies || policies.length === 0) {
            return null;
        }
        const totalCommission = policies.reduce(
            (prevItem, item) => {
                return {
                    ...prevItem,
                    amount: prevItem.amount + item.commission.amount,
                    currency: item.commission.currency
                };
            },
            {
                amount: 0
            }
        );
        const totalContent = translator(messages.statementTotalBalance);
        const negativeCommission = classNames({
            [styles.negativeCommission]: totalCommission.amount < 0
        });
        return (
            <div className={styles.statementBalanceContainer}>
                <span className={styles.totalHeader}>{totalContent}</span>
                <span className={negativeCommission}>
                    <CurrencyField
                        id="currency"
                        hideLabel
                        readOnly
                        value={totalCommission}
                        datatype="object"
                    />
                </span>
            </div>
        );
    };

    getStatementBalanceVisibility = (policies = []) => {
        return policies.length > 0;
    };

    updateStatementPolicies = (statementObj) => {
        const { selectedYearStatements } = this.state;
        const { authHeader } = this.props;
        if (!_.isEmpty(selectedYearStatements)) {
            const [selectedYearStatement] = selectedYearStatements;
            const selectedStatement = !_.isEmpty(statementObj)
                ? statementObj.statement
                : selectedYearStatement.statement;
            CommissionService.getStatementPolicies(selectedStatement, authHeader).then(
                (statementPolicies) => {
                    this.setState({
                        selectedStatementPolicies: statementPolicies
                    });
                }
            );
        }
        return '';
    };

    getStatementPoliciesTable = (policies) => {
        return policies.map((policy) => {
            return {
                policyNumber: policy.policyNumber,
                account: policy.account,
                earnedItems: policy.earnedItems,
                commissions: policy.commission,
                accountNumber: policy.accountNumber
            };
        });
    };

    getAvailableYearValues = (statementsResponse) => {
        const years = statementsResponse.reduce((list, { year }) => {
            return list.includes(year) ? list : [...list, year];
        }, []);
        return years.map((year) => {
            return {
                code: year,
                name: year
            };
        });
    };

    handleStatementYears = (value) => {
        const { statementsArray } = this.state;
        const { intl } = this.props;
        const selectedYearStatementsArray = statementsArray
            .filter((val) => val.year === parseInt(value, 10))
            .reverse();
        const [selectedYearStatement] = selectedYearStatementsArray;
        this.setState({
            selectedYearStatements: selectedYearStatementsArray,
            selectedYear: value,
            selectedStatement: selectedYearStatement
        });
        this.getStatementHeader(selectedYearStatement, intl);
        this.updateStatementPolicies(selectedYearStatement);
    };

    render() {
        const { intl } = this.props;
        const {
            selectedProducerCode,
            mtdAndYtdTileVisible,
            statementTile,
            currentView,
            showCommission,
            selectedStatement,
            selectedStatementPolicies,
            selectedYear,
            selectedYearStatements,
            years
        } = this.state;
        const overrideProps = {
            [currentView]: {
                active: false
            },
            availableProducerCode: {
                onValueChange: this.getStatementsForProducerCode,
                value: selectedProducerCode
            },
            mtdAndYtdTile: {
                active: mtdAndYtdTileVisible
            },
            statementTile: {
                active: statementTile
            },
            commissionPageMtd: {
                visible: !showCommission
            },
            selectStatementYearFilterDropDown: {
                availableValues: years,
                value: selectedYear,
                visible: showCommission
            },
            statementHeader: {
                content: this.getStatementHeader(selectedStatement, intl)
            },
            statementBalance: {
                value: this.getStatementBalance(selectedStatementPolicies),
                visible: this.getStatementBalanceVisibility(selectedStatementPolicies)
            },
            statementPoliciesTable: {
                data: this.getStatementPoliciesTable(selectedStatementPolicies)
            },
            statementTileContainer: {
                visible: currentView === 'statementTile'
            },
            titleContainer: {
                visible: !showCommission
            },
            headerDivider: {
                visible: !showCommission
            },
            ...this.generateStatementOverrides()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick,
                getCell: this.getCell,
                getLink: this.getLink,
                getCurrencyCell: this.getCurrencyCell,
                handleStatementYears: this.handleStatementYears
            },
            resolveComponentMap: {
                selectproducercode: SelectProducerCode,
                customizedheadercomponent: CustomizedHeaderComponent
            }
        };

        const getSelectedYearStatements = {
            selectedYearStatements
        };
        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                getSelectedYearStatements,
                id,
                path,
                overrideProps
            );
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={getSelectedYearStatements}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

export const CommissionComponent = CommissionLanding;
export default withIntl(withAuthenticationContext(withProducerContext(CommissionLanding)));
