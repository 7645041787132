import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';

import { useAuthentication } from 'gw-digital-auth-react';
import { Wizard } from 'gw-portals-wizard-react';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { MockUpUtil } from 'gw-portals-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { Loader, useModal } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';

import wizardConfig from './config/wc7-wizard-config.json5';
import wizardStepToFieldMapping from './config/wc7-wizard-step-to-field-mapping.json5';
import styles from './WC7Wizard.module.scss';
import messages from './WC7Wizard.messages';

const PATH_TO_MOCK_DATA = 'quote.wc7';
const MOCK_DATA_TO_SET = ['bindData.contactPhone'];
const MOCK_DATA_TO_REMOVE = ['bindData.contactPhone'];

function PEWC7Wizard(props) {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { viewModelService, history, location } = props;
    const bindData = {
        contactPhone: null,
        contactEmail: null
    };

    useEffect(() => {
        const viewModelContext = {
            DriverEmailRequired: true,
            AccountEmailRequired: false,
            AccountDOBRequired: false,
            RequireAccountInfo: true,
            RequireExpMod: true
        };

        if (!location.state) {
            history.push('/');
            return;
        }

        const { quoteentry } = location.state;
        const { postalCode, quoteID } = quoteentry;

        const requestData = {
            quoteID: quoteID,
            postalCode: postalCode
        };

        LoadSaveService.retrieveSubmission(requestData, authHeader)
            .then((response) => {
                return MockUpUtil.cleanUpMockedProperties(
                    response,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_REMOVE
                );
            })
            .then((retrieveSubmissionResponse) => {
                ClausesUtil.getDependentClausesFromServer(
                    ClauseService,
                    _.get(retrieveSubmissionResponse, 'quoteID'),
                    authHeader
                );
                const vmRequest = { bindData, ...retrieveSubmissionResponse };
                const submission = viewModelService.create(
                    vmRequest,
                    'pc',
                    'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                    viewModelContext
                );
                setInitialSubmission(submission);
                setIsLoading(false);
            });
        // Removed dependency since we don't need to execute this method on every rerender
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = useCallback(
        (cancelProps) => {
            const skipModal = ['knockoutpage', 'logout'];
            const {
                wizardSnapshot,
                param: nextPath = '',
                currentStepIndex,
                wizardData
            } = cancelProps;
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const quoteSummaryRegex = /^\/quotes\/[0-9]+\/summary/;

            // Don't display modal service for success page or if
            // next path matches /quotes/<job number>/summary
            if (
                currentStepIndex === steps.length - 1
                || skipModal.includes(lastPathName)
                || nextPath.match(quoteSummaryRegex)
                || hasErrorOccurred
            ) {
                return true;
            }

            return showConfirm({
                title: commonMessages.wantToCancel,
                message: commonMessages.infoWillBeSavedAsDraft,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.yes,
                cancelButtonText: commonMessages.cancel
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const isStatusQuoted = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === 'Quoted';

                let serverCall = LoadSaveService.updateDraftSubmission;

                if (isStatusQuoted) {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const isValid = wizardData.lobData.aspects.valid
                    && wizardData.lobData.aspects.subtreeValid;
                const payload = isValid
                    ? _.get(wizardData, 'value')
                    : _.get(wizardSnapshot, 'value');
                const payloadBeforeSave = _.omit(payload, 'bindData.paymentDetails');
                setIsLoading(true);

                const redirectPath = `/quotes/${payload.quoteID}/summary`;

                const dataToSave = MockUpUtil.setMockData(
                    payloadBeforeSave,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_SET
                );

                serverCall(dataToSave, authHeader)
                    .then(() => {
                        history.push(nextPath || redirectPath);
                    })
                    .catch(() => {
                        showAlert({
                            title: messages.anErrorOccurredTitle,
                            message: messages.anErrorOccurred,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                        history.push(nextPath || redirectPath);
                    });
                return true;
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, history, showAlert, showConfirm, steps.length]
    );

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
            const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
            const quoteID = quoteIDFromWizard || quoteIDFromLocation;
            const redirectPath = `/quotes/${quoteID}/summary`;
            const state = {
                pathname: redirectPath,
                state: error
            };

            return showAlert({
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [location, showAlert]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.wc7WizardContainer}>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialSubmission}
                    onCancel={handleCancel}
                    skipCompletedSteps
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onPreviousModalProps={{
                        title: commonMessages.wantToJump,
                        message: commonMessages.wantToJumpMessage,
                        status: 'warning',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.yesModel,
                        cancelButtonText: commonMessages.cancelModel
                    }}
                />
            </ErrorBoundary>
        </div>
    );
}

PEWC7Wizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withRouter(withViewModelService(PEWC7Wizard));
