import { defineMessages } from 'react-intl';

export default defineMessages({
    claims: {
        id: 'gateway.views.claims-landing.Claims',
        defaultMessage: 'Claims',
    },
    recentlyViewed: {
        id: 'gateway.views.claims-landing.Recently Viewed',
        defaultMessage: 'Recently Viewed'
    },
    recentlyCreated: {
        id: 'gateway.views.claims-landing.Recently Created',
        defaultMessage: 'Recently Created'
    },
    openClaims: {
        id: 'gateway.views.claims-landing.Open Claims',
        defaultMessage: 'Open Claims'
    },
    closedClaims: {
        id: 'gateway.views.claims-landing.Closed Claims',
        defaultMessage: 'Closed Claims'
    },
    product: {
        id: 'gateway.views.claims-landing.Line',
        defaultMessage: 'Product'
    },
    account: {
        id: 'gateway.views.claims-landing.Account',
        defaultMessage: 'Account'
    },
    claimNumber: {
        id: 'gateway.views.claims-landing.Claim Number',
        defaultMessage: 'Claim Number'
    },
    dateOfLoss: {
        id: 'gateway.views.claims-landing.Date of Loss',
        defaultMessage: 'Date of Loss'
    },
    status: {
        id: 'gateway.views.claims-landing.Status',
        defaultMessage: 'Status'
    },
    paid: {
        id: 'gateway.views.claims-landing.Paid',
        defaultMessage: 'Paid'
    },
    netIncurred: {
        id: 'gateway.views.claims-landing.Net Incurred',
        defaultMessage: 'Net Incurred'
    },
    policyNumber: {
        id: 'gateway.views.claims-landing.Policy Number',
        defaultMessage: 'Policy Number'
    },
    noResultFound: {
        id: 'gateway.views.claims-landing.No results found for this filter',
        defaultMessage: 'No results were found for this filter.'
    }
});
