import _ from 'lodash';
import EventTemplate from '../event-template/index';
import templateConfig from './templateConfig';

const isOdd = (index) => {
    if (index % 2) {
        return true;
    }
    return false;
};

const getProductCodeModifier = (event) => {
    return event.productCode ? event.productCode.toLowerCase() : 'generic';
};
const getObjValueByKey = (obj, key) => _.get(obj, key);

const getEventTemplate = (eventName) => EventTemplate(eventName);

const getHeaderEventName = (eventName) => {
    const event = getEventTemplate(eventName);
    return event && _.get(event, templateConfig.eventHeader).key;
};

const getTimelineDefinition = (eventName) => {
    const event = getEventTemplate(eventName);
    const timelineDetails = event && _.get(event, templateConfig.timelineDetails);
    if (timelineDetails) {
        return timelineDetails;
    }
    return null;
};

const getTypeBySplit = (type, delimeter = ':') => {
    const typeName = type.substr(0, type.indexOf(delimeter));
    const typeArgs = JSON.parse(type.substr(type.indexOf(delimeter) + 1).replace(/'/g, '"'));
    return [typeName, typeArgs];
};

export default {
    isOdd,
    getProductCodeModifier,
    getObjValueByKey,
    getHeaderEventName,
    getTimelineDefinition,
    getEventTemplate,
    getTypeBySplit
};
