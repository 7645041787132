import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicyChange'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class GatewayEndorsementService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof EndorsementService
     */
    static loadEndorsement(data, additionalHeaders = {}) {
        return processSubmission('load', [data], additionalHeaders);
    }

    static loadEndorsementWithEffectiveDate(data, additionalHeaders = {}) {
        return processSubmission('loadWithEffectiveDate', data, additionalHeaders);
    }

    static saveEndorsement(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    /* Reused from Quote and Buy and Method overrided */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    static updateCoverages(quoteID, sessionUUID, clausesToUpdate, additionalHeaders = {}) {
        return processSubmission('updateCoverages', [quoteID, clausesToUpdate], additionalHeaders);
    }

    static quoteEndorsement(data, additionalHeaders = {}) {
        return processSubmission('quote', data, additionalHeaders);
    }

    static bind(data, additionalHeaders = {}) {
        return processSubmission('bind', data, additionalHeaders);
    }

    static withdrawEndorsement(data, additionalHeaders = {}) {
        return processSubmission('withdraw', data, additionalHeaders);
    }

    static getAvailablePolicy(data, additionalHeaders = {}) {
        return processSubmission('getAvailablePolicy', [data], additionalHeaders);
    }

    static getAvailablePolicies(data, additionalHeaders = {}) {
        return processSubmission('getAvailablePolicies', data, additionalHeaders);
    }

    static checkEffectiveDateIsValid(data, additionalHeaders = {}) {
        return processSubmission('checkEffectiveDateIsValid', data, additionalHeaders);
    }

    static isQuoted(data, additionalHeaders = {}) {
        return processSubmission('isQuoted', data, additionalHeaders);
    }
}
