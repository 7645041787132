import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class JobService {
    static findSubmissionsByCreateUser(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'findSubmissionsByCreateUser',
            [],
            additionalHeaders
        );
    }

    static findSubmissionByCreateUserOpenedWithinNumberOfDays(numberOfDays, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'findSubmissionByCreateUserOpenedWithinNumberOfDays',
            [numberOfDays],
            additionalHeaders
        );
    }

    static findSubmissionsByAccount(accountNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'findSubmissionsByAccount',
            [accountNumber],
            additionalHeaders
        );
    }

    static findJobByJobNumber(jobNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'findJobByJobNumber',
            [jobNumber],
            additionalHeaders
        );
    }

    static withdrawJobByJobNumber(jobNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'withdrawJobByJobNumber',
            [jobNumber],
            additionalHeaders
        );
    }

    static getOpenJobsByJobTypeForCurrentUser(jobType, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'getOpenJobsByJobTypeForCurrentUser',
            [jobType],
            additionalHeaders
        );
    }
}
