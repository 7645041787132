import { defineMessages } from 'react-intl';

export default defineMessages({
    preferEnteringInformation: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Prefer entering information manually?',
        defaultMessage: 'Prefer entering information manually?'
    },
    downloadPreformattedTemplate: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Download the preformatted template.',
        defaultMessage: 'Download the preformatted template.'
    },
    dataProcessedProperly: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.This template is formatted in a way that ensures that your data is processed properly.',
        defaultMessage: 'This template is formatted in a way that ensures that your data is processed properly.'
    },
    templateIsPasswordProtected: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.The template is password-protected. In Excel, select Unprotect Sheet in the Review tab and enter the password 1234 to edit the file.',
        defaultMessage: 'The template is password-protected. In Excel, select Unprotect Sheet in the Review tab and enter the password 1234 to edit the file.'
    },
    downloadTemplate: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Download Template',
        defaultMessage: 'Download Template'
    },
    uploadUpdatedSpreadsheet: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Upload your updated spreadsheet.',
        defaultMessage: 'Upload your updated spreadsheet.'
    },
    upload: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Once you have updated the downloaded template with your changes, upload it.',
        defaultMessage: 'Once you have updated the downloaded template with your changes, upload it.'
    },
    uploadSpreadsheet: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Upload Spreadsheet',
        defaultMessage: 'Upload Spreadsheet'
    },
    downloading: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Downloading...',
        defaultMessage: 'Downloading...'
    },
    error: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Something went wrong. Template was not downloaded. Please try again.',
        defaultMessage: 'Something went wrong. Template was not downloaded. Please try again.'
    },
    importingSpreadsheet: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Importing spreadsheet...',
        defaultMessage: 'Importing spreadsheet...'
    },
    BuildingExcelLink: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Add information using Excel spreadsheets.',
        defaultMessage: 'Add information using {excel}. {faqlink}'
    },
    ExcelSpreadsheets: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Excel spreadsheets',
        defaultMessage: 'Excel spreadsheets'
    },
    NeedMoreInfo: {
        id: 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Need more information?',
        defaultMessage: 'Why do I have to pay a deductible? Need more information?'
    }
});
