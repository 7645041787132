import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AutomaticPaymentPolicyAffectedValueIterableComponent.metadata.json5';

function AutomaticPaymentPolicyAffectedValueIterableComponent(props) {
    const { data: aStateDetailsVM } = props;

    const getRelatedPolicyLink = (billingData, relatedPolicy) => {
        const policySummariesPath = 'policySummaries';
        const policySummaries = _.get(billingData, policySummariesPath, []);
        const policySummary = _.filter(policySummaries, (policy) => {
            return policy.policyNumber === relatedPolicy;
        });
        if (policySummary && policySummary.length > 0) {
            const relatedPolicyValue = `${policySummary[0].product.productName} (${relatedPolicy})`;
            return <Link to={`/policies/${relatedPolicy}/summary`}>{relatedPolicyValue}</Link>;
        }
        return null;
    };

    const overrideProps = useMemo(() => {
        const { paymentGroup } = aStateDetailsVM;
        const relatedPoliciesPath = 'relatedPolicies';
        const relatedPolicies = _.get(paymentGroup, relatedPoliciesPath, []);
        const overrides = relatedPolicies.map((relatedPolicy, policyIndex) => {
            return {
                [`tabAutomaticPaymentPolicyAffectedValue${policyIndex}`]: {
                    value: getRelatedPolicyLink(aStateDetailsVM, relatedPolicy)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [aStateDetailsVM]);

    const readValue = useCallback(
        (id, path) => {
            const vmValue = _.get(aStateDetailsVM, `${path}`);

            if (!vmValue) {
                return _.get(overrideProps, `${id}.value`);
            }

            return vmValue;
        },
        [aStateDetailsVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={aStateDetailsVM}
            resolveValue={resolvers.resolveValue}
            overrideProps={overrideProps}
        />
    );
}

AutomaticPaymentPolicyAffectedValueIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired // eslint-disable-line
};

export default AutomaticPaymentPolicyAffectedValueIterableComponent;
