import React, { Component } from 'react'
import _ from 'lodash'
import { Icon, ToastProvider, withModalContext, Link } from '@jutro/components'
import PropTypes from 'prop-types'
import { withAuthenticationContext } from 'gw-digital-auth-react'
import { TranslatorContext, withIntl } from '@jutro/locale'
import { UserService } from 'gw-capability-gateway'
import { ServiceManager } from '@jutro/services'
import { PhoneUtil } from 'gw-portals-viewmodel-js'
import cx from 'classnames'
import { DatatableUtil, LobIconUtil } from 'gw-portals-util-js'
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react'
import { Currency as CurrencyField } from 'gw-components-platform-react'
import { AccountService } from 'gw-capability-gateway-policy'
import metadata from './Summary.metadata.json5'
import EditAccountDetailsPopUp from './EditAccountDetailsPopUp'
import summaryStyles from './Summary.module.scss'
import messages from '../Accounts.messages'

class Summary extends Component {
  static propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
      create: PropTypes.func.isRequired,
      clone: PropTypes.func.isRequired
    }).isRequired,
    fromAccountLanding: PropTypes.shape({
      accountDetailsData: PropTypes.shape({}).isRequired
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    intl: PropTypes.shape({
      formatDate: PropTypes.func
    }).isRequired
  }

  static contextType = TranslatorContext

  state = {
    accountData: ''
    // showEditLink: true,
    // showNewQuoteButton: false
  }

  componentDidMount () {
    const { fromAccountLanding } = this.props
    // this.getShowNewQuoteButton();
    this.setState({
      accountData: fromAccountLanding.accountDetailsData
    })
    // this.hasUserAccesstoEditAccounts();
  }

  // hasUserAccesstoEditAccounts = () => {
  //     const { authHeader } = this.props;
  //     const permissionDTO = {
  //         permission: 'editaccountsummary'
  //     };
  //     UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
  //         (editUserPermission) => {
  //             this.setState({
  //                 showEditLink: editUserPermission
  //             });
  //         }
  //     );
  // };

  // getShowNewQuoteButton = async () => {
  //     const { authHeader } = this.props;
  //     const permissionDTO = {
  //         permission: 'createsubmission'
  //     };
  //     const showNewQuoteButton = await UserService.hasUserSystemPermission(
  //         permissionDTO,
  //         authHeader
  //     );
  //     this.setState({ showNewQuoteButton });
  // };

  getPolicyTable = () => {
    const { accountData } = this.state
    const { intl } = this.props
    const policyArray = accountData.policySummaries
    return policyArray.map(policyDetails => {
      return {
        productCode: policyDetails.product.productCode,
        productName: policyDetails.product.productName,
        insuredName: policyDetails.primaryInsuredName,
        displayStatus: policyDetails.displayStatus,
        policyNumber: policyDetails.policyNumber,
        effectiveDate: intl.formatDate(new Date(policyDetails.effective), {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }),
        expirationDate: intl.formatDate(new Date(policyDetails.expiration), {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }),
        isPendingFinalAudit: policyDetails.isPendingFinalAudit,
        isCancelled: policyDetails.isCancelled
      }
    })
  }

  readValue = (id, path) => {
    const { accountData } = this.state
    return _.get(accountData, path)
  }

  getCell = (item, index, { id: property }) => {
    const translator = this.context
    const toolTipMessage = {
      displayStatus: translator(messages.displayStatus),
      insuredName: translator(messages.insuredName),
      effectiveDate: translator(messages.effectiveDate),
      expirationDate: translator(messages.expirationDate)
    }
    return <span title={toolTipMessage[property]}>{item[property]}</span>
  }

  // getProductImage = (item) => {
  //     const icon = LobIconUtil.getFontIcon(item.productCode);
  //     return (
  //         <Icon
  //             icon={icon}
  //             title={item.productCode}
  //         />
  //     );
  // };

  getPolicyNoLink = (item, index, { id: property }) => {
    const translator = this.context
    const toolTipMessage = translator(messages.policyNumber)
    return item.isPendingFinalAudit && item.isCancelled ? (
      item.policyNumber
    ) : (
      <div>
        <Link to={`/policies/${item[property]}/summary`} title={toolTipMessage}>
          {item[property]}
        </Link>
      </div>
    )
  }

  showModal = async accountData => {
    const translator = this.context
    const { viewModelService, modalContext } = this.props
    const result = await modalContext.showModal(
      <EditAccountDetailsPopUp
        title={translator(messages.editContactDetails)}
        actionBtnLabel={messages.saveChanges}
        cancelBtnLabel={messages.cancel}
        accountData={accountData}
        viewModelService={viewModelService}
      />
    )
    return result
  }

  handleEditButtonClick = () => {
    const { accountData } = this.state
    const { authHeader } = this.props
    const translator = this.context
    this.showModal(accountData)
      .then(async requestObj => {
        const contactVM = await AccountService.updateAccountDetails(
          requestObj,
          authHeader
        )
        this.setState({ accountData: contactVM })
        ToastProvider.toast({
          type: 'success',
          message: translator(messages.savedMessage),
          autoClose: true
        })
      })
      .catch(_.noop)
  }

  // handleNewQuoteClick = () => {
  //     const { history } = this.props;
  //     const {
  //         accountData: { accountNumber }
  //     } = this.state;
  //     return history.push(`/new-quote/${accountNumber}`);
  // };

  getAccountHolderStateandPostalCode = accountData => {
    return `${accountData.accountHolder.primaryAddress.state} ${accountData.accountHolder.primaryAddress.postalCode}`
  }

  getAccountDisplayStatus = accountData => {
    return `${accountData.statusDisplayName}`
  }

  setCancelWarningMessageVisibility = accountData => {
    return accountData.policySummaries.find(
      policy =>
        policy.isPendingFinalAudit === true && policy.isCancelled === true
    )
  }

  getPremiumAmount = accountData => {
    const translator = this.context
    return this.setCancelWarningMessageVisibility(accountData) === undefined ? (
      <CurrencyField
        value={accountData.totalPremium}
        dataType='object'
        readOnly
        hideLabel
      />
    ) : (
      translator(messages.pendingAudit)
    )
  }

  getPhoneNumber = accountData => {
    let primaryPhoneNumber = ''
    const localeService = ServiceManager.getService('locale-service')
    const phoneUtil = PhoneUtil()
    switch (accountData.accountHolder.primaryPhoneType) {
      case 'work':
        primaryPhoneNumber = accountData.accountHolder.workNumber
        break
      case 'home':
        primaryPhoneNumber = accountData.accountHolder.homeNumber
        break
      case 'mobile':
        primaryPhoneNumber = accountData.accountHolder.cellNumber
        break
      default:
        primaryPhoneNumber = ''
    }
    return phoneUtil.prettyPrint(
      primaryPhoneNumber,
      localeService.getDefaultCountryCode()
    )
  }

  getPhoneNumberVisibility = accountData => {
    if (
      accountData.accountHolder.primaryPhoneType &&
      (accountData.accountHolder.homeNumber ||
        accountData.accountHolder.workNumber ||
        accountData.accountHolder.cellNumber)
    ) {
      return true
    }
    return false
  }

  render () {
    // const { accountData, showEditLink, showNewQuoteButton } = this.state;
    const { accountData } = this.state
    if (_.isEmpty(accountData)) {
      return null
    }
    const policySummaryArray = this.getPolicyTable()

    const overrides = {
      policyTable: {
        data: policySummaryArray
      },
      name: {
        content: _.get(accountData, 'accountHolder.displayName')
      },
      addressline1: {
        content: _.get(accountData, 'accountHolder.primaryAddress.addressLine1')
      },
      addressline2: {
        content: _.get(accountData, 'accountHolder.primaryAddress.addressLine2')
      },
      addressline3: {
        content: _.get(accountData, 'accountHolder.primaryAddress.addressLine3')
      },
      city: {
        content: _.get(accountData, 'accountHolder.primaryAddress.city')
      },
      noDataMessage: {
        visible: _.isEmpty(policySummaryArray)
      },
      stateAndPostalcode: {
        content: this.getAccountHolderStateandPostalCode(accountData)
      },
      renewals: {
        content: _.toString(accountData.numberOfOpenPolicyRenewals)
      },
      cancellations: {
        content: _.toString(accountData.numberOfOpenPolicyCancellations)
      },
      changes: {
        content: _.toString(accountData.numberOfOpenPolicyChanges)
      },
      accountStatus: {
        content: this.getAccountDisplayStatus(accountData)
      },
      phoneNumberLabel: {
        visible: this.getPhoneNumberVisibility(accountData)
      },
      phoneNumber: {
        content: this.getPhoneNumber(accountData),
        visible: this.getPhoneNumberVisibility(accountData)
      },
      premiumAmount: {
        content: this.getPremiumAmount(accountData)
      },
      cancellationWarningMessage: {
        visible:
          this.setCancelWarningMessageVisibility(accountData) !== undefined
      }
      // showEditButton: {
      //     visible: showEditLink
      // },
      // newQuoteButton: {
      //     visible: showNewQuoteButton
      // },
      // producerCode: {
      //     content: _.get(accountData, 'producerCodes[0].displayValue')
      // }
    }

    const resolvers = {
      resolveCallbackMap: {
        getCell: this.getCell,
        // getProductImage: this.getProductImage,
        getPolicyNoLink: this.getPolicyNoLink,
        handleEditButtonClick: this.handleEditButtonClick,
        // handleNewQuoteClick: this.handleNewQuoteClick,
        sortDate: DatatableUtil.sortDate,
        sortString: DatatableUtil.sortString,
        sortNumber: DatatableUtil.sortNumber
      },
      resolveValue: this.readValue,
      resolveClassNameMap: summaryStyles
    }

    return (
      <div className={cx(summaryStyles.summary)}>
        <ViewModelForm
          uiProps={metadata.pageContent}
          model={accountData}
          overrideProps={overrides}
          callbackMap={resolvers.resolveCallbackMap}
          componentMap={resolvers.resolveComponentMap}
          classNameMap={resolvers.resolveClassNameMap}
        />
      </div>
    )
  }
}
export const SummaryComponent = withModalContext(Summary)
export default withIntl(
  withAuthenticationContext(withViewModelService(withModalContext(Summary)))
)
