import React, {
    useContext, useCallback, useMemo, useEffect
} from 'react';
import _ from 'lodash';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useValidation } from 'gw-portals-validation-react';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { QuestionSetComponent } from 'gw-portals-questionsets-ui';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from '../../WC7Wizard.module.scss';
import metadata from './QualificationPage.metadata.json5';
import messages from './QualificationPage.messages';

const WC7_QUESTIONSET_PATH = 'lobData.wc7WorkersComp.preQualQuestionSets.children[0].value';

const generateData = (submissionData) => {
    const path = 'lobData.wc7WorkersComp.coverables.locationsAndEmployees';
    const locationsAndEmployees = _.get(submissionData, path);

    if (!locationsAndEmployees.length) {
        return submissionData;
    }
    // removing items from Locations And Employees array when it doesn't have state value in it.
    const newData = locationsAndEmployees.filter((locationsAndEmployee) => {
        return _.get(locationsAndEmployee, 'location.address.state');
    });
    _.set(submissionData, path, newData);
    return submissionData;
};
function QualificationPage(props) {
    const {
        showConfirm
    } = useModal();

    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation,
        onValidate
    } = useValidation('QualificationPage');
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;

    const cleanDependentQuestions = useCallback(() => {
        const questionSets = _.get(submissionVM, WC7_QUESTIONSET_PATH);

        Object.keys(questionSets.answers).forEach((key) => {
            if (questionSets.answers[key] === '') {
                questionSets.answers[key] = null;
            }
        });

        return submissionVM;
    }, [submissionVM]);

    const onNext = useCallback(
        async () => {
            _.unset(submissionVM.value, 'bindData');
            const updatedSubmission = cleanDependentQuestions();
            updatedSubmission.value = await LoadSaveService
                .updateDraftSubmission(generateData(updatedSubmission.value), authHeader);

            return updatedSubmission;
        },
        [submissionVM.value, cleanDependentQuestions, authHeader]
    );

    const generateOverrides = useCallback(() => {
        const additionalInterestData = _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.additionalNamedInsureds.value',
            []
        );
        const overrides = additionalInterestData.map((additionalInterest, i) => {
            const subtype = _.get(additionalInterest, 'accountContact.subtype') || 'Person';
            return {
                [`wc7CompanyContainer${i}`]: {
                    visible: subtype === 'Company'
                },
                [`wc7PersonalContainer${i}`]: {
                    visible: subtype === 'Person'
                },
                [`wc7PersonCompanybinaryswitch${i}`]: {
                    value: subtype
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM]);

    const numberOfCoveredEmployees = useMemo(() => {
        const locations = _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.locationsAndEmployees.value'
        );
        const employeesCount = locations.flatMap((location) => {
            return location.coveredEmployees;
        }).length;
        if (_.isUndefined(employeesCount)) {
            return 0;
        }
        return employeesCount;
    }, [submissionVM]);

    const getAddInsuredHeader = useCallback(() => {
        const additionalInterests = _.get(
            submissionVM.value,
            'lobData.wc7WorkersComp.coverables.additionalNamedInsureds'
        );
        if (additionalInterests && additionalInterests.length > 0) {
            return false;
        }
        return true;
    }, [submissionVM]);

    const getDoesHaveCompInsurance = useCallback(() => {
        const policyEntries = _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.policyHistory.policyEntries'
        );
        if (policyEntries && policyEntries.length > 0) {
            return true;
        }
        return false;
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'left'
        },
        wc7DoesHaveCompInsurance: {
            value: getDoesHaveCompInsurance()
        },
        wc7PriorPolicyContainer: {
            visible: getDoesHaveCompInsurance()
        },
        wc7QuestionSet_addition: {
            visible: getAddInsuredHeader()
        },
        wc7QuestionSet_anotherInsured: {
            visible: !getAddInsuredHeader()
        },
        ...generateOverrides()
    };

    const addInsured = useCallback(() => {
        const nameInsuredObj = {
            accountContact: {
                subtype: 'Person',
                primaryAddress: {}
            }
        };
        const additionalInterests = _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.additionalNamedInsureds'
        );
        // eslint-disable-next-line max-len
        const {
            _xCenter,
            _dtoName
        } = submissionVM.lobData.wc7WorkersComp.coverables.additionalNamedInsureds;
        const additionalVM = viewModelService.create(nameInsuredObj, _xCenter, _dtoName);

        const addPolicyInterest = additionalVM.accountContact;
        addPolicyInterest.value = addPolicyInterest.value || {};
        addPolicyInterest.primaryAddress.value = addPolicyInterest.primaryAddress.value || {};
        additionalVM.accountContact = addPolicyInterest;

        additionalInterests.pushElement(additionalVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const removeAdditionalInsured = useCallback(
        ({ path: additionInsuredPath }) => {
            // removes `.children[index].value` and replace with `.value`
            // so we can get an array of Additional Insureds
            const additionalInsuredListPath = additionInsuredPath.replace(
                /\.children\.\d+$/,
                '.value'
            );
            const additionalInsuredToRemove = _.get(submissionVM, additionInsuredPath);
            const additionalInsuredList = _.get(submissionVM, additionalInsuredListPath);
            showConfirm({
                title: messages.wc7RemoveNamedInsured,
                message: messages.wc7AddIntRemovalMsg,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.wc7Yes,
                cancelButtonText: messages.wc7No
            }).then(
                // eslint-disable-next-line consistent-return
                (result) => {
                    if (result === 'cancel' || result === 'close') {
                        return _.noop();
                    }
                    const newAdditionalInsuredList = additionalInsuredList.filter(
                        (additionalInsured, index) => {
                            // eslint-disable-next-line
                            const additionalInsuredListPathWithoutValue = additionalInsuredListPath.replace(
                                '.value',
                                ''
                            );
                            const currentAdditionalInsuredPath = `${additionalInsuredListPathWithoutValue}.children[${index}]`;
                            // eslint-disable-next-line
                            const isCorrectPath = currentAdditionalInsuredPath === additionInsuredPath;

                            return !(
                                isCorrectPath
                                && _.isEqual(additionalInsured, additionalInsuredToRemove.value)
                            );
                        }
                    );

                    _.set(submissionVM, additionalInsuredListPath, newAdditionalInsuredList);
                    updateWizardData(submissionVM);
                },
                _.noop
            );
        },
        [showConfirm, submissionVM, updateWizardData]
    );

    const handleDoesHaveWC7Insurance = useCallback((value) => {
        let policyEntries = _.get(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.policyHistory.policyEntries'
        );
        const policyEntriesVM = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.policyjob.lob.wc7workerscomp.policydata.dto.Wc7PriorPolicyEntryDTO'
        );
        if (value) {
            policyEntries.pushElement(policyEntriesVM);
        } else {
            policyEntries = [];
        }
        _.set(
            submissionVM,
            'lobData.wc7WorkersComp.coverables.policyHistory.policyEntries',
            policyEntries
        );
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const resolvers = {
        resolveComponentMap: { questionset: QuestionSetComponent },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onInsuredClick: addInsured,
            onRemoveAdditionalInsured: removeAdditionalInsured,
            onValidate: onValidate,
            handleDoesHaveWC7Insurance: handleDoesHaveWC7Insurance
        }
    };

    useEffect(() => {
        registerInitialComponentValidation(() => numberOfCoveredEmployees);

        return () => {
            updateWizardData(cleanDependentQuestions());
        };
    // It should call one
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage onNext={onNext} disableNext={!isComponentValid} skipWhen={initialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

QualificationPage.propTypes = wizardProps;
export default QualificationPage;
