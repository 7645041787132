import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { MetadataContent } from '@jutro/uiconfig';
import { withIntl, TranslatorContext } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { policyCommissionMessages } from 'gw-capability-gateway-commission-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { DatatableUtil } from 'gw-portals-util-js';
import policyBillingStyles from './Billing.module.scss';
import metadata from './Billing.metadata.json5';
import messages from './Billing.messages';

class PolicyBilling extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string,
                    account: PropTypes.shape({
                        accountNumber: PropTypes.string
                    })
                })
            }),
            billingData: PropTypes.shape({})
        }).isRequired,
        intl: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    state = {
        billingData: [],
        currentBillingData: {},
        billingInvoiceData: [],
        policyPeriodDropDownOptions: [],
        selectedPeriod: '',
        isBillingInfoAvailable: true,
        policyValue: []
    };

    componentDidMount() {
        this.getPolicyBillingSummaryInfo();
    }

    getPolicyBillingSummaryInfo = () => {
        const { fromAccountLanding, policyDetailsData } = this.props;
        const billingInfo = _.get(fromAccountLanding, 'billingData');
        const billingData = _.get(billingInfo, 'policyPeriodBillingSummaries');
        const policyValue = _.get(policyDetailsData, 'policyResponse.latestPeriod');
        const policyDetails = _.get(policyDetailsData, 'policyResponse');
        if (!_.isEmpty(billingInfo) && billingData.length > 0) {
            const invoiceData = billingData[0].invoices;
            const paymentScheduleDataTable = this.getPaymentScheduleDataTable(invoiceData);
            this.setState({
                billingData: billingData,
                billingInvoiceData: paymentScheduleDataTable,
                currentBillingData: billingData[0],
                policyPeriodDropDownOptions: this.getPolicyPeriodDropDownOptions(billingData),
                selectedPeriod: billingData[0].periodName,
                policyValue: policyValue,
                policyDetails: policyDetails,
            });
        } else {
            this.setState({ isBillingInfoAvailable: false });
        }
    };

    getPaymentScheduleDataTable = (invoiceData) => {
        let invoiceArrayResult = [];
        invoiceArrayResult = invoiceData.map((data) => {
            const objData = {
                dueDate: data.dueDate,
                paidStatus: data.paidStatus,
                status: data.status,
                paid: data.paid,
                outStanding: data.unpaid,
                statementAmount: data.amount
            };
            return objData;
        });
        return invoiceArrayResult;
    };

    getBillingStatus = (isDelinquent) => {
        const translator = this.context;
        if (isDelinquent) {
            return translator(messages.delinquentLabel);
        }
        return translator(messages.inGoodStandingLabel);
    };

    getCell = (items, index, property) => {
        const translator = this.context;
        const tooltipMessage = {
            dueDate: translator(messages.dueDateLabel),
            paidStatus: translator(messages.paidStatusLabel)
        };
        let showPastDueAlert = false;
        if (
            moment().isAfter(items.dueDate)
            && items.outStanding.amount > 0
            && property.id === 'paidStatus'
        ) {
            // eslint-disable-next-line no-param-reassign
            items.paidStatus = translator(messages.pastDue);
            showPastDueAlert = true;
        }

        const classNameShowPastDueAlert = classNames({
            [policyBillingStyles.negativePastDueAlert]: showPastDueAlert
        });

        return (
            <span title={tooltipMessage[property.id]} className={classNameShowPastDueAlert}>
                {items[property.id]}
            </span>
        );
    };

    getFormattedCurrency = (item, index, property) => {
        const translator = this.context;
        const tooltipMessage = {
            paid: translator(messages.PaidLabel),
            outStanding: translator(messages.outstandingLabel),
            statementAmount: translator(messages.statementAmountLabel)
        };

        return (
            <span
                title={tooltipMessage[property.id]}
            >
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            </span>
        );
    };

    getFormattedDate = (date) => {
        const { intl } = this.props;
        return intl.formatDate(new Date(date), { year: 'numeric', month: 'short', day: 'numeric' });
    };

    getPolicyPeriodDropDownOptions = (billingData) => {
        const translator = this.context;
        if (_.isEmpty(billingData)) {
            return null;
        }
        const now = moment();
        let currentPolicyPeriodBillingSummary;
        return billingData.map((key, index) => {
            let policyPeriodName = `${index + 1}: ${key.periodName}`;
            if (
                !currentPolicyPeriodBillingSummary
                && key.effectiveDate
                && now.isAfter(key.effectiveDate)
                && key.expirationDate
                && now.isBefore(key.expirationDate)
            ) {
                currentPolicyPeriodBillingSummary = key;
                policyPeriodName = `${policyPeriodName} ${translator(
                    policyCommissionMessages.current
                )}`;
            }
            return {
                code: key.periodName,
                name: policyPeriodName
            };
        });
    };

    handlePolicyPeriodChange = (policyPeriod) => {
        const { billingData } = this.state;
        const policyChangeData = billingData.find((data) => data.periodName === policyPeriod);
        const invoiceData = policyChangeData.invoices;
        const paymentScheduleDataTable = this.getPaymentScheduleDataTable(invoiceData);
        this.setState({
            billingInvoiceData: paymentScheduleDataTable,
            currentBillingData: policyChangeData,
            selectedPeriod: policyPeriod
        });
    };

    getPolicyStatusIcon = (billingData) => {
        if (billingData.isDelinquent) {
            return 'mi-warning';
        }
        return 'mi-check';
    };

    getPolicyStatusClassname = (billingData) => {
        return classNames(policyBillingStyles.policyBillingIcon, {
            [policyBillingStyles.policyBillingDelinquent]: billingData.isDelinquent,
            [policyBillingStyles.policyBillingGoodStanding]: !billingData.isDelinquent
        });
    };

    handleViewAccountBilling = () => {
        const {
            history,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const {
            account: { accountNumber }
        } = policyResponse;
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({ pathname: nextPath, accountDetailsData: { accountNumber: accountNumber } });
    };

    render() {
        const {
            billingInvoiceData,
            currentBillingData,
            policyPeriodDropDownOptions,
            selectedPeriod,
            isBillingInfoAvailable,
            policyValue,
            policyDetails
        } = this.state;
        const translator = this.context;
        const { disputedAmount } = currentBillingData;
        const showPastDueLine = currentBillingData.aggregatedPastDueAmount
            && currentBillingData.aggregatedPastDueAmount.amount > 0;
        const showCurrentPaymentLine = currentBillingData.nextDueAmount
            && currentBillingData.nextDueAmount.amount > 0;

        const { intl } = this.props;
        const overrides = {
            billingPagePolicyStatusIcon: {
                icon: this.getPolicyStatusIcon(currentBillingData),
                className: this.getPolicyStatusClassname(currentBillingData)
            },
            tabBillingSummaryTotalPremium: {
                value: policyValue.totalPremium
            },
            tabBillingSummaryTaxesAndFees: {
                value: policyValue.taxesAndFees
            },
            tabBillingSummaryTotalCosts: {
                value: policyValue.totalCost
            },
            billingPagePolicyBillingStatus: {
                value: this.getBillingStatus(currentBillingData.isDelinquent)
            },
            tabBillingSummaryPastDueContainer: {
                visible: showPastDueLine
            },
            tabBillingSummaryPastDue: {
                value: currentBillingData.aggregatedPastDueAmount
            },
            tabBillingSummaryPastDueDate: {
                content: this.getFormattedDate(currentBillingData.pastDueDate)
            },
            tabBillingSummaryNextPaymentContainer: {
                visible: showCurrentPaymentLine
            },
            tabBillingSummaryNextPayment: {
                value: currentBillingData.nextDueAmount
            },
            billingSummaryNextDueDate: {
                content: this.getFormattedDate(currentBillingData.nextDueDate)
            },
            tabBillingSummaryTotalDue: {
                value: currentBillingData.totalDueAmount
            },
            tabBillingSummaryBillingMethod: {
                value: translator({
                    id: `typekey.BillingMethod.${currentBillingData.billingMethod}`,
                    defaultMessage: `typekey.BillingMethod.${currentBillingData.billingMethod}`
                })
            },
            tabBillingSummaryPaymentPlan: {
                value: currentBillingData.paymentPlan
            },
            paymentScheduledTable: {
                data: billingInvoiceData
            },
            policyPeriodDropDown: {
                availableValues: policyPeriodDropDownOptions,
                value: selectedPeriod
            },
            billingInfoContainer: {
                visible: isBillingInfoAvailable
            },
            billingEmptyContainer: {
                visible: !isBillingInfoAvailable
            },
            warningMessage: {
                value: translator(messages.noBillingDataLabel)
            },
            totalDisputedAmountMessage: {
                message: translator(messages.totalDisputedAmountMessage, {
                    amount: intl.formatNumber(
                        disputedAmount ? disputedAmount.amount : 0,
                        {
                            style: 'currency',
                            currency: 'usd',
                        }
                    ),
                    currentDate: intl.formatDate(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' })
                }),
                visible: disputedAmount && disputedAmount.amount > 0
            },
            pendingCancellationwarning: {
                visible: !!policyDetails?.cancellationReason,
            }
        };
        const resolvers = {
            resolveClassNameMap: policyBillingStyles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getFormattedCurrency: this.getFormattedCurrency,
                handlePolicyPeriodChange: this.handlePolicyPeriodChange,
                handleViewAccountBilling: this.handleViewAccountBilling,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortCurrency: DatatableUtil.sortCurrency
            }
        };

        // eslint-disable-next-line max-len
        const policyPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return <div className={policyBillingStyles.policybilling}>{policyPage}</div>;
    }
}

export const BillingComponent = PolicyBilling;
export default withIntl(withAuthenticationContext(PolicyBilling));
