import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayCancellation'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class CancellationService
 */
export default class CancellationService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data the submission that will be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof CancellationService
     */

    static getRefundMethods(policyNumber, tempCancellation, additionalHeaders = {}) {
        return processSubmission('getValidRefundMethods', [policyNumber, tempCancellation], additionalHeaders);
    }

    static getEffectiveDateForCancellation(policyNumber, tempCancellation, additionalHeaders = {}) {
        return processSubmission(
            'getEffectiveDateForCancellation',
            [policyNumber, tempCancellation],
            additionalHeaders
        );
    }

    static findJobByJobNumber(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('findJobByJobNumber', [cancellationNumber], additionalHeaders);
    }

    static getCancellationSummary(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('getCancellationSummary', [cancellationNumber], additionalHeaders);
    }

    static getNotesForCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('getNotesForCancellation', cancellationNumber, additionalHeaders);
    }

    static createNoteForCancellation(params, additionalHeaders = {}) {
        return processSubmission(
            'createNoteForCancellation',
            params,
            additionalHeaders
        );
    }

    static getDocumentsForCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission(
            'getDocumentsForCancellation',
            [cancellationNumber],
            additionalHeaders
        );
    }

    static getUWIssuesForCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('getUWIssuesForCancellation', [cancellationNumber], additionalHeaders);
    }

    static referToUnderwriter(cancellationNumber, noteBody, additionalHeaders = {}) {
        return processSubmission(
            'referToUnderwriter',
            [cancellationNumber, noteBody],
            additionalHeaders
        );
    }

    static withdrawCancellationByCancellationNumber(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('withdrawCancellationByJobNumber', [cancellationNumber], additionalHeaders);
    }

    static bindCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('bindCancellation', [cancellationNumber], additionalHeaders);
    }
}
