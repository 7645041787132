import React, { Component } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { TranslatorContext, withIntl } from '@jutro/locale';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { Loader, Link } from '@jutro/components';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { gatewayMessages, accountMessages } from 'gw-capability-gateway-react';
import { DatatableUtil } from 'gw-portals-util-js';
import metadata from './AccountClaims.metadata.json5';
import styles from './AccountClaims.module.scss';
import claimsMessages from '../../Claims.messages';

const { fnolLobs, fnolLobsConfig } = appConfig;
const getFnolLobs = _.reduce(
    fnolLobs,
    (namesLiterals, policyType) => namesLiterals.concat(fnolLobsConfig[policyType]),
    []
);

class AccountClaims extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string,
                policySummaries: PropTypes.array
            })
        }).isRequired,
        intl: PropTypes.shape({
            formatDate: PropTypes.func
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        claimResponse: '',
        claimDataTable: '',
        selectedClaimLOB: '',
        claimLOBOptions: [],
        searchFilter: '',
        isLoading: true
    };

    componentDidMount() {
        const {
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const { authHeader } = this.props;
        AccountService.getAccountClaims(accountNumber, authHeader).then((responseData) => {
            const translator = this.context;
            const filter = {
                type: null,
                value: null
            };
            this.getClaimDataTable(responseData, filter);
            this.setState({
                claimResponse: responseData,
                selectedClaimLOB: translator(accountMessages.filterPlaceholder),
                claimLOBOptions: this.getClaimLOBOptions(responseData),
                isLoading: false
            });
        });
    }

    getLOBFilterValues = (claimsArrayResult, filter) => {
        return _.filter(claimsArrayResult, (res) => {
            return res.product === filter.value;
        });
    };

    getSearchFilterValues = (claimsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(claimsArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getClaimDataTable = (claimsData, filter) => {
        const { searchFilter, selectedClaimLOB } = this.state;
        const { intl } = this.props;
        let claimsArrayResult = claimsData;
        const combineFilter = {};
        switch (filter.type) {
            case 'LOBFilter':
                claimsArrayResult = this.getLOBFilterValues(claimsArrayResult, filter);
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    claimsArrayResult = this.getSearchFilterValues(
                        claimsArrayResult,
                        combineFilter
                    );
                }
                break;
            case 'SearchFilter':
                claimsArrayResult = this.getSearchFilterValues(claimsArrayResult, filter);
                if (typeof selectedClaimLOB === 'string' && selectedClaimLOB !== 'Filters') {
                    combineFilter.value = selectedClaimLOB;
                    claimsArrayResult = this.getLOBFilterValues(claimsArrayResult, combineFilter);
                }
                break;
            case null:
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    claimsArrayResult = this.getSearchFilterValues(
                        claimsArrayResult,
                        combineFilter
                    );
                }
                break;
            default:
                claimsArrayResult = claimsData;
                break;
        }
        claimsArrayResult = claimsArrayResult.map((claimInfo) => {
            const claims = {
                product: claimInfo.product,
                claimNumber: claimInfo.claimNumber,
                dateOfLoss: intl.formatDate(new Date(claimInfo.lossDate), { year: 'numeric', month: 'short', day: 'numeric' }),
                status: claimInfo.status,
                paid: claimInfo.totalPayments,
                netIncurred: claimInfo.totalIncurredNet,
                policyNumber: claimInfo.policyNumber
            };
            return claims;
        });
        this.setState({
            claimDataTable: claimsArrayResult
        });
    };

    getClaimLOBOptions = (response) => {
        const translator = this.context;
        let claimLOBOptionsArray;
        claimLOBOptionsArray = [translator(accountMessages.filterPlaceholder)];
        if (_.isEmpty(response)) {
            return claimLOBOptionsArray.map((key) => {
                return {
                    code: key,
                    name: key
                };
            });
        }
        response.map((claimInfo) => {
            if (claimLOBOptionsArray.indexOf(claimInfo.product) === -1) {
                claimLOBOptionsArray = [...claimLOBOptionsArray, claimInfo.product];
            }
            return claimLOBOptionsArray;
        });
        return claimLOBOptionsArray.map((key) => {
            return {
                code: key,
                name: key
            };
        });
    };

    handleFilterChange = (value, id) => {
        const { claimResponse } = this.state;
        const filter = {
            type: null,
            value: null
        };
        if (id === 'LOBFilter') {
            if (value !== 'Filters') {
                filter.type = 'LOBFilter';
                filter.value = value;
            }
            this.setState(
                {
                    selectedClaimLOB: value
                },
                () => {
                    this.getClaimDataTable(claimResponse, filter);
                }
            );
        }
        if (id === 'searchFilter') {
            filter.type = 'SearchFilter';
            filter.value = value;
            this.setState(
                {
                    searchFilter: value
                },
                () => {
                    this.getClaimDataTable(claimResponse, filter);
                }
            );
        }
    };

    handleFileClick = () => {
        const {
            history,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const redirectClaimsPath = `/accounts/${accountNumber}/claims`;
        return history.push({
            pathname: '/fnol-select-policy',
            state: {
                accountNumber: accountNumber,
                redirectPath: redirectClaimsPath
            }
        });
    };

    getCell = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            product: translator(claimsMessages.product),
            dateOfLoss: translator(claimsMessages.dateOfLoss),
            status: translator(claimsMessages.status)
        };
        return <span title={toolTipMessage[property.id]}>{item[property.id]}</span>;
    };

    getCurrencyCell = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            paid: translator(claimsMessages.paid),
            netIncurred: translator(claimsMessages.netIncurred)
        };
        return (
            <span title={toolTipMessage[property.id]} className={styles.currencyContainer}>
                <CurrencyField
                    id={`currencyCell_${index}`}
                    value={item[property.id]}
                    datatype="object"
                    title={toolTipMessage}
                    readOnly
                    hideLabel
                />
            </span>
        );
    };

    getLink = (item, index, property) => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const toolTipMessage = {
            policyNumber: translator(claimsMessages.policyNumber),
            claimNumber: translator(claimsMessages.claimNumber)
        };
        if (
            property.id === 'claimNumber'
            && item.status === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'draft' })
        ) {
            const redirectpath = {
                pathname: '/fnol-select-policy',
                state: {
                    claimNumber: item[property.id],
                    redirectPath: `/accounts/${accountNumber}/claims`,
                    claimStatus: item.status
                }
            };
            return (
                <div>
                    <Link
                        to={redirectpath}
                        title={toolTipMessage[property.id]}
                    >
                        {item[property.id]}
                    </Link>
                </div>
            );
        }
        const redirectRoute = property.id === 'policyNumber'
            ? `/policies/${item[property.id]}/summary`
            : `/claims/${item[property.id]}`;
        const redirectPath = {
            pathname: redirectRoute,
            state: {
                claimNumber: item[property.id],
                redirectPath: `/accounts/${accountNumber}/claims`,
                claimStatus: item.status
            }
        };
        return (
            <div>
                <Link
                    to={redirectPath}
                    title={toolTipMessage[property.id]}
                >
                    {item[property.id]}
                </Link>
            </div>
        );
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getMetadataImplementation = () => {
        const resolvers = {
            resolveClassNameMap: styles
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={{}} {...resolvers} />;
    };

    accountHasClaimLOBLines = (account) => {
        if (
            !_.isEmpty(account)
            && !_.isEmpty(account.policySummaries)
            && account.policySummaries.length > 0
        ) {
            return account.policySummaries.some((policySummary) => {
                return policySummary.policyLines.some(
                    (line) => getFnolLobs.includes(line.lineOfBusinessCode)
                );
            });
        }
        return false;
    };

    getFileClaimButtonVisible = () => {
        const {
            fromAccountLanding: { accountDetailsData = {} }
        } = this.props;
        return this.accountHasClaimLOBLines(accountDetailsData) && accountDetailsData.accountNumber;
    };

    render() {
        const {
            claimDataTable,
            claimResponse,
            selectedClaimLOB,
            claimLOBOptions,
            searchFilter,
            isLoading
        } = this.state;

        const translator = this.context;

        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        const overrides = {
            claimDataTable: {
                data: claimDataTable
            },
            LOBFilter: {
                availableValues: claimLOBOptions,
                value: selectedClaimLOB
            },
            fileClaimButton: {
                visible: this.getFileClaimButtonVisible()
            },
            searchFilter: {
                value: searchFilter
            },
            seachFilterLayout: {
                visible: !_.isEmpty(claimResponse)
            },
            noDataMessageContainer: {
                visible: _.isEmpty(claimResponse)
            },
            noDataMessage: {
                value: translator(gatewayMessages.noClaimsResult)
            },
            paidColumnTitle: {
                visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
            },
            netIncurredColumnTitle: {
                visible: appConfig.persona === 'producer' || appConfig.persona === 'csr'
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getCurrencyCell: this.getCurrencyCell,
                getAccount: this.getAccount,
                getLink: this.getLink,
                handleLOBValueChange: (value) => this.handleFilterChange(value, 'LOBFilter'),
                handleSearchValueChange: (value) => this.handleFilterChange(value, 'searchFilter'),
                handleFileClick: this.handleFileClick,
                sortCurrency: DatatableUtil.sortCurrency,
                sortString: DatatableUtil.sortString,
                sortDate: DatatableUtil.sortDate
            }
        };
        const claimPage = <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            {...resolvers}
        />;
        return (
            <div>
                {claimPage}
            </div>
        );
    }
}

export const AccountClaimComponent = AccountClaims;
export default withIntl(withAuthenticationContext(AccountClaims));
