import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.policyDetails.policyDetails.Policy Details',
        defaultMessage: 'Policy Details'
    },
    policyDetailsDescription: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.policyDetails.policyDetails.Policy Renewal Description',
        defaultMessage: 'Renewal Description'
    },
    policyRenewalEffectiveDate: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.policyDetails.policyDetails.Policy Renewal Effective Date',
        defaultMessage: 'Renewal Effective Date'
    },
    organizationType: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.policyDetails.policyDetails.Organization Type',
        defaultMessage: 'Organization Type'
    },
    smallBusinessType: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.policyDetails.policyDetails.Small Business Type',
        defaultMessage: 'Small Business Type'
    },
    renewalSaveModalTitle: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.Unable to save',
        defaultMessage: 'Unable to save'
    },
    renewalSaveModalMessage: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.FlowConfig.An error occurred while attempting to save.',
        defaultMessage: 'An error occurred while attempting to save.'
    }
});
