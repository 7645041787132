import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ContactService } from 'gw-capability-gateway';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Loader, Link } from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { PhoneUtil } from 'gw-portals-viewmodel-js';
import metadata from './ContactDetails.metadata.json5';
import styles from './ContactDetails.module.scss';
import messages from './ContactDetails.messages';

class ContactDetailsPage extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                contact: PropTypes.string,
                accountOrPolicyNumber: PropTypes.string,
                relatedTo: PropTypes.string,
                contactId: PropTypes.string
            })
        }).isRequired,
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired
    };

    state = {
        accountContactDetailsVM: {},
        showLoadingIndicator: true
    };

    componentDidMount() {
        const {
            authHeader,
            location: {
                state: { contactId }
            }
        } = this.props;

        this.getAccountContacts(contactId, authHeader);
    }

    getAccountContacts = (contactId, authHeader) => {
        const {
            location: {
                state: { accountOrPolicyNumber, contact }
            }
        } = this.props;
        let getContactDetailsPromise;

        if (contact !== 'policyContacts') {
            getContactDetailsPromise = ContactService.getAccountContactDetails(
                contactId,
                authHeader
            );
        } else {
            getContactDetailsPromise = ContactService.getPolicyContactDetails(
                contactId,
                accountOrPolicyNumber,
                authHeader
            );
        }

        getContactDetailsPromise.then((response) => {
            const accountContactDetailsVM = this.createVM(response);
            this.setState({
                accountContactDetailsVM,
                showLoadingIndicator: false
            });
        });
    };

    createVM = (model) => {
        const { viewModelService, location } = this.props;
        const {
            state: { relatedTo }
        } = location;

        const contactDetailsDTO = relatedTo === 'policies'
            ? 'edge.capabilities.gateway.contact.dto.PolicyContactDetailsDTO'
            : 'edge.capabilities.gateway.contact.dto.AccountContactDetailsDTO';

        return viewModelService.create(model, 'pc', contactDetailsDTO);
    };

    getAssociatedAccountsIcon = () => {
        const { accountContactDetailsVM } = this.state;
        const accountsList = _.get(accountContactDetailsVM.value, 'associatedAccounts');

        const overrides = accountsList
            && accountsList.map((account, index) => {
                return {
                    [`associatedAccounts${index}`]: {
                        to: `/accounts/${account.accountNumber}/summary`,
                        content: `${account.accountHolderName} (${account.accountNumber})`
                    },
                    [`associatedAccountsIcon${index}`]: {
                        icon: account.subtype === 'Company' ? 'business' : 'person'
                    }
                };
            });
        return Object.assign({}, ...overrides);
    };

    getWorkPhoneNumber = (workNumber) => {
        if (_.isNil(workNumber)) {
            return null;
        }
        const localeService = ServiceManager.getService('locale-service');
        const phoneUtil = PhoneUtil();
        return phoneUtil.prettyPrint(workNumber, localeService.getDefaultCountryCode());
    };

    render() {
        const { accountContactDetailsVM, showLoadingIndicator } = this.state;
        const {
            location: {
                state: { contact, accountOrPolicyNumber, relatedTo }
            }
        } = this.props;
        const translator = this.context;

        if (showLoadingIndicator) {
            return <Loader loaded={!showLoadingIndicator} />;
        }

        const overrideProps = {
            '@field': {
                readOnly: true
            },
            contactDetailsContainer: {
                visible: !showLoadingIndicator
            },
            policySourceState: {
                visible: contact === 'policyContacts',
                to: {
                    pathname: `/${relatedTo}/${accountOrPolicyNumber}/contacts`,
                    state: {
                        selectTab: 'policyContactsTab'
                    }
                }
            },
            accountSourceState: {
                visible: contact === 'accountContacts',
                to: {
                    pathname: `/${relatedTo}/${accountOrPolicyNumber}/contacts`,
                    state: {
                        selectTab: 'accountContactsTab'
                    }
                }
            },
            associatedSourceState: {
                visible: contact === 'associatedContacts',
                to: {
                    pathname: `/${relatedTo}/${accountOrPolicyNumber}/contacts`,
                    state: {
                        selectTab: 'associatedContactsTab'
                    }
                }
            },
            accountContactStatusContainer: {
                visible: relatedTo !== 'policies' && !_.get(accountContactDetailsVM.value, 'active')
            },
            contactDetailsCompanySection: {
                visible: _.get(accountContactDetailsVM.value, 'subtype') === 'Company'
            },
            contactDetailsPersonSection: {
                visible: _.get(accountContactDetailsVM.value, 'subtype') === 'Person'
            },
            companyWorkNumberContainer: {
                visible: _.get(accountContactDetailsVM.value, 'workNumber') !== undefined
            },
            companyFaxContainer: {
                visible: _.get(accountContactDetailsVM.value, 'faxNumber') !== undefined
            },
            companyEmailAddressContainer: {
                visible: _.get(accountContactDetailsVM.value, 'emailAddress1') !== undefined
            },
            contactCompanyAddressContainer: {
                visible:
                    _.get(accountContactDetailsVM.value, 'primaryAddress.displayName') !== undefined
            },
            personDateOfBirthContainer: {
                visible: _.get(accountContactDetailsVM.value, 'dateOfBirth') !== undefined
            },
            personMaritalStatusContainer: {
                visible: _.get(accountContactDetailsVM.value, 'maritalStatus') !== undefined
            },
            personHomeNumberContainer: {
                visible: _.get(accountContactDetailsVM.value, 'homeNumber') !== undefined
            },
            personHomeNumberLabel: {
                content:
                    _.get(accountContactDetailsVM.value, 'primaryPhoneType') === 'home'
                        ? translator(messages.homePhonePrimary)
                        : translator(messages.homePhone)
            },
            personWorkNumberContainer: {
                visible: _.get(accountContactDetailsVM.value, 'workNumber') !== undefined
            },
            personWorkNumberLabel: {
                content:
                    _.get(accountContactDetailsVM.value, 'primaryPhoneType') === 'work'
                        ? translator(messages.workPhonePrimary)
                        : translator(messages.workPhone)
            },
            personCellNumberContainer: {
                visible: _.get(accountContactDetailsVM.value, 'cellNumber') !== undefined
            },
            personCellNumberLabel: {
                content:
                    _.get(accountContactDetailsVM.value, 'primaryPhoneType') === 'mobile'
                        ? translator(messages.mobilePrimary)
                        : translator(messages.mobile)
            },
            personEmailAddressContainer: {
                visible: _.get(accountContactDetailsVM.value, 'emailAddress1') !== undefined
            },
            contactPersonAddressContainer: {
                visible:
                    _.get(accountContactDetailsVM.value, 'primaryAddress.displayName') !== undefined
            },
            contactRoles: {
                roles: _.get(
                    accountContactDetailsVM.value,
                    contact === 'policyContacts' ? 'policyContactRoles' : 'accountContactRoles'
                ),
                associatedContacts: contact,
                relatedRoles: contact === 'policyContacts' ? 'policy' : 'account',
                contactType: _.get(accountContactDetailsVM.value, 'subtype')
            },
            companyWorkNumber: {
                value: this.getWorkPhoneNumber(_.get(accountContactDetailsVM, 'workNumber.value'))
            },
            contactDisplayName: {
                content: _.get(accountContactDetailsVM, 'displayName.value')
            },
            ...this.getAssociatedAccountsIcon()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                link: Link
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                accountContactDetailsVM,
                id,
                path,
                overrideProps
            );
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={accountContactDetailsVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default withViewModelService(withAuthenticationContext(ContactDetailsPage));
