import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import styles from '../SpreadsheetProcessorResult.module.scss';
import SectionTemplate from './SectionTemplate';
import DetailedSectionTemplate from './DetailedSectionTemplate';

function SuccessTemplate({ uploadedStatus, isSuccess, productCode }) {
    const messages = require(`../${productCode}Result.messages.js`).default;
    const translator = useTranslator();
    const getReminderInfo = () => {
        return (
            <div className={styles.reminderInfo}>
                {translator(messages.downloadUploadedSpreadsheetFirst)}
            </div>
        );
    };
    return (
        <div>
            <div className={styles.gwResultSummary}>
                {uploadedStatus.resultData.rowsNew > 0 && (
                    <SectionTemplate
                        icon="mi-check"
                        message={messages.buildingsWereAdded}
                        value={uploadedStatus.resultData.rowsNew}
                        type={uploadedStatus.type}
                    />
                )}
                {uploadedStatus.resultData.rowsEdited > 0 && (
                    <SectionTemplate
                        message={messages.buildingsWereEdited}
                        value={uploadedStatus.resultData.rowsEdited}
                        type={uploadedStatus.type}
                    />
                )}
                {uploadedStatus.resultData.rowsRemoved > 0 && (
                    <SectionTemplate
                        message={messages.buildingsWereRemoved}
                        value={uploadedStatus.resultData.rowsRemoved}
                        type={uploadedStatus.type}
                    />
                )}
                {!isSuccess && (
                    <SectionTemplate
                        icon="mi-cancel"
                        message={messages.buildingsWereNotImportedCount}
                        value={uploadedStatus.resultData.rowsWithErrors}
                        type={uploadedStatus.type}
                    />
                )}
                {uploadedStatus.status === 'partial' && (
                    <p>{translator(messages.fixIssuesToImportSuccessful)}</p>
                )}
                {isSuccess && (
                    <>
                        <p>{translator(messages.successYouCanProceed)}</p>
                        <ul className={styles.successInstructionList}>
                            <li>{translator(messages.editIndividualBuildingsListBelow)}</li>
                            <li>{translator(messages.singleBuildingsAddBuildingButton)}</li>
                            <li>
                                <FormattedMessage
                                    {...messages.editBuildingDataOnceUploadSpreadsheet}
                                    values={{
                                        downloadreminder: getReminderInfo()
                                    }}
                                />
                            </li>
                        </ul>
                    </>
                )}
                {!_.isEmpty(uploadedStatus.resultData.errors) && (
                    <DetailedSectionTemplate
                        resultData={uploadedStatus.resultData.errors}
                        message={messages.affectedRowError}
                    />
                )}
                {uploadedStatus.status === 'partial' && (
                    <p className={styles.gwResulContents}>
                        {translator(messages.addCorrectDataDownloadTemplate)}
                    </p>
                )}
            </div>
        </div>
    );
}
SuccessTemplate.propTypes = {
    uploadedStatus: PropTypes.shape({
        status: PropTypes.string.isRequired,
        resultData: PropTypes.shape({
            rowsNew: PropTypes.string,
            rowsEdited: PropTypes.number,
            rowsRemoved: PropTypes.number,
            rowsWithErrors: PropTypes.number,
            errors: PropTypes.shape([])
        }).isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    isSuccess: PropTypes.bool.isRequired,
    productCode: PropTypes.string.isRequired
};
export default SuccessTemplate;
