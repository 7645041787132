import React, {
    useMemo, useState, useContext, useCallback, useEffect
} from 'react';
import _ from 'lodash';

import { useModal } from '@jutro/components';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { messages as commonMessages } from 'gw-platform-translations';

import metadata from './CoveragesAndExclusionsPage.metadata.json5';
import styles from './CoveragesAndExclusionsPage.module.scss';
import messages from '../../WC7Wizard.messages';

const clausePaths = [
    'lobData.wc7WorkersComp.clauses.coverages',
    'lobData.wc7WorkersComp.clauses.exclusions',
    'lobData.wc7WorkersComp.clauses.conditions'
];

function CoveragesAndExclusions(props) {
    const {
        showAlert
    } = useModal();

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();

    const { wizardData: submissionVM, updateWizardData } = props;

    const [loadingClause, updateLoadingClause] = useState();
    const [showConditions, updateConditions] = useState(false);
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('CoveragesAndExclusions');

    const enableConditionsToggle = useMemo(() => {
        const conditions = _.get(submissionVM.value, 'lobData.wc7WorkersComp.clauses.conditions');
        const isConditionSet = _.some(conditions, (condition) => {
            return condition.selected === true;
        });

        return isConditionSet;
    }, [submissionVM]);

    useEffect(() => {
        updateConditions(enableConditionsToggle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateClauses = useCallback(
        async (action, clausesToUpdate, path) => {
            const { quoteID, sessionUUID } = submissionVM.value;
            const newSubmissionVM = viewModelService.clone(submissionVM);

            let coveragePath = '';
            let baseCoveragePath = '';
            let dataForServer = {};

            try {
                if (action === 'update') {
                    coveragePath = clausePaths.find((clausePath) => _.includes(path, clausePath));
                    baseCoveragePath = coveragePath.replace(/\.\w+$/, '.value');
                    const coverageFullName = coveragePath.split('.');
                    const coverageName = coverageFullName[coverageFullName.length - 1];

                    const lobName = ClausesUtil.getLobNameFromPath(coveragePath);
                    const dataToModify = _.get(clausesToUpdate, coveragePath);
                    const data = {
                        [coverageName]: [dataToModify.value[path.match(/\d+/g)[1]]]
                    };

                    dataForServer = {
                        [lobName]: data
                    };
                } else {
                    coveragePath = 'lobData.wc7WorkersComp.clauses.coverages';
                    baseCoveragePath = coveragePath.replace(/\.\w+$/, '.value');
                    const lobName = ClausesUtil.getLobNameFromPath(coveragePath);

                    dataForServer = {
                        [lobName]:
                            _.get(clausesToUpdate, baseCoveragePath)
                            || _.get(newSubmissionVM, baseCoveragePath)
                    };
                }

                const updateCoveragesResponse = await LoadSaveService.updateCoverages(
                    quoteID,
                    sessionUUID,
                    dataForServer,
                    authHeader
                );

                // eslint-disable-next-line max-len
                _.set(newSubmissionVM.value, baseCoveragePath, updateCoveragesResponse.wc7WorkersComp);

                const fieldsToRemoveFromValidation = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    coveragePath
                );
                disregardFieldValidation(fieldsToRemoveFromValidation);
                updateWizardData(newSubmissionVM);
            } catch {
                showAlert({
                    title: messages.modalError,
                    message: messages.modalErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }

            return newSubmissionVM;
        },
        [authHeader, submissionVM, updateWizardData, disregardFieldValidation, viewModelService]
    );

    const onClauseChange = useCallback(
        async (changedPath) => {
            const { quoteID, sessionUUID } = submissionVM.value;
            const lobName = ClausesUtil.getLobNameFromPath(changedPath);

            const structuredClauses = clausePaths
                .map((pathToClause) => {
                    const clauseType = ClausesUtil.getLastSectionOfPath(pathToClause);
                    const clauses = _.get(submissionVM, `${pathToClause}.value`);
                    const structuredClausesToUpdate = ClausesUtil.structureClausesForServer(
                        clauses,
                        lobName,
                        clauseType
                    );
                    return structuredClausesToUpdate[lobName];
                })
                .filter((structuredClause) => {
                    return !Object.values(structuredClause).includes(undefined);
                });

            const clausesToUpdate = Object.assign({}, ...structuredClauses);
            const clausesToUpdateWithLob = { [lobName]: clausesToUpdate };

            let coverageResponse = {};
            try {
                const updateCoveragesResponse = await LoadSaveService.updateCoverages(
                    quoteID,
                    sessionUUID,
                    clausesToUpdateWithLob,
                    authHeader
                );
                const newSubmissionVM = viewModelService.clone(submissionVM);

                const clauseIDsToRemove = clausePaths.flatMap((pathToClause) => {
                    const clauseType = ClausesUtil.getLastSectionOfPath(pathToClause);
                    const updatedClauses = _.get(
                        updateCoveragesResponse,
                        `${lobName}.${clauseType}`
                    );
                    _.set(newSubmissionVM, pathToClause, updatedClauses);

                    return ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        pathToClause
                    );
                });

                disregardFieldValidation(clauseIDsToRemove);
                updateWizardData(newSubmissionVM);
                coverageResponse = newSubmissionVM;
            } catch {
                showAlert({
                    title: messages.modalError,
                    message: messages.modalErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
            updateLoadingClause(undefined);
            return coverageResponse;
        },
        [authHeader, submissionVM, disregardFieldValidation, updateWizardData, viewModelService]
    );

    const scheduleChange = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.set(newSubmissionVM, path, value);

            return updateClauses('update', newSubmissionVM, path);
        },
        [submissionVM, updateClauses, viewModelService]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const syncClauses = useCallback(
        async (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(submissionVM, basePath);
            const pathToClause = clausePaths.find((path) => _.includes(changedPath, path));

            const clauses = _.get(submissionVM, `${pathToClause}.value`);

            if (ClausesUtil.shouldClauseUpdate(baseObject, clauses)) {
                updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
                const newSubmissionData = await onClauseChange(changedPath);
                if (!_.isEmpty(newSubmissionData)) {
                    updateWizardData(newSubmissionData);
                }
            }
        },
        [onClauseChange, submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );

    const onNext = useCallback(updateClauses, [updateClauses]);

    const updateConditionsToggle = (value) => {
        updateConditions(value);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        exclusionClauses: {
            loadingClause: loadingClause
        },
        conditionsToggle: {
            value: showConditions,
            onValueChange: updateConditionsToggle
        },
        conditionsClauses: {
            visible: showConditions,
            loadingClause: loadingClause
        },
        coverageClauses: {
            loadingClause: loadingClause
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate: onValidate,
            onScheduleChange: scheduleChange
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    return (
        <WizardPage skipWhen={initialValidation} disableNext={!isComponentValid} onNext={onNext}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

CoveragesAndExclusions.propTypes = wizardProps;
export default CoveragesAndExclusions;
