import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Wc7CoverablesService, Wc7CoveragesService } from 'gw-capability-quoteandbind-wc7';
import { CustomizedHeaderComponent } from 'gw-components-platform-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';
import ExpModifiersIterableComponent from '../../components/ExpModifiersIterableComponent/ExpModifiersIterableComponent';
import ScheduleItemsIterableComponent from '../../components/ScheduleItemsIterableComponent/ScheduleItemsIterableComponent';
import metadata from './ExpModAndStateCoveragesPage.metadata.json5';
import styles from './ExpModAndStateCoveragesPage.module.scss';
import messages from './ExpModAndStateCoveragesPage.messages';

const isExpMod = (modifier) => modifier.patternCode === 'WC7ExpMod';
function ExpModAndStateCoverages(props) {
    const {
        showAlert
    } = useModal();

    const [expModifiersList, setExpModifiersList] = useState([]);
    const [updatedModifiers, setUpdatedModifiers] = useState([]);
    const [hasModifiers, setHasModifiers] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [initialStateDetails, setInitialStateDetails] = useState([]);
    const [hasPriorPolicies, updateHasPriorPolicies] = useState(false);
    const { wizardData: submissionVM, updateWizardData, authHeader } = props;
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('ExpModAndStateCoverages');
    const [loadingClause, updateLoadingClause] = useState();

    const withDefaultValues = useCallback((jurisdictionsDTO) => {
        const clonedJurisdictionsDTO = _.cloneDeep(jurisdictionsDTO);
        clonedJurisdictionsDTO
            .flatMap((jurisdiction) => jurisdiction.modifiers)
            .forEach((modifier) => {
                if (isExpMod(modifier) && _.isUndefined(modifier.rateModifier)) {
                    /* eslint-disable no-param-reassign */
                    modifier.rateModifier = 1;
                    modifier.experienceModifierStatus = 'Final';
                    /* eslint-enable no-param-reassign */
                }
            });
        return clonedJurisdictionsDTO;
    }, []);

    const getExpModifiers = useCallback((jurisdictionDetails) => {
        const expModifiers = jurisdictionDetails
            .flatMap((jurisdiction) => [...jurisdiction.modifiers])
            .filter(isExpMod)
            .map((modifier) => ({
                ...modifier,
                viewSchedules: _.get(modifier, 'schedules.length', 0) > 0,
                viewCoverages: _.get(modifier, 'coverages.length', 0) > 0
            }));
        setExpModifiersList(expModifiers);
    }, []);

    useEffect(() => {
        const { quoteID, sessionUUID } = submissionVM.value;
        const { policyHistory } = submissionVM.lobData.wc7WorkersComp.coverables.value;
        Wc7CoverablesService.getAllJurisdictionDetails(quoteID, sessionUUID, authHeader).then(
            (getAllJurisdictionDetails) => {
                submissionVM.lobData.wc7WorkersComp.stateDetails.value = withDefaultValues(
                    getAllJurisdictionDetails
                );
                getExpModifiers(getAllJurisdictionDetails);
                setHasModifiers(true);
                updateWizardData(submissionVM);
                setIsLoading(false);
                setInitialStateDetails(() => [...withDefaultValues(getAllJurisdictionDetails)]);
            }
        );
        if (policyHistory && policyHistory.policyEntries.length > 0) {
            updateHasPriorPolicies(true);
        }
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValidateForm = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM]);
    const validateForm = useCallback(() => {
        return hasModifiers && expModifiersList.length === updatedModifiers.length;
    }, [expModifiersList.length, hasModifiers, updatedModifiers.length]);
    useEffect(() => {
        registerComponentValidation(validateForm);
        registerInitialComponentValidation(initialValidateForm);
    }, [
        validateForm,
        initialValidateForm,
        registerComponentValidation,
        registerInitialComponentValidation
    ]);

    const setConditionsToggle = useCallback(
        (id, key, value) => {
            expModifiersList.forEach((val) => {
                if (val.publicID === id) {
                    // eslint-disable-next-line no-param-reassign
                    val[key] = value;
                }
            });

            setExpModifiersList(() => [...expModifiersList]);
        },
        [expModifiersList]
    );

    const cancelModifier = useCallback(
        (stateIndex, modifierIndex) => {
            const modifierVal = initialStateDetails[stateIndex].modifiers[modifierIndex];
            _.set(
                submissionVM.value,
                `lobData.wc7WorkersComp.stateDetails[${stateIndex}].modifiers[${modifierIndex}]`,
                modifierVal
            );
            updateWizardData(submissionVM);
        },
        [initialStateDetails, submissionVM, updateWizardData]
    );

    const updateModifier = useCallback(
        (modifier, index, modifierIndex) => {
            if (!_.inRange(modifier.rateModifier, 0.5, 6)) {
                return;
            }
            setUpdatedModifiers((prevList) => [...prevList, modifier.publicID]);
            const { quoteID, sessionUUID } = submissionVM.value;
            const modifierPath = `lobData.wc7WorkersComp.stateDetails[${index}].modifiers[${modifierIndex}]`;
            Wc7CoverablesService.updateModifier(quoteID, sessionUUID, modifier, authHeader)
                .then((updatedModifier) => {
                    _.set(submissionVM.value, modifierPath, updatedModifier);
                    updateWizardData(submissionVM);
                    const stateDetails = _.cloneDeep(
                        _.get(submissionVM, 'value.lobData.wc7WorkersComp.stateDetails')
                    );
                    setInitialStateDetails(() => [...stateDetails]);
                })
                .catch(() => {
                    showAlert({
                        title: messages.updatingError,
                        message: '',
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                });
        },
        [authHeader, submissionVM, updateWizardData]
    );

    const editBtnHandler = useCallback(
        (modifier) => {
            const updatedList = updatedModifiers.filter((val) => val !== modifier.publicID);
            setUpdatedModifiers([...updatedList]);
        },
        [updatedModifiers]
    );

    const getCurrentModifierPublicID = useCallback((states) => {
        return states.modifiers.find(isExpMod).publicID;
    }, []);

    const setToggleValue = useCallback(
        (key, publicID) => {
            return expModifiersList.some((val) => val[key] && val.publicID === publicID);
        },
        [expModifiersList]
    );

    const updatedModifierOverrides = useCallback(() => {
        const stateDetailsPath = 'lobData.wc7WorkersComp.stateDetails';
        const stateDetails = _.get(submissionVM.value, stateDetailsPath, []);
        const overridesObj = stateDetails.map((states, stateIndex) => {
            const publicID = getCurrentModifierPublicID(states);
            const isCoverageVisible = setToggleValue('viewCoverages', publicID);
            const isScheduleVisible = setToggleValue('viewSchedules', publicID);
            const overrideScheduledItems = {
                [`stateCoverages${stateIndex}`]: {
                    visible: true
                },
                [`addCoverages${stateIndex}`]: {
                    value: isCoverageVisible,
                    visible: !!states.clauses.coverages && states.clauses.coverages.length > 0,
                    onValueChange: (value) => {
                        setConditionsToggle(publicID, 'viewCoverages', value);
                    }
                },
                [`conditionsClausesCoverages${stateIndex}`]: {
                    visible: isCoverageVisible
                },
                [`addScheduledItems${stateIndex}`]: {
                    value: isScheduleVisible,
                    onValueChange: (value) => {
                        setConditionsToggle(publicID, 'viewSchedules', value);
                    }
                },
                [`supplementryDisease${stateIndex}`]: {
                    visible: isScheduleVisible
                },
                [`scheduleItemContainer${stateIndex}`]: {
                    visible: isScheduleVisible
                },
                [`expModifiersIterableComponentContainer${stateIndex}`]: {
                    editBtnHandler: editBtnHandler,
                    updateModifier: updateModifier,
                    cancelModifier: cancelModifier,
                    updatedModifiers: updatedModifiers,
                    setUpdatedModifiers: setUpdatedModifiers
                },
                [`clausesCoverages${stateIndex}`]: {
                    loadingClause: loadingClause
                }
            };

            return Object.assign({}, overrideScheduledItems);
        });
        return Object.assign({}, ...overridesObj);
    }, [
        submissionVM,
        updatedModifiers,
        editBtnHandler,
        updateModifier,
        setUpdatedModifiers,
        getCurrentModifierPublicID,
        setConditionsToggle,
        setToggleValue,
        cancelModifier,
        loadingClause
    ]);

    const updateClauses = useCallback(
        async (path, coverages, jurisdiction) => {
            const { quoteID, jobID, sessionUUID } = submissionVM.value;

            const getUpdatedData = await Wc7CoveragesService.updateJurisdictionClauses(
                quoteID || jobID,
                sessionUUID,
                coverages,
                jurisdiction,
                authHeader
            );
            _.set(submissionVM, `${path}.clauses.coverages.value`, getUpdatedData.coverages);
            _.set(submissionVM, `${path}.clauses.schedules.value`, getUpdatedData.schedules);
            updateWizardData(submissionVM);
            updateLoadingClause(undefined);
            return submissionVM;
        },
        [authHeader, submissionVM, updateWizardData]
    );

    const onScheduleChange = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            const covPath = path.replace(/\.chosenTerm/g, '');
            _.set(submissionVM, `${covPath}.updated`, true);
            const getPath = path.replace(/.clauses.+/g, '');
            const coverages = _.get(submissionVM, `${getPath}.clauses.value`);
            const getJurisdiction = _.get(submissionVM, `${getPath}.value.jurisdiction`);
            const basePath = ClausesUtil.getObjectPathFromChangedPath(path);
            const baseObject = _.get(submissionVM, basePath);

            if (/schedules/gi.test(path)) {
                const getScheduleItems = _.get(submissionVM, `${path}.scheduleItems`);
                const updatedScheduleItems = getScheduleItems.map((schedule) => {
                    if (schedule.itemData.Location) {
                        const location = _.cloneDeep(schedule.itemData.Location.locationValue);
                        return {
                            location,
                            itemNumber: null,
                            ...schedule
                        };
                    }
                    return schedule;
                });
                _.set(submissionVM, `${path}.scheduleItems`, updatedScheduleItems);
            }
            if (baseObject) {
                updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
            }
            return updateClauses(getPath, coverages, getJurisdiction);
        },
        [submissionVM, updateClauses, updateLoadingClause]
    );

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left'
        },
        expModInformationInfobox: {
            visible: !hasPriorPolicies
        },
        ...updatedModifierOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            customizedheadercomponent: CustomizedHeaderComponent,
            expmodifiersiterablecomponent: ExpModifiersIterableComponent,
            scheduleitemsiterablecomponent: ScheduleItemsIterableComponent
        },
        resolveCallbackMap: {
            onScheduleChange: onScheduleChange
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <WizardPage skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}
ExpModAndStateCoverages.propTypes = wizardProps;
export default withAuthenticationContext(ExpModAndStateCoverages);
