import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import styles from '../SpreadsheetProcessorResult.module.scss';

const subheadingContent = (subheading, elementStyle) => {
    return <p className={elementStyle || styles.gwResultSubheading}>{subheading}</p>;
};
function CommonTemplate({ template, status, error }) {
    const translator = useTranslator();
    return (
        <Fragment>
            {template.subheading && subheadingContent(translator(template.subheading))}
            {status === 'fail-processing' && error && subheadingContent(error)}
            {template.contents
                && subheadingContent(translator(template.contents), styles.gwResultContents)}
        </Fragment>
    );
}
CommonTemplate.propTypes = {
    template: PropTypes.shape({
        heading: PropTypes.string,
        subheading: PropTypes.string,
        contents: PropTypes.string
    }).isRequired,
    status: PropTypes.string.isRequired,
    error: PropTypes.string
};
CommonTemplate.defaultProps = {
    error: undefined
};
export default CommonTemplate;
