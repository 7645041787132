import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';

import styles from './CostBoxComponent.module.scss';

import metadata from './CostBoxComponent.metadata.json5';
import './CostBoxComponent.messages';

export class CostBoxComponent extends Component {
    static propTypes = {
        value: PropTypes.shape({}).isRequired
    };

    render() {
        const { value: submissionValue } = this.props;

        const overrideProps = {
            wc7PolicyPeriodDate: {
                value: {
                    startDate: _.get(submissionValue, 'baseData.periodStartDate'),
                    endDate: _.get(submissionValue, 'baseData.periodEndDate')
                }
            }
        };

        const resolvers = {
            resolveClassNameMap: styles
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={submissionValue}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default CostBoxComponent;
