import { defineMessages } from 'react-intl';

export default defineMessages({
    analytics: {
        id: 'app.html.index.Analytics',
        defaultMessage: 'Analytics'
    },

    growthAnalytics: {
        id: 'portal.infocenter.tab.Growth',
        defaultMessage: 'Growth',
    },

    lossratiosAnalytics: {
        id: 'portal.infocenter.tab.Loss Ratios',
        defaultMessage: 'Loss Ratios',

    }
});
