/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import React, { Component, useCallback, useContext } from 'react';
import {
    Chevron,
    Link,
    Button,
    withModalContext
} from '@jutro/components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import _ from 'lodash';
import { TranslatorContext, withIntl, IntlContext } from '@jutro/locale';

import { readViewModelValue } from 'gw-jutro-adapters-react';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { JobUtil, DatatableUtil } from 'gw-portals-util-js';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import CoveragesIterableComponent from '../../Components/CoveragesIterableComponent/CoveragesIterableComponent';
import PolicyDwellingInfo from './ho/PolicyDwellingInfo/PolicyDwellingInfo';
import PolicyVehiclesDriversInfo from './pa/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import Cancellation from '../Cancellation/Cancellation';
import ChangePolicy from '../ChangePolicy/ChangePolicy';
import metadata from './Summary.metadata.json5';
import styles from './Summary.module.scss';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';

function getCell(items, index, property) {
    return items[property.id];
}

function renderFormattedCurrencyField(item, loc) {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${loc}`}
                value={item}
                readOnly
                hideLabel
                className={styles.currencyNormal}
            />
        </div>
    );
}

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item[property.id], item.premiumLoc);
};
function getPremiumCell(items, index, property) {
    return !_.isObject(items[property.id]) ? items[property.id] : null;
}

function transactionTypeIsViewableInXCenter(transaction) {
    return (
        transaction.type.toLowerCase() !== 'reinstatement' &&
    transaction.type.toLowerCase() !== 'rewrite'
    );
}

function canUserOpenJobInXCenter(transaction) {
    return transaction.canUserView && transactionTypeIsViewableInXCenter(transaction);
}

class Summary extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                })
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        intl: PropTypes.func.isRequired,
    };

    static contextType = TranslatorContext;

    state = {
        policyData: '',
        disableCancelLink: true,
        disableChangePolicyLink: true,
        policyTransactionData: [],
        policyTransactionStatusValues: [],
        selectedTransactionStatus: 'all',
        searchTransactionKeyword: undefined,
        canAdvanceCancellation: false,
        canEditPolicy: false
    };

    componentDidMount() {
        this.hasAdvanceCancellationPermission();
        this.hasEditPolicyPermission();
        this.setPolicyCoveragesDetails();
        this.getPolicyTransactions();
        this.setPolicyPremiumDetails();
    }

    setPolicyCoveragesDetails = () => {
        const {
            fromAccountLanding: { policyDetailsData }
        } = this.props;
        const policyData = _.cloneDeep(policyDetailsData.policyResponse);
        this.setState({ policyData });
    };

    getPolicyTransactions = async () => {
        const { authHeader, fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const transactionResponse = await PolicyService.getPolicyTransactionsForPolicy(
            policyNumber,
            authHeader
        );
        this.getMappedTransactionData(transactionResponse);
        const policyTransactionStatusValues = this.getPolicyTransactionStatus();
        this.setState({
            transactionResponse,
            policyTransactionStatusValues,
            selectedTransactionStatus: policyTransactionStatusValues[0].code
        });
    };

    getFormattedCurrency = (premiumAmount, index) => {
        return (
            <CurrencyField
                id={`currency${index}`}
                value={premiumAmount}
                readOnly
                hideLabel
                showOptional={false}
                className={styles.currencyStyle}
            />
        );
    };

    getMappedTransactionData = (policyTransactionResponse, filter) => {
        const selectedStatus = _.get(filter, 'selectedTransactionStatus');
        const searchKeyword = _.get(filter, 'searchTransactionKeyword');
        const transactionData = _.cloneDeep(policyTransactionResponse);

        let policyTransactionData = transactionData.map((policy, index) => {
            return {
                jobNumber: policy.jobNumber,
                status: policy.status,
                displayType: policy.displayType,
                policyDisplayStatus: policy.policyDisplayStatus,
                totalPremium: this.getFormattedCurrency(policy.totalPremium, index),
                policyEffectiveDate: policy.policyEffectiveDate,
                canUserView: policy.canUserView,
                type: policy.type
            };
        }).sort((a, b) => {
            return new Date(b.policyEffectiveDate).getTime()
                - new Date(a.policyEffectiveDate).getTime();
        });
        if (selectedStatus && selectedStatus !== 'all') {
            policyTransactionData = _.filter(policyTransactionData, (transaction) => {
                return transaction.status === selectedStatus;
            });
        }
        if (searchKeyword) {
            policyTransactionData = this.getSearchFilterValues(
                policyTransactionData,
                searchKeyword
            );
        }
        this.setState({
            policyTransactionData
        });
    };

    hasAdvanceCancellationPermission = () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'advancecancellation'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (canAdvanceCancellation) => {
                this.setState({ canAdvanceCancellation });
            }
        );
    };

    hasEditPolicyPermission = () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'editpolchange'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (canEditPolicy) => this.setState({ canEditPolicy })
        );
    };

    getSearchFilterValues = (transactionData, keyword) => {
        const lowerCaseFilterValue = keyword.toLocaleLowerCase();
        return _.filter(transactionData, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getJobNumberLink = (item, index, property) => {
        if (canUserOpenJobInXCenter(item)) {
            if (this.showOpenJobInXCenter(item)) {
                return (
                    <Link
                        href="/"
                        onClick={() => JobUtil.openJobInXCenter(item.jobNumber)}
                        className={styles.jobNumberColumnLink}
                    >
                        {item[property.id]}
                    </Link>
                );
            }
            return (
                <Link
                    to={
                        JobUtil.getJobDetailURLByJobType(item.type, item.jobNumber)
                    }
                    className={styles.jobNumberColumnLink}
                >
                    {item[property.id]}
                </Link>
            );
        }
        return <span>{item.jobNumber}</span>;
    };

    showOpenJobInXCenter = (item) => {
        return (
            item.type !== 'Submission'
            && item.type !== 'PolicyChange'
            && item.type !== 'Cancellation'
            && item.type !== 'Renewal'
        );
    };

    onChangePolicy = async () => {
        const { policyData } = this.state;
        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], { policyNumber });
        } else {
            this.setState({ disableChangePolicyLink: false });
        }
    };

    getJobNumber = async (policyNumber) => {
        const { authHeader } = this.props;
        const renewalVM = await PolicyService.createNewPolicyRenewalTransaction(
            [policyNumber],
            authHeader
        );
        return _.get(renewalVM, 'jobNumber');
    };

    onRenewalPolicy = async () => {
        const { fromAccountLanding, history } = this.props;
        const { lobRenewalURL } = appConfig;
        const policy = _.get(fromAccountLanding, 'policyDetailsData.policyResponse');
        const policyNumber = _.get(policy, 'policyNumber');
        const lobCode = _.get(policy, 'latestPeriod.policyLines[0].lineOfBusinessCode');
        const jobNumber = await this.getJobNumber(policyNumber);
        if (!_.isEmpty(lobRenewalURL[lobCode])) {
            history.push(lobRenewalURL[lobCode], { jobNumber });
        } else {
            JobUtil.openJobInXCenter(jobNumber);
        }
    };

    handleCancelButtonClick = () => {
        this.setState({ disableCancelLink: false });
    };

    onDoNotCancel = () => {
        this.setState({ disableCancelLink: true });
    };

    onDoNotChangePolicy = () => {
        this.setState({ disableChangePolicyLink: true });
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getResponse = async () => {
        const result = await PolicyService.getPolicy();
        return result;
    };

    getHeaderSection = (title) => {
        return (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} />
                <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>{title}</h2>
            </React.Fragment>
        );
    };

    isGeneralCoverage = () => {
        const { policyData } = this.state;
        return (
            [
                'PersonalAuto',
                'Homeowners',
                'HOPHomeowners',
                'homeowners',
                'HomeownersLine_HOE'
            ].indexOf(policyData.product.productCode) === -1
        );
    };

    getPolicyTransactionStatus = () => {
        return [
            {
                code: 'all',
                name: gatewayMessages.all
            },
            {
                code: 'Draft',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Draft',
                    defaultMessage: 'Draft'
                }
            }
        ];
    };

    handleFilterValueChange = (value) => {
        const { transactionResponse, searchTransactionKeyword } = this.state;
        this.setState({ selectedTransactionStatus: value });
        this.getMappedTransactionData(transactionResponse, {
            selectedTransactionStatus: value,
            searchTransactionKeyword
        });
    };

    handleSearchValueChange = (value) => {
        const { transactionResponse, selectedTransactionStatus } = this.state;
        this.setState({ searchTransactionKeyword: value });
        this.getMappedTransactionData(transactionResponse, {
            searchTransactionKeyword: value,
            selectedTransactionStatus
        });
    };

    generateOverrides = () => {
    /*    const { policyData } = this.state;
        const overrides = policyData.coverables.map((coverable, i) => {
            return {
                [`namesGrid${i}`]: {
                    visible: !_.isEmpty(coverable.coverages)
                }
            };
        }); */

        const {
            fromAccountLanding: { policyDetailsData }
        } = this.props;

        const premiumSummary = _.cloneDeep(
            policyDetailsData.policyResponse.premiumSummary
        );

        const standardPremiumList = _.get(premiumSummary, 'standardPremiumList', []);
        const overrides = standardPremiumList.map((standardPremium, index) => {
            return {
                [`standardPremiumDetailsTable${index}`]: {
                    data: this.getStandardPremiumData(
                        standardPremium.standardPremiumDetails
                    )
                },
                [`standardPremiumHeader${index}`]: {
                    content: standardPremium.ratingPeriodDescription
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    getSelectedPolicy = (policyData) => {
        return (
            policyData.account?.policySummaries?.find(
                (policy) => policy.policyNumber === policyData.policyNumber
            ) ?? {}
        );
    };

    setPolicyPremiumDetails = () => {
        const {
            fromAccountLanding: { policyDetailsData }
        } = this.props;
        const premiumSummary = _.cloneDeep(
            policyDetailsData.policyResponse.premiumSummary
        );
        this.setState({ premiumSummary });
    };

    getStandardPremiumData = (data) => {
        return data
            .map((premium) => {
                return {
                    premiumLoc: premium.locationId,
                    premiumCode: premium.code,
                    premiumDescription: premium.description,
                    premiumExposure: premium.basis,
                    premiumRate: premium.rate,
                    premiumAmount: premium.amount
                };
            })
            .sort((a, b) => {
                return a.name - b.name;
            });
    };

    getOtherPremiumData = (data) => {
        return data.otherPremiumAndSurcharges
            .map((premium) => {
                return {
                    premiumLoc: premium.locationId,
                    premiumCode: premium.code,
                    premiumDescription: premium.description,
                    premiumExposure: premium.basis,
                    premiumRate: premium.rate,
                    premiumAmount: premium.amount
                };
            })
            .sort((a, b) => {
                return a.name - b.name;
            });
    };

    render() {
        const {
            policyData,
            disableCancelLink,
            policyTransactionData,
            policyTransactionStatusValues,
            selectedTransactionStatus,
            searchTransactionKeyword,
            disableChangePolicyLink,
            canAdvanceCancellation,
            canEditPolicy
        } = this.state;
        const {
            authHeader, history, fromAccountLanding, modalContext
        } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const productCode = _.get(policyData, 'product.productCode');
        if (_.isEmpty(policyData)) {
            return null;
        }
        const translator = this.context;
        const preRenewalDirection = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.latestPeriod.preRenewalDirection'
        );
        const selectedPolicy = this.getSelectedPolicy(policyData);

        async function createPolicyPayrollActivity() {

            const payrollActivityCreationRes = PolicyService.createPolicyPayrollActivity(
                policyNumber,
                authHeader
            );
            modalContext
                .showAlert({
                    title: translator(payrollActivityCreationRes
                        ? messages.successText : messages.failureText),
                    message: translator(payrollActivityCreationRes
                        ? messages.payrollActivitySuccessMessage :
                        messages.payrollActivityFailureMessage),
                    status: payrollActivityCreationRes ? 'success' : 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.okText
                })
                .catch(_.noop);
        }
        function PolicyPayrollReportMessage(componentProps) {
            const intl = useContext(IntlContext);
            return (
                <div className={componentProps.className}>
                    <p>
                        {translator(messages.payrollReportMessage, {
                            month: policyData.payRollMonth,
                            dueDate: intl.formatDate(new Date(policyData.payRollDueDate), {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            })
                        })}
                    </p>
                    <span>
                        <Button
                            id="Button"
                            size="medium"
                            type="filled"
                            onClick={createPolicyPayrollActivity}
                        >
                            {translator(messages.requestPayrollReport)}
                        </Button>
                    </span>
                </div>
            );
        }
        const overrides = {
            summaryPolicyTransactionsTableGrid: {
                data: policyTransactionData
            },
            coveragesSectionContainer: {
                visible: this.isGeneralCoverage()
            },
            coveragesSection: {
                renderHeader: this.getHeaderSection(translator(messages.Coverages))
            },
            cancellationContacts: {
                visible: !disableCancelLink && disableChangePolicyLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyContacts: {
                visible: !disableChangePolicyLink && disableCancelLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: !policyData.latestPeriod.canceled
                    && policyData.latestPeriod.canChange
                    && canEditPolicy
            },
            renewPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: !policyData.latestPeriod.canceled && policyData.latestPeriod.canRenew
            },
            cancelPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible:
                    !policyData.latestPeriod.canceled
                    && policyData.latestPeriod.canCancel
                    && canAdvanceCancellation
            },
            policyVehicleInfo: {
                visible: policyData.product.productCode === 'PersonalAuto',
                value: policyData.lobs.personalAuto
            },
            policyHouseOwnersInfo: {
                visible:
                    productCode === 'Homeowners'
                    || productCode === 'HOPHomeowners'
                    || productCode === 'homeowners'
                    || productCode === 'HomeownersLine_HOE',
                value: policyData.lobs.homeOwners
            },
            policyTransactionStatus: {
                availableValues: policyTransactionStatusValues,
                value: selectedTransactionStatus
            },
            searchFilter: {
                value: searchTransactionKeyword
            },
            preRenewalDirectionValueId: {
                value: !_.isNil(
                    preRenewalDirection
                ) ? [preRenewalDirection]
                    : translator(messages.SummaryDetailsPreRenewalDirectionNone)
            },
            producerOfRecordValueId: {
                value: `${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfRecordOrg'
                )}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfRecord'
                )})`
            },
            producerOfServiceValueId: {
                value: `${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfServiceOrg'
                )}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfService'
                )})`
            },
            policyCommonDetailsForPayrollReportingMessage: {
                visible: !!policyData.payRollMonth
            },
            pendingCancellationwarning: {
                visible: policyData.pendingCancellation
            },
            cancellationReasonColumn: {
                visible: selectedPolicy.isCancelled || policyData.pendingCancellation
            },
            makePaymentWarning: {
                visible: selectedPolicy.isCancelled || policyData.pendingCancellation
            },
            otherPremiumDetailsTable: {
                data: this.getOtherPremiumData(
                    _.get(
                        fromAccountLanding,
                        'policyDetailsData.policyResponse.premiumSummary'
                    )
                )
            },
            ...this.generateOverrides()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: getCell,
                getJobNumberLink: this.getJobNumberLink,
                onChangePolicy: this.onChangePolicy,
                onRenewalPolicy: this.onRenewalPolicy,
                handleCancelButtonClick: this.handleCancelButtonClick,
                onDoNotCancel: this.onDoNotCancel,
                handleFilterValueChange: this.handleFilterValueChange,
                handleSearchValueChange: this.handleSearchValueChange,
                onDoNotChangePolicy: this.onDoNotChangePolicy,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortCurrency: DatatableUtil.sortCurrency,
                sortNumber: DatatableUtil.sortNumber,
                createPolicyPayrollActivity: createPolicyPayrollActivity,
                getPremiumData: getPremiumCell,
                getFormattedCurrency,
            },
            resolveComponentMap: {
                cancelcomponent: Cancellation,
                policypacomponent: PolicyVehiclesDriversInfo,
                changepolicycomponent: ChangePolicy,
                policydwellingcomponent: PolicyDwellingInfo,
                coveragesiterablecomponent: CoveragesIterableComponent,
                policyPayrollReportMessageComponent: PolicyPayrollReportMessage
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, policyData, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    }
}

export const SummaryComponent = Summary;
export default withRouter(withIntl(withAuthenticationContext(withModalContext(Summary))));
