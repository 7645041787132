import React, {
    memo, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './TimelineDropdown.module.scss';
import metadata from './TimelineDropdown.metadata.json5';

const TimelineDropdown = memo((props) => {
    const { options, onValueChange } = props;
    const [selectedOption, setSelectedOption] = useState(undefined);

    const handleValueChange = useCallback(
        (selectedItem = 'all') => {
            setSelectedOption(selectedItem);
            onValueChange({
                code: selectedItem
            });
        },
        [onValueChange]
    );

    const overrideProps = {
        timelineDropdown: {
            availableValues: options,
            value: selectedOption
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange: handleValueChange
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, props, id, path, overrideProps);
        },
        [props, overrideProps]
    );
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={props}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
});

TimelineDropdown.propTypes = {
    options: PropTypes.shape([]).isRequired,
    onValueChange: PropTypes.func.isRequired
};

export default TimelineDropdown;
