import { defineMessages } from 'react-intl';

export default defineMessages({
    addEmployeeManually: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Add Employees Classes',
        defaultMessage: 'Add Employees\' Classes'
    },
    enterInformationManually: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Enter information manually?',
        defaultMessage: 'Enter information manually?'
    },
    addEmployeeClassesManually: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Add Employees Classes',
        defaultMessage: 'Add Employees\' Classes'
    },
    or: {
        id: 'quoteandbind.wc7.views.employees-and-locations.or',
        defaultMessage: 'or'
    },
    useSpreadSheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Use a spreadsheet?',
        defaultMessage: 'Use a spreadsheet?'
    },
    getStarted: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Get Started',
        defaultMessage: 'Get Started'
    },
    fillForm: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Add employees classes and locations by filling in details in the form.',
        defaultMessage: 'Add employees classes and locations by filling in details in the form.'
    },
    byFillExcel: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Add employees classes and locations by using Excel spreadsheets.',
        defaultMessage: 'Add employees\' classes and locations by using {excel}. {faqlink}'
    },
    excelSpreadsheets: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Excel spreadsheets',
        defaultMessage: 'Excel spreadsheets'
    },
    needMoreInfo: {
        id: 'quoteandbind.wc7.views.employees-and-locations.Need more information?',
        defaultMessage: 'Need more information?'
    },
    manuallySpreadSheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Use Spreadsheet',
        defaultMessage: 'Use Spreadsheet'
    },
    addEmployeeAndLocation: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor.spreadsheet-processor-result.Add Employee and Location',
        defaultMessage: 'Add Employee and Location'
    }
});
