import React, {
    useEffect, useContext, useCallback, useState, useMemo
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { PolicyChangeDetailsComponent } from 'gw-capability-policyjob-react';
import {
    ConfirmationQuoteDetailsPage,
    CPBOPPaymentPage
} from 'gw-capability-policychange-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyChangeCPQuotePage.metadata.json5';
import styles from './PolicyChangeCPQuotePage.module.scss';
import messages from './PolicyChangeCPQuotePage.messages';
import commonMessages from '../../CPPolicyChange.messages';

const paymentDetails = {
    id: 'paymentDetails',
    path: '/payment-details',
    component: CPBOPPaymentPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: commonMessages.cpPolicyChangePaymentDetails
};

const confirmation = {
    path: '/confirmation',
    component: ConfirmationQuoteDetailsPage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    }
};

function PolicyChangeCPQuotePage(props) {
    const {
        wizardData: submissionVM, updateWizardData, authHeader, changeNextSteps
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const [isPolicyBinding, setIsPolicyBinding] = useState(false);

    const setPolicyData = useMemo(() => {
        return {
            effectiveDate: _.get(submissionVM.value, 'baseData.effectiveDate'),
            policyPeriod: {
                startDate: _.get(submissionVM.value, 'baseData.periodStartDate'),
                endDate: _.get(submissionVM.value, 'baseData.periodEndDate')
            },
            previousPremium: _.get(submissionVM.value, 'previousTotalCost'),
            changeInCost: _.get(submissionVM.value, 'transactionCost'),
            newPremium: _.get(submissionVM.value, 'totalCost'),
            taxes: _.get(submissionVM.value, 'taxes'),
            newPremiumBeforeTaxes: {
                amount:
                    _.get(submissionVM.value, 'totalCost.amount')
                    - _.get(submissionVM.value, 'taxes.amount'),
                currency: _.get(submissionVM.value, 'taxes.currency')
            }
        };
        // doesn't required to return submissionVM
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const changeInCost = _.get(submissionVM.value, 'transactionCost.amount');
        let steps = [];
        if (
            changeInCost > 0
            && _.isEmpty(steps.find((step) => step.path === paymentDetails.path))
        ) {
            steps = [paymentDetails, confirmation];
            changeNextSteps(steps);
        }
        if (_.isEmpty(steps.find((step) => step.path === confirmation.path))) {
            const newSteps = [confirmation];
            changeNextSteps(newSteps);
        }
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNext = useCallback(async () => {
        setIsPolicyBinding(true);
        const changeInCost = _.get(submissionVM.value, 'transactionCost.amount');
        if (changeInCost <= 0) {
            const jobID = _.get(submissionVM.value, 'jobID');
            submissionVM.value = await EndorsementService.bind(
                [jobID, { paymentMethod: 'redistribute' }],
                authHeader
            );
        }
        setIsPolicyBinding(false);
        return submissionVM;
    }, [EndorsementService, authHeader, submissionVM]);
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            policychangedetailscomponent: PolicyChangeDetailsComponent
        }
    };
    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        cpPolicyChangeDetails: {
            policyDetails: setPolicyData,
        },
        cpPolicyChangeContainer: {
            visible: _.get(submissionVM.value, 'transactionCost.amount') > 0
        }
    };

    return (
        <WizardPage
            disableNext={isPolicyBinding}
            onNext={handleNext}
            nextLabel={
                _.get(submissionVM.value, 'transactionCost.amount') > 0
                    ? translator(messages.next)
                    : translator(messages.finish)
            }
        >
            <ViewModelForm
                model={submissionVM}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}
PolicyChangeCPQuotePage.propTypes = wizardProps;
export default withAuthenticationContext(PolicyChangeCPQuotePage);
