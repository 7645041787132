import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import utils from './util/timelineUtils';
import EventCardHeader from './EventCardHeader';
import Styles from './css/TimelineEvent.module.scss';
import EventCardInformation from './EventCardInformation';

class EventCard extends Component {
    state = {
        isOpen: false
    };

    static propTypes = {
        event: PropTypes.shape({ id: PropTypes.string }),
        index: PropTypes.number.isRequired
    };

    static defaultProps = {
        event: undefined
    };

    toggleOpen = () => {
        const { isOpen: previousIsOpen } = this.state;
        this.setState({
            isOpen: !previousIsOpen
        });
    };

    render() {
        const { event, index } = this.props;
        const { isOpen } = this.state;
        const isOdd = utils.isOdd(index);

        const classContainer = classNames(
            Styles.gwTimelineContainer,
            Styles[`gwTimelineContainerEventColor--${utils.getProductCodeModifier(event)}`],
            {
                [Styles.gwTimelineContainerLeft]: isOdd,
                [Styles.gwTimelineContainerRight]: !isOdd
            }
        );

        return (
            <div id={event.id} className={classContainer}>
                <EventCardHeader
                    onClick={this.toggleOpen}
                    event={event}
                    isOpen={isOpen}
                    index={index}
                    isOdd={isOdd}
                />
                <EventCardInformation isOpen={isOpen} event={event} />
            </div>
        );
    }
}
export default EventCard;
