import { defineMessages } from 'react-intl';

export default defineMessages({
    weAreUnableToProcess: {
        id: 'endorsement.common.views.contact-underwriter.We are unable to process this policy change online',
        defaultMessage: 'We are unable to process this policy change online.',
    },
    pleaseCallOneOurUnderwriters: {
        id: 'endorsement.common.views.contact-underwriter.Please call one of our underwriters for assistance in submitting your policy change',
        defaultMessage: 'Please call one of our underwriters for assistance in submitting your policy change.',
    },
    contactUnderwriterText: {
        id: 'endorsement.common.views.contact-underwriter.Contact Underwriter',
        defaultMessage: 'Contact Underwriter',
    }
});
