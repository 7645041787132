import _ from 'lodash';

export default function formatDocumentTableData(intl, documentsData, keyword) {
    const filterDocuments = (documents) => {
        if (_.isEmpty(keyword)) {
            return documents;
        }
        return _.filter(documents, (document) => _.includes(
            document.name.toLocaleLowerCase(),
            keyword.toLocaleLowerCase()
        ));
    };
    if (!_.isEmpty(documentsData)) {
        const documents = documentsData.map((documentsInfo) => {
            const documentDateModified = intl.formatDate(new Date(documentsInfo.dateModified), { year: 'numeric', month: 'short', day: 'numeric' });
            return {
                name: documentsInfo.name,
                dateModified: documentDateModified,
                remove: '',
                sessionID: documentsInfo.sessionID,
                publicID: documentsInfo.publicID,
                author: documentsInfo.author,
                canDelete: documentsInfo.canDelete
            };
        });
        return filterDocuments(documents);
    }
    return documentsData;
}
