import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { Link } from '@jutro/components';
import TimelineDropdown from './TimelineDropdown/TimelineDropdown';
import styles from './css/TimelineFilterPolicy.module.scss';
import messages from './timeline.messages';
import PolicyFilterUtil from './util/PolicyFilter';

const TimelineFilterPolicy = (props) => {
    const {
        onValueChange,
        filterData: { options, yearList }
    } = props;
    const translator = useTranslator();
    const [stick, setStick] = useState(false);
    const [years, setYears] = useState([]);
    const [initialOffset, setOffset] = useState(0);

    const scrollToIndex = useCallback((e, index, id) => {
        e.preventDefault();
        PolicyFilterUtil.scrollToYear(index, id);
    }, []);
    const handleScroll = useCallback(() => {
        const element = document.getElementById('filterPolicy');
        if (element) {
            const elOffset = element.offsetTop;
            const { scrollY } = window;
            const isScroll = scrollY >= initialOffset && scrollY >= elOffset;
            setStick(isScroll);
        }
    }, [initialOffset]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        setOffset(document.getElementById('filterPolicy').offsetTop);
        setYears(yearList);
    }, [options, handleScroll, yearList]);

    const stayOnPageClass = cx(styles.gwStayOnPageBar, {
        [styles.StayOnPageBarHashedStick]: stick
    });

    return (
        <div id="filterPolicy" className={stayOnPageClass}>
            <div className={styles.gwTimelineFilters}>
                <div className={styles.gwTimelineFiltersJumpto}>
                    <span>{translator(messages.timelineFiltersJumpto)}</span>
                    <ul className={styles.gwTimelineFiltersYears}>
                        {years && years.map((yearData, index) => (
                            <li className={styles.gwTimelineFilterYearsList}>
                                <Link onClick={(e) => scrollToIndex(e, index, yearData.id)} to="/" className="jumpToNewest">
                                    {(index === 0)
                                        ? translator(messages.timelineFiltersNewest)
                                        : yearData.year}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div id="policyFilterContainer" className={styles.gwTimelineFiltersDropdown}>
                    <TimelineDropdown options={options} onValueChange={onValueChange} />
                </div>
            </div>
        </div>
    );
};

TimelineFilterPolicy.propTypes = {
    filterData: PropTypes.shape({
        yearList: PropTypes.shape([]).isRequired,
        options: PropTypes.shape([]).isRequired
    }).isRequired,
    onValueChange: PropTypes.func.isRequired
};
export default TimelineFilterPolicy;
