import { setComponentMapOverrides } from '@jutro/uiconfig';

import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';

import PolicyDetailsPage from './pages/PolicyDetails/PolicyDetailsPage';
import QualificationPage from './pages/Qualification/QualificationPage';
import EmployeesAndLocationPage from './pages/EmployeesAndLocation/EmployeesAndLocationPage';
import ExpModAndStateCoveragesPage from './pages/ExpModAndStateCoverages/ExpModAndStateCoveragesPage';
import CoveragesAndExclusionsPage from './pages/CoveragesAndExclusions/CoveragesAndExclusionsPage';
import SupplementalQuestionsPage from './pages/SupplementalQuestions/SupplementalQuestionsPage';
import QuotePage from './pages/Quote/QuotePage';
import AdditionalInformationPage from './pages/AdditionalInformation/AdditionalInformationPage';
import FinalQuotePage from './pages/FinalQuote/FinalQuotePage';

import SuccessPage from './pages/Success/SuccessPage';

setComponentMapOverrides(
    {
        PolicyDetailsPage: { component: 'PolicyDetailsPage' },
        QualificationPage: { component: 'QualificationPage' },
        EmployeesAndLocationPage: { component: 'EmployeesAndLocationPage' },
        ExpModAndStateCoveragesPage: { component: 'ExpModAndStateCoveragesPage' },
        CoveragesAndExclusions: { component: 'CoveragesAndExclusionsPage' },
        SupplementalQuestionsPage: { component: 'SupplementalQuestionsPage' },
        WC7QuotePage: { component: 'WC7QuotePage' },
        AdditionalInformationPage: { component: 'AdditionalInformationPage' },
        FinalQuote: { component: 'FinalQuotePage' },
        WC7PaymentDetailsPage: { component: 'WC7PaymentDetailsPage' },
        SuccessPage: { component: 'SuccessPage' },
    },
    {
        PolicyDetailsPage,
        QualificationPage,
        EmployeesAndLocationPage,
        ExpModAndStateCoveragesPage,
        CoveragesAndExclusionsPage,
        SupplementalQuestionsPage,
        WC7QuotePage: QuotePage,
        AdditionalInformationPage,
        FinalQuotePage,
        WC7PaymentDetailsPage: PaymentDetailsPage,
        SuccessPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEWC7Wizard } from './WC7Wizard';
export { default as CostBoxComponent } from './components/CostBoxComponent/CostBoxComponent';
export { default as AddEmployeeLocationComponent } from './components/AddEmployeesLocation/AddEmployeeLocationComponent';
export { default as RatesAndCostsComponent } from './components/RatesAndCostsComponent/RatesAndCostsComponent';
export { default as CoverageTermsTableComponent } from './components/CoverageTermsTableComponent/CoverageTermsTableComponent';
export { default as ExpModifiersIterableComponent } from './components/ExpModifiersIterableComponent/ExpModifiersIterableComponent';
export { default as ScheduleItemsIterableComponent } from './components/ScheduleItemsIterableComponent/ScheduleItemsIterableComponent';
export { default as StateIdComponent } from './components/StateIdComponent/StateIdComponent';
