import { defineMessages } from 'react-intl';

export default defineMessages({
    company: {
        id: 'gateway.contacts.components.ContactDetailsCompany.Company',
        defaultMessage: 'Company'
    },
    officePhone: {
        id: 'gateway.contacts.components.ContactDetailsCompany.OfficePhone',
        defaultMessage: 'Office Phone'
    },
    fax: {
        id: 'gateway.contacts.components.ContactDetailsCompany.Fax',
        defaultMessage: 'Fax'
    },
    companyPrimaryEmail: {
        id: 'gateway.contacts.components.ContactDetailsCompany.PrimaryEmail',
        defaultMessage: 'Primary Email'
    },
    companyAddress: {
        id: 'gateway.contacts.components.ContactDetailsCompany.Address',
        defaultMessage: 'Address'
    },
    person: {
        id: 'gateway.contacts.components.ContactDetailsPerson.Person',
        defaultMessage: 'Person'
    },
    dateOfBirth: {
        id: 'gateway.contacts.components.ContactDetailsPerson.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    maritaStatus: {
        id: 'gateway.contacts.components.ContactDetailsPerson.Marital Status',
        defaultMessage: 'Marital Status'
    },
    homePhone: {
        id: 'gateway.contacts.components.ContactDetailsPerson.home',
        defaultMessage: 'Home Phone'
    },
    homePhonePrimary: {
        id: 'gateway.contacts.components.ContactDetailsPerson.home primary',
        defaultMessage: 'Home Phone (primary)'
    },
    workPhone: {
        id: 'gateway.contacts.components.ContactDetailsPerson.work',
        defaultMessage: 'Work Phone'
    },
    workPhonePrimary: {
        id: 'gateway.contacts.components.ContactDetailsPerson.work primary',
        defaultMessage: 'Work Phone (primary)'
    },
    mobile: {
        id: 'gateway.contacts.components.ContactDetailsPerson.mobile',
        defaultMessage: 'Mobile'
    },
    mobilePrimary: {
        id: 'gateway.contacts.components.ContactDetailsPerson.mobile primary',
        defaultMessage: 'Mobile (primary)'
    },
    personPrimaryEmail: {
        id: 'gateway.contacts.components.ContactDetailsPerson.Primary Email',
        defaultMessage: 'Primary Email'
    },
    personAddress: {
        id: 'gateway.contacts.components.ContactDetailsPerson.Address',
        defaultMessage: 'Address'
    },
    roles: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Title',
        defaultMessage: 'Roles'
    },
    associatedAccounts: {
        id: 'gateway.contacts.common.components.AccountContactDetail.Associated Accounts',
        defaultMessage: 'Associated Accounts'
    }
});
