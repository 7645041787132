import { defineMessages } from 'react-intl';

export default defineMessages({
    showLess: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Show Less',
        defaultMessage: 'Show Less'
    },
    showAll: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Show All',
        defaultMessage: 'Show All'
    },
    somethingDataWasNotImported: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Something went wrong and your data was not imported.',
        defaultMessage: 'Something went wrong and your data was not imported.'
    },
    yourSpreadsheetWasEmpty: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Your spreadsheet was empty.',
        defaultMessage: 'Your spreadsheet was empty.'
    },
    affectedRow: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Affected Row',
        defaultMessage: 'Affected Row: {count}'
    },
    continueUpdatingtheSpreadsheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Continue Updating the Spreadsheet',
        defaultMessage: 'Continue Updating the Spreadsheet'
    },
    buildingsWereNotImported: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Locations and Employees were not imported.',
        defaultMessage: 'Locations and employees were not imported'
    },
    uploadTheSpreadsheetAgain: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Upload the Spreadsheet Again',
        defaultMessage: 'Upload the Spreadsheet Again'
    },
    buildingsWereNotImportedCount: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.count Locations and Employees were not imported.',
        defaultMessage: '{count} locations and employees were not imported.'
    },
    affectedRowError: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Affected Row:',
        defaultMessage: 'Affected Row: {count}'
    },
    fixIssuesToImportSuccessful: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Fix the issues below to successfully import the locations and employees.',
        defaultMessage: 'Fix the issues below to successfully import the locations and employees.'
    },
    buildingsWereAdded: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Locations and Employees were added.',
        defaultMessage: '{count} locations and employees were added.'
    },
    buildingsWereEdited: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Locations and Employees were edited.',
        defaultMessage: '{count} locations and employees were edited.'
    },
    buildingsWereRemoved: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Locations and Employees were removed.',
        defaultMessage: '{count} locations and employees were removed.'
    },
    successYouCanProceed: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Now you can:',
        defaultMessage: 'Now you can:'
    },
    editIndividualBuildingsListBelow: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Edit individual locations and employees from the list below.',
        defaultMessage: 'Edit individual locations and employees from the list below.'
    },
    singleBuildingsAddBuildingButton: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Add a new single location and employee to your list using the Add Location and Employees button.',
        defaultMessage: 'Add a new single location and employee to your list using the Add Location and Employees button.'
    },
    editBuildingDataOnceUploadSpreadsheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Edit all of your locations and employees data at once in your spreadsheet',
        defaultMessage: 'Edit all of your locations and employees\' data at once in your spreadsheet. {downloadreminder}'
    },
    downloadUploadedSpreadsheetFirst: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.You will need to download the uploaded spreadsheet first.',
        defaultMessage: 'You will need to download the uploaded spreadsheet first.'
    },
    continueUpdatingSpreadsheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Continue Updating the Spreadsheet',
        defaultMessage: 'Continue Updating the Spreadsheet'
    },
    spreadsheetDataSuccessfullyImported: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Your spreadsheet data was successfully imported.',
        defaultMessage: 'Your spreadsheet data was successfully imported.'
    },
    singleLocationEmployeeButton: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Add a new single location and employee to your list using the Add Location and Employees button.',
        defaultMessage: 'Add a new single location and employee to your list using the Add Location and Employees button.'
    },
    editLocationEmployeeDataOnceUploadSpreadsheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Edit all of your locations and employees data at once in your spreadsheet',
        defaultMessage: 'Edit all of your locations and employees\' data at once in your spreadsheet. {downloadreminder}'
    },
    noDataImported: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.There was no data to be imported.',
        defaultMessage: 'There was no data to be imported.'
    },
    uploadSpreadsheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Upload Spreadsheet',
        defaultMessage: 'Upload Spreadsheet'
    },
    noChangeInSpreadsheet: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Your spreadsheet did not have any changes.',
        defaultMessage: 'Your spreadsheet did not have any changes.'
    },
    updatedSpreadsheetInformation: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Click the button below to upload a spreadsheet containing information about your locations and employees.',
        defaultMessage: 'Click the button below to upload a spreadsheet containing information about your locations and employees.'
    },
    spreadSheetFailEmpty: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Your spreadsheet was empty.',
        defaultMessage: 'Your spreadsheet was empty.'
    },
    spreadSheetUpload: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Upload Spreadsheet',
        defaultMessage: 'Upload Spreadsheet'
    },
    wentWrongPartialUpload: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Something went wrong and your data was only partially imported.',
        defaultMessage: 'Something went wrong and your data was only partially imported.'
    },
    addCorrectDataDownloadTemplate: {
        id: 'quoteandbind.wc7.views.employees-and-locations.spreadsheet-processor-result.Click the button below to download your spreadsheet again and try adding the correct data.',
        defaultMessage: 'Click the button below to download your spreadsheet again and try adding the correct data.'
    }
});
