const templateConfig = {
    eventHeader: 'gw-timeline-event-header',
    timelineDetails: 'gw-timeline-details',
    eventDefinition: 'gw-timeline-definition',
    eventDefinitionDiff: 'gw-timeline-definition-diff',
    eventDefinitionArray: 'gw-timeline-definition-array',
    eventCoverageArray: 'gw-timeline-coverage-array',
    eventCoverageDiff: 'gw-timeline-coverage-diff'
};

export default templateConfig;
