import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'gateway.views.account-detail.All',
        defaultMessage: 'All'
    },
    issued: {
        id: 'gateway.directives.JobSummary.Issued',
        defaultMessage: 'Issued'
    },
    notTaken: {
        id: 'gateway.directives.JobSummary.Not Taken',
        defaultMessage: 'Not Taken'
    },
    errorLoadingDocument: {
        id: 'gateway.views.quote-documents.Error loading documents',
        defaultMessage: 'Error loading documents'
    },
    modalError: {
        id: 'gateway.views.modal.Error',
        defaultMessage: 'Error'
    },
    errorLoadingNotes: {
        id: 'gateway.views.quote-notes.Error loading notes',
        defaultMessage: 'Error loading notes.'
    },
    noClaimsResult: {
        id: 'gateway.directives.ClaimSummaryDirective.ClaimSummary.There are no claims',
        defaultMessage: 'There are no claims'
    },
    gatewayViewsModalErrorData: {
        id: 'gateway.views.modal.Error.Data',
        defaultMessage: 'An error occurred while attempting to retrieve information. Please contact your administrator.'
    },
    withdrawRenewalCtrlTransaction: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Withdraw renewal transaction.',
        defaultMessage: 'Withdraw renewal transaction.'
    },
    startedByMistake: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Was this renewal started by mistake?',
        defaultMessage: 'Was this renewal started by mistake? {link}'
    },
    fileAClaim: {
        id: 'gateway.directives.ClaimSummaryDirective.claim-summary.File a Claim',
        defaultMessage: 'File a Claim'
    },
    noteCreated: {
        id: 'gateway.directives.activity-schedule.Done',
        defaultMessage: 'Note Created',
    },
    noteCreatedSuccessfully: {
        id: 'gateway.directives.activity-schedule.Note created',
        defaultMessage: 'You have successfully created a note.',
    },
    inactive: {
        id: 'gateway.contacts.common.components.Contacts.AccountContacts.Inactive',
        defaultMessage: 'Inactive',
    },
    newQuote: {
        id: 'gateway.views.new-quote.New Quote',
        defaultMessage: 'New Quote'
    }
});
