import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';

import { ServiceManager } from '@jutro/services';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';

import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CostBoxComponent } from '../../components/CostBoxComponent/CostBoxComponent';
import RatesAndCostsComponent from '../../components/RatesAndCostsComponent/RatesAndCostsComponent';
import metadata from './FinalQuotePage.metadata.json5';

function initialiseVM(submissionVM) {
    const vm = submissionVM;
    vm.bindData.value = vm.bindData.value || {};
    vm.bindData.billingAddress.value = vm.bindData.billingAddress.value
    || vm.baseData.policyAddress.value;
    vm.bindData.contactEmail.value = vm.bindData.contactEmail.value
    || vm.baseData.accountHolder.emailAddress1.value;

    return vm;
}

function FinalQuotePage(props) {
    const {
        wizardData: submissionVM, updateWizardData, steps, jumpTo
    } = props;
    const { authHeader } = useAuthentication();
    const [isSameBillingAddress, updateIsSameBillingAddress] = useState(true);
    const localeService = ServiceManager.getService('locale-service');
    const { onValidate, isComponentValid } = useValidation('FinalQuotePage');

    useEffect(() => {
        const nextSubmissionVM = initialiseVM(submissionVM);
        updateWizardData(nextSubmissionVM);
    }, [submissionVM, updateWizardData]);

    const handleNext = useCallback(async () => {
        const updatedQuoteRequest = _.omit(submissionVM.value, 'bindData.paymentDetails');
        submissionVM.value = await LoadSaveService.updateQuotedSubmission(
            updatedQuoteRequest,
            authHeader
        );
        return submissionVM;
    }, [submissionVM, authHeader]);

    const editQuote = useCallback(
        (destinationPage) => {
            const indexOfDestinationPage = _.findIndex(
                steps,
                ({ path }) => path === destinationPage
            );
            jumpTo(indexOfDestinationPage);
        },
        [jumpTo, steps]
    );

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left'
        },
        sameBillingAddress: {
            value: isSameBillingAddress,
            onValueChange: updateIsSameBillingAddress,
            showOptional: false
        },
        billingAddressFields: {
            visible: !isSameBillingAddress
        },
        phone: {
            countryCode: localeService.getDefaultPhoneCountry()
        },
        ratesandcostscomponentContainer: {
            goToEmployeesAndLocations: () => editQuote('/employees-and-locations'),
            showButton: false
        }
    };

    const resolvers = {
        resolveComponentMap: {
            costboxcomponent: CostBoxComponent,
            ratesandcostscomponent: RatesAndCostsComponent
        },
        resolveCallbackMap: {
            updateIsSameBillingAddress
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage onNext={handleNext} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

FinalQuotePage.propTypes = wizardProps;
export default FinalQuotePage;
