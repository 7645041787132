import { defineMessages } from 'react-intl';

export default defineMessages({
    policyPeriodLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Policy Period',
        defaultMessage: 'Policy Period'
    },
    taxesFeesLabel: {
        id: 'gateway.views.policy-detail.Taxes and Fees',
        defaultMessage: 'Taxes and Fees'
    },
    totalCostsLabel: {
        id: 'gateway.views.policy-detail.Total Costs',
        defaultMessage: 'Total Costs'
    },
    statusLabel: {
        id: 'gateway.views.policy-detail.Status',
        defaultMessage: 'Status'
    },
    delinquentLabel: {
        id: 'gateway.views.policy-detail.Delinquent',
        defaultMessage: 'Delinquent'
    },
    inGoodStandingLabel: {
        id: 'gateway.views.policy-detail.In Good Standing',
        defaultMessage: 'In Good Standing'
    },
    viewAccountBillingLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.View Account Billing',
        defaultMessage: 'View Account Billing'
    },
    customerPaymentSummaryLabel: {
        id: 'gateway.views.policy-detail.Customer Payment Summary',
        defaultMessage: 'Customer Payment Summary'
    },
    totalPastDueLabel: {
        id: 'gateway.views.policy-detail.Total Past Due',
        defaultMessage: 'Total Past Due'
    },
    nextPaymentLabel: {
        id: 'gateway.views.policy-detail.Next Payment',
        defaultMessage: 'Next Payment'
    },
    totalDueLabel: {
        id: 'gateway.views.policy-detail.Total Due',
        defaultMessage: 'Total Due'
    },
    billingMethodLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Billing Method',
        defaultMessage: 'Billing Method'
    },
    paymentPlanLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    paymentScheduleLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Schedule',
        defaultMessage: 'Payment Schedule'
    },
    dueDateLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Due Date',
        defaultMessage: 'Due Date'
    },
    paidStatusLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Paid Status',
        defaultMessage: 'Paid Status'
    },
    PaidLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Paid',
        defaultMessage: 'Paid'
    },
    outstandingLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Outstanding',
        defaultMessage: 'Outstanding'
    },
    statementAmountLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Invoice Total',
        defaultMessage: 'Invoice Total'
    },
    loadingLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Loading',
        defaultMessage: 'Loading...'
    },
    noBillingDataLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.No billing data for this policy, check back again in the next',
        defaultMessage: 'No billing data for this policy, check back again in the next 24 hour'
    },
    pastDue: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Past Due',
        defaultMessage: 'Past Due'
    },
    totalDisputedAmountMessage: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Total Disputed Amount Warning Message',
        defaultMessage: 'This policy has {amount} in dispute as of {currentDate} which is not reflected in the invoice totals below.'
    },
    pendingCancellationNotification: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Pending Cancellation Notification',
        defaultMessage: 'Making a payment does not guarantee a reinstatement of a pending or cancelled policy.'

    }
});
