import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'endorsement.common.views.payment.Policy Details',
        defaultMessage: 'Policy Details'
    },
    policyChangeDescription: {
        id: 'gw-portals-policytransaction-pc-cp-angular.policyDetails.policyDetails.Policy Change Description',
        defaultMessage: 'Policy Change Description'
    },
});
