import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { Wc7CoverablesService } from 'gw-capability-quoteandbind-wc7';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyDetailsPage.metadata.json5';

function PolicyDetailsPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const [industryCodeList, updateIndustryCodeList] = useState(null);
    const { authHeader } = useAuthentication();
    const accountType = _.get(submissionVM, 'baseData.accountHolder.subtype.value');

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('PolicyDetailsPage');
    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [submissionVM, authHeader]);

    const getIndustryClassCodeList = useCallback((propertyCodes) => {
        const industryClassCodesArray = propertyCodes
            && propertyCodes.map((industryClassCode) => {
                const industryCodesData = {
                    code: industryClassCode.code,
                    name: industryClassCode.classification
                };
                return industryCodesData;
            });
        return industryClassCodesArray;
    }, []);

    useEffect(
        () => {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const sessionId = _.get(submissionVM, 'sessionUUID.value');
            Wc7CoverablesService.getIndustryCodes(quoteId, sessionId, authHeader).then(
                (response) => {
                    const updatedData = getIndustryClassCodeList(response);
                    updateIndustryCodeList(updatedData);
                }
            );
        },
        // Disabled as useEffect to be called only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        wc7IndustryCode: {
            availableValues: industryCodeList
        },
        wc7SSN: {
            visible: accountType === 'Person',
            showOptional: true
        },
        wc7FEIN: {
            visible: accountType === 'Company',
            showOptional: true
        }
    };
    const onValidationChange = useCallback(() => {
        const industryCode = _.get(
            submissionVM.value,
            'lobData.wc7WorkersComp.coverables.industryCode.code'
        );
        return !_.isNil(industryCode);
    }, [submissionVM]);
    useEffect(() => {
        registerComponentValidation(onValidationChange);
    }, [onValidationChange, registerComponentValidation]);
    return (
        <WizardPage
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

PolicyDetailsPage.propTypes = {
    ...wizardProps
};

export default PolicyDetailsPage;
