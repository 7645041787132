import _ from 'lodash';
import { LobIconUtil } from 'gw-portals-util-js';

export default class {
    constructor(events) {
        this.itemsWithPolicyNumber = _.uniq(events, false, (x) => x.policyNumber)
            .filter((x) => x.policyNumber)
            .map(this.getOption).reverse();
        this.options = this.itemsWithPolicyNumber;
    }

    getOption = (event) => {
        return {
            code: event.policyNumber,
            icon: LobIconUtil.getFontIcon(event.productCode).split('-')[1],
            name: event.policyNumber
        };
    };

    static scrollToYear(index, id) {
        const getOffSet = document.getElementById(id).offsetTop;
        window.scrollTo({
            top: (index === 0) ? 0 : getOffSet,
            left: 100,
            behavior: 'smooth'
        });
    }

    static markYears(events) {
        const lastOfTheYear = [];
        events.forEach((event, index) => {
            const currentYear = new Date(events[index].timestamp).getFullYear();
            if (!_.some(lastOfTheYear, (item) => item.year === currentYear)) {
                const year = {
                    year: currentYear,
                    index: index,
                    id: event.id
                };
                lastOfTheYear.push(year);
            }
        });
        return lastOfTheYear;
    }

    static getYearList(events) {
        this.displayEvents = events
            .map((event, __index) => {
                return Object.assign({}, event, { __index });
            });
        this.lastOfTheYear = this.markYears(this.displayEvents);
        return this.lastOfTheYear;
    }
}
