import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import AccountContactsPage from '../../Contacts/AccountContacts/AccountContactsPage';
import AssociatedContactsPage from '../../Contacts/AssociatedContacts/AssociatedContactsPage';
import metadata from './Contacts.metadata.json5';
import accountsMessages from '../Accounts.messages';
import tabStyles from './Contacts.module.scss';

class ContactsPage extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string,
                accountHolder: PropTypes.shape({
                    displayName: PropTypes.string
                }),
                numberOfContacts: PropTypes.string
            }),
            basePath: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                selectTab: PropTypes.string
            })
        }).isRequired
    };

    state = {
        selectedTab: 'accountContactsTab'
    };

    componentDidMount() {
        const {
            location: { state }
        } = this.props;

        if (state && state.selectTab) {
            this.setState({
                selectedTab: state.selectTab
            });
        }
    }

    updateAccountContactsHeading = () => {
        const { fromAccountLanding } = this.props;
        const {
            accountDetailsData: { numberOfContacts },
            basePath
        } = fromAccountLanding;
        const translator = this.context;

        const contactsCount = basePath.includes('account') ? `(${numberOfContacts})` : '';

        return translator(
            accountsMessages.accountContactsCount,
            { accountContactsCount: contactsCount }
        );
    };

    render() {
        const { fromAccountLanding } = this.props;
        const { selectedTab } = this.state;
        const { accountNumber, accountHolder } = fromAccountLanding.accountDetailsData;

        const overrideProps = {
            accountContacts: {
                accountNumber: accountNumber,
                relatedTo: 'accounts'
            },
            associatedContacts: {
                accountName: accountHolder.displayName,
                accountNumber: accountNumber,
                relatedTo: 'accounts'
            },
            accountContactsTab: {
                heading: {
                    id: this.updateAccountContactsHeading()
                }
            },
            contactsTabSetContainer: {
                defaultActiveTab: selectedTab
            }
        };

        const resolvers = {
            resolveClassNameMap: tabStyles,
            resolveComponentMap: {
                accountcontactspage: AccountContactsPage,
                associatedcontactspage: AssociatedContactsPage
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}
export default ContactsPage;
