import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteTitle: {
        id: 'gateway.views.new-quote-account-search.New Quote: Search for Existing Account',
        defaultMessage: 'New Quote: Search for Existing Account',
    },
    accountType: {
        id: 'gateway.views.new-quote-account-search.ContactType',
        defaultMessage: 'Account Type',
    },
    commercial: {
        id: 'gateway.views.new-quote-account-search.ContactType.Company',
        defaultMessage: 'Commercial',
    },
    personal: {
        id: 'gateway.views.new-quote-account-search.ContactType.Person',
        defaultMessage: 'Personal',
    },
    person: {
        id: 'gateway.views.new-quote.Person',
        defaultMessage: 'Person',
    },
    company: {
        id: 'gateway.views.new-quote.Company',
        defaultMessage: 'Company',
    },
    city: {
        id: 'gateway.views.new-quote.City',
        defaultMessage: 'City',
    },
    zipCode: {
        id: 'gateway.views.new-quote.ZipCode',
        defaultMessage: 'ZIP Code',
    },
    state: {
        id: 'gateway.views.new-quote.State',
        defaultMessage: 'State',
    },
    cancel: {
        id: 'gateway.views.new-quote.Cancel',
        defaultMessage: 'Cancel',
    },
    search: {
        id: 'gateway.views.new-quote-account-search.Search',
        defaultMessage: 'Search',
    },
    firstName: {
        id: 'gateway.views.new-quote.FirstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'gateway.views.new-quote.LastName',
        defaultMessage: 'Last Name',
    },
    possibleAccountMatches: {
        id: 'gateway.views.new-quote-account-search.Possible Account Matches',
        defaultMessage: 'Possible Account Matches',
    },
    searchAgain: {
        id: 'gateway.views.new-quote-account-search.Search Again',
        defaultMessage: 'Search Again',
    },
    continueNewCustomer: {
        id: 'gateway.views.new-quote-account-search.Continue as a New Customer',
        defaultMessage: 'Continue as a New Customer',
    },
    possibleBaseQuote: {
        id: 'gateway.views.new-quote-account-search.The following existing accounts may represent this customer. Please review, and if there is a match, use the existing account as a base for this quote',
        defaultMessage: 'The following existing accounts may represent this customer. Please review, and if there is a match, use the existing account as a base for this quote.'
    },
    detailsAccount: {
        id: 'gateway.views.new-quote-account-search.You do not have access to the details of this account',
        defaultMessage: 'You do not have access to the details of this account.'
    },
    headerUseAccount: {
        id: 'gateway.views.new-quote-account-search.Use this Account',
        defaultMessage: 'Use this Account'
    },
    headerNumber: {
        id: 'gateway.views.new-quote-account-search.Number',
        defaultMessage: 'Number'
    },
    headerName: {
        id: 'gateway.views.new-quote-account-search.Name',
        defaultMessage: 'Name'
    },
    headerAddress: {
        id: 'gateway.views.new-quote-account-search.Address',
        defaultMessage: 'Address'
    },
    startNewQuote: {
        id: 'gateway.views.new-quote-account-search.Start New Quote',
        defaultMessage: 'Start New Quote'
    },
    cancelQuote: {
        id: 'gateway.controllers.NewQuoteCtrl.Cancel Quote',
        defaultMessage: 'Cancel Quote?'
    },
    cancelMessage: {
        id: 'gateway.controllers.NewQuoteCtrl.Are you sure you want to cancel the new quote?',
        defaultMessage: 'Are you sure you want to cancel the new quote?'
    },
    quoteTitleNewQuote: {
        id: 'gateway.views.new-quote.New Quote: New Account Details',
        defaultMessage: 'New Quote: New Account Details',
    },
    email: {
        id: 'gateway.views.new-quote.Email Address',
        defaultMessage: 'Email Address',
    },
    next: {
        id: 'gateway.views.new-quote.Next',
        defaultMessage: 'Next',
    },
    effectiveDate: {
        id: 'gateway.views.new-quote.Effective Date',
        defaultMessage: 'Effective Date',
    },
    productCode: {
        id: 'gateway.views.new-quote.Product Code',
        defaultMessage: 'Product Code',
    },
    existingAccountPolicyDetails: {
        id: 'gateway.views.new-quote.New Quote: Policy Details for Existing Account',
        defaultMessage: 'New Quote: Policy Details for Existing Account',
    },
    account: {
        id: 'gateway.views.new-quote.Account',
        defaultMessage: 'Account',
    },
    accountTypeErrorMessage: {
        id: 'gateway.views.new-quote.error.findAccount.account.type',
        defaultMessage: 'Account type must be a Company or a Person'
    },
    contactTypeCommercial: {
        id: 'gateway.views.new-quote-account-search.ContactType.Company',
        defaultMessage: 'Commercial'
    },
    contactTypePersonal: {
        id: 'gateway.views.new-quote-account-search.ContactType.Person',
        defaultMessage: 'Personal'
    },
    newAccountCreateErrorMessage: {
        id: 'gateway.views.new-quote.error.create.account',
        defaultMessage: 'Could not create the Account'
    },
    quoteQrganisation: {
        id: 'gateway.views.new-quote.Organization',
        defaultMessage: 'Organization'
    },
    noResults: {
        id: 'gateway.views.new-quote.No results found!',
        defaultMessage: 'No account has been found based on your criteria.'
    },
    noLengthMatch: {
        id: 'gateway.views.new-quote.PersonNo results found! At least five letters are required',
        defaultMessage: 'No results found! At least five letters are required for person first and last name without exact matches'
    },
    noResultsFound: {
        id: 'gateway.views.new-quote.No results found! At least five letters are required',
        defaultMessage: 'No results found. At least five letters are required for the first and last name of a person without exact matches.'
    }
});
