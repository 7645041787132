import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class SubmissionService {
    static createSubmission(newSubmission, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'createNewSubmission',
            [newSubmission],
            additionalHeaders
        );
    }

    static createNoteForSubmission(params, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'createNoteForSubmission',
            params,
            additionalHeaders
        );
    }

    static getSubmissionSummary(params, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'getSubmissionSummary',
            [params],
            additionalHeaders
        );
    }

    static getNotesForSubmission(params, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'getNotesForSubmission',
            params,
            additionalHeaders
        );
    }

    static getDocumentsForSubmission(params, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'getDocumentsForSubmission',
            [params],
            additionalHeaders
        );
    }

    static referToUnderwriter(jobNumber, noteBody, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'referToUnderwriter',
            [jobNumber, noteBody],
            additionalHeaders
        );
    }
}
