import React, { useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';

import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyChangeCPPolicyDetailsPage.metadata.json5';
import './PolicyChangeCPPolicyDetailsPage.messages';

function PolicyChangeCPPolicyDetailsPage(props) {
    const { initialValidation, onValidate } = useValidation('PolicyChangeCPPolicyDetailsPage');
    const { EndorsementService } = useDependencies('EndorsementService');

    const {
        wizardData: submissionVM, updateWizardData, authHeader, wizardSnapshot
    } = props;

    const onNext = useCallback(async () => {
        if (!_.isEqual(submissionVM.value, wizardSnapshot.value)) {
            submissionVM.value = await EndorsementService.saveEndorsement(
                [submissionVM.value],
                authHeader
            );
        }
        return submissionVM;
    }, [EndorsementService, authHeader, submissionVM, wizardSnapshot.value]);
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        cpEffectiveDate: {
            minDate: _.get(submissionVM, 'baseData.minimumEffectiveDate.value', new Date()),
            maxDate: _.get(submissionVM, 'baseData.periodEndDate.value')
        }
    };

    return (
        <WizardPage showPrevious={false} onNext={onNext} skipWhen={initialValidation}>
            <ViewModelForm
                model={submissionVM}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}
PolicyChangeCPPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(PolicyChangeCPPolicyDetailsPage);
