import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { Wc7CoverablesService } from 'gw-capability-quoteandbind-wc7';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { SpreadsheetProcessor } from 'gw-capability-spreadsheet-react';
import { Loader } from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './EmployeesAndLocationPage.metadata.json5';
import styles from './EmployeesAndLocationPage.module.scss';
import messages from './EmployeesAndLocationPage.messages';
import AddEmployeeLocationComponent from '../../components/AddEmployeesLocation/AddEmployeeLocationComponent';

function EmployeesAndLocationPage(props) {
    const translator = useTranslator();
    const { wizardData: submissionVM, updateWizardData } = props;
    const { authHeader } = useAuthentication();
    const [showAddEmployeeClass, setAddEmployeeClassVisible] = useState(false);
    const [showSpreadsheetProcessor, setSpreadsheetProcessorVisible] = useState(false);
    const [showAddEmployeeAndLocation, setManuallyAddEmployeeAndLocation] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [employees, updateEmployees] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { isComponentValid, initialValidation, registerComponentValidation } = useValidation(
        'EmployeesAndLocationPage'
    );

    const getNumberOfCoveredEmployees = useCallback(() => {
        const locations = _.get(submissionVM, 'lobData.wc7WorkersComp.coverables.locationsAndEmployees.value')
            || [];
        const isCoveredEmployeeAvailable = locations.some((location) => {
            return location.coveredEmployees.some((employee) => {
                return employee.publicID;
            });
        });
        if (_.isUndefined(isCoveredEmployeeAvailable)) {
            return false;
        }
        return isCoveredEmployeeAvailable || employees;
    }, [submissionVM, employees]);

    useEffect(() => {
        const quoteID = _.get(submissionVM.value, 'quoteID', '');
        const sessionUUID = _.get(submissionVM.value, 'sessionUUID', '');
        const locationsPath = 'lobData.wc7WorkersComp.coverables.locationsAndEmployees.value';

        Wc7CoverablesService.getAllLocationsAndEmployees(quoteID, sessionUUID, authHeader).then(
            (locationsAndEmployees) => {
                _.set(submissionVM, locationsPath, locationsAndEmployees);
                updateWizardData(submissionVM);
                setIsLoading(false);
            }
        );
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getNumberOfCoveredEmployees()) {
            setAddEmployeeClassVisible(true);
        }
    }, [getNumberOfCoveredEmployees]);

    const validateForm = useCallback(() => {
        return getNumberOfCoveredEmployees() || isEditing;
    }, [getNumberOfCoveredEmployees, isEditing]);
    useEffect(() => {
        registerComponentValidation(validateForm);
    }, [validateForm, registerComponentValidation]);

    const addEmployeeClass = useCallback(() => {
        setAddEmployeeClassVisible(true);
    }, []);

    const addEmployeeAndLocationClicked = useCallback(() => {
        setSpreadsheetProcessorVisible(false);
        setAddEmployeeClassVisible(true);
        setManuallyAddEmployeeAndLocation(false);
    }, []);

    const onManualEntry = useCallback(() => {
        setSpreadsheetProcessorVisible(false);
        setAddEmployeeClassVisible(true);
    }, []);

    const addSpreadSheetClass = useCallback(() => {
        setSpreadsheetProcessorVisible(true);
    }, []);

    const hasEmployees = useCallback(
        (employeeCount) => {
            return updateEmployees(employeeCount);
        },
        [updateEmployees]
    );

    const getFormattedMessage = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.byFillExcel}
                values={{
                    excel: <strong>{translator(messages.excelSpreadsheets)}</strong>,
                    faqlink: (
                        <Link to="/faq" target="_blank" className={styles.wc7NeedMoreInfoSection}>
                            {translator(messages.needMoreInfo)}
                        </Link>
                    )
                }}
            />
        );
    }, [translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            addemployeelocation: AddEmployeeLocationComponent,
            spreadsheetpreprocessor: SpreadsheetProcessor,
            formattedmessage: getFormattedMessage
        },
        resolveCallbackMap: {
            addEmployeeClass: addEmployeeClass,
            addSpreadSheetClass: addSpreadSheetClass,
            useSpreadSheetLinkClicked: addSpreadSheetClass,
            addEmployeeAndLocationClicked: addEmployeeAndLocationClicked
        }
    };

    const onUploadSpreadsheetComplete = useCallback(
        (updatedSubmission, status) => {
            if (status === 'success') {
                const newlyAddedCoveredEmployeesList = _.get(updatedSubmission.value, 'newlyAddedCoveredEmployeesList')
                    || _.get(updatedSubmission.value, 'updatedLocations');
                const coverables = _.get(submissionVM.value, 'lobData.wc7WorkersComp.coverables');
                coverables.locationsAndEmployees.length = 0;
                coverables.locationsAndEmployees = [...newlyAddedCoveredEmployeesList];
                updateWizardData(submissionVM);
            }
            setManuallyAddEmployeeAndLocation(true);
        },
        [submissionVM, updateWizardData]
    );

    const updateCoveredEmployees = useCallback(() => {
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const pageTitle = useMemo(() => {
        const title = showSpreadsheetProcessor
            ? messages.wc7WizardEmployeesAndLocationTitle
            : messages.addEmployeeManually;

        return translator(title);
    }, [showSpreadsheetProcessor, translator]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const overrideProps = {
        addEmployeeLocation: {
            visible: showAddEmployeeClass && !showSpreadsheetProcessor,
            wizardData: submissionVM,
            hasEmployees: hasEmployees,
            updateCoveredEmployees: updateCoveredEmployees,
            setIsEditing: setIsEditing
        },
        spreadsheetpreprocessor: {
            visible: showSpreadsheetProcessor,
            onManualEntry: onManualEntry,
            submissionVM: submissionVM,
            onCallbackResponse: onUploadSpreadsheetComplete
        },
        employeesAndLocationContainer: {
            visible: !showAddEmployeeClass && !showSpreadsheetProcessor
        },
        employeeAndLocationInformationInfobox: {
            visible: !showAddEmployeeClass && !showSpreadsheetProcessor
        },
        addSpreadSheetLink: {
            visible: showAddEmployeeClass && !showSpreadsheetProcessor,
            onManualEntry: onManualEntry,
            submissionVM: submissionVM,
            onCallbackResponse: onUploadSpreadsheetComplete
        },
        employeesAndLocationsTitle: {
            content: pageTitle
        },
        addEmployeeAndLocationButtonContainer: {
            visible: showAddEmployeeAndLocation,
            wizardData: submissionVM,
            updateCoveredEmployees: updateCoveredEmployees
        }
    };

    return (
        <WizardPage skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

EmployeesAndLocationPage.propTypes = wizardProps;
export default EmployeesAndLocationPage;
