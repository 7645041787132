/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
import React, {
    useContext, useCallback, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import {
    withModalContext,
} from '@jutro/components';
import { AccountService } from 'gw-capability-gateway-account';
import { MetadataContent } from '@jutro/uiconfig';
import { checkEmailValidity, isEmpty, checkAgencyCodeValidity } from 'gw-portals-login-react/LoginUtil';
import UserRegisterMessageComponent from '../UserRegisterMessageComponent/UserRegisterMessageComponent';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { useValidation } from 'gw-portals-validation-react';
import { PdfCustomModal } from 'gw-portals-login-react';
import { PDFViewer } from 'gw-document-pdfdisplay-react';
import metadata from './UserSignUpComponent.metadata.json5';
import messages from './UserSignUpComponent.messages';
import styles from './UserSignUpComponent.module.scss';

const { siteKeyMap, useRecaptcha, applicationDetails } = appConfig;

const PAGEVIEW = {
    registerFormView: 'REGISTER_FORM_VIEW',
    successView: 'REGISTER_SUCCESS',
    failFeinView: 'REGISTER_FEIN_FAIL',
    failUserIdView: 'REGISTER_UserID_FAIL'
};

const ERRORVIEW = {
    feinnotExist: 'REGISTER_FORM_VIEW',
    successView: 'REGISTER_SUCCESS',
    failFeinView: 'REGISTER_FEIN_FAIL',
    failUserIdView: 'REGISTER_UserID_FAIL'
};

function UserSignUpComponent(props) {
    const translator = useContext(TranslatorContext);
    const [pageError, updatePageError] = useState();
    const [formData, updateFormData] = useState({});
    const { authHeader } = useAuthentication();
    const { isComponentValid, registerComponentValidation } = useValidation('UserSignUpComponent');
    const [captchaChecked, updateCaptchaChecked] = useState(false);
    const [pageView, updatePageView] = useState(PAGEVIEW.registerFormView);
    const [descriptionMessage, updateDescriptionMessage] = useState('');
    const [isLoading, setLoadingState] = useState(false);
    const [result, updateResult] = useState(null);
    const [agreeTerm, updateAgreeTerm] = useState(false);


    const handleSuccessClick = useCallback((data) => {
        if (data.status === 'Success') {
            updatePageView(PAGEVIEW.successView);
        } else {
            const { description } = data;
            updateDescriptionMessage(description);
            updatePageView(PAGEVIEW.failView);
        }
    }, []);

    const handleRegisterClick = useCallback(() => {
        updatePageView(PAGEVIEW.registerFormView);
    }, []);

    const handleAcceptWork = useCallback((actionData) => {
        updateResult(actionData);
    }, []);

    const triggerModal = useCallback(() => {
        const { modalContext } = props;
        modalContext.showModal(
            <PdfCustomModal message="" confirmButtonText="Close" cancelButtonText="Disagree">
                <PDFViewer />
            </PdfCustomModal>
        ).then(
            handleAcceptWork
        ).catch(_.noop);
    }, [handleAcceptWork]);

    const onValueChangeFuc = (value) => {
        updateAgreeTerm(value);
    };

    const disableSubmit = useCallback(() => {
        const email = _.get(formData, 'emailAddress');
        const firstName = _.get(formData, 'firstName');
        const lastName = _.get(formData, 'lastName');
        const agencyCode = _.get(formData, 'agencyCode');
        const telephone = _.get(formData, 'telephone');

        return !isEmpty(email) && !isEmpty(firstName) && !isEmpty(lastName)
        && !isEmpty(agencyCode) && !isEmpty(telephone) && agreeTerm && captchaChecked;
    }, [formData, agreeTerm, captchaChecked]);

    const handleUserReg = useCallback((evt) => {
        if (evt) {
            evt.preventDefault();
        }
        const email = _.get(formData, 'emailAddress');
        const firstname = _.get(formData, 'firstName');
        const lastname = _.get(formData, 'lastName');
        const agencyCode = _.get(formData, 'agencyCode');
        const userType = 'broker';
        const telephone = _.get(formData, 'telephone');
        const userid = _.get(formData, 'username');
        const reg = /^[0-9]{9}/;
        const source = 'PE';

        if (!checkEmailValidity(email)) {
            updatePageError('userInvalidEmail');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(firstname)) {
            updatePageError('userFirstNameIsEmpty');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(lastname)) {
            updatePageError('userLastNameIsEmpty');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (!checkAgencyCodeValidity(agencyCode)) {
            updatePageError('agencyCodeError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(telephone)) {
            updatePageError('telephone');
            setLoadingState(false);
            return Promise.resolve();
        }

        if (useRecaptcha && isEmpty(captchaChecked) && !captchaChecked) {
            updatePageError('captchaCheckedError');
            return Promise.resolve();
        }
        setLoadingState(true);

        AccountService.createNewUser(
            {
                userid: userid || '',
                firstName: firstname,
                lastName: lastname,
                email: email,
                agencyCode: agencyCode,
                telephone: telephone,
                userType: userType,
                source: source
            },
        ).then((data) => {
            updateCaptchaChecked(false);
            handleSuccessClick(data);
            updateFormData({});
            updateAgreeTerm(false);
            Promise.resolve();
        }).catch(() => {
            setLoadingState(false);
        }).finally(() => {
            setLoadingState(false);
        });
    }, [formData, null, AccountService, handleSuccessClick, agreeTerm, captchaChecked]);
    
    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const captchaCallbackfunction = useCallback(
        (response) => {
        }
    );

    const verifyCaptchaCallbackfunction = useCallback((response) => {
        if (!isEmpty(response)) {
            updateCaptchaChecked(() => true);
        }
    }, []);

    const captchaExpiredCallbackfunction = useCallback(
        (response) => {
            if (!response) {
                updateCaptchaChecked(false);
            }
        }
    );
    const backToRegistration = useCallback(
        () => {
            updatePageError(undefined);
            updatePageView(PAGEVIEW.registerFormView);
        }
    );

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const overrideProps = useMemo(
        () => ({
            '@field': {
                onValueChange: writeValue
            },
            errorMsg: {
                visible: !_.isUndefined(pageError),
                message: translator(messages[pageError])
            },
            signUpButton: {
                onTrigger: handleUserReg,
                visible: !isLoading,
                disabled: !disableSubmit()
            },
            successfulContainer: {
                visible: pageView === PAGEVIEW.successView
            },
            successfulContainerReview: {
                visible: pageView === PAGEVIEW.successView
            },
            successfulContainerQuestion: {
                visible: pageView === PAGEVIEW.successView
            },
            userSignUpContainer: {
                visible: pageView === PAGEVIEW.registerFormView
            },
            failedContainer: {
                description: descriptionMessage,
                visible: pageView === PAGEVIEW.failView
            },
            failedUserIdContainer: {
                visible: pageView === PAGEVIEW.failUserIdView
            },
            inlineLoaderCom: {
                visible: isLoading,
                loading: true
            },
            CheckboxField: {
                value: agreeTerm
            },
            termCheck: {
                value: agreeTerm
            },
            failedContainerMessage: {
                content: translator(descriptionMessage),
                visible: pageView === PAGEVIEW.failView
            },
            captchaele: {
                sitekey: (_.find(siteKeyMap, (o) => {
                    const found = _.startsWith(window.location, o.key);
                    if (found) {
                        return o;
                    }
                }).value
                ),
                onloadCallback: captchaCallbackfunction,
                verifyCallback: verifyCaptchaCallbackfunction,
                expiredCallback: captchaExpiredCallbackfunction
            }
        }),
        [writeValue, pageError, translator, handleUserReg, isLoading,
            disableSubmit, pageView, descriptionMessage, agreeTerm,
            captchaCallbackfunction, verifyCaptchaCallbackfunction, captchaExpiredCallbackfunction]
    );

    const resolvers = useMemo(
        () => ({
            resolveValue: readValue,
            resolveCallbackMap: {
                onCallback: handleRegisterClick,
                onValueChangeFuc: onValueChangeFuc,
                handleCaptchaFun: captchaCallbackfunction,
                onTriggerFunction: triggerModal,
                backToRegistration: backToRegistration,
            },
            resolveClassNameMap: styles
        }),
        [handleRegisterClick, readValue]
    );

    const renderedContentFromMetadata = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers}
    />;

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
        />
    );
}

UserSignUpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    //successPath: PropTypes.string.isRequired,
    onBackToSignIn: PropTypes.func,
    onBackToLanding: PropTypes.func,
    captchaCallbackfunction: PropTypes.func
};

UserSignUpComponent.defaultProps = {
    onBackToSignIn: undefined,
    onBackToLanding: undefined,
    captchaCallbackfunction: undefined
};

export default withRouter(withModalContext(UserSignUpComponent));
