import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@jutro/components';
import HeaderStyles from './css/TimelineEventHeader.module.scss';
import getChannelIcon from './util/timelineChannelIcon';

const IconChannel = (props) => {
    const {
        channelName, isOdd, productCode, isOpen
    } = props;
    const ChannelIcon = getChannelIcon(channelName);

    const EventIcon = classNames(HeaderStyles.gwTimelineEventIcon, {
        [HeaderStyles.gwTimelineEventIcon_odd]: isOdd,
        [HeaderStyles.gwTimelineEventIcon_oddOpen]: isOpen
    });
    const EventInnerIcon = classNames(
        HeaderStyles.gwTimelineEventIcon_inner,
        HeaderStyles[`gwTimelineEventIcon_inner--${productCode}`]
    );

    return (
        <div className={EventIcon}>
            <div className={EventInnerIcon}>{channelName && <Icon icon={ChannelIcon} />}</div>
        </div>
    );
};

IconChannel.propTypes = {
    channelName: PropTypes.string.isRequired,
    isOdd: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    productCode: PropTypes.string.isRequired
};

export default IconChannel;
