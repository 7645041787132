import { defineMessages } from 'react-intl';

export default defineMessages({
    makePaymentLabel: {
        id: 'billing.view.invoice-payment.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    noPaymentsDueLabel: {
        id: 'billing.view.invoice-payment.No payments are currently due',
        defaultMessage: 'No payments are currently due.'
    },
    selectInvoicePayLabel: {
        id: 'billing.view.invoice-payment.Please select an invoice to pay.',
        defaultMessage: 'Please select an invoice to pay.'
    },
    invoiceSummaryLabel: {
        id: 'billing.view.invoice-payment.Invoice(s) Summary',
        defaultMessage: 'Invoice(s) Summary'
    },
    totalPaymentSelectedLabel: {
        id: 'billing.view.invoice-payment.Total Payment Selected',
        defaultMessage: 'Total Payment Selected'
    },
    enterPaymentAmountLabel: {
        id: 'billing.view.invoice-payment.Enter Your Payment Amount',
        defaultMessage: 'Enter Your Payment Amount.'
    },
    cancelLabel: {
        id: 'billing.view.invoice-payment.Cancel',
        defaultMessage: 'Cancel'
    },
    paymentMethodLabel: {
        id: 'billing.view.invoice-payment.Payment Method',
        defaultMessage: 'Payment Method'
    },
    changePaymentAmountLabel: {
        id: 'billing.view.invoice-payment.Change Payment Amount',
        defaultMessage: 'Change Payment Amount'
    },
    payNowLabel: {
        id: 'billing.view.invoice-payment.Pay Now',
        defaultMessage: 'Pay Now'
    },
    invoiceLabel: {
        id: 'billing.directives.templates.invoice-select.Invoice #',
        defaultMessage: 'Invoice #:'
    },
    dueDateLabel: {
        id: 'billing.directives.templates.invoice-select.Due Date',
        defaultMessage: 'Due Date:'
    },
    invoiceDateLabel: {
        id: 'billing.directives.templates.invoice-select.Invoice Date',
        defaultMessage: 'Invoice Date:'
    },
    totalInvoiceAmountLabel: {
        id: 'billing.directives.templates.invoice-select.Total Invoice Amount',
        defaultMessage: 'Total Invoice Amount:'
    },
    hideInvoiceDetailsLabel: {
        id: 'billing.directives.templates.invoice-select.Hide Invoice Details',
        defaultMessage: 'Hide Invoice Details'
    },
    viewInvoiceDetailsLabel: {
        id: 'billing.directives.templates.invoice-select.View Invoice Details',
        defaultMessage: 'View Invoice Details'
    },
    lineLabel: {
        id: 'billing.directives.templates.invoice-select.Line',
        defaultMessage: 'Line'
    },
    policyLabel: {
        id: 'billing.directives.templates.invoice-select.Policy',
        defaultMessage: 'Policy'
    },
    chargeTypeLabel: {
        id: 'billing.directives.templates.invoice-select.Charge Type',
        defaultMessage: 'Charge Type'
    },
    itemTypeLabel: {
        id: 'billing.directives.templates.invoice-select.Item Type',
        defaultMessage: 'Item Type'
    },
    amountLabel: {
        id: 'billing.directives.templates.invoice-select.Amount',
        defaultMessage: 'Amount'
    },
    overpayNotSupported: {
        id: 'billing.controllers.InvoicePaymentCtrl.Overpay is not supported',
        defaultMessage: 'Overpay is not supported'
    },
    sorryYourPaymentCould: {
        id: 'billing.controllers.InvoicePaymentCtrl.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    paymentRequestFailed: {
        id: 'billing.controllers.InvoicePaymentCtrl.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
    paymentNumbersOnly: {
        id: 'billing.controllers.InvoicePaymentCtrl.Please enter numbers only',
        defaultMessage: 'Please enter numbers only.'
    },
    paymentPositiveNumber: {
        id: 'billing.controllers.InvoicePaymentCtrl.Payment amount must be positive',
        defaultMessage: 'Payment amount must be positive.'
    },
    policyInvoiceLabel: {
        id: 'billing.directives.templates.invoice-select.Policy#',
        defaultMessage: 'Policy #:'
    },
    billedDateLabel: {
        id: 'billing.directives.templates.invoice-select.Billed Date',
        defaultMessage: 'Billed Date:'
    },
    invoiceTotalLabel: {
        id: 'billing.directives.templates.invoice-select.Invoice Total',
        defaultMessage: 'Invoice Total:'
    },
    paidTotalLabel: {
        id: 'billing.directives.templates.invoice-select.Paid',
        defaultMessage: 'Paid:'
    },
    outStandingAmountLabel: {
        id: 'billing.directives.templates.Out Standing Amount',
        defaultMessage: 'OutStanding:'
    },
    backLabel: {
        id: 'billing.directives.templates.invoice-payment.Back',
        defaultMessage: 'Back'
    },
    amountToPayErrorMessage: {
        id: 'billing.directives.templates.amountToPayErrorMessage',
        defaultMessage: 'You must pay {amount}'
    },
    leastAmountToPayErrorMessage: {
        id: 'billing.directives.templates.leastAmountToPayErrorMessage',
        defaultMessage: 'You must pay at least {amount}'
    },
    notValidPolicySelectionMessage: {
        id: 'billing.directives.templates.notValidPolicySelectionMessage',
        defaultMessage: 'Payment cannot process for multiple policies in single transaction'
    },
    multiplePolicyCheckMessage: {
        id: 'billing.directives.templates.multiplePolicyCheckMessage',
        defaultMessage: 'Make payment error'
    },
    convertedPolicyWarningMessage: {
        id: 'billing.billing-summary.verbiage Warning Message',
        defaultMessage: 'IMPORTANT: SWIF recently implemented a new computer system. All invoices prior to this policy term will not be reflected on this portal. Prior terms will be invoiced via paper and delivered by USPS. If you received a mailed invoice with a balance due or a credit balance on a previous term, that amount is not included on the portal. If you need to check your current balance, please contact our Customer Service department at 570-963-4635.'
    },
    renewalPolicyWarningMessage: {
        id: 'billing.billing-summary.renewal Warning Message',
        defaultMessage: 'If you wish to renew a policy and are opting to pay by credit card instead of by check, the payment for renewals is not currently available via the portal.  It can be paid by calling SWIF at 570-941-1947.  Note that the balance due is not reflected on the portal but is itemized on the invoice you received in the mail.'
    }
});
