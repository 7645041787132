import { defineMessages } from 'react-intl';

export default defineMessages({
    finish: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Finish',
        defaultMessage: 'Finish'
    },
    next: {
        id: 'integration.quoteandbind.directives.custom-quote.BOP.Quote.Next',
        defaultMessage: 'Next'
    },
    paymentDetails: {
        id: 'gw-portals-policytransaction-pc-bop-angular.config.FlowConfig.Payment Details',
        defaultMessage: 'Payment Details'
    }
});
