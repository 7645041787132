import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class AccountService {
    static findSubmissionSummariesByAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'findSubmissionSummariesByAccount',
            [accountNumber],
            additionalHeaders
        );
    }
}
