import React, { useCallback } from 'react';
import _ from 'lodash';
import { useValidation } from 'gw-portals-validation-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { QuestionSetComponent } from 'gw-portals-questionsets-ui';
import { useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './SupplementalQuestionsPage.metadata.json5';
import './SupplementalQuestionsPage.messages';

function SupplementalQuestionsPage(props) {
    const { authHeader } = useAuthentication();
    const { wizardData: submissionVM, updateWizardData } = props;
    const { onValidate, initialValidation, isComponentValid } = useValidation(
        'SupplementalQuestionsPage'
    );

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        const saveQuoteRequest = _.omit(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            saveQuoteRequest,
            authHeader
        );
        return submissionVM;
    }, [submissionVM, authHeader]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'left'
        }
    };

    const resolvers = {
        resolveComponentMap: { questionset: QuestionSetComponent },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

SupplementalQuestionsPage.propTypes = wizardProps;
export default SupplementalQuestionsPage;
