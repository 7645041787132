import { defineMessages } from 'react-intl';

export default defineMessages({
    daysAgo: {
        id: 'gateway.activity.date.filter.days ago',
        defaultMessage: '{days} days ago'
    },
    escalationDate: {
        id: 'gateway.directives.activity-summary.Escalation Date',
        defaultMessage:
            'Escalation Date'
    },
    createdBy: {
        id: 'gateway.directives.activity-view-summary.Created By',
        defaultMessage:
            'Created By'
    },
    aType: {
        id: 'gateway.directives.activity-view-summary.Type',
        defaultMessage:
            'Type'
    },
    created: {
        id: 'gateway.directives.activity-view-summary.Created',
        defaultMessage:
            'Created'
    },
    description: {
        id: 'gateway.directives.activity-view-summary.Description',
        defaultMessage:
            'Description'
    },
    completed: {
        id: 'gateway.directives.activity-view-summary.COMPLETED',
        defaultMessage:
            'COMPLETED'
    },
    complete: {
        id: 'gateway.directives.activity-view-summary.Complete',
        defaultMessage:
            'Complete'
    },
    yesterday: {
        id: 'gateway.activity.date.filter.Yesterday',
        defaultMessage:
            'Yesterday'
    },
    tomorrow: {
        id: 'gateway.activity.date.filter.Tomorrow',
        defaultMessage:
            'Tomorrow'
    },
    today: {
        id: 'gateway.activity.date.filter.Today',
        defaultMessage:
            'Today'
    },
    monday: {
        id: 'gateway.activity.date.filter.Monday',
        defaultMessage:
            'Monday'
    },
    tuesday: {
        id: 'gateway.activity.date.filter.Tuesday',
        defaultMessage:
            'Tuesday'
    },
    wednesday: {
        id: 'gateway.activity.date.filter.Wednesday',
        defaultMessage:
            'Wednesday'
    },
    thursday: {
        id: 'gateway.activity.date.filter.Thursday',
        defaultMessage:
            'Thursday'
    },
    friday: {
        id: 'gateway.activity.date.filter.Friday',
        defaultMessage:
            'Friday'
    },
    saturday: {
        id: 'gateway.activity.date.filter.Saturday',
        defaultMessage:
            'Saturday'
    },
    sunday: {
        id: 'gateway.activity.date.filter.Sunday',
        defaultMessage: 'Sunday'
    },
    for: {
        id: 'gateway.activity.date.filter.For',
        defaultMessage: 'for'
    },
});
