import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { useAuthentication } from 'gw-digital-auth-react';

import metadata from './EntryPage.metadata.json5';
import styles from './EntryPage.module.scss';

const { applicationDetails, oobloginURL } = appConfig;

function EntryPage({ location }) {
    const { isLoggedIn } = useAuthentication();
    const { state } = location;

    const overrideProps = {
        gwLoginComponent: {
            successPath: _.get(state, 'from.pathname') || '/home'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    let disableOOTBLogin = false;

    // Disable OOTB Login
    const urls = _.find(oobloginURL, (value) => {
        return _.endsWith(window.location.origin, value.url);
    });

    if (!isLoggedIn && (location.pathname && _.endsWith(location.pathname, '/login-page'))
    && (urls === undefined || urls == null)) {
        disableOOTBLogin = true;
    }

    
    const renderedContentFromMetadata = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers}
    />;

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
            disableOOTBLogin={disableOOTBLogin}
        />
    );
}

EntryPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({})
    })
};

EntryPage.defaultProps = {
    location: {}
};

export default EntryPage;
