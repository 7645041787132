import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext, withIntl, IntlContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import {
    Icon,
    withModalContext,
    Button,
    ModalNext,
    ToastProvider
} from '@jutro/components';
import { LobIconUtil } from 'gw-portals-util-js';
import {
    PaymentComponent,
    Currency as CurrencyField
} from 'gw-components-platform-react';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import {
    GatewayBillingService,
    GatewayPaymentService
} from 'gw-capability-gateway-billing';
import { Link, withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { PdfCustomModal } from 'gw-portals-login-react';
import { PDFViewer } from 'gw-document-pdfdisplay-react';
import metadata from './MakePayment.metadata.json5';
import makePaymentStyles from './MakePayment.module.scss';
import messages from './MakePayment.messages';
import ProcessPaymentPopUp from './ProcessPaymentPopUp';

class MakePayment extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                accountNumber: PropTypes.string,
                xCenter: PropTypes.string.isRequired,
                billingData: PropTypes.shape({}).isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired
    };

    state = {
        invoiceData: [],
        hideShowInvoice: [],
        showTotalPayment: true,
        showEnterPayment: false,
        totalPayment: 0,
        selectedInvoiceIds: [],
        amountVM: {},
        showNoInvoiceMsg: false,
        selectedBilledInvoiceIds: 0,
        showPayeezyIframe: false,
        showPayErrorMsg: false,
        showPayAtLeastErrorMsg: false,
        isTnCSelected: false,
        policySelected: '',
        isConvertRenewal: false,
        hasRenewingTransaction: false
    };

    localeService = ServiceManager.getService('locale-service');

    componentDidMount() {
        const {
            viewModelService,
            authHeader,
            location: {
                state: { accountNumber, billingData: { getAccountDetails: { policySummaries } } }
            }
        } = this.props;

        GatewayBillingService.getAccountBillingSummaryData(
            accountNumber,
            authHeader
        ).then((responseData) => {
            const invoiceData = responseData;

            this.setState({
                invoiceData: invoiceData,
                selectedInvoiceIds: invoiceData.activeInvoices.map((data) => data.id),
                selectedBilledInvoiceIds: 0,
                policySummaries: policySummaries
            });
        });

        const amountVM = viewModelService.create(
            {
                currency: this.localeService.getDefaultCurrencyCode()
            },
            'bc',
            'edge.capabilities.currency.dto.AmountDTO'
        );
        this.setState({ amountVM });
    }

    getPaymentScheduleDataTable = (invoiceData) => {
        const translator = this.context;
        let invoiceArrayResult = [];
        invoiceArrayResult = invoiceData.map((data) => {
            const objData = {
                line: data.lineOfBusiness,
                policy: data.policyId,
                chargeType: data.chargeName,
                itemType: translator({
                    id: `typekey.InvoiceItemType.${data.invoiceItemType}`,
                    defaultMessage: data.invoiceItemType
                }),
                amount: data.unsettledGrossAmount
            };
            return objData;
        });
        return invoiceArrayResult;
    };

    getCell = (items, index, property) => {
        return items[property.id];
    };

    getFormattedCurrency = (item, index, property) => {
        return (
            <CurrencyField
                id={`currency_${index}`}
                value={item[property.id]}
                readOnly
                hideLabel
                className={makePaymentStyles.currencyAmount}
            />
        );
    };

    generateInvoiceDataOverrides = () => {
        const { invoiceData, hideShowInvoice } = this.state;
        const overrides = invoiceData.activeInvoices.map(
            (invoiceMapData, index) => {
                const visibleValue = hideShowInvoice.find(
                    (data) => Number(data.id) === index
                );
                return {
                    [`invoiceSummaryCheck${index}`]: {
                        value: visibleValue ? visibleValue.checkInvoice : false,
                        onValueChange: this.selectInvoice(index)
                        // disabled: invoiceMapData.invoiceStatus === 'planned'
                    },
                    [`hideInvoiceDetailsLabel${index}`]: {
                        visible: visibleValue ? !visibleValue.viewInvoice : false,
                        onClick: this.handleHideShowInvoiceDetails(index)
                    },
                    [`viewInvoiceDetailsLabel${index}`]: {
                        visible: visibleValue ? visibleValue.viewInvoice : true,
                        onClick: this.handleHideShowInvoiceDetails(index)
                    },
                    [`invoiceDataTable${index}`]: {
                        visible: visibleValue ? !visibleValue.viewInvoice : false,
                        data: this.getPaymentScheduleDataTable(invoiceMapData.invoiceItems)
                    }
                };
            }
        );
        return Object.assign({}, ...overrides);
    };

    onValueChangePaymentAmount = (value) => {
        const {
            amountVM,
            dueInvoicesTotal,
            billedInvoicesTotal
        } = this.state;
        _.set(amountVM, 'amount.value', value);
        this.setState({ amountVM });
        if (
            billedInvoicesTotal > 0 &&
      value < dueInvoicesTotal + billedInvoicesTotal
        ) {
            this.setState({
                showPayErrorMsg: false,
                showPayAtLeastErrorMsg: true
            });
        } else if (value < dueInvoicesTotal) {
            this.setState({
                showPayErrorMsg: true,
                showPayAtLeastErrorMsg: false
            });
        } else {
            this.setState({
                showPayErrorMsg: false,
                showPayAtLeastErrorMsg: false
            });
        }
    };

    anyPolicyChecked(hideShowInvoice, index) {
        if (hideShowInvoice.filter((x) => x.id !== index).some((x) => x.checkInvoice)
        || !hideShowInvoice.find((x) => x.id === index)?.checkInvoice) return true;
        return false;
    }

    selectInvoice = (index) => () => {
        const {
            hideShowInvoice,
            invoiceData,
            totalPayment,
            selectedInvoiceIds,
            policySelected,
            policySummaries,
        } = this.state;

        const { modalContext } = this.props;
        let { selectedBilledInvoiceIds, billedInvoicesTotal, dueInvoicesTotal } = this.state;
        this.setState({
            showTotalPayment: true,
            showEnterPayment: false,
            showPayErrorMsg: false,
            showPayAtLeastErrorMsg: false
        });
        const newInvoiceData = hideShowInvoice.find((data) => data.id === index);
        const activeInvoiceData = invoiceData.activeInvoices.find(
            (data, indexNo) => {
                return indexNo === index;
            }
        );
        const clickedPolicy = activeInvoiceData.invoiceItems[0].policyId;
        const { invoiceStatus } = activeInvoiceData;

        /* check exist checked policy matches with selected to restrict multi policy selection */
        if (clickedPolicy === policySelected
            && _.isUndefined(newInvoiceData) || !this.anyPolicyChecked(hideShowInvoice, index)) {
            this.setState({ policySelected: '' });
        } else if (clickedPolicy !== policySelected && !_.isEmpty(policySelected)) {
            const translator = this.context;
            return modalContext
                .showAlert({
                    title: `${translator(messages.multiplePolicyCheckMessage)}`,
                    message: `${translator(messages.notValidPolicySelectionMessage)}`,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: 'Ok'
                })
                .catch(_.noop);
        } else {
            this.setState({ policySelected: clickedPolicy });
        }

        let check = true;
        if (newInvoiceData) {
            newInvoiceData.checkInvoice = !newInvoiceData.checkInvoice;
            check = newInvoiceData.checkInvoice;
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newInvoiceData]
            });
        } else {
            const newData = {
                id: index,
                checkInvoice: check,
                viewInvoice: true
            };
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newData]
            });
        }

        this.setRenewalAndCovertedWarning(policySummaries, clickedPolicy, check);

        const selectedInvoiceAmount = activeInvoiceData.amountDue.amount;

        if (invoiceStatus === 'billed') {
            if (check) {
                selectedBilledInvoiceIds += 1;
                billedInvoicesTotal += selectedInvoiceAmount;
            } else {
                selectedBilledInvoiceIds -= 1;
                billedInvoicesTotal -= selectedInvoiceAmount;
            }
        } else if (invoiceStatus === 'due') {
            if (check) {
                dueInvoicesTotal += selectedInvoiceAmount;
            } else {
                selectedBilledInvoiceIds -= 1;
                dueInvoicesTotal -= selectedInvoiceAmount;
            }
        }
        this.setState({
            billedInvoicesTotal: Math.abs(billedInvoicesTotal),
            dueInvoicesTotal: Math.abs(dueInvoicesTotal),
        });

        if (activeInvoiceData) {
            let totalAmount = 0;
            if (check) {
                totalAmount = totalPayment + selectedInvoiceAmount;
                this.setState({
                    totalPayment: Math.abs(totalAmount),
                    selectedBilledInvoiceIds: selectedBilledInvoiceIds,
                    selectedInvoiceIds: [...selectedInvoiceIds, activeInvoiceData.id]
                });
            } else {
                totalAmount = totalPayment - selectedInvoiceAmount;
                this.setState({
                    totalPayment: Math.abs(totalAmount),
                    selectedBilledInvoiceIds: selectedBilledInvoiceIds,
                    selectedInvoiceIds: selectedInvoiceIds.filter(
                        (item) => item !== activeInvoiceData.id
                    )
                });
            }
            const {
                amountVM,
            } = this.state;
            _.set(amountVM, 'amount.value', totalAmount);
        }
    };

    handleHideShowInvoiceDetails = (index) => () => {
        const { hideShowInvoice } = this.state;
        const newInvoiceData = hideShowInvoice.find((data) => data.id === index);
        if (newInvoiceData) {
            newInvoiceData.viewInvoice = !newInvoiceData.viewInvoice;
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newInvoiceData]
            });
        } else {
            const newData = {
                id: index,
                checkInvoice: true,
                viewInvoice: false
            };
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newData]
            });
        }
    };

    handleCheckInvoice = (invoiceMapData, visibleValue, selectedPolicy) => {
        if (
            invoiceMapData.invoiceStatus === 'billed' &&
      visibleValue?.checkInvoice === undefined
        ) {
            return false;
        }
        if (
            visibleValue === undefined &&
            invoiceMapData.invoiceItems[0].policyId === selectedPolicy && invoiceMapData.invoiceStatus === 'due'
        ) {
            return true;
        }
        return visibleValue ? visibleValue.checkInvoice : false;
    };

    handleChangePaymentAmount = () => {
        this.setState({
            showTotalPayment: false,
            showEnterPayment: true
        });
    };

    handleCancelEnteredPaymentAmount = () => {
        const {
            amountVM,
            totalPaymentAmount
        } = this.state;
        _.set(amountVM, 'amount.value', totalPaymentAmount);
        this.setState({
            showTotalPayment: true,
            showEnterPayment: false,
            showPayAtLeastErrorMsg: false,
            showPayErrorMsg: false,
            amountVM
        });
    };

    getProductImage = (item) => {
        const icon = LobIconUtil.getFontIcon(item.productCode);
        return <Icon icon={icon} title={item.productCode} />;
    };

    getPolicyNoLink = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.policyNumber);
        return (
            <Link
                to={`/policies/${item[property]}/summary`}
                className={makePaymentStyles.removeLinkStyle}
                title={toolTipMessage}
            >
                {item[property]}
            </Link>
        );
    };

    onResultCallback = (paymentData, paymentMethod) => {
        const {
            authHeader, match, history, modalContext
        } = this.props;
        const {
            params: { accountNumber }
        } = match;
        const {
            selectedInvoiceIds,
            showEnterPayment,
            totalPayment,
            amountVM
        } = this.state;
        const enteredPaymentAmount = _.get(amountVM, 'amount.value', 0);

        if (selectedInvoiceIds.length === 0) {
            this.setState({
                showNoInvoiceMsg: true
            });
        } else {
            const paymentInstrument = {};

            if (paymentMethod === 'wire') {
                const bankAccountData = {
                    bankAccountType: _.get(paymentData, 'bankAccountType.value.code'),
                    bankAccountNumber: _.get(paymentData, 'bankAccountNumber.value'),
                    bankABANumber: _.get(paymentData, 'bankABANumber.value'),
                    bankName: _.get(paymentData, 'bankName.value')
                };

                paymentInstrument.paymentMethod = paymentMethod;
                paymentInstrument.bankAccountData = bankAccountData;
                paymentInstrument.creditCardData = null;
            } else {
                const creditBankAccountData = {
                    creditCardIssuer: _.get(paymentData, 'creditCardIssuer.value.code'),
                    creditCardNumber: _.get(paymentData, 'creditCardNumber.value'),
                    creditCardExpDate: _.get(paymentData, 'creditCardExpDate.value')
                };

                paymentInstrument.paymentMethod = 'creditcard';
                paymentInstrument.creditCardData = creditBankAccountData;
                paymentInstrument.bankAccountData = null;
            }

            const request = {
                invoiceIds: selectedInvoiceIds,
                amountPaid: showEnterPayment
                    ? _.floor(_.toNumber(enteredPaymentAmount), 1)
                    : _.floor(totalPayment, 1),
                paymentInstrument: paymentInstrument
            };
            GatewayPaymentService.makeDirectBillPayment(
                accountNumber,
                request,
                authHeader
            )
                .then(() => {
                    history.push(`/make-payment-confirmation/${accountNumber}`);
                })
                .catch(() => {
                    modalContext
                        .showAlert({
                            title: messages.paymentRequestFailed,
                            message: messages.sorryYourPaymentCould,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        })
                        .catch(_.noop);
                });
        }
    };

    onCancelPayment = () => {
        const {
            location: {
                state: { accountNumber, billingData }
            },
            history
        } = this.props;
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({
            pathname: nextPath,
            accountDetailsData: {
                accountNumber: accountNumber
            },
            billingDataFromMakePayment: billingData
        });
    };

    getManualValidationsMessages = (error) => {
        const { dueInvoicesTotal, billedInvoicesTotal, invoiceData } = this.state;
        const translator = this.context;
        const { intl } = this.props;

        const { activeInvoices } = invoiceData;
        let amountCurrency = '';
        if (activeInvoices && activeInvoices[0]) {
            amountCurrency = activeInvoices[0].amountDue.currency;
        }
        if (error === 'payErrorMessage') {
            return translator(messages.amountToPayErrorMessage, {
                amount: intl.formatNumber(dueInvoicesTotal, {
                    style: 'currency',
                    currency: amountCurrency
                })
            });
        }
        if (error === 'leastPayErrorMessage') {
            return translator(messages.leastAmountToPayErrorMessage, {
                amount: intl.formatNumber(dueInvoicesTotal + billedInvoicesTotal, {
                    style: 'currency',
                    currency: amountCurrency
                })
            });
        }
    };

    payNowDisabled = () => {
        const {
            showPayErrorMsg,
            showPayAtLeastErrorMsg,
            isTnCSelected,
            totalPayment
        } = this.state;
        return showPayErrorMsg || showPayAtLeastErrorMsg || !isTnCSelected || totalPayment === 0;
    };

    onClickConfirmMakePayment = async () => {
        const translator = this.context;
        const {
            authHeader,
            modalContext,
            location: {
                state: { accountNumber, billingData }
            },
            history
        } = this.props;
        const {
            showPayeezyIframe, policySelected, totalPayment, showEnterPayment, amountVM
        } = this.state;

        const transactionAmount = showEnterPayment ? _.get(amountVM, 'amount.value', 0) : totalPayment;

        GatewayBillingService.getPaymentInformation(
            policySelected,
            transactionAmount,
            authHeader
        ).then((paymentInformation) => {
            const componentProps = {
                paymentInformation: paymentInformation,
                policyNumber: policySelected,
                authHeader: authHeader,
                accountNumber: accountNumber,
                billingData: billingData,
                history: history
            };
            this.showModal(componentProps)
                .then(async (requestObj) => {
                    this.setState({ showPayeezyIframe: true });
                })
                .catch(_.noop);
        });
    };

    showModal = async (modalData) => {
        const { modalContext } = this.props;
        const result = await modalContext
            .showModal(<ProcessPaymentPopUp {...modalData} />)
            .then((results) => {
                this.setState({ showPayeezyIframe: true });
                return results;
            })
            .catch(_.noop);
        return result;
    };

    handleTnCSelection = () => {
        const { isTnCSelected } = this.state;
        this.setState({ isTnCSelected: !isTnCSelected });
    };

    triggerModal = () => {
        const { modalContext } = this.props;
        modalContext.showModal(
            <PdfCustomModal message="" confirmButtonText="Close" cancelButtonText="Disagree">
                <PDFViewer isPaymentTnC />
            </PdfCustomModal>
        ).catch(_.noop);
    };

    setRenewalAndCovertedWarning = (policySummaries, policySelected, check) => {
        if (!_.isEmpty(policySelected) || !_.isUndefined(policySelected)) {
            policySummaries.filter((value) => {
                if (value.policyNumber === policySelected) {
                    this.setState({
                        hasRenewingTransaction:
                        check ? value.hasRenewingTransaction : false,
                        isConvertRenewal:
                        check ? value.isConvertRenewal : false,
                    });
                }
            });
        }
    };

    render() {
        const {
            invoiceData,
            showTotalPayment,
            showEnterPayment,
            totalPayment,
            amountVM,
            showNoInvoiceMsg,
            showPayErrorMsg,
            showPayAtLeastErrorMsg,
            isTnCSelected,
            hasRenewingTransaction,
            isConvertRenewal,
            policySelected,
            policySummaries
        } = this.state;
        const { showPayeezyIframe } = this.state;
        const enteredPaymentAmount = _.get(amountVM, 'amount.value', 0);
        const {
            location: { state }
        } = this.props;
        if (_.isEmpty(invoiceData)) {
            return null;
        }

        const { activeInvoices } = invoiceData;

        const totalPaymentAmount = {
            amount: 0,
            currency: ''
        };
        let amountCurrency = '';
        if (activeInvoices && activeInvoices[0]) {
            amountCurrency = activeInvoices[0].amountDue.currency;
            totalPaymentAmount.currency = amountCurrency;
            totalPaymentAmount.amount = totalPayment;
        }

        const manualErrorMessage = this.getManualValidationsMessages();
        // const checkAmount = manualErrorMessage !== '';

        const overrides = {
            enterPaymentAmount: {
                value:
          enteredPaymentAmount === 0
              ? _.floor(totalPaymentAmount.amount, 1)
              : _.floor(enteredPaymentAmount, 1),
                onValueChange: this.onValueChangePaymentAmount
            },
            totalPaymentSelected: {
                value: totalPaymentAmount
            },
            totalPaymentSelectedContainer: {
                visible: showTotalPayment
            },
            enterPaymentContainer: {
                visible: showEnterPayment
            },
            makePaymentInvoiceContainer: {
                visible: activeInvoices.length > 0 && !showPayeezyIframe
            },
            makePaymentNoPayableInvoicesMessage: {
                visible: activeInvoices.length === 0
            },
            /*  totalPaymentSelectedContainerMainDiv: {
                visible: true// _.floor(totalPaymentAmount.amount, 2) > 0
            }, */
            paymentComponent: {
                xCenter: state ? state.xCenter : '',
                isDisabled: false
            },
            /* errorNotification: {
                message: 'Hello..............',
                visible: true
            }, */
            noInvoiceMsg: {
                visible: showNoInvoiceMsg
            },
            payeezyPageContainerId: {
                visible: showPayeezyIframe
            },
            payNow: {
                disabled: this.payNowDisabled()
            },
            amountToPayErrorMessageId: {
                visible: showPayErrorMsg && showEnterPayment,
                content: this.getManualValidationsMessages('payErrorMessage')
            },
            leastAmountToPayErrorMessageId: {
                visible: showPayAtLeastErrorMsg && showEnterPayment,
                content: this.getManualValidationsMessages('leastPayErrorMessage')
            },
            billingSummaryPaymentTnCId: {
                value: isTnCSelected
            },
            convertedPolicyWarning: {
                visible: isConvertRenewal
            },
            renewalPolicyWarning: {
                visible: hasRenewingTransaction
            },
            ...this.generateInvoiceDataOverrides()
        };

        function PayeezyIframe(props) {
            return (
                <iframe
                    title="Payeezy page iframe"
                    name="payeezy_page"
                    id="payeezy_page"
                    // src="https://webflow.com/made-in-webflow/website/relume-cloneable"
                    width="100%"
                    height="600px"
                />
            );
        }

        const resolvers = {
            resolveClassNameMap: makePaymentStyles,
            resolveComponentMap: {
                paymentcomponent: PaymentComponent,
                payeezyiframecomponent: PayeezyIframe
            },
            resolveCallbackMap: {
                getCell: this.getCell,
                getFormattedCurrency: this.getFormattedCurrency,
                handleHideShowInvoiceDetails: this.handleHideShowInvoiceDetails,
                handleChangePaymentAmount: this.handleChangePaymentAmount,
                handleCancelEnteredPaymentAmount: this.handleCancelEnteredPaymentAmount,
                selectInvoice: this.selectInvoice,
                getProductImage: this.getProductImage,
                getPolicyNoLink: this.getPolicyNoLink,
                onResultCallback: this.onResultCallback,
                onCancelPayment: this.onCancelPayment,
                handleCheckInvoice: this.handleCheckInvoice,
                onClickConfirmMakePayment: this.onClickConfirmMakePayment,
                handleInvoiceSummarySelection: this.handleInvoiceSummarySelection,
                handleTnCSelection: this.handleTnCSelection,
                payNowDisabled: this.payNowDisabled,
                setRenewalAndCovertedWarning: this.setRenewalAndCovertedWarning,
                onTriggerFunction: this.triggerModal
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                invoiceData,
                id,
                path,
                overrides
            );
        };

        return (
            <div className={makePaymentStyles.makePayment}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={invoiceData}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    resolveValue={readValue}
                />
            </div>
        );
    }
}

export const MakePaymentComponent = withModalContext(MakePayment);
// eslint-disable-next-line max-len
export default withRouter(
    withViewModelService(
        withIntl(withAuthenticationContext(withModalContext(MakePayment)))
    )
);
