import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    Button,
    CheckboxField,
    DropdownSelectField,
    InputNumberField,
    withModalContext
} from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';

import { withAuthenticationContext } from 'gw-digital-auth-react';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import { Wc7CoverablesService } from 'gw-capability-quoteandbind-wc7';
import { messages as commonMessages } from 'gw-platform-translations';

import metadata from './AddEmployeeLocationComponent.metadata.json5';
import messages from './AddEmployeeLocationComponent.messages';
import styles from './AddEmployeeLocationComponent.module.scss';
import '../WC7CommonComponent.messages';

class AddEmployeeLocationComponent extends Component {
    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        wizardData: PropTypes.shape({}).isRequired,
        hasEmployees: PropTypes.func.isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        updateCoveredEmployees: PropTypes.func.isRequired,
        setIsEditing: PropTypes.func.isRequired
    };

    static contextType = TranslatorContext;

    state = {
        initialCoveredEmployee: {
            exposureClearBasisAmount: false
        },
        rowContent: [],
        displayAddMore: false,
        employeeStateAndCodeData: [],
        locationsAndEmployees: [],
        cancelTag: false
    };

    componentDidMount() {
        const { wizardData } = this.props;

        const locationsAndEmployees = _.get(
            wizardData,
            'lobData.wc7WorkersComp.coverables.locationsAndEmployees'
        );
        if (locationsAndEmployees.length === 0) {
            this.createNewLocationAndEmployee();
        } else if (this.shouldPrimaryAddressUpdate(locationsAndEmployees)) {
            const { location } = locationsAndEmployees.value.find(
                (locationAndEmployee) => locationAndEmployee.location.isPrimaryLocation
            );
            location.isTemplateLocation = true;
            this.updateLocation(location);
        } else {
            this.structureLocationsAndEmployees(locationsAndEmployees, true);
            this.setState({ locationsAndEmployees, displayAddMore: true });
        }
    }

    structureLocationsAndEmployees = (locationsAndEmployees, isNotEditable = false) => {
        const { rowContent, cancelTag } = this.state;
        const rowData = locationsAndEmployees.children.flatMap((location, locationIndex) => {
            if (location.coveredEmployees.children.length === 0) {
                const coveredEmployeesVM = this.createNewCoveredEmployee();
                location.coveredEmployees.pushElement(coveredEmployeesVM);
            }
            return location.coveredEmployees.children.map((emp, employeeIndex) => {
                return this.locationAndEmployeeToRow(
                    location,
                    locationIndex,
                    emp,
                    employeeIndex,
                    location.coveredEmployees.length,
                    isNotEditable
                );
            });
        });
        let rowContentData = [];
        const filteredRow = _.xorBy(rowData, rowContent, 'publicID');
        if (cancelTag) {
            rowContentData = filteredRow.filter((filterdata) => {
                return !_.isUndefined(filterdata.state);
            });
        } else {
            rowContentData = filteredRow;
        }

        this.setState(
            {
                rowContent: [...rowContent, ...rowContentData],
                cancelTag: false
            },
            () => {
                const { rowContent: updatedContent } = this.state;
                if (updatedContent.length === 1 && _.isEmpty(updatedContent[0].classCode)) {
                    // Fetch class codes for first added covered employee
                    this.getClassCodesByStateAndGovLaw(0);
                }
            }
        );
    };

    locationAndEmployeeToRow = (
        { location },
        locationIndex,
        employee,
        employeeIndex,
        numberOfEmployees,
        isNotEditable
    ) => {
        let stateName;

        if (location.address.state.value) {
            const state = location.address.state.aspects.availableValues.find(({ code }) => {
                return code === location.address.state.value.code;
            });
            if (state) {
                stateName = state.name;
            }
        }

        const defaultRowDetails = {
            classCode: '',
            basisAmount: undefined,
            numberOfEmployees: undefined,
            isNotEditable: isNotEditable
        };

        return {
            stateName,
            locationPublicID: location.address.publicID.value,
            state: location.address.state.value ? location.address.state.value.code : undefined,
            stateValues: location.address.state.aspects.availableValues,
            isPrimaryLocation: location.isPrimaryLocation.value,
            locationIndex: locationIndex,
            employeeIndex: employeeIndex,
            showRemoveBtn: !location.isPrimaryLocation.value || numberOfEmployees > 1,
            ...defaultRowDetails,
            ...employee.value
        };
    };

    getClassCodeList = () => {
        const { employeeStateAndCodeData } = this.state;

        return (
            employeeStateAndCodeData
            && employeeStateAndCodeData.map((industryClassCode) => {
                return {
                    displayName: `${industryClassCode.code}: ${industryClassCode.classification}`,
                    id: industryClassCode.classification
                };
            })
        );
    };

    getClassCodesByStateAndGovLaw = async (rowIndex) => {
        const { wizardData, authHeader } = this.props;
        const { rowContent } = this.state;
        let selectedState = '';

        selectedState = rowContent[rowIndex].state;

        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');

        Wc7CoverablesService.getClassCodesByStateAndGovLaw(
            quoteID.value,
            rowContent[rowIndex].governingLaw,
            selectedState,
            sessionUUID.value,
            authHeader
        ).then((classCodeListData) => {
            this.setState({
                employeeStateAndCodeData: classCodeListData
            });
        });
    };

    getEmployeesClasses = (item, id, { path }) => {
        const { rowContent, employeeStateAndCodeData } = this.state;
        let classCodeTag = true;
        if (rowContent[id].state) {
            classCodeTag = false;
        }
        let codeValues = {};
        let employeeClassCodeValue = '';
        if (!_.isEmpty(rowContent[id].classCode) && _.isString(item.classCode)) {
            codeValues = employeeStateAndCodeData.find((res) => {
                return res.classification === rowContent[id].classCode;
            });
            employeeClassCodeValue = `${codeValues.code} - ${codeValues.classification}`;
        } else {
            employeeClassCodeValue = `${item.classCode.code} - ${item.classCode.classification}`;
        }
        const disableTag = classCodeTag && rowContent.length !== 1;
        const employeeClassValue = _.isString(item.classCode)
            ? item.classCode
            : item.classCode.classification;

        return rowContent[id].isNotEditable ? (
            <div className={styles.employeeClassesWordWrap}>{employeeClassCodeValue}</div>
        ) : (
            <div>
                <DropdownSelectField
                    id="employeeClassSearch"
                    availableValues={this.getClassCodeList()}
                    onValueChange={(e) => this.writeValue(e, id, path)}
                    value={employeeClassValue}
                    disabled={disableTag}
                    className={styles.employeeClasses}
                />
            </div>
        );
    };

    getState = (data, index, { path }) => {
        const { rowContent } = this.state;
        const translator = this.context;

        const stateEditTag = rowContent.length === 1;
        return stateEditTag
            || rowContent[index].isNotEditable
            || !_.isUndefined(rowContent[index].locationPublicID) ? (
                <div>
                    {translator({
                        id: `typekey.State.${data.state}`,
                        defaultMessage: data.state
                    })}
                </div>
            ) : (
                <div>
                    <DropdownSelectField
                        id="stateSearch"
                        availableValues={data.stateValues.map((item) => {
                            return {
                                id: item.code,
                                displayName: {
                                    id: `typekey.State.${item.code}`,
                                    defaultMessage: item.code
                                }
                            };
                        })}
                        dataType="string"
                        onValueChange={(e) => this.writeValue(e, index, path)}
                        value={data.state}
                        className={styles.employeeState}
                    />
                </div>
            );
    };

    getAnnualWages = (data, id, { path }) => {
        const { rowContent } = this.state;
        let annualWagesTag = false;
        if (rowContent[id].exposureClearBasisAmount && !rowContent[id].basisAmount) {
            annualWagesTag = true;
        }

        return rowContent[id].isNotEditable ? (
            <div>{data.basisAmount}</div>
        ) : (
            <div>
                <InputNumberField
                    id="annualWagesField"
                    type="field"
                    onValueChange={(e) => this.writeValue(e, id, path)}
                    value={data.basisAmount}
                    disabled={annualWagesTag}
                    className={styles.annualWages}
                />
            </div>
        );
    };

    getNumberOfEmployees = (data, index, { path }) => {
        const { rowContent } = this.state;

        return rowContent[index].isNotEditable ? (
            <div>{data.numberOfEmployees}</div>
        ) : (
            <div>
                <InputNumberField
                    id="noOfEmployeesField"
                    type="field"
                    onValueChange={(e) => this.writeValue(e, index, path)}
                    value={data.numberOfEmployees}
                    className={styles.noOfEmployees}
                />
            </div>
        );
    };

    getIfAnyEmployee = (item, id, { path }) => {
        const { rowContent } = this.state;
        const translator = this.context;
        const labelName = translator(messages.ifAny);
        const ifAnyLabel = true;
        return rowContent[id].isNotEditable ? (
            <div>{item.exposureClearBasisAmount && labelName}</div>
        ) : (
            <div>
                <CheckboxField
                    id="ifAnyEmployeeCheckBox"
                    onValueChange={(e) => this.writeValue(e, id, path)}
                    value={item.exposureClearBasisAmount}
                    label={labelName}
                    labelClassName={styles.labelStyle}
                    showInlineLabel={ifAnyLabel}
                    className={styles.anyOptions}
                />
            </div>
        );
    };

    getInitialLocation = () => {
        const localeService = ServiceManager.getService('locale-service');
        return {
            location: {
                isTemplateLocation: true,
                address: {
                    country: localeService.getDefaultCountryCode()
                },
                isNonSpecificLocation: true
            }
        };
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    getInitialCoveredEmployee = () => {
        return {
            exposureClearBasisAmount: false
        };
    };

    updateLocation = async (location) => {
        const { wizardData } = this.props;
        const { authHeader } = this.props;

        const locationsAndEmployees = _.get(
            wizardData,
            'lobData.wc7WorkersComp.coverables.locationsAndEmployees'
        );

        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');

        const locationDTO = await Wc7CoverablesService.updateLocation(
            quoteID.value,
            sessionUUID.value,
            location,
            authHeader
        );
        const primaryAddressIndex = locationsAndEmployees.children.findIndex(
            (locationAndEmployee) => locationAndEmployee.location.isPrimaryLocation
        );
        locationsAndEmployees.children[primaryAddressIndex].location = locationDTO;

        this.structureLocationsAndEmployees(locationsAndEmployees);
        this.setState({ locationsAndEmployees });
    };

    isCoveredEmployeeUpdating = (rowContent, location, index) => {
        const { publicID: employeePublicID, locationPublicID } = rowContent[index];
        return (
            locationPublicID
            && employeePublicID
            && location.address.state === rowContent[index].state
        );
    };

    isLocationAlreadyAdded = (rowContent, location, index) => {
        return (
            location.address.state
            && location.address.state === rowContent[index].state
            && location.isTemplateLocation
        );
    };

    shouldPrimaryAddressUpdate = (locationAndEmployeesVM) => {
        const { coveredEmployees } = locationAndEmployeesVM.value.find(
            ({ location }) => location.isPrimaryLocation
        );
        return locationAndEmployeesVM.length === 1 && coveredEmployees.length === 0;
    };

    writeValue = (value, id, path) => {
        if (!_.isUndefined(value)) {
            const { rowContent } = this.state;
            rowContent[id][path] = value;
            if (path === 'state') {
                this.getClassCodesByStateAndGovLaw(id);
            }
            if (value && path === 'exposureClearBasisAmount') {
                _.set(rowContent[id], 'basisAmount', '');
            }
            this.setState({
                rowContent
            });
        }
    };

    getGoverningLaw = (coveredEmployeesVM) => {
        return coveredEmployeesVM.governingLaw.aspects.availableValues.find((value) => {
            return value.code === 'state';
        });
    };

    createNewCoveredEmployee = () => {
        const { initialCoveredEmployee } = this.state;
        const { viewModelService } = this.props;

        const coveredEmployeesVM = viewModelService.create(
            initialCoveredEmployee,
            'pc',
            'edge.capabilities.policyjob.lob.wc7workerscomp.coverables.dto.Wc7CoveredEmployeesDTO'
        );

        coveredEmployeesVM.governingLaw.value = this.getGoverningLaw(coveredEmployeesVM);
        return coveredEmployeesVM;
    };

    addNewEmployeeLocation = (index) => {
        const { hasEmployees } = this.props;
        const { locationsAndEmployees, rowContent } = this.state;

        const nonPrimaryLocationIndex = locationsAndEmployees.value.findIndex(
            ({ location }) => location.address.state === rowContent[index].state
            && location.isTemplateLocation
        );
        const locationIndex = nonPrimaryLocationIndex !== -1
            ? nonPrimaryLocationIndex
            : rowContent[index].locationIndex;
        const { location, coveredEmployees } = _.get(locationsAndEmployees.value, locationIndex);
        const coveredEmployeesAddLcaotion = rowContent[index];
        if (this.isCoveredEmployeeUpdating(rowContent, location, index)) {
            this.updateCoveredEmployee(rowContent, index, locationIndex);
        } else if (this.isLocationAlreadyAdded(rowContent, location, index)) {
            rowContent[index].locationPublicID = location.publicID;
            this.addCoveredEmployeeToLocation(rowContent, index, locationIndex, coveredEmployees);
            if (locationIndex !== rowContent[index].locationIndex) {
                locationsAndEmployees.value = this.removedLocation(
                    locationsAndEmployees.value,
                    rowContent[index].locationIndex
                );
            }
        } else {
            this.addLocationAndCoveredEmployee(coveredEmployeesAddLcaotion, rowContent, index);
        }
        this.setState({
            locationsAndEmployees
        });
        hasEmployees(locationsAndEmployees.length);
    };

    updateCoveredEmployeeFromRow = (rowData, index, locationIndex) => {
        const { locationsAndEmployees, employeeStateAndCodeData, rowContent } = this.state;
        const { coveredEmployees } = _.get(locationsAndEmployees.value, locationIndex);
        if (_.isString(rowData[index].classCode)) {
            const codeValues = employeeStateAndCodeData.find((res) => {
                return res.classification === rowData[index].classCode;
            });
            rowContent[index].classCode = codeValues;
        }
        let updatedCoveredEmployee = {};
        coveredEmployees.forEach((coverEmployee) => {
            if (coverEmployee.publicID === rowData[index].publicID) {
                updatedCoveredEmployee = {
                    publicID: coverEmployee.publicID,
                    amount: coverEmployee.amount,
                    basisAmount: rowData[index].basisAmount,
                    classCode: rowData[index].classCode,
                    exposureClearBasisAmount: rowData[index].exposureClearBasisAmount,
                    governingLaw: rowData[index].governingLaw,
                    numberOfEmployees: rowData[index].numberOfEmployees,
                    locationPublicID: rowData[index].locationPublicID
                };
            }
            return updatedCoveredEmployee;
        });
        this.setState({
            rowContent
        });
        return updatedCoveredEmployee;
    };

    updateCoveredEmployee = (rowData, index, locationIndex) => {
        const {
            wizardData, authHeader, updateCoveredEmployees, modalContext
        } = this.props;
        const { locationsAndEmployees, rowContent } = this.state;
        const { setIsEditing } = this.props;
        const coveredEmployee = this.updateCoveredEmployeeFromRow(rowData, index, locationIndex);
        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');
        Wc7CoverablesService.updateCoveredEmployee(
            quoteID.value,
            sessionUUID.value,
            coveredEmployee,
            authHeader
        )
            .then((coveredEmployeeDTO) => {
                const location = locationsAndEmployees.value[rowData[index].locationIndex];
                location.coveredEmployees[rowData[index].employeeIndex] = coveredEmployeeDTO;
                rowContent[index].isNotEditable = true;
                updateCoveredEmployees();
                this.setState({
                    displayAddMore: true,
                    rowContent
                });
                setIsEditing(false);
            })
            .catch(() => {
                modalContext.showAlert({
                    title: messages.updateEmployeeClass,
                    message: '',
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            });
    };

    addLocationAndCoveredEmployee = (coveredEmployees, rowData, index) => {
        const {
            wizardData, authHeader, updateCoveredEmployees, setIsEditing, modalContext
        } = this.props;
        const { locationsAndEmployees, rowContent, employeeStateAndCodeData } = this.state;
        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');
        const { employeeIndex } = rowData[index];
        const filteredCoveredEmployee = _.omit(
            _.isArray(coveredEmployees) ? coveredEmployees[employeeIndex] : coveredEmployees,
            ['amount', 'publicID', 'locationPublicID']
        );

        let codeValues = {};
        codeValues = employeeStateAndCodeData.find((res) => {
            return res.classification === filteredCoveredEmployee.classCode;
        });
        rowContent[index].classCode = codeValues;
        const newCoveredEmployee = {
            basisAmount: filteredCoveredEmployee.basisAmount,
            classCode: codeValues,
            exposureClearBasisAmount: filteredCoveredEmployee.exposureClearBasisAmount,
            governingLaw: filteredCoveredEmployee.governingLaw,
            numberOfEmployees: filteredCoveredEmployee.numberOfEmployees
        };
        const newLocation = _.merge({}, this.getInitialLocation(), {
            location: { address: { state: rowData[index].state } }
        });
        newLocation.coveredEmployees = [newCoveredEmployee];

        Wc7CoverablesService.addLocationAndCoveredEmployee(
            quoteID.value,
            sessionUUID.value,
            newLocation,
            authHeader
        )
            .then((locationAndEmployees) => {
                const location = locationsAndEmployees.value;
                location[rowData[index].locationIndex] = locationAndEmployees;
                rowContent[index].isNotEditable = true;
                rowContent[index].locationPublicID = locationAndEmployees.location.publicID;
                rowContent[index].publicID = locationAndEmployees
                    .coveredEmployees[employeeIndex].publicID;
                rowContent[index].employeeIndex = employeeIndex;
                updateCoveredEmployees();
                this.setState({
                    displayAddMore: true,
                    rowContent
                });
                setIsEditing(false);
            })
            .catch(() => {
                modalContext.showAlert({
                    title: messages.addEmployeeAndLocationClass,
                    message: '',
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            });
    };

    addCoveredEmployeeToLocation = (rowData, index, locationIndex, coveredEmployees) => {
        const { wizardData, authHeader, updateCoveredEmployees } = this.props;
        const { employeeStateAndCodeData, locationsAndEmployees, rowContent } = this.state;
        const { setIsEditing } = this.props;

        const employeeClass = coveredEmployees[rowData[index].employeeIndex];
        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');
        let codeValues = {};
        codeValues = employeeStateAndCodeData.find((res) => {
            return res.classification === rowData[index].classCode;
        });
        rowContent[index].classCode = codeValues;
        const newFormData = {
            classCode: codeValues,
            basisAmount: rowData[index].basisAmount,
            exposureClearBasisAmount: rowData[index].exposureClearBasisAmount,
            governingLaw: rowData[index].governingLaw,
            locationPublicID: rowData[index].locationPublicID,
            numberOfEmployees: rowData[index].numberOfEmployees
        };

        Wc7CoverablesService.addCoveredEmployee(
            quoteID.value,
            sessionUUID.value,
            newFormData,
            authHeader
        ).then((coveredEmployeeDTO) => {
            const location = locationsAndEmployees.value[locationIndex];
            rowContent[index].isNotEditable = true;
            rowContent[index].locationIndex = locationIndex;
            rowContent[index].locationPublicID = coveredEmployeeDTO.locationPublicID;
            rowContent[index].publicID = coveredEmployeeDTO.publicID;
            if (employeeClass && employeeClass.publicID) {
                location.coveredEmployees.push(coveredEmployeeDTO);
            } else {
                location.coveredEmployees[rowContent[index].employeeIndex] = coveredEmployeeDTO;
            }
            updateCoveredEmployees();
            this.setState({
                displayAddMore: true,
                rowContent
            });
            setIsEditing(false);
        });
    };

    isValid = (rowContent, index) => {
        return (
            rowContent[index].classCode
            && (rowContent[index].exposureClearBasisAmount || rowContent[index].basisAmount)
        );
    };

    getAddButton = (item, index) => {
        const { rowContent } = this.state;
        const translator = this.context;
        return !rowContent[index].isNotEditable ? (
            <div>
                <Button
                    size="small"
                    type="filled"
                    onClick={() => this.addNewEmployeeLocation(index)}
                    className={styles.addEmployeeButton}
                    disabled={!this.isValid(rowContent, index)}
                >
                    {translator(messages.addButton)}
                </Button>
            </div>
        ) : (
            <Button
                className="actionIcon"
                onClick={() => this.onEditClick(index)}
                icon="mi-edit"
                type="text"
            />
        );
    };

    onEditClick = (index) => {
        const { rowContent } = this.state;
        const { setIsEditing } = this.props;
        rowContent[index].isNotEditable = false;
        this.getClassCodesByStateAndGovLaw(index);
        this.setState({
            rowContent,
            displayAddMore: false
        });
        setIsEditing(true);
    };

    createNewLocationAndEmployee = () => {
        const { locationsAndEmployees } = this.state;

        const locationsAndEmployeesVM = this.createNewLocation();
        const coveredEmployeesVM = this.createNewCoveredEmployee();
        locationsAndEmployeesVM.coveredEmployees.value = [coveredEmployeesVM.value];
        locationsAndEmployees.value.push(locationsAndEmployeesVM.value);
        this.structureLocationsAndEmployees(locationsAndEmployees);

        this.setState({ locationsAndEmployees });
    };

    createNewLocation = () => {
        const { viewModelService } = this.props;
        const initialContext = this.getInitialLocation();

        return viewModelService.create(
            initialContext,
            'pc',
            'edge.capabilities.policyjob.lob.wc7workerscomp.coverables.dto.Wc7LocationsAndEmployeesDTO'
        );
    };

    getCancelButton = (item, index) => {
        const { rowContent } = this.state;
        const translator = this.context;
        let primaryLocationCount = 0;
        if (rowContent[index].isPrimaryLocation) {
            primaryLocationCount = rowContent.reduce((n, rowData) => {
                return (
                    n
                    + (rowData.locationPublicID
                        === rowContent[rowContent[index].locationIndex].locationPublicID)
                );
            }, 0);
        }
        if (primaryLocationCount !== 1) {
            return !rowContent[index].isNotEditable ? (
                <div>
                    <Button
                        size="small"
                        type="outlined"
                        className={styles.addEmployeeButton}
                        onClick={() => this.cancelEdit(index)}
                    >
                        {translator(messages.cancelButton)}
                    </Button>
                </div>
            ) : (
                <Button
                    className="actionIcon"
                    onClick={() => this.removeRow(index)}
                    icon="mi-delete"
                    type="text"
                />
            );
        }
        return null;
    };

    removedLocation = (locations, locationIndex) => {
        return locations.filter((_element, index) => {
            return index !== locationIndex;
        });
    };

    removeRow = (index) => {
        const { modalContext } = this.props;
        modalContext.showConfirm({
            title: messages.removeEmployeeClasses,
            message: messages.removeLocationMessage,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: messages.removeEmployeeClassYes,
            cancelButtonText: messages.removeEmployeeClassNo
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            const { rowContent, locationsAndEmployees } = this.state;
            const { location, coveredEmployees } = locationsAndEmployees.value[
                rowContent[index].locationIndex
            ];
            if (coveredEmployees.length === 1) {
                this.removeLocation(rowContent, location.publicID, index);
            } else {
                this.removeCoveredEmployee(rowContent, index);
            }
            return true;
        }, _.noop);
    };

    removeLocation = (rowData, locationPublicID, index) => {
        const { wizardData, authHeader, modalContext } = this.props;
        const { locationsAndEmployees } = this.state;
        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');
        const isSavedInBackend = rowData[index].locationPublicID;
        if (isSavedInBackend) {
            Wc7CoverablesService.removeLocation(
                quoteID.value,
                sessionUUID.value,
                locationPublicID,
                authHeader
            )
                .then(() => {
                    locationsAndEmployees.value = this.removedLocation(
                        locationsAndEmployees.value,
                        rowData[index].locationIndex
                    );
                    this.setState(
                        {
                            locationsAndEmployees,
                            rowContent: [],
                            cancelTag: true
                        },
                        () => {
                            this.structureLocationsAndEmployees(locationsAndEmployees, true);
                        }
                    );
                })
                .catch(() => {
                    modalContext.showAlert({
                        title: messages.removeEmployeeAndLocationClass,
                        message: '',
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                });
        } else {
            locationsAndEmployees.value = this.removedLocation(
                locationsAndEmployees.value,
                rowData[index].locationIndex
            );
        }
    };

    cancelEdit = (index) => {
        const { rowContent, locationsAndEmployees } = this.state;
        const { setIsEditing } = this.props;
        if (_.isUndefined(rowContent[index].locationPublicID)) {
            rowContent[index].isNotEditable = false;
            this.setState(
                {
                    rowContent: [],
                    displayAddMore: true,
                    cancelTag: true
                },
                () => {
                    this.structureLocationsAndEmployees(locationsAndEmployees, true);
                }
            );
        } else if (
            !rowContent[index].isNotEditable
            && !rowContent[rowContent.length - 1].locationPublicID
        ) {
            rowContent[index].isNotEditable = true;
            this.setState(
                {
                    rowContent,
                    displayAddMore: true
                },
                () => {
                    this.structureLocationsAndEmployees(locationsAndEmployees, true);
                }
            );
        } else if (
            !rowContent[index].isNotEditable
            && rowContent[rowContent.length - 1].locationPublicID
        ) {
            rowContent[index].isNotEditable = true;
            this.setState(
                {
                    rowContent,
                    displayAddMore: true,
                    cancelTag: true
                },
                () => {
                    this.structureLocationsAndEmployees(locationsAndEmployees, true);
                }
            );
        } else {
            rowContent[index].isNotEditable = false;
            this.setState(
                {
                    rowContent,
                    cancelTag: true,
                    displayAddMore: true
                },
                () => {
                    this.structureLocationsAndEmployees(locationsAndEmployees, true);
                }
            );
        }
        setIsEditing(false);
    };

    removeCoveredEmployee = (rowContent, index) => {
        const { wizardData, authHeader, modalContext } = this.props;
        const { locationsAndEmployees } = this.state;
        const quoteID = _.get(wizardData, 'quoteID');
        const sessionUUID = _.get(wizardData, 'sessionUUID');
        const coveredEmployeePublicID = rowContent[index].publicID;

        if (coveredEmployeePublicID) {
            Wc7CoverablesService.removeCoveredEmployee(
                quoteID.value,
                sessionUUID.value,
                coveredEmployeePublicID,
                authHeader
            )
                .then(() => {
                    locationsAndEmployees.value = this.removeEmployee(
                        locationsAndEmployees.value,
                        rowContent,
                        index
                    );
                    this.setState(
                        {
                            locationsAndEmployees,
                            rowContent: [],
                            cancelTag: true
                        },
                        () => {
                            this.structureLocationsAndEmployees(locationsAndEmployees, true);
                        }
                    );
                })
                .catch(() => modalContext.showAlert({
                    title: messages.removeEmployeeClass,
                    message: '',
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop));
        } else {
            locationsAndEmployees.value = this.removeEmployee(
                locationsAndEmployees.value,
                rowContent,
                index
            );
        }
    };

    onAddMoreClick = () => {
        const { setIsEditing } = this.props;
        this.createNewLocationAndEmployee();
        this.setState({
            displayAddMore: false
        });
        setIsEditing(true);
    };

    removeEmployee = (locationsAndEmployees, rowContent, id) => {
        const location = locationsAndEmployees[rowContent[id].locationIndex];
        location.coveredEmployees = location.coveredEmployees.filter(
            (element) => element.publicID !== rowContent[id].publicID
        );
        return locationsAndEmployees;
    };

    render() {
        const { displayAddMore, rowContent } = this.state;
        const overrideProps = {
            '@field': {
                onValueChange: this.writeValue
            },
            wc7AddLocationTable: {
                data: rowContent
            },
            wc7AddMoreButton: {
                visible: displayAddMore
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getIfAnyEmployee: this.getIfAnyEmployee,
                getAnnualWages: this.getAnnualWages,
                removeCartRow: this.deleteDataRow,
                getNumberOfEmployees: this.getNumberOfEmployees,
                getEmployeesClasses: this.getEmployeesClasses,
                getAddButton: this.getAddButton,
                onAddMoreClick: this.onAddMoreClick,
                getState: this.getState,
                editDataRow: this.editDataRow,
                getCancelButton: this.getCancelButton
            }
        };

        return (
            <MetadataContent
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        );
    }
}

// eslint-disable-next-line max-len
export default withViewModelService(withAuthenticationContext(withModalContext(AddEmployeeLocationComponent)));
