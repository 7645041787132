import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentComplete: {
        id: 'amp.bc3.views.make-payment-confirmation.Payment Complete',
        defaultMessage: 'Payment Complete',
    },
    returnToSummary: {
        id: 'amp.bc3.views.make-payment-confirmation.Return to Summary',
        defaultMessage: 'Return to Summary',
    },
    thankYouPaymentSuccessfullyProcessed: {
        id: 'amp.bc3.directives.templates.payment-confirmation-bc3.Thank you for your payment which is now being processed',
        defaultMessage: 'Thank you for your payment which is now being processed. The status of your invoice will be updated once payment has been successfully processed.'
    }
});
