import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import Utils from './util/timelineUtils';
import template from './util/templateConfig';
import EventDefinition from './EventDefinition';
import EventDefinitionDifference from './EventDefinitionDifference';
import EventDefinitionArray from './EventDefinitionArray';
import EventCoverage from './EventCoverage';

const resolveEventValues = (event, key) => {
    if (!key) {
        return event;
    }
    return Utils.getObjValueByKey(event, key);
};

const resolveTypeValues = (event, type) => {
    if (type && type.includes(':')) {
        const [typeName, typeArgs] = Utils.getTypeBySplit(type);
        if (typeArgs && typeArgs.isResolve && typeArgs.key) {
            typeArgs.key = Utils.getObjValueByKey(event, typeArgs.key);
            if (!typeArgs.key) {
                return typeName;
            }
            if (typeof typeArgs.key === 'object') {
                const typeArgsTransform = JSON.stringify(typeArgs.key);
                return `${typeName}: ${typeArgsTransform}`;
            }
            return `${typeName}: ${JSON.stringify(typeArgs)}`;
        }
    }
    return type;
};

const getEventDefinition = (eventTemplates, event, translator) => {
    if (eventTemplates) {
        return eventTemplates.map((eventTemplate, index) => {
            const keyIndex = index;
            if (eventTemplate.template === template.eventDefinition) {
                const eventValue = resolveEventValues(event, eventTemplate.value);
                return (
                    eventValue && (
                        <EventDefinition
                            key={`definition${keyIndex}`}
                            term={translator(eventTemplate.term)}
                            value={eventValue}
                            type={
                                eventTemplate.type && resolveTypeValues(event, eventTemplate.type)
                            }
                        />
                    )
                );
            }
            if (eventTemplate.template === template.eventDefinitionDiff) {
                const type = eventTemplate.type && resolveTypeValues(event, eventTemplate.type);
                return (
                    <EventDefinitionDifference
                        key={`eventdifference${keyIndex}`}
                        term={translator(eventTemplate.term)}
                        newValue={resolveEventValues(event, eventTemplate.newValue)}
                        oldValue={resolveEventValues(event, eventTemplate.oldValue)}
                        type={type}
                    />
                );
            }
            if (eventTemplate.template === template.eventDefinitionArray) {
                const eventValues = resolveEventValues(event, eventTemplate.values);
                if (Array.isArray(eventValues) && eventValues.length > 0) {
                    return (
                        <EventDefinitionArray
                            key={`eventlist${keyIndex}`}
                            term={translator(eventTemplate.term)}
                            values={eventValues}
                        />
                    );
                }
            }
            if (
                eventTemplate.template === template.eventCoverageArray
                || eventTemplate.template === template.eventCoverageDiff
            ) {
                const eventValues = resolveEventValues(event, eventTemplate.coverages);
                if (Array.isArray(eventValues) && eventValues.length > 0) {
                    const isDifference = eventTemplate.template !== template.eventCoverageArray;
                    return (
                        <EventCoverage
                            key={`eventcoverage${keyIndex}`}
                            coverages={eventValues}
                            isDifference={isDifference}
                        />
                    );
                }
            }
            return null;
        });
    }
    return null;
};

const EventDefinitionCard = (props) => {
    const { event } = props;
    const translator = useTranslator();
    const eventTemplates = Utils.getTimelineDefinition(event.eventName);
    const Definition = eventTemplates
        ? getEventDefinition(eventTemplates, event, translator)
        : null;
    return Definition;
};

EventDefinitionCard.propTypes = {
    event: PropTypes.shape({}).isRequired
};

export default EventDefinitionCard;
