import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import PolicyContactsPage from '../../Contacts/PolicyContacts/PolicyContactsPage';
import AccountContactsPage from '../../Contacts/AccountContacts/AccountContactsPage';
import AssociatedContactsPage from '../../Contacts/AssociatedContacts/AssociatedContactsPage';
import messages from './Contacts.messages';

import metadata from './Contacts.metadata.json5';

import tabStyles from './Contacts.module.scss';

class PolicyContacts extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    account: PropTypes.shape({
                        accountNumber: PropTypes.string,
                        accountHolder: PropTypes.shape({
                            displayName: PropTypes.string
                        })
                    }),
                    policyNumber: PropTypes.string,
                    numberOfContacts: PropTypes.string
                })
            }),
            basePath: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                selectTab: PropTypes.string
            })
        }).isRequired
    };

    state = {
        selectedTab: 'policyContactsTab'
    };

    componentDidMount() {
        const {
            location: { state }
        } = this.props;

        if (state && state.selectTab) {
            this.setState({
                selectedTab: state.selectTab
            });
        }
    }

    updatePolicyContactsHeading = () => {
        const { fromAccountLanding } = this.props;
        const {
            policyDetailsData: {
                policyResponse: { numberOfContacts }
            }
        } = fromAccountLanding;
        const { basePath } = fromAccountLanding;
        const translator = this.context;

        const contactsCount = basePath.includes('policies') ? `(${numberOfContacts})` : '';

        return translator(messages.policyContactsCount, { policyContactsCount: contactsCount });
    };

    render() {
        const { fromAccountLanding } = this.props;
        const { selectedTab } = this.state;

        if (_.isEmpty(fromAccountLanding.policyDetailsData)) {
            return null;
        }

        const {
            policyDetailsData: {
                policyResponse: { account, policyNumber }
            }
        } = fromAccountLanding;
        const {
            accountNumber,
            accountHolder: { displayName }
        } = account;

        const overrideProps = {
            policyContacts: {
                policyNumber: policyNumber
            },
            accountContacts: {
                accountNumber: accountNumber,
                relatedTo: 'policies'
            },
            associatedContacts: {
                accountName: displayName,
                accountNumber: accountNumber,
                relatedTo: 'policies'
            },
            policyContactsTab: {
                heading: this.updatePolicyContactsHeading()
            },
            contactsTabSetContainer: {
                defaultActiveTab: selectedTab
            }
        };

        const resolvers = {
            resolveClassNameMap: tabStyles,
            resolveComponentMap: {
                policycontactspage: PolicyContactsPage,
                accountcontactspage: AccountContactsPage,
                associatedcontactspage: AssociatedContactsPage
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default PolicyContacts;
