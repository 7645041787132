import { MetadataContent } from '@jutro/uiconfig';
import React from 'react';
import metadata from './CoverageTermsTableComponent.metadata.json5';

function CoverageTermsTableComponent(props) {
    const { value: coverageTerms } = props;

    const getCell = (item, index, { id: property }) => {
        return item[property];
    };

    const overrideProps = {
        coverageTable: {
            data: coverageTerms.terms
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getCell: getCell
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default CoverageTermsTableComponent;
