import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class GatewayBillingService {
    static getAccountBillingSummary(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'getAccountBillingDetails',
            [accountNumber],
            additionalHeaders
        );
    }

    static getAccountBillingSummaryData(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'getAccountBillingSummary',
            [accountNumber],
            additionalHeaders
        );
    }

    static getPaymentGroupPaymentInformation(
        accountNumber,
        streamInternalId,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'getPaymentGroupPaymentInformation',
            [accountNumber, streamInternalId],
            additionalHeaders
        );
    }

    static setPaymentGroupPaymentInformation(
        accountNumber,
        streamInternalId,
        paymentMethod,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'setPaymentGroupPaymentInformation',
            [accountNumber, streamInternalId, paymentMethod],
            additionalHeaders
        );
    }

    static getPolicyBillingSummary(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'getPolicyBillingSummary',
            [policyNumber],
            additionalHeaders
        );
    }

    static createPaymentReportRecord(policyNumber, source, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'createPaymentReportRecord',
            [policyNumber, source],
            additionalHeaders
        );
    }

    static getPaymentInformation(policyNumber, amount, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'getPaymentInformation',
            [policyNumber, amount],
            additionalHeaders
        );
    }
}
