/* eslint-disable react/destructuring-assignment*/
import { compact } from 'lodash';
import { ServiceManager } from '@jutro/services';

function NameFormatter(name) {
    if (!name) return null;
    const delimiter = ' ';

    const localeService = ServiceManager.getService('locale-service');
    const countryCode = localeService.getDefaultCountryCode();

    const companyName = name.nameKanji || name.name;
    if (companyName) return companyName;

    const lastName = name.lastNameKanji || name.lastName;
    const firstName = name.firstNameKanji || name.firstName;

    if (countryCode === 'FR' || countryCode === 'JP') {
        return compact([lastName, firstName]).join(delimiter);
    }

    return [firstName, lastName].join(delimiter);
}

export default NameFormatter;
