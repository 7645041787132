const channelMap = {
    desktop: 'desktop',
    phone: 'phone',
    tablet: 'tablet',
    insuranceSuite: 'user',
    default: 'user'
};

const getChannelIcon = (channelName) => {
    return channelMap[channelName] || channelMap.default;
};

export default getChannelIcon;
