/* eslint-disable max-len*/
import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class AccountService {
    static getPotentialExistingAccounts(accountSearchCritera, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getPotentialExistingAccounts',
            [accountSearchCritera],
            additionalHeaders
        );
    }

    static getAccountDetails(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountDetails',
            [accountNumber],
            additionalHeaders
        );
    }

    static getAccountBillingData(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountBillingData',
            [accountNumber],
            additionalHeaders
        );
    }

    static addRecentlyViewedAccounts(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'addRecentlyViewedAccounts',
            [accountNumber],
            additionalHeaders
        );
    }

    static getRecentlyViewedAccounts(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getRecentlyViewedAccounts',
            [],
            additionalHeaders
        );
    }

    static getAvailableProductsForAccount(newSubmission, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAvailableProductsForAccount',
            [newSubmission],
            additionalHeaders
        );
    }

    static getAccountClaims(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountClaims',
            [accountNumber],
            additionalHeaders
        );
    }

    static getAccountsForCurrentUser(createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountsForCurrentUser',
            [createdInLastXDays, queryOptions, queryParameters],
            additionalHeaders
        );
    }

    static getPersonalAccountsForCurrentUser(createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getPersonalAccountsForCurrentUser',
            [createdInLastXDays, queryOptions, queryParameters],
            additionalHeaders
        );
    }

    static getCommercialAccountsForCurrentUser(createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getCommercialAccountsForCurrentUser',
            [createdInLastXDays, queryOptions, queryParameters],
            additionalHeaders
        );
    }

    static getAccountsForProducerCode(createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountsForProducerCode',
            [createdInLastXDays, queryOptions, queryParameters],
            additionalHeaders
        );
    }

    static getPersonalAccountsForProducerCode(createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getPersonalAccountsForProducerCode',
            [createdInLastXDays, queryOptions, queryParameters],
            additionalHeaders
        );
    }

    static getCommercialAccountsForProducerCode(createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getCommercialAccountsForProducerCode',
            [createdInLastXDays, queryOptions, queryParameters],
            additionalHeaders
        );
    }

    static getOpenPolicyTransactionsForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getOpenPolicyTransactionsForAccount',
            [accountNumber],
            additionalHeaders
        );
    }

    static getOrCreateAccount(account, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getOrCreateAccount',
            [account],
            additionalHeaders
        );
    }

    static getAccountJobsSummariesForCurrentUser(createdInLastXDays, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountJobsSummariesForCurrentUser',
            [createdInLastXDays],
            additionalHeaders
        );
    }

    static updateAccountDetails(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'updateAccountDetails',
            [accountNumber],
            additionalHeaders
        );
    }

    static getAccountJobsForCurrentUser(noOfDays, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayAccount'),
            'getAccountJobsForCurrentUser',
            [noOfDays],
            additionalHeaders
        );
    }
}
