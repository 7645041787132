import { defineMessages } from 'react-intl';

export default defineMessages({
    choseProducerCodePlaceholder: {
        id: 'gateway.views.new-quote.Choose Producer Code',
        defaultMessage: 'Please Select',
    },
    choseProductPlaceholder: {
        id: 'gateway.views.new-quote.Choose Product',
        defaultMessage: 'Please Select',
    },
    producerCode: {
        id: 'gateway.views.new-quote.Producer Code',
        defaultMessage: 'Producer Code',
    },
});
