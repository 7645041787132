import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './css/TimelineDetails.module.scss';
import DefinitionHeader from './EventDefinitionHeader';
import EventDefinitionCard from './EventDefinitionCard';

const EventCardInformation = (props) => {
    const { event, isOpen } = props;
    if (!isOpen) return null;

    const EventDetailClass = classNames(
        Styles.gwTimelineDetails,
        Styles.gwTimelineDetails__animation_allowed
    );

    return (
        <div className={EventDetailClass}>
            {event.policyNumber && (
                <div className={Styles.gwTimelineDetails__head}>
                    <DefinitionHeader event={event} />
                </div>
            )}

            <div className={Styles.gwTimelineDetails__body}>
                <div className={Styles.gwTimelineDetails}>
                    <EventDefinitionCard event={event} />
                </div>
            </div>
        </div>
    );
};
EventCardInformation.propTypes = {
    event: PropTypes.shape({ policyNumber: PropTypes.string }).isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default EventCardInformation;
