import { setComponentMapOverrides } from '@jutro/uiconfig';
import RenewalConfirmationPage from './pages/RenewalConfirmationPage/ConfirmationPage';
import RenewalPaymentPage from './pages/RenewalPaymentPage/PaymentPage';

setComponentMapOverrides(
    {
        RenewalConfirmationPage: { component: 'RenewalConfirmationPage' },
        RenewalPaymentPage: { component: 'RenewalPaymentPage' }
    },
    {
        RenewalConfirmationPage,
        RenewalPaymentPage
    }
);

export { default as RenewalConfirmationPage } from './pages/RenewalConfirmationPage/ConfirmationPage';
export { default as RenewalPaymentPage } from './pages/RenewalPaymentPage/PaymentPage';
export { default as policyRenewalMessages } from './PolicyRenewal.messages';
