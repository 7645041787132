import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './HoPolicyCoverageTermsIterableComponent.metadata.json5';
import styles from '../../Policies/Summary/Summary.module.scss';

function HoPolicyCoverageTermsIterableComponent(props) {
    const { data: coveragesTermsData } = props;

    const generateCoverageTermsOverrides = useMemo(() => {
        const overrides = coveragesTermsData.terms.map((term, tindex) => {
            return {
                [`hoCoveragesTermsContent${tindex}`]: {
                    label: term.name
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [coveragesTermsData]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ...generateCoverageTermsOverrides
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveragesTermsData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

HoPolicyCoverageTermsIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired
};

export default HoPolicyCoverageTermsIterableComponent;
