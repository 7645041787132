import { defineMessages } from 'react-intl';

export default defineMessages({
    startNewQuote: {
        id: 'gateway.views.new-quote.Start New Quote',
        defaultMessage: 'Start New Quote',
    },
    searchPlaceHolder: {
        id: 'gateway.views.search-quote.Search by account or policy number.',
        defaultMessage: 'Search by account or policy number...',
    },
    login: {
        id: 'auth.components.AuthStatus.Login',
        defaultMessage: 'Login',
    },
    account: {
        id: 'auth.components.AuthStatus.Account',
        defaultMessage: 'Account',
    },
    preferences: {
        id: 'auth.components.AuthStatus.Preferences',
        defaultMessage: 'Preferences',
    },
    logOut: {
        id: 'auth.components.AuthStatus.Log out',
        defaultMessage: 'Log out',
    },
    quoteAnyAccount: {
        id: 'gateway.views.new-quote.Quote for Any Account',
        defaultMessage: 'Quote for Any Account'
    },
    quoteThisAccount: {
        id: 'gateway.views.new-quote.Quote for This Account',
        defaultMessage: 'Quote for This Account'
    }
});
