import { defineMessages } from 'react-intl';

export default defineMessages({
    buildingsAndLocations: {
        id: 'gw-portals-policytransaction-pc-cp-angular.config.FlowConfig.Buildings and Locations',
        defaultMessage: 'Buildings and Locations'
    },
    cpPolicyRenewalPolicyDetails: {
        id: 'gw-portals-policytransaction-pc-cp-angular.config.FlowConfig.Policy Details',
        defaultMessage: 'Policy Details'
    },
    cpPolicyChange: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    cpPolicyChangeQuote: {
        id: 'gw-portals-policytransaction-pc-cp-angular.config.FlowConfig.Quote',
        defaultMessage: 'Quote'
    },
    cpPolicyChangePaymentDetails: {
        id: 'gw-portals-policytransaction-pc-cp-angular.config.FlowConfig.Payment Details',
        defaultMessage: 'Payment Details'
    }
});
