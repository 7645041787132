import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ContactUnderwriter.metadata.json5';

import './ContactUnderwriter.messages';

function ContactUnderwriter(props) {
    const { history, match } = props;

    const goToPolicy = useCallback(() => {
        const {
            params: { policyNumber }
        } = match;
        const redirectLobPath = `/policies/${policyNumber}/summary`;
        history.push(redirectLobPath);
    });

    const resolvers = {
        resolveCallbackMap: {
            goToPolicy: goToPolicy
        }
    };

    return (
        <ViewModelForm uiProps={metadata.pageContent} callbackMap={resolvers.resolveCallbackMap} />
    );
}

ContactUnderwriter.propTypes = wizardProps;
export default withRouter(ContactUnderwriter);
