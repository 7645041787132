import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import _ from 'lodash';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import {
    Main,
    useModal,
    ModalNextEmitter
} from '@jutro/components';
import './App.scss';
import 'gw-capability-quoteandbind-react';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { TranslatorContext } from '@jutro/locale';
import { routeConfirmationModal } from '@jutro/router';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import { platformComponents, platformComponentMap, ImageComponent } from 'gw-components-platform-react';
import { brandingData, setBranding } from 'gw-portals-branding-js';
import {
    MenuBarComponent,
    PageHeaderComponent,
    producerEngageComponentMap,
    producerEngageAdditionalComponents
} from 'gw-capability-gateway-react';
import { SelectProducerContextProvider } from 'gw-gateway-common-react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { ErrorBoundary } from 'gw-portals-error-react';
import { RoutingTracking } from 'gw-portals-tracking-react';
import { DynamicRoute, withAuthContextProvider } from 'gw-digital-auth-react';
import { GatewayAvailabilityService } from 'gw-capability-gateway-policycommon';
import { PDFViewer } from 'gw-document-pdfdisplay-react';
import { GatewayRenewalService } from 'gw-capability-gateway-policyrenewal';
import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { LoadSaveService, CustomQuoteService } from 'gw-capability-gateway-quoteandbind';
import { UserService, SubmissionService, JobService } from 'gw-capability-gateway';
import { BopCoverablesService } from 'gw-capability-gateway-policyjob-bop';
import { GatewayClaimService } from 'gw-capability-gateway-claim';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { GatewayFNOLService } from 'gw-capability-gateway-fnol';
import { AccountService } from 'gw-capability-gateway-policy';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { GatewaySpreadsheetService } from 'gw-capability-gateway-spreadsheet';
import { ProducerInfoService } from 'gw-capability-profileinfo';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { PropertyCodeLookupService } from 'gw-capability-quoteandbind-cp';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import {
    LoginCapabilityComponentMap, LoginCapabilityComponents, UserSignUpComponent, PdfCustomModal
} from 'gw-portals-login-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { GatewayClaimDocumentService } from 'gw-capability-gateway-claimdocument';

import Recaptcha from 'react-recaptcha';
import EntryPage from '../pages/EntryPage/EntryPage';
import PageRouting from '../routes/PageRouting';
import messages from './App.messages';
// make sure the IFRAME is imported as is
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import '!!raw-loader!../../public/redirect-login.html';

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...AddressLookupComponentMap,
        ...VehicleInfoLookupComponentMap,
        ...producerEngageComponentMap,
        ...policyJobComponentMap,
        ...LoginCapabilityComponentMap,
        Recaptcha: { component: 'Recaptcha' },
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
        PdfCustomModal: { component: 'PdfCustomModal' },
        PDFViewer: { component: 'PDFViewer' },
    },
    {
        ...platformComponents,
        ...AddressLookupComponents,
        ...VehicleInfoLookupComponents,
        ...producerEngageAdditionalComponents,
        ...policyJobComponents,
        ...LoginCapabilityComponents,
        Recaptcha: Recaptcha,
        WizardPageTemplateWithTitle,
        img: ImageComponent,
        PdfCustomModal,
        PDFViewer
    }
);

const getUserAuthData = (authData, authHeader) => {
    return UserService.getGateWayCurrentUser(authHeader);
};

const modalEmitter = new ModalNextEmitter();

function App() {
    const {
        showAlert
    } = useModal();

    const translator = useContext(TranslatorContext);
    const [viewModelService, updateViewModelService] = useState(undefined);
    useEffect(
        () => {
            const translatorFn = vmTranslator(translator);
            import(
                /* webpackChunkName: "product-metadata" */
                // eslint-disable-next-line import/no-unresolved
                'product-metadata'
            ).then((productMetadata) => {
                const { default: result } = productMetadata;
                updateViewModelService(
                    ViewModelServiceFactory.getViewModelService(result, translatorFn)
                );
            });
        },
        [translator]
    );
    if (brandingData.BRANDING) {
        setBranding();
    }

    const handleError = useCallback((error = {}) => {
        const state = {
            pathname: '/',
            state: error
        };
        return showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(
            () => <Redirect to={state} />,
            _.noop
        );
    }, []);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    BopCoverablesService: BopCoverablesService,
                    LoadSaveService: LoadSaveService,
                    CustomQuoteService: CustomQuoteService,
                    ClaimService: GatewayClaimService,
                    ClaimDownloadService: GatewayClaimDocumentService,
                    FNOLService: GatewayFNOLService,
                    AvailabilityService: GatewayAvailabilityService,
                    RenewalService: GatewayRenewalService,
                    DocumentService: GatewayDocumentService,
                    SpreadsheetService: GatewaySpreadsheetService,
                    EndorsementService: GatewayEndorsementService,
                    AccountService: AccountService,
                    BillingService: GatewayBillingService,
                    UserService: UserService,
                    PropertyCodeLookupService: PropertyCodeLookupService,
                    ContactService: ProducerInfoService,
                    quoteUnderWritingService: {
                        referToUnderwriter: SubmissionService.referToUnderwriter,
                        withdrawJobByJobNumber: JobService.withdrawJobByJobNumber,
                        approveUnderwritingIssue: LoadSaveService.approveUnderwritingIssue
                    }
                }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <ErrorBoundary onError={handleError}>
                        <Router
                            basename="/producer-engage"
                            getUserConfirmation={
                                (message, callback) => {
                                    return routeConfirmationModal(message, callback, modalEmitter);
                                }
                            }
                        >
                            <RoutingTracking />
                            <Switch>
                                <Route exact path="/login-page" component={EntryPage} />
                                <Route exact path="/auth/register" component={UserSignUpComponent} />
                                <DynamicRoute path="/">
                                    <SelectProducerContextProvider>
                                        <PageHeaderComponent />
                                        <MenuBarComponent />
                                        <Main className="producerEngageContainer">
                                            <PageRouting />
                                        </Main>
                                    </SelectProducerContextProvider>
                                </DynamicRoute>
                            </Switch>
                        </Router>
                    </ErrorBoundary>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthContextProvider({ onAuthDataCreation: getUserAuthData })(App);
