import { defineMessages } from 'react-intl';

export default defineMessages({
    employerInformation: {
        id: 'quoteandbind.wc7.views.additional-info.Employer Information',
        defaultMessage: 'Employer Information'
    },
    additionalDisclaimer: {
        id: 'quoteandbind.wc7.views.additional-info.Disclaimer',
        defaultMessage: 'Before you can bind the policy, you need to provide additional information. Note that this quote may change if this information does not match what you have entered earlier.'
    },
    coverageStartDate: {
        id: 'quoteandbind.wc7.views.finalquote.Coverage Start Date',
        defaultMessage: 'Coverage Start Date'
    },
    addressPhoneNumber: {
        id: 'quoteandbind.wc7.views.additional-info.Phone Number',
        defaultMessage: 'Phone Number'
    },
    addressEmail: {
        id: 'quoteandbind.wc7.views.additional-info.Email',
        defaultMessage: 'Email'
    },
    attachItHere: {
        id: 'quoteandbind.wc7.views.additional-info.Your underwriter might want to run a loss run reports...',
        defaultMessage: 'Your underwriter might want to run a loss run report. You can attach it {link}.'
    },
    addressState: {
        id: 'quoteandbind.wc7.views.qualification.State',
        defaultMessage: 'State'
    },
    officialId: {
        id: 'quoteandbind.wc7.views.additional-info.info.OfficialId',
        defaultMessage: 'IDs'
    },
    interstateID: {
        id: 'quoteandbind.wc7.views.additional-info.interStateID',
        defaultMessage: 'Interstate ID'
    },
    toIssueThePolicy: {
        id: 'quoteandbind.wc7.views.select-location.To issue the policy...',
        defaultMessage: 'To issue the policy, you need to provide all the locations where employees are working, and {employeesNumberAndWages}. Note that the quote may change if the numbers do not match the ones you entered earlier.'
    },
    assign: {
        id: 'quoteandbind.wc7.views.select-location.Assign',
        defaultMessage: 'Assign'
    },
    state: {
        id: 'quoteandbind.wc7.views.employees-classes-jurisdictions.State',
        defaultMessage: 'State'
    },
    employeesClasses: {
        id: 'quoteandbind.wc7.views.employees-classes-jurisdictions.Employees Classes',
        defaultMessage: 'Employees\' Classes'
    },
    annualWages: {
        id: 'quoteandbind.wc7.views.employees-classes-jurisdictions.Annual Wages',
        defaultMessage: 'Annual Wages'
    },
    numberOfEmployees: {
        id: 'quoteandbind.wc7.views.employees-classes-jurisdictions.Number of Employees',
        defaultMessage: 'Number of Employees'
    },
    ifAny: {
        id: 'quoteandbind.wc7.views.employees-classes-jurisdictions.If Any',
        defaultMessage: 'If Any'
    },
    employeesClassesEnteredForTheQuote: {
        id: 'quoteandbind.wc7.views.select-location.Employees Classes entered for the Quote',
        defaultMessage: 'Employees\' Classes Entered for the Quote'
    },
    haveManyLocationsAndEmployees: {
        id: 'quoteandbind.wc7.views.select-location.How many locations and employees? Add then using a...',
        defaultMessage: 'Have many locations and employees? Add them using a {spreadsheet}'
    },
    spreadsheet: {
        id: 'quoteandbind.wc7.views.select-employees-classes.spreadsheet',
        defaultMessage: 'spreadsheet'
    },
    enterTheLocationWhereTheEmployeesWork: {
        id: 'quoteandbind.wc7.views.edit-location.1. Enter the location where the employees work',
        defaultMessage: '1. Enter the location where the employees work'
    },
    theNumbers: {
        id: 'quoteandbind.wc7.views.select-employees-classes.The numbers',
        defaultMessage: 'If the number of employees you enter in this section is different than the number you entered earlier, the estimated quote might change.'
    },
    continue: {
        id: 'quoteandbind.wc7.views.select-employees-classes.Continue',
        defaultMessage: 'Continue'
    },
    addEmployeeState: {
        id: 'quoteandbind.wc7.views.edit-location.Add employees.State',
        defaultMessage: 'State'
    },
    chooseYourState: {
        id: 'quoteandbind.wc7.views.edit-location.Placeholder.Choose your State',
        defaultMessage: 'Choose Your State'
    },
    priorLossesAndClaims: {
        id: 'quoteandbind.wc7.views.additional-info.Prior Losses and Claims',
        defaultMessage: 'Prior Losses and Claims'
    },
    here: {
        id: 'quoteandbind.wc7.views.additional-info.here',
        defaultMessage: 'here'
    },
    employeesClassesAndLocations: {
        id: 'quoteandbind.wc7.views.additional-info.Employees Classes And Locations',
        defaultMessage: 'Employees Classes And Locations'
    },
    name: {
        id: 'quoteandbind.wc7.views.additional-info.Name',
        defaultMessage: 'Name'
    },
    dateModified: {
        id: 'quoteandbind.wc7.views.additional-info.Date Modified',
        defaultMessage: 'Date Modified'
    },
    remove: {
        id: 'quoteandbind.wc7.views.additional-info.Remove',
        defaultMessage: 'Remove'
    },
    searchDocuments: {
        id: 'quoteandbind.wc7.views.additional-info.Search documents',
        defaultMessage: 'Search documents'
    },
    errorLoadingDocument: {
        id: 'quoteandbind.wc7.views.additional-info.Error loading documents',
        defaultMessage: 'Error loading documents'
    },
    modalError: {
        id: 'quoteandbind.wc7.views.additional-info.Error',
        defaultMessage: 'Error'
    },
    finalQuote: {
        id: 'quoteandbind.wc7.views.additional-info.Final Quote',
        defaultMessage: 'Final Quote'
    },
    assignTheExactNumber: {
        id: 'quoteandbind.wc7.views.select-location.assign the exact number of employees and their wages to those locations',
        defaultMessage: 'assign the exact number of employees and their wages to those locations'
    },
    locationCode: {
        id: 'quoteandbind.wc7.views.edit-location.Add employees.Location Code',
        defaultMessage: 'Location Code'
    },
    locationName: {
        id: 'quoteandbind.wc7.views.edit-location.Add employees.Location Name',
        defaultMessage: 'Location Name'
    },
    save: {
        id: 'quoteandbind.wc7.views.edit-location.Employees.Save',
        defaultMessage: 'Save'
    },
    cancel: {
        id: 'quoteandbind.wc7.views.edit-location.Employees.Cancel',
        defaultMessage: 'Cancel'
    },
    specficLocation: {
        id: 'quoteandbind.wc7.views.edit-location.Specific location',
        defaultMessage: 'Specific location'
    },
    nonSpecficLocation: {
        id: 'quoteandbind.wc7.views.edit-location.Non-Specific location',
        defaultMessage: 'Non-Specific location'
    },
    addEmployeesClasses: {
        id: 'quoteandbind.wc7.views.edit-location.2. Add employees classes',
        defaultMessage: '2. Add employees\' classes'
    },
    employees: {
        id: 'quoteandbind.wc7.views.employees-classes-locations.Employees',
        defaultMessage: 'Employees'
    },
    processingFileFailed: {
        id: 'quoteandbind.wc7.views.additional-info.Processing file failed.',
        defaultMessage: 'Processing file failed.'
    },
    locationType: {
        id: 'quoteandbind.wc7.views.edit-location.Location Type',
        defaultMessage: 'Location Type'
    }
});
