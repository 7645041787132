import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class Wc7CoverablesService {
    static getIndustryCodes(policyNumber, sessionId, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'getIndustryCodes',
            [policyNumber, sessionId],
            additionalHeaders
        );
    }

    static updateModifier(
        jobNumber,
        sessionUUID,
        wc7ModifierDTO,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'updateModifier',
            [jobNumber, sessionUUID, wc7ModifierDTO],
            additionalHeaders
        );
    }

    static getAllJurisdictionDetails(
        jobNumber,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'getAllJurisdictionDetails',
            [jobNumber, sessionUUID],
            additionalHeaders
        );
    }

    static updateLocationWithCoveredEmployees(
        jobNumber,
        sessionUUID,
        wc7LocationsAndEmployeesDTO,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'updateLocationWithCoveredEmployees',
            [jobNumber, sessionUUID, wc7LocationsAndEmployeesDTO],
            additionalHeaders
        );
    }

    static getAllLocationsAndEmployees(jobNumber, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'getAllLocationsAndEmployees',
            [jobNumber, sessionUUID],
            additionalHeaders
        );
    }

    static updateLocation(jobNumber, sessionUUID, wc7LocationDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'updateLocation',
            [jobNumber, sessionUUID, wc7LocationDTO],
            additionalHeaders
        );
    }

    static getClassCodesByStateAndGovLaw(
        jobNumber,
        governingState,
        addressState,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'getClassCodesByStateAndGovLaw',
            [jobNumber, governingState, addressState, sessionUUID],
            additionalHeaders
        );
    }

    static addCoveredEmployee(
        jobNumber,
        sessionUUID,
        wc7CoveredEmployeesDTO,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'addCoveredEmployee',
            [jobNumber, sessionUUID, wc7CoveredEmployeesDTO],
            additionalHeaders
        );
    }

    static addLocationAndCoveredEmployee(
        jobNumber,
        sessionUUID,
        wc7LocationsAndEmployeesDTO,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'addLocationAndCoveredEmployee',
            [jobNumber, sessionUUID, wc7LocationsAndEmployeesDTO],
            additionalHeaders
        );
    }

    static removeLocation(
        jobNumber,
        sessionUUID,
        locationPublicID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'removeLocation',
            [jobNumber, sessionUUID, locationPublicID],
            additionalHeaders
        );
    }

    static removeCoveredEmployee(
        jobNumber,
        sessionUUID,
        coveredEmployeePublicID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'removeCoveredEmployee',
            [jobNumber, sessionUUID, coveredEmployeePublicID],
            additionalHeaders
        );
    }

    static updateCoveredEmployee(
        jobNumber,
        sessionUUID,
        wc7CoveredEmployeesDTO,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverables'),
            'updateCoveredEmployee',
            [jobNumber, sessionUUID, wc7CoveredEmployeesDTO],
            additionalHeaders
        );
    }
}
