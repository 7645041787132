import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';

import metadata from './ScheduleItemsIterableComponent.metadata.json5';

function ScheduleItemsIterableComponent(props) {
    const {
        value: stateDetails, path, onScheduleChange, showScheduleItemsTitle
    } = props;

    const generateOverrides = useMemo(() => {
        const overrides = stateDetails.clauses.schedules.map((aSchedules, idx) => {
            return {
                [`scheduleItemsComponentContainer${idx}`]: {
                    path: `${path}.clauses.schedules.children[${idx}].value`,
                    value: aSchedules,
                    showTitle: showScheduleItemsTitle
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [stateDetails.clauses.schedules, path, showScheduleItemsTitle]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        scheduleItemsIterableContainer: {
            data: stateDetails.clauses.schedules
        },
        ...generateOverrides
    };

    const resolvers = {
        resolveCallbackMap: {
            onScheduleChange
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

ScheduleItemsIterableComponent.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.shape({}),
    onScheduleChange: PropTypes.func.isRequired,
    showScheduleItemsTitle: PropTypes.bool
};

ScheduleItemsIterableComponent.defaultProps = {
    showScheduleItemsTitle: true,
    value: { clauses: {} }
};

export default ScheduleItemsIterableComponent;
