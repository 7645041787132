import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { UserService } from 'gw-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { routes } from './MenuBarComponent.json5';
import styles from './MenuBarComponent.module.scss';
import './MenuBarComponent.messages';

const { gatewayAnalytics, capabilitiesConfig, persona } = appConfig;

const MenuBar = (props) => {
    const translator = useTranslator();
    const { authHeader } = props;
    const isAgent = persona === 'producer';

    const [userAccessConfiguration, updateUserAccessConfiguration] = useState({});

    const updateConfiguation = useCallback((accessProperty) => {
        updateUserAccessConfiguration((previousState) => ({
            ...previousState,
            [accessProperty]: true
        }));
    }, []);

    const checkViewReportsPermission = useCallback(() => {
        const permissionDTO = {
            permission: 'gpaviewreports'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then((isPermission) => {
            if (isPermission && gatewayAnalytics) {
                updateConfiguation('canViewReports');
            }
        });
    }, [authHeader, updateConfiguation]);

    const checkViewCommissionsPermission = useCallback(() => {
        const permissionDTO = {
            permission: 'viewcommissions'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then((isPermission) => {
            if (
                isPermission
                && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
            ) {
                updateConfiguation('canViewCommission');
            }
        });
    }, [authHeader, updateConfiguation]);

    const checkViewClaimsPermission = useCallback(() => {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' })) {
            updateConfiguation('canViewClaims');
        }
    }, [updateConfiguation]);

    useEffect(() => {
        if (isAgent) {
            const executePermissionConfiguration = () => {
                checkViewClaimsPermission();
                checkViewReportsPermission();
                checkViewCommissionsPermission();
            };
            executePermissionConfiguration();
        }
        // it should call once when page is rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const routeWithUserAccess = useCallback(
        (route) => {
            if (route.visibleWhen) {
                return userAccessConfiguration[route.visibleWhen] && route;
            }
            return route;
        },
        [userAccessConfiguration]
    );

    const NavItems = useCallback(() => {
        const otherUsersMenu = ['/activities', '/'];
        const menuListByAgent = routes.filter((menu) => {
            return isAgent ? routeWithUserAccess(menu) : otherUsersMenu.indexOf(menu.path) !== -1;
        });

        return menuListByAgent.map(({
            title, path, icon, activeClassName
        }) => {
            const activeClass = styles[activeClassName];
            return (
                <NavLink exact={path === '/'} to={path} activeClassName={activeClass}>
                    {icon && <Icon icon={icon} className={styles.navIcon} />}
                    {translator(title)}
                </NavLink>
            );
        });
    }, [isAgent, routeWithUserAccess, translator]);

    return (
        <div className={styles.menuBar}>
            <NavItems />
        </div>
    );
};

export default withAuthenticationContext(MenuBar);

MenuBar.propTypes = {
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }),
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

MenuBar.defaultProps = {
    authUserData: {
        userType: ''
    }
};
