import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { consumerThemeConfig } from '@jutro/theme-styles';
import { LocaleService } from 'gw-portals-i18n-react';

import 'regenerator-runtime';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import App from './app/App';

const { trackingConfig = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (window.self === window.top) {
    const theBody = document.getElementsByTagName('body')[0];
    theBody.style.display = 'block';
} else {
    window.top.location = window.self.location;
}

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLanguageOrLocaleChange: true,
    onInit: () => {
        LocaleService.register();
    },
    themeConfig: consumerThemeConfig,
    appName: {
        id: 'digital.appName',
        defaultMessage: 'ProducerEngage'
    },
    appDescription: 'ProducerEngage'
});
