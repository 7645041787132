import React, { useCallback } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './BOPPolicyDetailsPage.metadata.json5';
import './BOPPolicyDetailsPage.messages';

function BOPPolicyDetailsPage(props) {
    const { onValidate, isComponentValid, initialValidation } = useValidation(
        'BOPPolicyDetailsPage'
    );
    const { wizardData: submissionVM, updateWizardData, authHeader } = props;
    const { EndorsementService } = useDependencies('EndorsementService');

    const onNext = useCallback(async () => {
        submissionVM.value = await EndorsementService.saveEndorsement(
            [submissionVM.value],
            authHeader
        );
        return submissionVM;
    }, [EndorsementService, authHeader, submissionVM]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        policyChangeEffectiveDate: {
            minDate: _.get(submissionVM, 'baseData.minimumEffectiveDate.value', new Date()),
            maxDate: _.get(submissionVM, 'baseData.periodEndDate.value')
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                model={submissionVM}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

BOPPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(BOPPolicyDetailsPage);
