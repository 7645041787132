import { defineMessages } from 'react-intl';

export default defineMessages({
    experienceModAndStateCoverages: {
        id: 'quoteandbind.wc7.wizard.step.Experience Mod and State Coverages',
        defaultMessage: 'Experience Mod and State Coverages'
    },
    experienceModDefaultForNewPolicies: {
        id: 'quoteandbind.wc7.views.experience-mod.Experience mod is set to 1 by default for new policies.',
        defaultMessage: 'Experience mod is set to 1 by default for new policies.'
    },
    experienceModFinal: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.Experience Mod Final?',
        defaultMessage: 'Experience Mod Final?'
    },
    edit: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.Edit',
        defaultMessage: 'Edit'
    },
    update: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.Update',
        defaultMessage: 'Update'
    },
    cancel: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.Cancel',
        defaultMessage: 'Cancel'
    },
    experienceModFactor: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.Experience Modification Factor (EMOD)',
        defaultMessage: 'Experience Modification Factor (EMOD)'
    },
    doYouWantToAddScheduledItemsToThisState: {
        id: 'quoteandbind.wc7.views.jurisdiction-details.Do you want to add scheduled items to this state?',
        defaultMessage: 'Do you want to add scheduled items to this state?'
    },
    doYouWantToAddCoveragesToThisState: {
        id: 'quoteandbind.wc7.views.jurisdiction-details.Do you want to add coverages to this state?',
        defaultMessage: 'Do you want to add coverages to this state?'
    },
    supplementryDiseaseLabel: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.Supplementary Disease Exposure',
        defaultMessage: 'Supplementary Disease Exposure'
    },
    rangeError: {
        id: 'The value {value} is not in the range ({minValue}, {maxValue})',
        defaultMessage: 'The value {value} is not in the range ( {minValue}, {maxValue} )'
    },
    updatingError: {
        id: 'quoteandbind.wc7.views.experience-mod.modifier.There was an error updating this modifier',
        defaultMessage: 'There was an error updating this modifier.'
    }
});
