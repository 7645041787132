import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { withRouter } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { policyRenewalMessages } from 'gw-capability-policyrenewal-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PRCPPolicyDetailsPage.metadata.json5';
import './PRCPPolicyDetailsPage.messages';

function PRCPPolicyDetailsPage(props) {
    const {
        showAlert
    } = useModal();

    const { isComponentValid, initialValidation, onValidate } = useValidation('PRCPPolicyDetailsPage');
    const { RenewalService } = useDependencies('RenewalService');
    const {
        wizardData: submissionVM,
        updateWizardData,
        authHeader,
        history,
        wizardSnapshot
    } = props;

    const onNext = useCallback(async () => {
        try {
            if (!_.isEqual(submissionVM.value, wizardSnapshot.value)) {
                submissionVM.value = await RenewalService.saveRenewal(
                    [submissionVM.value],
                    authHeader
                );
            }
            return submissionVM;
        } catch {
            return showAlert({
                title: policyRenewalMessages.renewalSaveModalTitle,
                message: policyRenewalMessages.renewalSaveModalMessage,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then(() => {
                const jobID = _.get(submissionVM, 'jobID.value');
                const redirectPath = `/renewal/${jobID}/summary`;
                return history.push(redirectPath);
            }, _.noop);
        }
    }, [RenewalService, submissionVM, wizardSnapshot, authHeader, history]);
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        }
    };

    return (
        <WizardPage
            showPrevious={false}
            onNext={onNext}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}
PRCPPolicyDetailsPage.propTypes = wizardProps;
PRCPPolicyDetailsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withAuthenticationContext(PRCPPolicyDetailsPage));
