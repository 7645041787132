import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { AccountService } from 'gw-capability-gateway-policy';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Icon, Loader } from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { LobIconUtil } from 'gw-portals-util-js';
import messages from '../Accounts/Accounts.messages';
import metadata from './SRELandingPage.metadata.json5';
import styles from './SRELandingPage.module.scss';
import './SRELandingPage.messages';

const accountPermCheckDTO = {
    permEntityType: 'Account',
    permEntityID: null,
    permission: 'create',
    isCheckPermEntity: false
};

const permissionDTO = {
    permission: 'createsubmission'
};

const newQuoteAccountURL = '/new-quote-account-search';

function SRELandingPage(props) {
    const [accountData, setAccountData] = useState([]);
    const [policiesData, setPoliciesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [canCreateSubmission, updateCanCreateSubmission] = useState(false);
    const [canCreateAccount, updateCanCreateAccount] = useState(false);
    const translator = useTranslator();
    const { authHeader } = props;
    const intl = useContext(IntlContext);

    const getCell = useCallback(
        (items, index, { id: property }) => {
            const tooltipMessages = {
                dateCreated: translator(messages.DateCreated),
                address: translator(messages.Address),
                displayStatus: translator(messages.status),
                premium: translator(messages.premium),
                effectiveDate: translator(messages.effectiveDate),
                expirationDate: translator(messages.expirationDate)
            };

            const toolTipMessage = tooltipMessages[property] || '';
            return <span title={toolTipMessage}>{items[property]}</span>;
        },
        [translator]
    );

    const getAccount = useCallback(
        (item, index, { id: property }) => {
            const toolTipMessage = translator(messages.Account);
            const icon = item.type === 'Personal' ? 'mi-person' : 'mi-apartment';
            return (
                <div className={styles.linkAccount}>
                    <Icon icon={icon} />
                    <Link to={`/accounts/${item.accountNumber}/summary`} title={toolTipMessage}>
                        {item[property]}
                    </Link>
                </div>
            );
        },
        [translator]
    );

    const getPolicyLink = useCallback(
        (item, index, { id: property }) => {
            let toolTipMessage = messages.OpenActivities;
            let redirectPath = 'activities';

            if (property === 'policy') {
                toolTipMessage = messages.Policies;
                redirectPath = 'summary';
            }

            return (
                <Link
                    to={{
                        pathname: `/accounts/${item.accountNumber}/${redirectPath}`,
                        state: redirectPath
                    }}
                    title={translator(toolTipMessage)}
                >
                    {item[property]}
                </Link>
            );
        },
        [translator]
    );

    const processResponseData = useCallback((data) => {
        const accountArray = data.map((accountInfo) => {
            const isPersonal = accountInfo.isPersonalAccount;
            const account = {
                type: isPersonal ? 'Personal' : 'Commercial',
                insuredName: accountInfo.accountHolder,
                policy: accountInfo.policySummaries.length,
                dateCreated: intl.formatDate(new Date(accountInfo.accountCreatedDate), { year: 'numeric', month: 'short', day: 'numeric' }),
                address: accountInfo.accountHolderAddress.displayName,
                openActivities: accountInfo.numberOfOpenActivities,
                accountNumber: accountInfo.accountNumber
            };
            return account;
        });
        return accountArray;
    }, [intl]);

    const setAccountResponse = useCallback(
        (response) => {
            setAccountData(processResponseData(response));
        },
        [processResponseData]
    );

    const getProductImage = useCallback((item) => {
        const icon = LobIconUtil.getFontIcon(item.productCode);
        return (
            <Icon
                icon={icon}
                title={item.productCode}
            />
        );
    }, []);

    const getPoliciesCell = useCallback(
        (item, index, { id: property }) => {
            const toolTipMessage = translator(messages.insuredName);

            return <span title={toolTipMessage}>{item[property]}</span>;
        },
        [translator]
    );

    const getLink = useCallback(
        (item, index, { id: property }) => {
            return (
                <Link
                    to={`/policies/${item[property]}/summary`}
                    className={styles.removeLinkStyle}
                    title={translator(messages[property])}
                >
                    {item[property]}
                </Link>
            );
        },
        [translator]
    );

    const getAccountNumberLink = useCallback(
        (item, index, { id: property }) => {
            const toolTipMessage = translator(messages.Account);
            const { accountHolderName } = item;
            return (
                <Link
                    to={`/accounts/${item[property]}/summary`}
                    className={styles.removeLinkStyle}
                    title={toolTipMessage}
                >
                    {accountHolderName}
                </Link>
            );
        },
        [translator]
    );

    const setPoliciesResponse = useCallback((data) => {
        const policyItems = [];

        data.forEach((policyDetails) => {
            const { amount, currency } = policyDetails.premium;
            const premiumAmount = intl.formatNumber(
                amount,
                {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'code'
                }
            );
            const policy = {
                productCode: policyDetails.product.productCode,
                insuredName: policyDetails.primaryInsuredName,
                displayStatus: policyDetails.displayStatus,
                accountNumber: policyDetails.accountNumber,
                policyNumber: policyDetails.policyNumber,
                account: policyDetails.accountNumber,
                accountHolderName: policyDetails.accountHolderName,
                primaryInsuredName: policyDetails.primaryInsuredName,
                premium: premiumAmount,
                effectiveDate: intl.formatDate(new Date(policyDetails.effective), { year: 'numeric', month: 'short', day: 'numeric' }),
                expirationDate: intl.formatDate(new Date(policyDetails.expiration), { year: 'numeric', month: 'short', day: 'numeric' })
            };
            policyItems.push(policy);
            return true;
        });
        setPoliciesData(policyItems);
    }, []);

    const checkCanCreateSubmission = useCallback(async () => {
        const canCreateSubmissionResponse = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        updateCanCreateSubmission(canCreateSubmissionResponse);
    }, [authHeader]);

    const checkCanCreateAccount = useCallback(async () => {
        const canCreateAccountResponse = await UserService.hasUserPermission(
            accountPermCheckDTO,
            authHeader
        );
        updateCanCreateAccount(canCreateAccountResponse);
    }, [authHeader]);

    useEffect(() => {
        checkCanCreateSubmission();
        checkCanCreateAccount();
        AccountService.getRecentlyViewedAccounts(authHeader).then((accountsResponse) => {
            setAccountResponse(accountsResponse, messages.RecentlyViewed);
            setIsLoading(false);
        });

        PolicyService.getRecentlyViewedPolicies(authHeader).then((policiesResponse) => {
            setPoliciesResponse(policiesResponse);
            setIsLoading(false);
        });
        // It should execute only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToNewQuote = useCallback(() => {
        const { history } = props;
        return history.push(newQuoteAccountURL);
    }, [props]);

    const overrides = {
        recentlyViewedTableGrid: {
            visible: accountData.length > 0,
            data: accountData
        },
        recentlyViewedPoliciesTableGrid: {
            visible: policiesData.length > 0,
            data: policiesData
        },
        noPoliciesFound: {
            visible: !policiesData.length
        },
        noAccountsFound: {
            visible: !accountData.length
        },
        startNewQuoteBtn: {
            visible: canCreateAccount && canCreateSubmission
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell,
            getProductImage,
            getPoliciesCell,
            getAccount,
            getPolicyLink,
            getAccountNumberLink,
            getLink,
            goToNewQuote
        }
    };
    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const landingPage = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrides}
        {...resolvers}
    />;
    return <div className={styles.landingStyles}>{landingPage}</div>;
}

SRELandingPage.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withAuthenticationContext(SRELandingPage));
