import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class GatewayPaymentService {
    static makeDirectBillPayment(
        accountNumber,
        request,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBillingDirectBillPayment'),
            'makeDirectBillPayment',
            [accountNumber, request],
            additionalHeaders
        );
    }
}
