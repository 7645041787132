import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class CommisionAccountService {
    static getPoliciesInForceByAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaycommissionCommission'),
            'getPoliciesInForceByAccount',
            [accountNumber],
            additionalHeaders
        );
    }

    static getCommissionsTransactionsByAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaycommissionCommission'),
            'getCommissionsTransactionsByAccount',
            [accountNumber],
            additionalHeaders
        );
    }
}
