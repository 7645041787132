import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { ActivitiesService } from 'gw-capability-gateway';
import OpenActivitiesComponent from '../../Components/OpenActivitiesComponent/OpenActivitiesComponent';
import metadata from './Activities.metadata.json5';

class QuoteDetailsActivities extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string,
                getQuoteSummaryCount: PropTypes.func
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    getActivities = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber }
            }
        } = this.props;
        const getAvailableActivities = await ActivitiesService.getActivitiesForJob(
            jobNumber,
            authHeader
        );
        return getAvailableActivities;
    };

    createNewActivity = async (newActivityData) => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { getQuoteSummaryCount }
            }
        } = this.props;
        const newActivityResponse = await ActivitiesService.createNewActivity(
            newActivityData,
            null,
            authHeader
        );
        if (!_.isEmpty(newActivityResponse)) {
            getQuoteSummaryCount();
            return newActivityResponse;
        }
        return {};
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { jobNumber }
            }
        } = this.props;
        const overrides = {
            quoteDetailsActivities: {
                getActivities: this.getActivities,
                createNewActivity: this.createNewActivity,
                activityEntity: 'job',
                activityEntityId: jobNumber
            }
        };
        const resolvers = {
            resolveComponentMap: {
                openactivitiescomponent: OpenActivitiesComponent
            }
        };
        // eslint-disable-next-line max-len
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

export default withAuthenticationContext(QuoteDetailsActivities);
