import { defineMessages } from 'react-intl';

export default defineMessages({
    otherPremiums: {
        id: 'quoteandbind.wc7.views.standards-and-other.premiums.Other Premiums',
        defaultMessage: 'Other Premiums'
    },
    employeesCodeRatesAndCostByState: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.Employee Code Rates and Costs by State',
        defaultMessage: 'Employees Code Rates and Costs by State'
    },
    employeesClassesAddress: {
        id: 'quoteandbind.wc7.view.edit-employees-classes.Employees Classes',
        defaultMessage: 'Employees\' Classes'
    },
    code: {
        id: 'quoteandbind.wc7.views-employee-locations.Code',
        defaultMessage: 'Code'
    },
    description: {
        id: 'quoteandbind.wc7.views-employee-locations.Description',
        defaultMessage: 'Description'
    },
    rate: {
        id: 'quoteandbind.wc7.views-employee-locations.Rate',
        defaultMessage: 'Rate'
    },
    amount: {
        id: 'quoteandbind.wc7.views-employee-locations.Amount',
        defaultMessage: 'Amount'
    },
    basis: {
        id: 'quoteandbind.wc7.views-employee-locations.Basis',
        defaultMessage: 'Basis'
    },
    chageOrAddEmployeeClasses: {
        id: 'quoteandbind.wc7.views.standards-and-other.premiums.Change or Add Employees Classes',
        defaultMessage: 'Change or Add Employees Classes'
    },
    state: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.State',
        defaultMessage: 'State'
    },
    expMod: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.Exp.Mod',
        defaultMessage: 'Exp.Mod'
    },
    premium: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.Premium',
        defaultMessage: 'Premium'
    },
    taxesAndSurcharges: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.Taxes and Surcharges',
        defaultMessage: 'Taxes and Surcharges'
    },
    employeesClasses: {
        id: 'quoteandbind.wc7.views-employees-locations.Employees Classes',
        defaultMessage: 'Employees\' Classes'
    },
    totalCost: {
        id: 'quoteandbind.wc7.views.jurisdiction-costs.Total Cost',
        defaultMessage: 'Total Cost'
    }
});
