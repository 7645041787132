import React, {
    useCallback, useState, useEffect, Fragment
} from 'react';
import PropTypes from 'prop-types';
import { Link } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import FilterOptions from './FilterOptions';
import styles from './ActivityAdvancedFilter.module.scss';
import ActivitiesLandingUtil from './ActivitiesLandingUtil';
import messages from './ActivityAdvancedFilter.messages';

const ActivityAdvancedFilter = (props) => {
    const ActivityUtil = new ActivitiesLandingUtil();
    const {
        activities, selectedTile, onValueChange, viewModelService
    } = props;
    const translator = useTranslator();
    const [customFilters, setCustomFilters] = useState(ActivityUtil.customFilter.filters);

    useEffect(() => {
        const activityVM = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.activity.dto.ActivityDTO'
        );

        ActivityUtil.createCustomFilterOptions(activities, activityVM, translator);
        ActivityUtil.setCustomFilterFieldsForQuickFilter(selectedTile);
        setCustomFilters([...ActivityUtil.customFilter.filters]);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterCallback = useCallback((filters) => {
        onValueChange(ActivityUtil.filterActivities(filters, activities), selectedTile);
    }, []);

    const writeValue = useCallback(
        (filters) => {
            const selectedFilters = ActivityUtil.selectedOption(customFilters, filters);
            filterCallback(selectedFilters);
            setCustomFilters([...selectedFilters]);
        },
        [ActivityUtil, customFilters]
    );

    const setAllOptions = useCallback(
        (event, index, value) => {
            event.preventDefault();
            ActivityUtil.setAllFilterOptionsSelected(customFilters[index], value);
            filterCallback(customFilters);
            setCustomFilters([...customFilters]);
        },
        [ActivityUtil, customFilters]
    );

    const clearAllSelectedOptions = useCallback(
        (event) => {
            event.preventDefault();
            ActivityUtil.setCustomFiltersSelected(customFilters, false);
            filterCallback(customFilters);
            setCustomFilters([...customFilters]);
        },
        [ActivityUtil, customFilters]
    );

    return (
        <div>
            <div className={styles.gwAdvancedFilter}>
                <div className={styles.gwFilterSection}>
                    {customFilters.map((filter, index) => (
                        <Fragment>
                            <div className={styles.gwFilterSectionTitle}>
                                {translator(filter.displayKey)}
                            </div>
                            <FilterOptions filters={filter} onValueChange={writeValue} />
                            {filter.options.length > 1 && (
                                <div className={styles.gwFilterSelectLink}>
                                    <Link
                                        id={`selectAll${index}`}
                                        onClick={(e) => setAllOptions(e, index, true)}
                                        to="/"
                                    >
                                        {translator(messages.activitieslandingSelectAll)}
                                    </Link>
                                    <Link
                                        id={`clearAll${index}`}
                                        onClick={(e) => setAllOptions(e, index, false)}
                                        to="/"
                                    >
                                        {translator(messages.activitieslandingClearAll)}
                                    </Link>
                                </div>
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
            <div className={styles.gwClearFilterLink}>
                <Link id="clearAll" onClick={(e) => clearAllSelectedOptions(e)} to="/">
                    {translator(messages.activitieslandingClearAllFilters)}
                </Link>
            </div>
        </div>
    );
};
ActivityAdvancedFilter.propTypes = {
    activities: PropTypes.shape([]),
    selectedTile: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired
    }).isRequired
};
ActivityAdvancedFilter.defaultProps = {
    activities: [],
    selectedTile: undefined
};

export default withViewModelService(ActivityAdvancedFilter);
