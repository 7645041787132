import { defineMessages } from 'react-intl';

export default defineMessages({
    retrievingPaymentPlan: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.Retrieving payment plans...',
        defaultMessage: 'Retrieving payment plans...'
    },
    issueNow: {
        id: 'gw-portals-policytransaction-common-angular.components.paymentWithPaymentPlan.payment.Issue Now',
        defaultMessage: 'Issue Now'
    },
    unableToReferUW: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.Unable to refer renewal',
        defaultMessage: 'Unable to refer renewal'
    },
    errorOccurWhileRenewal: {
        id: 'gw-portals-policytransaction-renewal-bop-angular.config.FlowConfig.An error occurred while attempting to refer your renewal',
        defaultMessage: 'An error occurred while attempting to refer your renewal'
    },
    renewalSaveModalTitle: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.config.FlowConfig.Unable to save',
        defaultMessage: 'Unable to save'
    },
    renewalSaveModalMessage: {
        id: 'gw-portals-policytransaction-renewal-cp-angular.FlowConfig.An error occurred while attempting to save.',
        defaultMessage: 'An error occurred while attempting to save.'
    }
});
