import LoginComponent from './LoginComponent/LoginComponent';
import SignUpComponent from './SignUpComponent/SignUpComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent/ForgotPasswordComponent';
import ResetPasswordComponent from './ResetPasswordComponent/ResetPasswordComponent';
import UserSignUpComponent from './UserSignUpComponent/UserSignUpComponent';
import UserRegisterMessageComponent from './UserRegisterMessageComponent/UserRegisterMessageComponent';
import PdfCustomModal from './PdfCustomModal/PdfCustomModal';


const LoginCapabilityComponentMap = {
    LoginComponent: { component: 'LoginComponent' },
    SignUpComponent: { component: 'SignUpComponent' },
    ForgotPasswordComponent: { component: 'ForgotPasswordComponent' },
    ResetPasswordComponent: { component: 'ResetPasswordComponent' },
    UserSignUpComponent: { component: 'UserSignUpComponent' },
    UserRegisterMessageComponent: { component: 'UserRegisterMessageComponent' },
    PdfCustomModal: { component: 'PdfCustomModal' },
};

const LoginCapabilityComponents = {
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserSignUpComponent,
    UserRegisterMessageComponent,
    PdfCustomModal
};

export {
    LoginCapabilityComponentMap,
    LoginCapabilityComponents,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserSignUpComponent,
    UserRegisterMessageComponent,
    PdfCustomModal
};
