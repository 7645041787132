import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class PolicyService {
    static createNewPolicyCancellationTransaction(
        policyNumber,
        cancellation,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyCancellationTransaction',
            [policyNumber, cancellation],
            additionalHeaders
        );
    }

    static createNewPolicyChangeTransaction(policyChange, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyChangeTransaction',
            [policyChange],
            additionalHeaders
        );
    }

    static getPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getPolicy',
            [policyNumber],
            additionalHeaders
        );
    }

    static getPolicyClaims(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyClaims',
            [policyNumber],
            additionalHeaders
        );
    }

    static getRecentlyIssuedPolicies(numberOfDays, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getRecentlyIssuedPolicies',
            [numberOfDays],
            additionalHeaders
        );
    }

    static getRecentlyViewedPolicies(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getRecentlyViewedPolicies',
            [],
            additionalHeaders
        );
    }

    static getPolicySummariesForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getPolicySummariesForCurrentUser',
            [],
            additionalHeaders
        );
    }

    static getPolicyBillingSummaryInfo(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyBillingSummaryInfo',
            [policyNumber],
            additionalHeaders
        );
    }

    static addRecentlyViewedPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'addRecentlyViewedPolicy',
            [policyNumber],
            additionalHeaders
        );
    }

    static getNotesForPolicy(policyNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getNotesForPolicy',
            [policyNumber],
            additionalHeaders
        );
    }

    static getDocumentsForPolicy(policyNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getDocumentsForPolicy',
            [policyNumber],
            additionalHeaders
        );
    }

    static createNoteForPolicy(params, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'createNoteForPolicy',
            params,
            additionalHeaders
        );
    }

    static createNewPolicyRenewalTransaction(params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyRenewalTransaction',
            params,
            additionalHeaders
        );
    }

    static getPolicyTransactionsForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyTransactionsForPolicy',
            [policyNumber],
            additionalHeaders
        );
    }

    static getPolicyPeriodJobType(params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyPeriodJobType',
            params,
            additionalHeaders
        );
    }

    static createPolicyPayrollActivity(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'), 'createPolicyPayrollActivity', [policyNumber], authHeader);
    }

    static getViewDocument(publicID, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'viewDocument',
            [publicID],
            additionalHeaders
        );
    }
}
