import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { useTranslator } from '@jutro/locale';
import EventTypeResolver from './resolvers/typeResolver';
import definitionDiffStyle from './css/TimelineDefinitionDiff.module.scss';

const classDefinition = definitionDiffStyle.gwTimelineDefinitionDiff;
const classDefinitionTerm = definitionDiffStyle.gwTimelineDefinitionDiff__term;
const classDefinitionDiff = definitionDiffStyle.gwTimelineDefinitionDiff__diff;
const classDefinitionNew = definitionDiffStyle.gwTimelineDefinitionDiff__new;
const classDefinitionOld = definitionDiffStyle.gwTimelineDefinitionDiff__old;

const getCurrency = (value) => {
    return <CurrencyField id="currencyCost" value={value} readOnly hideLabel />;
};
const resolveEventValue = (type, value, translator) => {
    if (type) {
        if (type === 'gwCurrency') {
            return getCurrency(value);
        }
        const eventValue = EventTypeResolver(type, value, translator);
        if (typeof eventValue === 'object') {
            return JSON.stringify(eventValue);
        }
        return eventValue;
    }
    return value;
};

const EventDefinitionDifference = (props) => {
    const translator = useTranslator();
    const {
        term, newValue, oldValue, type
    } = props;
    if (!newValue && !oldValue) {
        return null;
    }
    const resolvedNewValue = resolveEventValue(type, newValue, translator);
    const resolvedOldValue = resolveEventValue(type, oldValue, translator);
    if (_.isEmpty(_.trim(resolvedNewValue)) && _.isEmpty(_.trim(resolvedOldValue))) {
        return null;
    }
    return (
        <div className={classDefinition}>
            <h3 className={classDefinitionTerm}>{term}</h3>
            <div className={classDefinitionDiff}>
                <div className={classDefinitionNew}>{resolvedNewValue}</div>
                <div className={classDefinitionOld}>{resolvedOldValue}</div>
            </div>
        </div>
    );
};
EventDefinitionDifference.propTypes = {
    term: PropTypes.string.isRequired,
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    type: PropTypes.string
};

EventDefinitionDifference.defaultProps = {
    type: undefined,
    newValue: undefined,
    oldValue: undefined
};

export default EventDefinitionDifference;
