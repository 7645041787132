import { defineMessages } from 'react-intl';

export default defineMessages({
    Notes: {
        id: 'gateway.views.activity-detail.Notes',
        defaultMessage: 'Notes'
    },
    Documents: {
        id: 'gateway.views.cancellation-detail.Documents',
        defaultMessage: 'Documents'
    },
    Claims: {
        id: 'gateway.views.policy-detail-summary.Claims',
        defaultMessage: 'Claims'
    },
    searchTransactions: {
        id: 'gateway.views.policy-detail.Search Transactions',
        defaultMessage: 'Search Transactions'
    },
    Billing: {
        id: 'gateway.views.policy-detail-summary.Billing',
        defaultMessage: 'Billing'
    },
    Commission: {
        id: 'gateway.views.policy-detail-summary.Commission',
        defaultMessage: 'Commission'
    },
    Businessowners: {
        id: 'gateway.views.policy-detail-summary.Businessowners',
        defaultMessage: 'Businessowners'
    },
    JobNumber: {
        id: 'gateway.directives.policy-transaction.Job Number',
        defaultMessage: 'Job Number'
    },
    TransactionStatus: {
        id: 'gateway.directives.policy-transaction.Transaction Status',
        defaultMessage: 'Transaction Status'
    },
    Type: {
        id: 'gateway.directives.policy-transaction.Type',
        defaultMessage: 'Type'
    },
    PeriodStatus: {
        id: 'gateway.directives.policy-transaction.Period Status',
        defaultMessage: 'Period Status'
    },
    Premium: {
        id: 'gateway.directives.policy-transaction.Premium',
        defaultMessage: 'Premium'
    },
    EffectiveDate: {
        id: 'gateway.directives.policy-transaction.Effective Date',
        defaultMessage: 'Effective Date'
    },
    CancelPolicy: {
        id: 'gateway.views.policy-detail-summary.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },
    ChangePolicy: {
        id: 'gateway.views.policy-detail-summary.Change Policy',
        defaultMessage: 'Change Policy'
    },
    RenewPolicy: {
        id: 'gateway.views.policy-detail-summary.Renew Policy',
        defaultMessage: 'Renew Policy'
    },
    DeclarationPage: {
        id: 'gateway.views.policy-detail-summary.Declaration page',
        defaultMessage: 'Declaration page'
    },
    IdCard: {
        id: 'gateway.views.policy-detail-summary.ID Card',
        defaultMessage: 'ID Card'
    },
    PolicyTransactions: {
        id: 'gateway.views.policy-detail.Policy Transactions',
        defaultMessage: 'Policy Transactions'
    },
    Details: {
        id: 'gateway.views.policy-detail.Details',
        defaultMessage: 'Details'
    },
    SummaryDetails: {
        id: 'gateway.views.policy-detail.Summary Details',
        defaultMessage: 'Summary Details'
    },
    SummaryDetailsPreRenewalDirectionNone: {
        id: 'gateway.views.policy-detail.None',
        defaultMessage: 'None'
    },
    ProducerInformation: {
        id: 'gateway.views.policy-detail.Producer Information',
        defaultMessage: 'Producer Information'
    },
    PolicyValue: {
        id: 'gateway.views.policy-detail.Policy Value',
        defaultMessage: 'Policy Value'
    },
    Coverages: {
        id: 'gateway.views.policy-detail.Coverages',
        defaultMessage: 'Coverages'
    },
    PolicyInception: {
        id: 'gateway.views.policy-detail-summary.Policy Inception',
        defaultMessage: 'Policy Inception'
    },
    PolicyExpiration: {
        id: 'gateway.views.policy-detail-summary.Policy Expiration',
        defaultMessage: 'Policy Expiration'
    },
    ProducerOfRecord: {
        id: 'gateway.views.policy-detail.Producer of Record',
        defaultMessage: 'Producer of Record'
    },
    PreRenewalDirection: {
        id: 'gateway.views.policy-detail-summary.Pre Renewal Direction',
        defaultMessage: 'Pre-Renewal Direction'
    },
    PolicyStatus: {
        id: 'gateway.views.policy-detail-summary.Policy Status',
        defaultMessage: 'Policy Status'
    },
    ProducerOfService: {
        id: 'gateway.views.policy-detail.Producer of Service',
        defaultMessage: 'Producer of Service'
    },
    TotalPremium: {
        id: 'gateway.views.policy-detail.Total Premium',
        defaultMessage: 'Total Premium'
    },
    TaxesAndFees: {
        id: 'gateway.views.renewal.renewal-summary.Taxes and Fees',
        defaultMessage: 'Taxes and Fees'
    },
    TotalCost: {
        id: 'gateway.views.renewal.renewal-summary.Total Cost',
        defaultMessage: 'Total Cost'
    },
    NoneRenew: {
        id: 'gateway.views.policy-detail-summary.None',
        defaultMessage: 'None'
    },
    vehicles: {
        id: 'policy.pa.directives.policy-vehicle-info.vehicles',
        defaultMessage: 'Vehicles'
    },
    make: {
        id: 'policy.pa.directives.policy-vehicle-info.make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'policy.pa.directives.policy-vehicle-info.model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'policy.pa.directives.policy-vehicle-info.year',
        defaultMessage: 'Year'
    },
    licensePlate: {
        id: 'policy.pa.directives.policy-vehicle-info.license plate',
        defaultMessage: 'License Plate'
    },
    hideVehicleCoverages: {
        id: 'policy.pa.directives.policy-vehicle-info.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'policy.pa.directives.policy-vehicle-info.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    Coverage: {
        id: 'policy.pa.directives.policy-vehicle-info.coverage',
        defaultMessage: 'Coverage'
    },
    Deductible: {
        id: 'policy.pa.directives.policy-vehicle-info.deductible',
        defaultMessage: 'Deductible'
    },
    Limits: {
        id: 'policy.pa.directives.policy-vehicle-info.limits',
        defaultMessage: 'Limits'
    },
    PremiumBreakdown: {
        id: 'policy.pa.directives.policy-vehicle-info.premium breakdown',
        defaultMessage: 'Premium Breakdown'
    },
    vehicleInfoType: {
        id: 'policy.pa.directives.policy-vehicle-info.type',
        defaultMessage: 'Type'
    },
    vehicleInfoPolicy: {
        id: 'policy.pa.directives.policy-vehicle-info.Policy',
        defaultMessage: 'Policy'
    },
    coveredDrivers: {
        id: 'policy.pa.directives.policy-driver-info.Covered Drivers',
        defaultMessage: 'Covered Drivers'
    },
    coveredDriversName: {
        id: 'policy.pa.directives.policy-driver-info.Name',
        defaultMessage: 'Name'
    },
    coveredDriversLicense: {
        id: 'policy.pa.directives.policy-driver-info.License',
        defaultMessage: 'License'
    },
    dateOfBirth: {
        id: 'policy.pa.directives.policy-driver-info.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    licencePlate: {
        id: 'policy.pa.directives.policy-vehicle-info.license plate',
        defaultMessage: 'License Plate'
    },
    totalPremium: {
        id: 'integration.policy.directives.policy-coverages.Total Premium:',
        defaultMessage: 'Total Premium:'
    },
    payrollReportMessage: {
        id: 'gateway.views.policy-detail.Payroll Report Due',
        defaultMessage: 'A payroll report for {month} is due on {dueDate}. Click below to request that a blank copy of this payroll report be emailed to you.'
    },
    requestPayrollReport: {
        id: 'policy.view.policy-details.Request Payroll Report',
        defaultMessage: 'Request Payroll Report'
    },
    payrollActivitySuccessMessage: {
        id: 'policy.view.policy-details.Payroll Activity Success Message',
        defaultMessage: 'Your request to receive your monthly payroll report form has been received and is being processed.  Please watch for it in your email inbox.'
    },
    successText: {
        id: 'policy.view.policy-details.Success Text',
        defaultMessage: 'Success'
    },
    payrollActivityFailureMessage: {
        id: 'policy.view.policy-details.Payroll Activity Failure Message',
        defaultMessage: 'Unable to complete request. Please contact our Customer Service Department at 570-963-4635, or email your request to ra-liswif-prl-rpts@pa.gov'
    },
    failureText: {
        id: 'policy.view.policy-details.Failure Text',
        defaultMessage: 'Failure'
    },
    okText: {
        id: 'policy.view.policy-details.Okay Text',
        defaultMessage: 'Ok'
    },
    CancellationReason: {
        id: 'policycommon.component.policy-common-details.Policy Cancellation Reason',
        defaultMessage: 'Cancellation Reason'
    },
    makePaymentWarningMessage: {
        id: 'billing.billing-summary.Make Payment Warning Message',
        defaultMessage: 'Making a payment does not guarantee a reinstatement of a pending or cancelled policy.'
    },
    policyPendingCancellationWarningMessage: {
        id: 'billing.billing-summary.Policy Pending Cancellation Warning Message',
        defaultMessage: 'This policy is pending cancellation.'
    },
    EmployerCommercialPremium: {
        id: 'policycommon.component.policy-common-details.Employer Commercial Premium',
        defaultMessage: 'Employer Assessment Amount (Commercial)'
    },
    EmployerCoalPremium: {
        id: 'policycommon.component.policy-common-details.Employer Coal Premium',
        defaultMessage: 'Employer Assessment Amount (Coal)'
    },
    sectionTitle: {
        id: 'policy.common.component.premium.Premium Details',
        defaultMessage: 'Premium Details'
    },
    standardPremiumTitle: {
        id: 'policy.common.component.premium.Standard Premium',
        defaultMessage: 'Standard Premium'
    },
    otherPremiumTitle: {
        id: 'policy.common.component.premium.Other Premium',
        defaultMessage: 'Other Premium and Surcharges'
    },
    premiumLoc: {
        id: 'policy.common.component.premiums.LocationId',
        defaultMessage: 'Loc.'
    },
    premiumCode: {
        id: 'policy.common.component.premiums.Code',
        defaultMessage: 'Code'
    },
    premiumDescription: {
        id: 'policy.common.component.premiums.Description',
        defaultMessage: 'Description'
    },
    premiumExposure: {
        id: 'policy.common.component.premiums.Exposure',
        defaultMessage: 'Exposure'
    },
    premiumRate: {
        id: 'policy.common.component.premiums.Rate',
        defaultMessage: 'Rate'
    },
    premiumAmount: {
        id: 'policy.common.component.premiums.Amount',
        defaultMessage: 'Amount'
    }

});
