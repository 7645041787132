import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class Wc7CoveragesService {
    static updateJurisdictionClauses(
        jobNumber,
        sessionUUID,
        coverages,
        jurisdiction,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/wc7coverages'),
            'updateJurisdictionClauses',
            [jobNumber, sessionUUID, coverages, jurisdiction],
            additionalHeaders
        );
    }
}
