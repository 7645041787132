const defaultTemplate = (eventName) => {
    try {
        return require(`./${eventName}.json`);
    } catch (error) {
        if (error.message.match('Cannot find module')) {
            return null;
        }
        throw error;
    }
};

export default defaultTemplate;
