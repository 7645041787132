import { defineMessages } from 'react-intl';

export default defineMessages({
    accountHolder: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Account Holder.Title',
        defaultMessage: 'Account Holder'
    },
    additionalInsured: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Insured.Title',
        defaultMessage: 'Additional Insured Types'
    },
    additionalInterest: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Interest.Title',
        defaultMessage: 'Additional Interest'
    },
    detail: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Title',
        defaultMessage: 'Additional Interest Details: {subtype}'
    },
    additionalInterestCertificateRequired: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Certificate Required',
        defaultMessage: 'Certificate Required'
    },
    additionalInteresContractNumber: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Contract Number',
        defaultMessage: 'Contract Number'
    },
    additionalInterestInterest: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Interest',
        defaultMessage: 'Interest'
    },
    additionalInterestType: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Additional Interest.Detail.Type',
        defaultMessage: 'Type'
    },
    namedInsured: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Named Insured.Title',
        defaultMessage: 'Named Insured'
    },
    namedInsuredIndustryCode: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Named Insured.Industry Code',
        defaultMessage: 'Industry Code'
    },
    namedInsuredIndustryCodeClassification: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Named Insured.Industry Code Classification',
        defaultMessage: 'Classification'
    },
    namedInsuredOrganizationType: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Named Insured.Organization Type',
        defaultMessage: 'Organization Type'
    },
    namedInsuredBusOpDescription: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Named Insured.Business Operation Description',
        defaultMessage: 'Business Operation Description'
    },
    driver: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Title',
        defaultMessage: 'Driver'
    },
    driverAccountAccidents: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Account.Accidents',
        defaultMessage: 'Account Level : Number of Accidents'
    },
    driverPolicyLevelNumberOfAccidents: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Policy.Accidents',
        defaultMessage: 'Policy Level : Number of Accidents'
    },
    driverAccountViolations: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Account.Violations',
        defaultMessage: 'Account Level : Number of Violations'
    },
    driverPolicyLevelNumberOfViolations: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Policy.Violations',
        defaultMessage: 'Policy Level : Number of Violations'
    },
    driverLicenseNumber: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.License Number',
        defaultMessage: 'License Number'
    },
    driverLicenseState: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.License State',
        defaultMessage: 'License State'
    },
    driverYearLicensed: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Year Licensed',
        defaultMessage: 'Year First Licensed'
    },
    driverDateCompletedTrainingClass: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Date Completed Training Class',
        defaultMessage: 'Date Completed Training Class'
    },
    driverTrainingClassType: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Training Class Type',
        defaultMessage: 'Training Class Type'
    },
    driverGoodStudent: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Good Student',
        defaultMessage: 'Good Student'
    },
    driverExcluded: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Excluded',
        defaultMessage: 'Excluded?'
    },
    driverApplicableGoodDriverDiscount: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Apply Good Driver Discount',
        defaultMessage: 'Apply Good Driver Discount?'
    },
    driverGoodDriverDiscount: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Qualifies Driver Discount',
        defaultMessage: 'Qualifies for a Good Driver Discount'
    },
    yes: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Driver.No',
        defaultMessage: 'No'
    },
    laborClient: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Title',
        defaultMessage: 'WC Labor Client'
    },
    laborClientDetail: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Detail',
        defaultMessage: 'WC Labor Client Detail: {description}'
    },
    laborClientContractEffectiveDate: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Contract Effective Date',
        defaultMessage: 'Contract Effective Date'
    },
    laborClientContractExpirationDate: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Contract Expiration Date',
        defaultMessage: 'Contract Expiration Date'
    },
    laborClientEmployees: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Employees',
        defaultMessage: 'Number of Employees'
    },
    laborClientInclusion: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Inclusion',
        defaultMessage: 'Inclusion'
    },
    laborClientLocation: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.Location',
        defaultMessage: 'Work Location'
    },
    laborClientState: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Client.State',
        defaultMessage: 'State'
    },
    laborContractor: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Title',
        defaultMessage: 'WC Labor Contractor'
    },
    laborContractorDetail: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Detail',
        defaultMessage: 'WC Labor Contractor Detail: {description}'
    },
    laborContractorContractEffectiveDate: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Contract Effective Date',
        defaultMessage: 'Contract Effective Date'
    },
    laborContractorContractExpirationDate: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Contract Expiration Date',
        defaultMessage: 'Contract Expiration Date'
    },
    laborContractorEmployees: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Employees',
        defaultMessage: 'Number of Employees'
    },
    laborContractorInclusion: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Inclusion',
        defaultMessage: 'Inclusion'
    },
    laborContractorLocation: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.Location',
        defaultMessage: 'Work Location'
    },
    laborContractorState: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Labor Contractor.State',
        defaultMessage: 'State'
    },
    ownerOfficer: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.Title',
        defaultMessage: 'Owner/Officer'
    },
    ownerOfficerClassCode: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.Class Code',
        defaultMessage: 'Class Code'
    },
    ownerOfficerInclusion: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.Inclusion',
        defaultMessage: 'Inclusion'
    },
    ownerOfficerOwnershipPercentage: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.Ownership Percentage',
        defaultMessage: 'Ownership Percentage'
    },
    ownerOfficerPolicyRelationship: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.Policy Relationship',
        defaultMessage: 'Policy Relationship'
    },
    ownerOfficerRelationshipTitle: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.Relationship Title',
        defaultMessage: 'Title/Relationship'
    },
    ownerOfficerState: {
        id: 'gateway.contacts.components.ContactDetails.Roles.Owner Officer.State',
        defaultMessage: 'State'
    },
    claimsInfoContact: {
        id: 'gateway.contacts.common.views.roles.ClaimsInfoContact',
        defaultMessage: 'Claims Info Contact'
    },
    billingContact: {
        id: 'gateway.contacts.common.views.roles.BillingContact',
        defaultMessage: 'Billing Contact'
    },
    accountingContact: {
        id: 'gateway.contacts.common.views.roles.AccountingContact',
        defaultMessage: 'Accounting Contact'
    },
    inspectionContact: {
        id: 'gateway.contacts.common.views.roles.InspectionContact',
        defaultMessage: 'Inspection Contact'
    },
    secondaryContact: {
        id: 'gateway.contacts.common.views.roles.SecondaryContact',
        defaultMessage: 'Secondary Contact'
    },
});
