import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from 'gw-portals-wizard-react';
import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { messages as commonMessages } from 'gw-platform-translations';
import { messages as changeMessages } from 'gw-capability-policychange-common-react';
import messages from './CPPolicyChangeWizard.messages';
import wizardConfig from './config/cp-wizard-config.json5';
import styles from './CPPolicyChangeWizard.module.scss';

function CPPolicyChangeWizard() {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();
    const location = useLocation();
    const { authHeader } = useAuthentication();
    const [toSkip, updateSkip] = useState(true);

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');
                return;
            }
            const {
                state: { policyNumber }
            } = location;
            let endorsementResponse = {};
            if (!policyNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push(`/contactAgent/${policyNumber}`);
                }, _.noop);
            };
            GatewayEndorsementService.loadEndorsement(policyNumber, authHeader)
                .then(async (loadResponse) => {
                    let submission = {};
                    endorsementResponse = loadResponse;
                    const resume = loadResponse.jobID != null;
                    if (!resume) {
                        endorsementResponse = await GatewayEndorsementService.saveEndorsement(
                            [loadResponse],
                            authHeader
                        );
                        updateSkip(false);
                    }
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(endorsementResponse, 'jobID'),
                        authHeader
                    );
                    submission = viewModelService.create(
                        endorsementResponse,
                        'pc',
                        'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                    );
                    setInitialSubmission(submission);
                    setIsLoading(false);
                })
                .catch(() => {
                    errorModalBox({
                        title: changeMessages.unableToLoadDraftPolicy,
                        message: changeMessages.somethingWentWrong,
                        status: 'warning',
                        icon: 'mi-error-outline',
                    });
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback(
        ({ wizardSnapshot, wizardData, param: nextPath = '' }) => {
            const skipModal = ['contactAgent'];
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const isBound = _.get(wizardData.value, 'policyChange.status') === 'Bound';
            if (isBound || skipModal.includes(lastPathName) || hasErrorOccurred) {
                return true;
            }
            return showConfirm({
                title: commonMessages.wantToCancel,
                message: commonMessages.infoWillBeSavedAsDraft,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                setIsLoading(true);
                const { jobID } = wizardData.value;
                const notQuoted = _.get(wizardData.value, 'status') !== 'Quoted';
                const redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;
                if (notQuoted) {
                    GatewayEndorsementService.saveEndorsement([wizardSnapshot.value], authHeader)
                        .then(() => {
                            return history.push(redirectLobPath);
                        })
                        .catch(() => {
                            showAlert({
                                title: changeMessages.unableToDraftPolicy,
                                message: changeMessages.anErrorOccurred,
                                status: 'warning',
                                icon: 'mi-error-outline',
                            });
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                    return true;
                }
                return history.push(redirectLobPath);
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, history, showAlert, showConfirm]
    );

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
            const jobIDFromLocation = _.get(location, 'state.policyNumber');
            let redirectPath = `/contactAgent/${jobIDFromLocation}`;
            if (!_.isEmpty(jobIDFromWizard)) {
                redirectPath = `/change/${jobIDFromWizard}/summary`;
            }
            const state = {
                pathname: redirectPath,
                state: error
            };

            return showAlert({
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);
                return <Redirect to={state} />;
            }, _.noop);
        },
        [location, showAlert]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.cpPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <DependencyProvider
                    value={{
                        LoadSaveService: GatewayEndorsementService
                    }}
                >
                    <Wizard
                        initialSteps={steps}
                        wizardTitle={title}
                        initialData={initialSubmission}
                        onCancel={handleOnCancel}
                        skipCompletedSteps={toSkip}
                        onPreviousModalProps={{
                            title: commonMessages.wantToJump,
                            message: commonMessages.wantToJumpMessage,
                            status: 'warning',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.yesModel,
                            cancelButtonText: commonMessages.cancelModel
                        }}
                    />
                </DependencyProvider>
            </ErrorBoundary>
        </div>
    );
}

export default CPPolicyChangeWizard;
