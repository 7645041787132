import { defineMessages } from 'react-intl';

export default defineMessages({
    payeezyAttentionMessage: {
        id: 'billing.billing-summary.Payeezy Attention Message',
        defaultMessage: 'ATTENTION: PRIOR TO SUBMITTING A PAYMENT, we strongly recommend that you contact your credit card issuing company. Transactions for high dollar amounts are most likely to be declined. This will keep you from repeating the online payment process.'
    },
    payeezyServiceFeeMessage: {
        id: 'billing.billing-summary.Payeezy Service Fee Message',
        defaultMessage: 'By clicking Confirm you will be charged a service fee of $20.00 for paying by credit card once the payment is processed.'
    },
    payeezyCreditCardPaymentAlternatives: {
        id: 'billing.billing-summary.Payeezy Credit Card Payment Alternatives',
        defaultMessage: 'Alternatives to paying by credit card include cashier’s checks, certified checks, money orders, business/personal checks or phone. Contact information below:'
    },
    paymentAlternativesContactInformation: {
        id: 'billing.billing-summary.Payment Alternatives Contact Information',
        defaultMessage: "State Workers' Insurance Fund  \n100 Lackawanna Ave. \nP.O. Box 5100  Scranton, PA 18505-5100 \n(570)-963-4635"
    },
    policyDisputedAmountWarningMessage: {
        id: 'billing.billing-summary.Policy Disputed Amount Warning Message',
        defaultMessage: 'This policy has {amount} in dispute as of {currentDate} which is not reflected in the invoice totals below.'
    }
});
