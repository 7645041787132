import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'gw-portals-policytransaction-pc-bop-angular.policyDetails.policyDetails.Policy Details',
        defaultMessage: 'Policy Details'
    },
    policyDetailsDescription: {
        id: 'gw-portals-policytransaction-pc-bop-angular.policyDetails.policyDetails.Policy Change Description',
        defaultMessage: 'Policy Change Description'
    },
    policyChangeEffectiveDate: {
        id: 'gw-portals-policytransaction-pc-bop-angular.policyDetails.policyDetails.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    },
    organizationType: {
        id: 'gw-portals-policytransaction-pc-bop-angular.policyDetails.policyDetails.Organization Type',
        defaultMessage: 'Organization Type'
    },
    smallBusinessType: {
        id: 'gw-portals-policytransaction-pc-bop-angular.policyDetails.policyDetails.Small Business Type',
        defaultMessage: 'Small Business Type'
    },
});
