import React, { Component } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { TimelineService } from 'gw-capability-journey';
import TimelineComponent from '../../components/TimelineComponent/TimelineComponent';
import metadata from './AccountTimeline.metadata.json5';
import styles from '../../components/TimelineComponent/css/Timeline.module.scss';

class AccountTimeline extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired
    };

    state = {
        loaded: true,
        EventsData: []
    };

    getResponse = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const responseData = await TimelineService.getTimelineForAccount(accountNumber, authHeader);
        this.setState({
            EventsData: responseData.events,
            loaded: true
        });
    };

    componentDidMount() {
        this.setState({ loaded: false });
        this.getResponse();
    }

    render() {
        const { EventsData, loaded } = this.state;
        const overrideProps = {
            accountTimeline: {
                events: EventsData,
                visible: loaded
            },
            timelineLoader: {
                loaded: loaded
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                timelinecomponent: TimelineComponent
            }
        };
        return (
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        );
    }
}

export default withAuthenticationContext(AccountTimeline);
