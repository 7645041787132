import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { LobIconUtil } from 'gw-portals-util-js';
import utils from './util/timelineUtils';
import IconChannel from './IconChannel';
import Styles from './css/TimelineEvent.module.scss';
import HeaderStyles from './css/TimelineEventHeader.module.scss';

class EventCardHeader extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        event: PropTypes.shape({
            id: PropTypes.string,
            eventName: PropTypes.string,
            productCode: PropTypes.string,
            channel: PropTypes.string,
            timestamp: PropTypes.string
        }),
        onClick: PropTypes.func.isRequired,
        isOdd: PropTypes.bool.isRequired
    };

    static defaultProps = {
        event: undefined
    };

    toggleOpen = () => {
        const { onClick } = this.props;
        onClick();
    };

    render() {
        const { isOpen, event, isOdd } = this.props;
        const productCode = utils.getProductCodeModifier(event);
        const EventHeader = utils.getHeaderEventName(event.eventName);
        const ClassHeaderPrefix = 'gwTimelineEventHeader';
        const HeaderColor = HeaderStyles[`${ClassHeaderPrefix}--${productCode}`];
        const ClassHeader = classNames(HeaderStyles.gwTimelineEventHeader, HeaderColor, {
            [HeaderStyles.gwTimelineEventHeaderOdd]: isOdd
        });
        const classLineIcon = classNames(
            HeaderStyles[`${ClassHeaderPrefix}__lineIcon`],
            HeaderStyles[`${ClassHeaderPrefix}--${productCode}__lineIcon`]
        );
        const classTitle = `${ClassHeaderPrefix}__title`;
        const classDate = `${ClassHeaderPrefix}__date`;
        const classCollapse = `${ClassHeaderPrefix}__collapse`;
        const chevronIcon = isOpen ? 'mi-keyboard-arrow-up' : 'mi-keyboard-arrow-down';
        const productIcon = LobIconUtil.getFontIcon(event.productCode);

        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <div className={Styles.gwTimelineContent}>
                        <IconChannel
                            channelName={event.channel}
                            productCode={productCode}
                            isOdd={isOdd}
                            isOpen={isOpen}
                        />
                        <button
                            id={`event${event.id}`}
                            type="button"
                            className={ClassHeader}
                            onClick={() => this.toggleOpen()}
                        >
                            <Icon icon={productIcon} className={classLineIcon} />
                            <div className={HeaderStyles[classTitle]}>
                                <h2>{translator(EventHeader)}</h2>
                                <span className={HeaderStyles[classDate]}>
                                    {moment(event.timestamp).format('M/D/YYYY')}
                                </span>
                            </div>
                            <div className={HeaderStyles[classCollapse]}>
                                <Icon icon={chevronIcon} />
                            </div>
                        </button>
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default EventCardHeader;
