import React, { useCallback } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { readViewModelValue } from 'gw-jutro-adapters-react';

import { wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';

import styles from './SuccessPage.module.scss';
import metadata from './SuccessPage.metadata.json5';
import './SuccessPage.messages';
import '../../WC7Wizard.messages';

function SuccessPage(props) {
    const { wizardData: submissionVM } = props;
    const handlePrint = () => {
        window.print();
    };

    const getAccountNumber = () => {
        const accountNumber = _.get(submissionVM, 'bindData.accountNumber', '');
        return (
            <Link
                to={`/accounts/${accountNumber.value}/summary`}
                className={styles.removeLinkStyle}
            >
                {accountNumber.value}
            </Link>
        );
    };

    const getPolicyNumber = () => {
        const policyNumber = _.get(submissionVM, 'bindData.policyNumber')
            ? _.get(submissionVM, 'bindData.policyNumber')
            : '';
        return (
            <Link to={`/policies/${policyNumber.value}/summary`} className={styles.removeLinkStyle}>
                {policyNumber.value}
            </Link>
        );
    };

    // FIXME : getting state value : check error
    // const getAccordionHeader = () => {
    //     let statesValue;
    //     const coverablesArray = 'lobData.wc7WorkersComp.coverables.primaryInsuredIds.stateIDs';
    //     const stateIdsArray = _.get(submissionVM, coverablesArray)
    //         .map((wc7QuoteDetails) => {
    //             statesValue = wc7QuoteDetails.jurisdiction;
    //             return statesValue;
    //         });
    //     return stateIdsArray;
    // };

    const getAccordionHeaderValues = (state, pathData, index) => {
        const statePrice = _.get(state, pathData);
        const accordionHeaderId = `${pathData}_${state}_${index}`;
        return (
            <CurrencyField
                id={accordionHeaderId}
                value={statePrice}
                readOnly
                hideLabel
            />
        );
    };

    const stateTableData = () => {
        const summaryByStateDataList = [];
        const stateData = _.get(submissionVM.value, 'lobData.wc7WorkersComp.coverables.states', []);

        let index = 0;
        stateData.forEach((state) => {
            index += 1;
            const stateName = {
                id: `typekey.State.${state.jurisdiction}`,
                defaultMessage: `typekey.State.${state.jurisdiction}`
            };
            const taxesData = getAccordionHeaderValues(state, 'taxesAndSurcharges', index);
            const totalCostData = getAccordionHeaderValues(state, 'totalCost', index);
            const premiumData = getAccordionHeaderValues(state, 'totalPremium', index);

            const summaryByStateData = {
                stateName,
                taxesData,
                totalCostData,
                premiumData
            };

            summaryByStateDataList.push(summaryByStateData);
        });

        const resultStateData = {
            stateHeaderTable: {
                data: summaryByStateDataList
            }
        };
        return resultStateData;
    };

    const getCell = (item, index, { id: property }) => {
        return item[property];
    };

    const findCoverages = (coverages, coverageCode) => {
        let coverage;
        coverages.some(({ terms }) => {
            coverage = terms.find((term) => term.publicID === coverageCode);
            return !!coverage;
        });
        return coverage;
    };

    const generateClauseOverrides = () => {
        const coveragesPath = 'lobData.wc7WorkersComp.clauses.coverages';
        const coveragesToRender = ['WC7EmpLiabLimit', 'WC7StopGap', 'WC7OtherStatesOpt'];
        const coverages = _.get(submissionVM.value, coveragesPath);
        const filterData = coveragesToRender.map((coverage) => findCoverages(coverages, coverage));
        return filterData;
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        accountNumber: {
            value: getAccountNumber()
        },
        policyNumber: {
            value: getPolicyNumber()
        },
        policyTotalAmount: {
            value: _.find(submissionVM.value.quoteData.offeredQuotes, {
                publicID: submissionVM.value.bindData.chosenQuote
            }).premium.total
        },
        paymentPlanName: {
            value: _.find(submissionVM.value.bindData.paymentPlans, {
                billingId: submissionVM.value.bindData.selectedPaymentPlan
            }).name
        },
        currentAmount: {
            value: _.find(submissionVM.value.bindData.paymentPlans, {
                billingId: submissionVM.value.bindData.selectedPaymentPlan
            }).downPayment
        },
        coverageTableColumnContainer: {
            data: generateClauseOverrides()
        },
        effectiveDateRange: {
            value: {
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value'),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value')
            }
        },
        ...stateTableData()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            getCell: getCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={resolvers.resolveValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

SuccessPage.propTypes = wizardProps;
export default SuccessPage;
