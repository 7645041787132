import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from '../SpreadsheetProcessorResult.module.scss';
import SectionTemplate from './SectionTemplate';
import DetailedSectionTemplate from './DetailedSectionTemplate';

function FailFullTemplate({ uploadedStatus, productCode }) {
    const messages = require(`../${productCode}Result.messages.js`).default;
    return (
        <div>
            <div className={styles.gwResultSummary}>
                <SectionTemplate
                    icon="mi-cancel"
                    message={messages.buildingsWereNotImportedCount}
                    value={uploadedStatus.resultData.rowsWithErrors}
                    type={uploadedStatus.type}
                    content={messages.fixIssuesToImportSuccessful}
                />
                {!_.isEmpty(uploadedStatus.resultData.errors) && (
                    <DetailedSectionTemplate
                        resultData={uploadedStatus.resultData.errors}
                        message={messages.affectedRowError}
                    />
                )}
            </div>
        </div>
    );
}
FailFullTemplate.propTypes = {
    uploadedStatus: PropTypes.shape({
        status: PropTypes.string.isRequired,
        resultData: PropTypes.shape({
            rowsWithErrors: PropTypes.string,
            errors: PropTypes.shape([])
        }).isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    productCode: PropTypes.string.isRequired
};
export default FailFullTemplate;
