import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Loader, withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { CancellationService } from 'gw-capability-gateway';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';
import gatewayMessages from '../../gateway.messages';
import metadata from './Summary.metadata.json5';
import messages from '../Cancellation.messages';
import styles from './Summary.module.scss';

class CancellationSummary extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string.isRequired
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadCancellationSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired
    };

    state = {
        isLoading: true
    };

    getCancellationStatus = (status) => {
        const translator = this.context;
        return translator({ id: `typekey.PolicyPeriodStatus.${status}`, defaultMessage: status });
    };

    getCancellationSummary = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber, updateJobSummary }
            }
        } = this.props;
        const cancellation = await CancellationService.findJobByJobNumber(jobNumber, authHeader);
        updateJobSummary(cancellation);
    };

    onBindCancellation = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            authHeader,
            modalContext
        } = this.props;
        modalContext.showConfirm({
            title: messages.bindCancellation,
            message: `${translator(messages.wontUndoOnceBound)} ${translator(
                messages.sureBindCancellation
            )}`,
            confirmButtonText: messages.bindCancellation,
            cancelButtonText: messages.doNotBind
        }).then((results) => {
            if (results === 'confirm') {
                CancellationService.bindCancellation(cancellationVM.jobNumber, authHeader).then(
                    () => {
                        this.getCancellationSummary();
                    },
                    () => {
                        modalContext.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedToBindCancellation,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                    }
                );
            }
        }, _.noop);
    };

    onWithdrawCancellation = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            authHeader,
            modalContext
        } = this.props;
        modalContext.showConfirm({
            title: messages.withdrawCancellation,
            message: messages.wantWithdrawCancellation,
            confirmButtonText: messages.withdrawCancellation,
            cancelButtonText: messages.doNotWithdraw
        }).then((results) => {
            if (results === 'confirm') {
                CancellationService.withdrawCancellationByCancellationNumber(
                    cancellationVM.jobNumber,
                    authHeader
                ).then(
                    () => {
                        this.getCancellationSummary();
                    },
                    () => {
                        modalContext.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedWithDrawCancellation,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                    }
                );
            }
        }, _.noop);
    };

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            authUserData
        } = this.props;
        const content = {};
        if (
            cancellationVM
            && cancellationVM.status !== this.getCancellationStatus('Draft')
            && cancellationVM.status !== this.getCancellationStatus('Canceling')
        ) {
            if (cancellationVM.status === this.getCancellationStatus('Withdrawn')) {
                content.infoMessageTitle = translator(messages.cancellationHasBeenWithDrawn);
                content.infoMessageDescription = translator(
                    messages.needToCancelStartNewCancellation
                );
            } else if (cancellationVM.status === this.getCancellationStatus('Bound')) {
                content.infoMessageTitle = translator(messages.cancellationHasBeenWithBound);
                content.infoMessageDescription = translator(
                    messages.needReinitiateContactUnderWritter
                );
            } else if (cancellationVM.isInsuredSource) {
                content.infoMessageTitle = cancellationVM.isRefund
                    ? translator(messages.cancellationAmountToBeCalculated)
                    : translator(messages.stillOwedHasBeenCalculated);
                content.infoMessageDescription = cancellationVM.canWithdraw
                && cancellationVM.canBind
                    ? translator(messages.bindActivitiesNotesDocument)
                    : translator(messages.withDrawActivitiesNotesDocument);
            } else {
                content.infoMessageTitle = translator(messages.cancellationInitiatedByInsurer);
                const isAgent = authUserData.userType === 'producer';
                content.infoMessageDescription = isAgent
                    ? translator(messages.canNotBindOrWithDrawButCanAddInfo)
                    : (content.infoMessageDescription = translator(
                        messages.canBindCancelCannotWithdraw
                    ));
            }
        }
        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            withDrawContent: translator(messages.withdrawCancellation),
            continueContent: translator(messages.bindCancellation)
        };
    };

    getCancellationToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            }
        } = this.props;

        return {
            isContinueTransaction: cancellationVM.canBind,
            isWithdrawTransaction: cancellationVM.canWithdraw
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            }
        } = this.props;

        return (
            !_.isEmpty(cancellationVM.latestPeriod)
            && cancellationVM.latestPeriod.policyNumber
            && cancellationVM.latestPeriod.policyNumber !== 'Unassigned'
        );
    };

    getRefundInfo = (cancellation) => {
        const translator = this.context;
        const labelInfo = cancellation.isRefund ? messages.refundAmount : messages.stillOwed;
        const valueInCost = cancellation.changeInCost;
        if (cancellation.isRefund && valueInCost.amount < 0) {
            valueInCost.amount *= -1;
        }
        return {
            label: translator(labelInfo),
            value: valueInCost
        };
    };

    render() {
        const translator = this.context;
        const { isLoading } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            }
        } = this.props;

        if (_.isEmpty(cancellationVM)) {
            return <Loader loaded={!isLoading} />;
        }

        const cancellationDate = _.get(cancellationVM, 'effectiveDate');
        const overrides = {
            quoteNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getCancellationToProceed()
            },
            underwritingTable: {
                data: cancellationVM.underwritingIssues
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible()
            },
            detailGridTotalColumn: {
                visible: cancellationVM.statusCode === 'Quoted'
            },
            cancellationEffectDate: {
                value: cancellationDate
            },
            reasonId: {
                value: translator({
                    id: `typekey.ReasonCode.${cancellationVM.cancelReasonCode}`,
                    defaultMessage: cancellationVM.cancelReasonCode
                })
            },
            refundMethodId: {
                value: translator({
                    id: `typekey.CalculationMethod.${cancellationVM.cancellationRefundMethod}`,
                    defaultMessage: cancellationVM.cancellationRefundMethod
                })
            },
            refundAmountDetails: this.getRefundInfo(cancellationVM)
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onContinueTransaction: this.onBindCancellation,
                onWithdrawTransaction: this.onWithdrawCancellation
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, cancellationVM, id, path, overrides);
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={cancellationVM}
                overrideProps={overrides}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default withAuthenticationContext(withModalContext(CancellationSummary));
