import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import styles from './SpreadsheetProcessorResult.module.scss';
import TemplateResource from './TemplateResource';
import CommonTemplate from './template/CommonTemplate';
import FailFullTemplate from './template/FailFullTemplate';
import PartialSuccessTemplate from './template/PartialSuccessTemplate';
import './CommercialPropertyResult.messages';
import './WC7WorkersCompResult.messages';
import metadata from './SpreadsheetProcessorResult.metadata.json5';

const getTypeIcon = (type) => {
    switch (type) {
        case 'error':
            return 'mi-remove_circle_outline';
        case 'warning':
            return 'mi-warning';
        default:
            return 'mi-info';
    }
};
const getTemplate = (uploadedStatus, template, productCode) => {
    if (
        uploadedStatus.status === 'fail-processing'
        || uploadedStatus.status === 'fail-empty'
        || uploadedStatus.status === 'fail-unchanged'
    ) {
        const errors = _.get(uploadedStatus, 'resultData.fatalErrors');
        return (
            <CommonTemplate
                template={template}
                status={uploadedStatus.status}
                error={uploadedStatus.status === 'fail-processing' ? errors : null}
            />
        );
    }
    if (uploadedStatus.status === 'fail-full') {
        return <FailFullTemplate uploadedStatus={uploadedStatus} productCode={productCode} />;
    }
    if (uploadedStatus.status === 'partial') {
        return (
            <PartialSuccessTemplate
                uploadedStatus={uploadedStatus}
                isSuccess={false}
                productCode={productCode}
            />
        );
    }
    if (uploadedStatus.status === 'success') {
        return (
            <PartialSuccessTemplate
                uploadedStatus={uploadedStatus}
                isSuccess
                productCode={productCode}
            />
        );
    }
    return null;
};

function SpreadsheetProcessorResult({ uploadedStatus, onCallback, productCode }) {
    const translator = useTranslator();
    if (!uploadedStatus) {
        return null;
    }
    const template = _.get(TemplateResource()[productCode], uploadedStatus.status);
    const spreadSheetResultClass = classnames(
        styles.spreadsheetProcessorResultBody,
        styles.gwResultAlert,
        {
            [styles.gwAlertTypeError]: uploadedStatus.type === 'error',
            [styles.gwAlertTypeInfo]: uploadedStatus.type === 'info',
            [styles.gwAlertTypeWarning]: uploadedStatus.type === 'warning'
        }
    );
    const iconClass = classnames(styles.gwResultAlertIcon, {
        [styles.gwResultAlertIconTypeError]: uploadedStatus.type === 'error',
        [styles.gwResultAlertIconTypeInfo]: uploadedStatus.type === 'info',
        [styles.gwResultAlertIconTypeWarning]: uploadedStatus.type === 'warning'
    });
    const resultContentComponent = () => {
        return (
            <div className={styles.gwResultActionMessageContent}>
                <h3 className={styles.gwResultTitlesHeading}>{translator(template.heading)}</h3>
                {getTemplate(uploadedStatus, template, productCode)}
                <Button
                    key="button"
                    icon="mi-insert_drive_file"
                    id="uploadSpreadsheet"
                    type="neutral"
                    onClick={onCallback}
                >
                    {translator(template.buttonContent)}
                </Button>
            </div>
        );
    };
    const overrideProps = {
        spreadsheetResultContainer: {
            className: spreadSheetResultClass
        },
        typeIcon: {
            icon: getTypeIcon(uploadedStatus.type),
            className: iconClass
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            resultcontentcomponent: resultContentComponent
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}
SpreadsheetProcessorResult.propTypes = {
    uploadedStatus: PropTypes.shape({
        status: PropTypes.string.isRequired,
        resultData: PropTypes.shape({
            fatalErrors: PropTypes.string
        }).isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    onCallback: PropTypes.func.isRequired,
    productCode: PropTypes.string.isRequired
};
export default SpreadsheetProcessorResult;
