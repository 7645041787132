import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './ExpModifiersIterableComponent.scss';
import metadata from './ExpModifiersIterableComponent.metadata.json5';
import messages from './ExpModifiersIterableComponent.messages';

const isExpMod = (modifier) => modifier.patternCode === 'WC7ExpMod';
function ExpModifiersIterableComponent(props) {
    const {
        data: aStateDetailsVM,
        index: stateIndex,
        onValueChange,
        editBtnHandler,
        updateModifier,
        cancelModifier,
        updatedModifiers,
        setUpdatedModifiers
    } = props;

    const translator = useTranslator();

    const generateOverrides = useMemo(() => {
        const overrides = aStateDetailsVM.modifiers.children.map((aModifierVM, modifierIndex) => {
            return {
                [`modifier${modifierIndex}`]: {
                    visible: isExpMod(aModifierVM.value)
                },
                [`expModUpdate${modifierIndex}`]: {
                    onClick: () => {
                        updateModifier(aModifierVM.value, stateIndex, modifierIndex);
                    }
                },
                [`expModCancel${modifierIndex}`]: {
                    onClick: () => {
                        cancelModifier(stateIndex, modifierIndex);
                        setUpdatedModifiers((prevList) => [
                            ...prevList,
                            aModifierVM.publicID.value
                        ]);
                    }
                },
                [`pageTitle${modifierIndex}`]: {
                    content: translator(messages.experienceModificationTitle, {
                        jurisdictionName: translator({
                            id: `typekey.Jurisdiction.${aStateDetailsVM.jurisdiction.value.code}`,
                            defaultMessage: aStateDetailsVM.jurisdiction.value.code
                        })
                    })
                },
                [`editButton${modifierIndex}`]: {
                    visible: updatedModifiers.indexOf(aModifierVM.publicID.value) !== -1,
                    onClick: () => {
                        editBtnHandler(aModifierVM.value);
                    }
                },
                [`expModActions${modifierIndex}`]: {
                    visible: updatedModifiers.indexOf(aModifierVM.publicID.value) === -1
                },
                [`expModFactor${modifierIndex}`]: {
                    readOnly: updatedModifiers.indexOf(aModifierVM.publicID.value) !== -1
                },
                [`expModFinal${modifierIndex}`]: {
                    readOnly: updatedModifiers.indexOf(aModifierVM.publicID.value) !== -1
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [
        translator,
        aStateDetailsVM,
        stateIndex,
        editBtnHandler,
        updateModifier,
        cancelModifier,
        updatedModifiers,
        setUpdatedModifiers
    ]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left'
        },
        ...generateOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={aStateDetailsVM}
            overrideProps={overrideProps}
            onModelChange={onValueChange}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ExpModifiersIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired,
    onValueChange: PropTypes.func.isRequired,
    editBtnHandler: PropTypes.func.isRequired,
    updateModifier: PropTypes.func.isRequired,
    cancelModifier: PropTypes.func.isRequired,
    updatedModifiers: PropTypes.arrayOf.isRequired,
    setUpdatedModifiers: PropTypes.func.isRequired
};

export default ExpModifiersIterableComponent;
