import { defineMessages } from 'react-intl';

export default defineMessages({
    reason: {
        id: 'gateway.directives.cancel-policy.Reason',
        defaultMessage: 'Reason'
    },
    cancellationCreatedDate: {
        id: 'gateway.views.cancellation-detail.Cancellation Created Date',
        defaultMessage: 'Cancellation Created Date'
    },
    continueCancellation: {
        id: 'gateway.views.cancellation-detail.Continue Cancellation',
        defaultMessage: 'Continue Cancellation'
    },
    refundMethod: {
        id: 'gateway.directives.cancel-policy.Refund Method',
        defaultMessage: 'Refund Method'
    },
    policy: {
        id: 'gateway.views.cancellation-detail.Policy',
        defaultMessage: 'Policy'
    },
    account: {
        id: 'gateway.views.cancellation-detail.Account',
        defaultMessage: 'Account'
    },
    customerCancellations: {
        id: 'gateway.directives.cancel-policy.Please only initiate customer requested cancellations',
        defaultMessage: 'Please initiate cancellations requested by customers only.'
    },
    customerCancellationsUnderwriter: {
        id: 'gateway.directives.cancel-policy.For all other cancellations please create an activity for your underwriter',
        defaultMessage: 'For all other cancellations, create an activity for your underwriter.'

    },
    startCancellation: {
        id: 'gateway.directives.cancel-policy.Start Cancellation',
        defaultMessage: 'Start Cancellation'
    },
    donotCancel: {
        id: 'gateway.directives.cancel-policy.Do Not Cancel',
        defaultMessage: 'Do Not Cancel'
    },
    descriptionPolicy: {
        id: 'gateway.directives.cancel-policy.Description',
        defaultMessage: 'Description'
    },
    selectAfterDate: {
        id: 'gateway.directives.cancel-policy.Please set a date that is after the start date of the policy',
        defaultMessage: 'Please set a date that is after the start date of the policy'
    },
    selectBeforeDate: {
        id: 'gateway.directives.cancel-policy.Please set a date that is before the expiry date of the policy',
        defaultMessage: 'Please set a date that is before the expiry date of the policy'
    },
    reasonPlcaeHolder: {
        id: 'gateway.directives.cancel-policy.-- Please Choose --',
        defaultMessage: '-- Please Choose --'
    }
});
