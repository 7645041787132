import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import DefinitionStyle from './css/TimelineDefinition.module.scss';
import EventTypeResolver from './resolvers/typeResolver';

const EventDefinition = (props) => {
    const { term, value, type } = props;
    const translator = useTranslator();
    const getCurrency = useCallback(() => {
        return <CurrencyField id={`currencyCost${term}`} className={DefinitionStyle.gwTimelineCurrencyStyle} value={value} readOnly hideLabel />;
    }, [term, value]);
    const getResolveValue = useCallback(() => {
        if (type) {
            if (type === 'gwCurrency') {
                return getCurrency();
            }
            return EventTypeResolver(type, value, translator);
        }
        return value;
    }, [getCurrency, type, value, translator]);

    if (!value) {
        return null;
    }
    return (
        <div className={DefinitionStyle.gwTimelineDefinition}>
            <h3 className={DefinitionStyle.gwTimelineDefinition__term}>{term}</h3>
            <div className={DefinitionStyle.gwTimelineDefinition__definition}>
                {getResolveValue()}
            </div>
        </div>
    );
};
EventDefinition.propTypes = {
    term: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    type: PropTypes.string
};

EventDefinition.defaultProps = {
    type: undefined,
    value: undefined
};

export default EventDefinition;
