/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import cx from 'classnames';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { ActivitiesService } from 'gw-capability-gateway';
import { Loader, withModalContext } from '@jutro/components';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from 'gw-platform-translations';
import _ from 'lodash';
import moment from 'moment';
import ActivityTableComponent from '../Components/Activity/ActivityTableComponent/ActivityTableComponent';
import ActivityAdvancedFilter from '../Components/Activity/AdvancedFilter/ActivityAdvancedFilter';
import messages from './Activities.messages';
import metadata from './Activities.metadata.json5';
import styles from './Activities.module.scss';
import gatewayMessages from '../gateway.messages';

const tableHeadings = {
    yourOpenActivityTile: messages.yourOpen,
    yourCompletedActivityTile: messages.yourComplete,
    createdByYouActivityTile: messages.createdByYou,
    allOpenActivityTile: messages.allOpen,
    allCompletedActivityTile: messages.allCompleted,
    allCancelledActivityTile: messages.allCanceled,
    advancedFilter: messages.activities
};
class Activities extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        activitiesResult: [],
        activitiesYourCompleteCount: 0,
        activitiesYourOpenCount: 0,
        activitiesAllOpenCount: 0,
        activitiesCreatedByYouCount: 0,
        activitiesAllCompletedCount: 0,
        activitiesCanceledCount: 0,
        activitiesComplete: [],
        activitiesFuture: [],
        activitiesOverdue: [],
        activitiesThisWeek: [],
        activitiesToday: [],
        activitiesTomorrow: [],
        yourOpenActivityTileStatus: false,
        yourCompletedActivityTileStatus: false,
        createdByYouActivityTileStatus: false,
        allOpenActivityTileStatus: false,
        allCompletedActivityTileStatus: false,
        allCancelledActivityTileStatus: false,
        displayNoActivities: false,
        toggleAdvancedFilter: true,
        currentView: 'yourOpenActivityTile',
        isLoading: true,
        publicId: ''
    };

    componentDidMount() {
        this.getActivitySummaries();
    }

    getActivitySummaries = async () => {
        const { authHeader } = this.props;
        ActivitiesService.getActivitiesForCurrentUserAndGroups(authHeader).then((response) => {
            const activities = _.map(response, (activity) => {
                return _.extend({}, activity, { expanded: false });
            });
            this.setState({
                activitiesResult: activities,
                isLoading: false
            });
            this.handleOnClick('yourOpenActivityTile');
            this.getTilesCount(activities);
        });
    };

    getTilesCount = (activitiesData) => {
        const activitiesYourCompleteCount = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.status === 'complete' && activitiesInfo.isAssignedToCurrentUser;
        });
        const activitiesYourOpenCount = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.status === 'open' && activitiesInfo.isAssignedToCurrentUser;
        });
        const activitiesCanceledCount = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.status === 'canceled';
        });
        const activitiesAllCompletedCount = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.status === 'complete';
        });
        const activitiesAllOpenCount = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.status === 'open';
        });
        const activitiesCreatedByYouCount = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.isCreatedByCurrentUser;
        });
        this.setState({
            activitiesYourCompleteCount: activitiesYourCompleteCount.length,
            activitiesYourOpenCount: activitiesYourOpenCount.length,
            activitiesAllOpenCount: activitiesAllOpenCount.length,
            activitiesCreatedByYouCount: activitiesCreatedByYouCount.length,
            activitiesAllCompletedCount: activitiesAllCompletedCount.length,
            activitiesCanceledCount: activitiesCanceledCount.length
        });
    };

    getNoOfDays = (data) => {
        const currentDate = new Date();
        const eDate = new Date(data.dueDate);
        const noOfDays = currentDate - eDate;
        const noOfDueDay = noOfDays / (1000 * 3600 * 24);
        return Math.floor(noOfDueDay);
    };

    // eslint-disable-next-line class-methods-use-this,react/no-unused-class-component-methods
    dateFormat = (date) => {
        const newdate = moment(date).toDate();
        return moment(newdate).format('M/D/YY');
    };

    createNote = async (data, publicID) => {
        const { authHeader, modalContext } = this.props;
        const { activitiesResult, currentView } = this.state;
        const activityNoteData = await ActivitiesService.createNoteForActivity(
            publicID,
            data,
            authHeader
        );
        if (!_.isUndefined(activityNoteData)) {
            modalContext.showAlert({
                title: gatewayMessages.noteCreated,
                message: gatewayMessages.noteCreatedSuccessfully,
                status: 'success',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                const activityObj = activitiesResult.find((obj) => obj.publicID === publicID);
                activityObj.notes.push(activityNoteData);
                this.handleOnClick(currentView);
            }, _.noop);
        }
    };

    onClickComplete = (data) => {
        const { activitiesResult } = this.state;
        const { authHeader } = this.props;
        const activityIndex = activitiesResult.findIndex((obj) => obj.publicID === data);
        ActivitiesService.markActivityAsCompleted(data, authHeader).then((completeActivity) => {
            activitiesResult[activityIndex].status = completeActivity.status;
            activitiesResult[activityIndex].closeDate = completeActivity.closeDate;
            activitiesResult[activityIndex].completedDate = completeActivity.completedDate;
            activitiesResult[activityIndex].canComplete = false;
            this.setState({
                publicId: data,
                activitiesResult
            });
            this.getTilesCount(activitiesResult);
        });
    };

    handleFilterClick = () => {
        const { toggleAdvancedFilter, currentView } = this.state;
        this.setState({ toggleAdvancedFilter: !toggleAdvancedFilter });
        this.handleOnClick(currentView);
    };

    handleCustomFilterClick = (activities) => {
        const activitiesData = activities;
        let activitiesOverdue = [];
        let activitiesFuture = [];
        let activitiesToday = [];
        let activitiesComplete = [];
        let activitiesThisWeek = [];
        let activitiesTomorrow = [];
        activitiesFuture = this.activitiesFutureAdvanceData(activitiesData);
        activitiesOverdue = this.activitiesAdvanceOverdueData(activitiesData);
        activitiesToday = this.activitiesTodayAdvanceData(activitiesData);
        activitiesTomorrow = this.activitiesTomorrowAdvanceData(activitiesData);
        activitiesComplete = this.activitiesCompleteData(activitiesData);
        activitiesThisWeek = this.activitiesThisWeekAdvanceData(activitiesData);
        if (
            activitiesOverdue.length === 0
            && activitiesToday.length === 0
            && activitiesFuture.length === 0
            && activitiesComplete.length === 0
            && activitiesTomorrow.length === 0
            && activitiesThisWeek.length === 0
        ) {
            this.setState({
                displayNoActivities: true,
                activitiesOverdue: activitiesOverdue,
                activitiesToday: activitiesToday,
                activitiesFuture: activitiesFuture,
                activitiesComplete: activitiesComplete,
                activitiesThisWeek: activitiesThisWeek,
                activitiesTomorrow: activitiesTomorrow
            });
        } else {
            this.setState({
                activitiesOverdue: _.sortBy(activitiesOverdue, 'dueDate'),
                activitiesToday: _.sortBy(activitiesToday, 'dueDate'),
                activitiesFuture: _.sortBy(activitiesFuture, 'dueDate'),
                activitiesComplete: _.sortBy(activitiesComplete, 'dueDate'),
                activitiesThisWeek: _.sortBy(activitiesThisWeek, 'dueDate'),
                activitiesTomorrow: _.sortBy(activitiesTomorrow, 'dueDate'),
                displayNoActivities: false
            });
        }
    };

    activitiesCompleteData = (data) => data.filter((activitiesInfo) => {
        return activitiesInfo.status === 'complete';
    });

    activitiesThisWeekAdvanceData = (data) => data.filter((activitiesInfo) => {
        const today = moment();
        const tomorrow = moment().add(1, 'days');
        return (
            !moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
                && !moment(activitiesInfo.dueDate).isSame(today, 'd')
                && activitiesInfo.status !== 'complete'
                && (this.getNoOfDays(activitiesInfo) > 0
                    ? false
                    : this.getNoOfDays(activitiesInfo) * -1
                      < moment(activitiesInfo.dueDate).isoWeekday())
        );
    });

    activitiesTomorrowAdvanceData = (data) => data.filter((activitiesInfo) => {
        const tomorrow = moment().add(1, 'days');
        return (
            activitiesInfo.status !== 'complete'
                && moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
        );
    });

    activitiesTodayAdvanceData = (data) => data.filter((activitiesInfo) => {
        const today = moment();
        return (
            activitiesInfo.status !== 'complete'
                && moment(activitiesInfo.dueDate).isSame(today, 'd')
        );
    });

    activitiesAdvanceOverdueData = (data) => data.filter((activitiesInfo) => {
        return activitiesInfo.status !== 'complete' && this.getNoOfDays(activitiesInfo) > 0;
    });

    activitiesFutureAdvanceData = (data) => data.filter((activitiesInfo) => {
        return (
            activitiesInfo.status !== 'complete'
                && !moment(activitiesInfo.dueDate).isBefore(moment().endOf('isoWeek'), 'd')
        );
    });

    activitiesTodayData = (data, status) => data.filter((activitiesInfo) => {
        const today = moment();
        return (
            activitiesInfo.status === status
                && moment(activitiesInfo.dueDate).isSame(today, 'd')
        );
    });

    activitiesFutureData = (data, status) => data.filter((activitiesInfo) => {
        return (
            activitiesInfo.status === status
                && !moment(activitiesInfo.dueDate).isBefore(moment().endOf('isoWeek'), 'd')
        );
    });

    activitiesOverdueData = (data, status) => data.filter((activitiesInfo) => {
        return activitiesInfo.status === status && this.getNoOfDays(activitiesInfo) > 0;
    });

    activitiesThisWeekData = (data, status) => data.filter((activitiesInfo) => {
        const today = moment();
        const tomorrow = moment().add(1, 'days');
        return (
            !moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
                && !moment(activitiesInfo.dueDate).isSame(today, 'd')
                && activitiesInfo.status === status
                && (this.getNoOfDays(activitiesInfo) > 0
                    ? false
                    : this.getNoOfDays(activitiesInfo) * -1
                      < moment(activitiesInfo.dueDate).isoWeekday())
        );
    });

    activitiesTomorrowData = (data, status) => data.filter((activitiesInfo) => {
        const tomorrow = moment().add(1, 'days');
        return (
            activitiesInfo.status === status
                && moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
        );
    });

    handleOnClick = (id) => {
        const { activitiesResult: activitiesResponse } = this.state;
        let activitiesOverdue = [];
        let activitiesFuture = [];
        let activitiesToday = [];
        let activitiesComplete = [];
        let activitiesThisWeek = [];
        let activitiesTomorrow = [];
        let yourOpenActivityTile = false;
        let yourCompletedActivityTile = false;
        let createdByYouActivityTile = false;
        let allOpenActivityTile = false;
        let allCompletedActivityTile = false;
        let allCancelledActivityTile = false;
        const activityUndefinedOverdue = [];
        const activitiesData = [];
        let activityOverdue = [];
        activitiesResponse.forEach((activitiesInfo) => {
            if (_.isUndefined(activitiesInfo.dueDate) && activitiesInfo.status !== 'complete') {
                activityUndefinedOverdue.push(activitiesInfo);
            } else {
                activitiesData.push(activitiesInfo);
            }
        });
        const isAssignedToCurrentUserData = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.isAssignedToCurrentUser;
        });
        const isCreatedByCurrentUserData = activitiesData.filter((activitiesInfo) => {
            return activitiesInfo.isCreatedByCurrentUser;
        });
        switch (id) {
            case 'yourOpenActivityTile':
                activitiesFuture = this.activitiesFutureData(isAssignedToCurrentUserData, 'open');
                activitiesOverdue = this.activitiesOverdueData(isAssignedToCurrentUserData, 'open');
                activitiesToday = this.activitiesTodayData(isAssignedToCurrentUserData, 'open');
                activitiesThisWeek = this.activitiesThisWeekData(
                    isAssignedToCurrentUserData,
                    'open'
                );
                activitiesTomorrow = this.activitiesTomorrowData(
                    isAssignedToCurrentUserData,
                    'open'
                );
                activityOverdue = activityUndefinedOverdue.filter((activitiesInfo) => {
                    return (
                        activitiesInfo.isAssignedToCurrentUser && activitiesInfo.status === 'open'
                    );
                });
                yourOpenActivityTile = true;
                break;
            case 'yourCompletedActivityTile':
                activitiesComplete = this.activitiesCompleteData(isAssignedToCurrentUserData);
                yourCompletedActivityTile = true;
                break;
            case 'createdByYouActivityTile':
                activitiesFuture = this.activitiesFutureAdvanceData(isCreatedByCurrentUserData);
                activitiesOverdue = this.activitiesAdvanceOverdueData(isCreatedByCurrentUserData);
                activitiesToday = this.activitiesTodayAdvanceData(isCreatedByCurrentUserData);
                activitiesTomorrow = this.activitiesTomorrowAdvanceData(isCreatedByCurrentUserData);
                activitiesComplete = this.activitiesCompleteData(isCreatedByCurrentUserData);
                activitiesThisWeek = this.activitiesThisWeekAdvanceData(isCreatedByCurrentUserData);
                activityOverdue = activityUndefinedOverdue.filter((activitiesInfo) => {
                    return activitiesInfo.isCreatedByCurrentUser;
                });
                createdByYouActivityTile = true;
                break;
            case 'allOpenActivityTile':
                activitiesFuture = this.activitiesFutureData(activitiesData, 'open');
                activitiesOverdue = this.activitiesOverdueData(activitiesData, 'open');
                activitiesToday = this.activitiesTodayData(activitiesData, 'open');
                activitiesThisWeek = this.activitiesThisWeekData(activitiesData, 'open');
                activitiesTomorrow = this.activitiesTomorrowData(activitiesData, 'open');
                activityOverdue = activityUndefinedOverdue.filter((activitiesInfo) => {
                    return activitiesInfo.status === 'open';
                });
                allOpenActivityTile = true;
                break;
            case 'allCompletedActivityTile':
                activitiesComplete = this.activitiesCompleteData(activitiesData);
                allCompletedActivityTile = true;
                break;
            case 'allCancelledActivityTile':
                activitiesFuture = this.activitiesFutureData(activitiesData, 'canceled');
                activitiesOverdue = this.activitiesOverdueData(activitiesData, 'canceled');
                activitiesToday = this.activitiesTodayData(activitiesData, 'canceled');
                activitiesTomorrow = this.activitiesTomorrowData(activitiesData, 'canceled');
                activitiesThisWeek = this.activitiesThisWeekData(activitiesData, 'canceled');
                activityOverdue = activityUndefinedOverdue.filter((activitiesInfo) => {
                    return activitiesInfo.status === 'canceled';
                });
                allCancelledActivityTile = true;
                break;
            default:
                break;
        }
        activitiesOverdue = activityOverdue.concat(_.sortBy(activitiesOverdue, 'dueDate'));
        if (
            activitiesOverdue.length === 0
            && activitiesToday.length === 0
            && activitiesFuture.length === 0
            && activitiesComplete.length === 0
            && activitiesTomorrow.length === 0
        ) {
            this.setState({
                displayNoActivities: true,
                activitiesOverdue: activitiesOverdue,
                activitiesToday: activitiesToday,
                activitiesFuture: activitiesFuture,
                activitiesComplete: activitiesComplete,
                activitiesThisWeek: activitiesThisWeek,
                activitiesTomorrow: activitiesTomorrow,
                yourOpenActivityTileStatus: yourOpenActivityTile,
                yourCompletedActivityTileStatus: yourCompletedActivityTile,
                createdByYouActivityTileStatus: createdByYouActivityTile,
                allOpenActivityTileStatus: allOpenActivityTile,
                allCompletedActivityTileStatus: allCompletedActivityTile,
                allCancelledActivityTileStatus: allCancelledActivityTile
            });
        } else {
            this.setState({
                activitiesOverdue: activitiesOverdue,
                activitiesToday: _.sortBy(activitiesToday, 'dueDate'),
                activitiesFuture: _.sortBy(activitiesFuture, 'dueDate'),
                activitiesComplete: _.sortBy(activitiesComplete, 'dueDate'),
                activitiesThisWeek: _.sortBy(activitiesThisWeek, 'dueDate'),
                activitiesTomorrow: _.sortBy(activitiesTomorrow, 'dueDate'),
                yourOpenActivityTileStatus: yourOpenActivityTile,
                yourCompletedActivityTileStatus: yourCompletedActivityTile,
                createdByYouActivityTileStatus: createdByYouActivityTile,
                allOpenActivityTileStatus: allOpenActivityTile,
                allCompletedActivityTileStatus: allCompletedActivityTile,
                allCancelledActivityTileStatus: allCancelledActivityTile,
                displayNoActivities: false
            });
        }
        this.setState({ currentView: id });
    };

    getSelectedTitle = () => {
        const { toggleAdvancedFilter, currentView } = this.state;
        return toggleAdvancedFilter ? tableHeadings[currentView] : messages.activities;
    };

    render() {
        const translator = this.context;
        const {
            yourOpenActivityTileStatus,
            yourCompletedActivityTileStatus,
            createdByYouActivityTileStatus,
            allOpenActivityTileStatus,
            allCompletedActivityTileStatus,
            allCancelledActivityTileStatus,
            activitiesOverdue,
            activitiesToday,
            activitiesFuture,
            activitiesComplete,
            displayNoActivities,
            activitiesThisWeek,
            activitiesTomorrow,
            activitiesYourOpenCount,
            activitiesCanceledCount,
            activitiesAllOpenCount,
            activitiesAllCompletedCount,
            activitiesCreatedByYouCount,
            activitiesYourCompleteCount,
            toggleAdvancedFilter,
            currentView,
            isLoading,
            activitiesResult,
            publicId
        } = this.state;

        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        const overrides = {
            yourOpenActivityTile: {
                value: activitiesYourOpenCount,
                active: yourOpenActivityTileStatus
            },
            yourCompletedActivityTile: {
                value: activitiesYourCompleteCount,
                active: yourCompletedActivityTileStatus
            },
            createdByYouActivityTile: {
                value: activitiesCreatedByYouCount,
                active: createdByYouActivityTileStatus
            },
            allOpenActivityTile: {
                value: activitiesAllOpenCount,
                active: allOpenActivityTileStatus
            },
            allCompletedActivityTile: {
                value: activitiesAllCompletedCount,
                active: allCompletedActivityTileStatus
            },
            allCancelledActivityTile: {
                value: activitiesCanceledCount,
                active: allCancelledActivityTileStatus
            },
            noActivitySeparator: {
                visible: displayNoActivities !== false
            },
            selectedPoliciyTitle: {
                content: translator(this.getSelectedTitle())
            },
            advancedFilter: {
                activities: activitiesResult,
                selectedTile: currentView,
                visible: !toggleAdvancedFilter
            },
            advanceFilterLink: {
                visible: toggleAdvancedFilter
            },
            quickFilterLink: {
                visible: !toggleAdvancedFilter
            },
            quickFilterContainer: {
                visible: toggleAdvancedFilter
            },
            activitiesTableWrapper: {
                className: cx(styles.gwActivitiesTableWrapper, {
                    [styles.gwAdvancedFilterTableContainer]: toggleAdvancedFilter,
                    [styles.gwQuickFilterTableContainer]: !toggleAdvancedFilter
                })
            },
            activitiesTableContainer: {
                activitiesOverdue: activitiesOverdue,
                activitiesToday: activitiesToday,
                activitiesTomorrow: activitiesTomorrow,
                activitiesThisWeek: activitiesThisWeek,
                activitiesFuture: activitiesFuture,
                activitiesComplete: activitiesComplete,
                displayNoActivities: displayNoActivities,
                createNote: this.createNote,
                onClickComplete: this.onClickComplete,
                publicId: publicId
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                advancedfilter: ActivityAdvancedFilter,
                activitytablecomponent: ActivityTableComponent
            },
            resolveCallbackMap: {
                handleOnClick: this.handleOnClick,
                handleFilterClick: this.handleFilterClick,
                handleCustomFilterClick: this.handleCustomFilterClick
            }
        };
        return (
            <div className={styles.activities}>
                <MetadataContent
                    uiProps={metadata.pageContent}
                    overrideProps={overrides}
                    {...resolvers}
                />
            </div>
        );
    }
}

export const ActivitiesComponent = withModalContext(Activities);
export default withAuthenticationContext(withModalContext(Activities));
