import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'gateway.views.renewal.renewal-activities.Activities',
        defaultMessage: 'Activities'
    },
    activity: {
        id: 'gateway.views.renewal.renewal-activities.Activity',
        defaultMessage: 'Activity'
    },
    searchactivities: {
        id: 'gateway.views.renewal.renewal-activities.Search activities',
        defaultMessage: 'Search activities'
    },
    note: {
        id: 'gateway.views.renewal.renewal-notes.Note',
        defaultMessage: 'Note'
    },
    billingPageLink: {
        id: 'gateway.views.renewal.renewal-summary.If you need to make a payment, please goto...',
        defaultMessage: 'If you need to make a payment, please go to the {billingPageLink}. If you would like to cancel the renewal please go to the %%policyLink%%.'
    },
    policyStartedSuccessfully: {
        id: 'gateway.views.renewal.renewal-summary.The renewal for this Policy has been successfully started.',
        defaultMessage: 'The renewal for {lob} Policy {policyNumber} has been successfully started.'
    },
    mayWithdrawRenewal: {
        id: 'gateway.views.renewal.renewal-summary.You may withdraw the renewal',
        defaultMessage: 'You may withdraw the renewal'
    },
    cancelRenewal: {
        id: 'gateway.views.renewal.renewal-summary.Cancel Renewal',
        defaultMessage: 'Cancel Renewal'
    },
    editRenewal: {
        id: 'gateway.views.renewal.renewal-summary.Edit Renewal',
        defaultMessage: 'Edit Renewal'
    },
    premiumRenewalCalculated: {
        id: 'gateway.views.renewal.renewal-summary.The premium for this renewal has been calculated',
        defaultMessage: 'The premium for this renewal has been calculated'
    },
    thisRenewalHasBeenBound: {
        id: 'gateway.views.renewal.renewal-summary.This renewal has been bound',
        defaultMessage: 'This renewal has been bound'
    },
    newPremium: {
        id: 'gateway.views.renewal.renewal-summary.New Premium',
        defaultMessage: 'New Premium'
    },
    transactionSummary: {
        id: 'gateway.views.renewal.renewal-summary.Transaction Summary',
        defaultMessage: 'Transaction Summary'
    },
    increaseOf: {
        id: 'gateway.views.renewal.renewal-summary.Increase of',
        defaultMessage: 'Increase of {value}%'
    },
    totalPremium: {
        id: 'gateway.views.renewal.renewal-summary.Total Premium',
        defaultMessage: 'Total Premium'
    },
    currentPremium: {
        id: 'gateway.views.renewal.renewal-summary.Current Premium',
        defaultMessage: 'Current Premium'
    },
    changeinCost: {
        id: 'gateway.views.renewal.renewal-summary.Change in Cost',
        defaultMessage: 'Change in Cost'
    },
    tax: {
        id: 'gateway.views.renewal.renewal-summary.Tax',
        defaultMessage: 'Tax'
    },
    totalPremiumTaxesandFeesTax: {
        id: 'gateway.views.renewal.renewal-summary.Total Premium + Taxes and Fees(Tax)',
        defaultMessage: '%%totalPremium%% + %%taxesAndFees%% (Tax)'
    },
    createdDate: {
        id: 'gateway.views.renewal.renewal-summary.Created Date',
        defaultMessage: 'Date Created'
    },
    policyInception: {
        id: 'gateway.views.renewal.renewal-summary.Policy Inception',
        defaultMessage: 'Policy Inception'
    },
    status: {
        id: 'gateway.views.renewal.renewal-summary.Status',
        defaultMessage: 'Status'
    },
    policy: {
        id: 'gateway.views.renewal.renewal-summary.Policy',
        defaultMessage: 'Policy'
    },
    renewalEffectiveDate: {
        id: 'gateway.views.renewal.renewal-summary.Renewal Effective Date',
        defaultMessage: 'Renewal Effective Date'
    },
    currentPolicyExpiration: {
        id: 'gateway.views.renewal.renewal-summary.Current Policy Expiration',
        defaultMessage: 'Current Policy Expiration'
    },
    currentPolicy: {
        id: 'gateway.views.renewal.renewal-summary.Current Policy',
        defaultMessage: 'Current Policy'
    },
    policybillingpage: {
        id: 'gateway.views.renewal.renewal-summary.policy billing page',
        defaultMessage: 'policy billing page'
    },
    policydetailspage: {
        id: 'gateway.views.renewal.renewal-summary.policy details page',
        defaultMessage: 'policy details page'
    },
    clickEditRenewal: {
        id: 'gateway.views.renewal.renewal-summary.If you need to make a change, click Edit Renewal.',
        defaultMessage: 'If you need to make a change, click Edit Renewal.'
    },
    selectReasonForCancellation: {
        id: 'gateway.views.renewal.renewal-summary.To cancel the renewal, click Cancel Renewal and select a reason for the cancellation.',
        defaultMessage: 'To cancel the renewal, click Cancel Renewal and select a reason for the cancellation.'
    },
    withdrawRenewalTransaction: {
        id: 'gateway.views.renewal.renewal-summary.If you need to withdraw this renewal transaction, click Withdraw Renewal.',
        defaultMessage: 'If you need to withdraw this renewal transaction, click Withdraw Renewal.'
    },
    withdrawRenewal: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Withdraw Renewal',
        defaultMessage: 'Withdraw Renewal'
    },
    renewalNonRendering: {
        id: 'gateway.views.renewal.renewal-summary.This renewal is non-renewing.',
        defaultMessage: 'This renewal is non-renewing.'
    },
    renewalPolicyQuoted: {
        id: 'gateway.views.renewal.renewal-summary.The renewal for this policy is quoted.',
        defaultMessage: 'The renewal for {lob} policy {policyNumber} is quoted. It will be bound automatically on {effectiveDate}.'
    },
    policyIsBound: {
        id: 'gateway.views.renewal.renewal-summary.The renewal for this policy is now bound.',
        defaultMessage: 'The renewal for {lob} policy {policyNumber} is now bound.'
    },
    renewalNotTaken: {
        id: 'gateway.views.renewal.renewal-summary.This renewal has not been taken.',
        defaultMessage: 'This renewal has not been taken.'
    },
    renewalWithdrawn: {
        id: 'gateway.views.renewal.renewal-summary.This renewal has been withdrawn.',
        defaultMessage: 'This renewal has been withdrawn.'
    },
    doNotCancel: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Do Not Cancel',
        defaultMessage: 'Do Not Cancel'
    },
    decidedToAllowRenewPolicy: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.It has been decided not to allow renewal of this policy.',
        defaultMessage: 'It has been decided not to allow renewal of this policy. The policy will expire on the current expiration date.'
    },
    insurerRequest: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Insurer Request',
        defaultMessage: 'Insurer Request'
    },
    decidedToDeclineRenewPolicy: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.The customer has decided to decline the renewal of the policy.',
        defaultMessage: 'The customer has decided to decline the renewal of the policy.'
    },
    customerRequest: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Customer Request',
        defaultMessage: 'Customer Request'
    },
    sureWithdrawRenew: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Are you sure you want to withdraw the Renewal?',
        defaultMessage: 'Are you sure you want to withdraw the Renewal?'
    },
    withdrawTheRenewal: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Withdraw the Renewal?',
        defaultMessage: 'Withdraw the Renewal?'
    },
    reasonForCancellingRenew: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Reason for Cancelling the Renewal',
        defaultMessage: 'Reason for Cancelling the Renewal'
    },
    cancelCtrlRenewal: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Cancel Renewal',
        defaultMessage: 'Cancel Renewal'
    },
    doNotWithdraw: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Do Not Withdraw',
        defaultMessage: 'Do Not Withdraw'
    },
    withdrawCtrlRenewal: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Withdraw Renewal',
        defaultMessage: 'Withdraw Renewal'
    },
    startedByMistakeNoProblem: {
        id: 'gateway.controllers.renewal.RenewalDetailCtrl.Was this renewal started by mistake? No problem...',
        defaultMessage: 'Was this renewal started by mistake? No problem, you can always withdraw the renewal transaction.'
    },
    totalPremiumAndTaxes: {
        id: 'gateway.views.renewal.renewal-summary.Total Premium + Taxes and Fees (Tax)',
        defaultMessage: '{totalPremium} + {taxesAndFees} (Tax)'
    },
    failedToRenewal: {
        id: 'gateway.views.renewal.renewal-summary.Failed to cancel renewal.',
        defaultMessage: 'Failed to cancel renewal.'
    }
});
