import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class CommissionService {
    static getStatements(producerCode, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaycommissionCommission'),
            'getStatements',
            [producerCode],
            additionalHeaders
        );
    }

    static getStatementPolicies(statement, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaycommissionCommission'),
            'getStatementPolicies',
            [statement],
            additionalHeaders
        );
    }
}
