import { defineMessages } from 'react-intl';

export default defineMessages({
    ifAny: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.If Any',
        defaultMessage: 'If Any'
    },
    noOfEmployee: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Number of Employees',
        defaultMessage: 'Number of Employees'
    },
    annualWages: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Annual Wages',
        defaultMessage: 'Annual Wages'
    },
    employeesClasses: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Employees Classes',
        defaultMessage: 'Employees\' Classes'
    },
    cancelButton: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Cancel',
        defaultMessage: 'Cancel'
    },
    addButton: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Add',
        defaultMessage: 'Add'
    },
    addMoreButton: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Add More',
        defaultMessage: 'Add More'
    },
    removeLocationMessage: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Are you sure you want to remove this employee class from the list?',
        defaultMessage: 'Are you sure you want to remove this employee class from the list?'
    },
    removeEmployeeClasses: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Remove Employee Class?',
        defaultMessage: 'Remove Employee Class?'
    },
    removeEmployeeClassYes: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.Yes',
        defaultMessage: 'Yes'
    },
    removeEmployeeClassNo: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.No',
        defaultMessage: 'No'
    },
    removeEmployeeClass: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.There was an error removing this employee class',
        defaultMessage: 'There was an error removing this employee class.'
    },
    updateEmployeeClass: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.There was an error updating this employee class',
        defaultMessage: 'There was an error updating this employee class.'
    },
    addEmployeeAndLocationClass: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.There was an error adding this location and employee class',
        defaultMessage: 'There was an error adding this location and employee class.'
    },
    removeEmployeeAndLocationClass: {
        id: 'quoteandbind.wc7.views.employees-and-locations.manually-add.There was an error removing this location and employee',
        defaultMessage: 'There was an error removing this location and employee.'
    }
});
