import React, {
    Fragment, useState, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@jutro/locale';
import styles from '../SpreadsheetProcessorResult.module.scss';
import BreadCrumbTemplate from './BreadCrumbTemplate';

function DetailedSectionTemplate({ resultData, message }) {
    const dataPageSize = 20;
    const errors = resultData;
    const showErrorPageLinks = errors.length >= dataPageSize;
    const [errorDataPageSize, setErrorDataPageSize] = useState(dataPageSize);
    const [showLessErrors, setShowLessErrors] = useState(true);

    useEffect(() => {
        const pageSize = showLessErrors ? dataPageSize : errors.length;
        setErrorDataPageSize(pageSize);
    }, [errors.length, showLessErrors]);

    const callBackShowData = useCallback(() => {
        setShowLessErrors((showLess) => !showLess);
    }, [setShowLessErrors]);

    return (
        <Fragment>
            {errors.map((error, index) => {
                return (
                    index + 1 <= errorDataPageSize && (
                        <div className={styles.section}>
                            <div className={styles.report}>
                                <div className={styles.gridResultWrapper}>
                                    <div className={styles.errors}>
                                        <FormattedMessage
                                            className={styles.errorDescriptor}
                                            {...message}
                                            values={{ count: <strong>{error.rowNumber}</strong> }}
                                        />
                                    </div>
                                    <div className={styles.errorDescription}>
                                        {error.issues
                                            && error.issues.map((issue) => {
                                                return <div>{issue}</div>;
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                );
            })}
            {showErrorPageLinks && (
                <BreadCrumbTemplate
                    showLessErrorsLink={showLessErrors}
                    callBackShowData={callBackShowData}
                />
            )}
        </Fragment>
    );
}
DetailedSectionTemplate.propTypes = {
    resultData: PropTypes.shape({
        rowsWithErrors: PropTypes.string,
        errors: PropTypes.shape([])
    }).isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired
};
export default DetailedSectionTemplate;
