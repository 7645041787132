import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import messages from './timeline.messages';

const EventDefinitionHeader = (props) => {
    const { event } = props;
    const translator = useTranslator();
    let Header;
    if (event.productCode === 'PersonalAuto') {
        Header = messages.timelineDetailsAutoPolicy;
    }
    if (event.productCode === 'Homeowners') {
        Header = messages.timelineDetailsHomePolicy;
    }
    if (event.productCode !== 'Homeowners' && event.productCode !== 'PersonalAuto') {
        Header = messages.timelineDetailsPolicy;
    }
    return translator(Header, { policyNumber: event.policyNumber });
};

EventDefinitionHeader.propTypes = {
    event: PropTypes.shape({}).isRequired
};

export default EventDefinitionHeader;
