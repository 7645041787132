import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { withValidation, validationPropTypes, validationDefaultProps } from 'gw-portals-validation-react';
import metadata from './EditAccountDetailsPopUp.metadata.json5';
import styles from './EditAccountDetailsPopUp.module.scss';

const EditAccountDetailsPopUp = (props) => {
    const {
        accountData,
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        isComponentValid,
        setComponentValidation,
        viewModelService
    } = props;
    const [accountDetailsVM, setAccountDetailsVM] = useState(null);
    const [primaryPhone, setPrimaryPhone] = useState('cellNumber');
    const writeValue = useCallback((value, path) => {
        if (accountDetailsVM) {
            const model = viewModelService.clone(accountDetailsVM);
            _.set(model, path, value);
            setAccountDetailsVM(model);
        }
    }, [accountDetailsVM, viewModelService]);

    const handleSave = useCallback(() => {
        const requestObject = {
            accountHolder: accountDetailsVM.value,
            accountNumber: accountData.accountNumber
        };
        onResolve(requestObject);
    }, [onResolve, accountData, accountDetailsVM]);

    useEffect(() => {
        const accountHolderVM = viewModelService.create(
            accountData.accountHolder,
            'pc',
            'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO'
        );
        let primaryPhoneType = '';
        switch (_.get(accountHolderVM, 'primaryPhoneType.value.code')) {
            case 'work':
                primaryPhoneType = 'workNumber';
                break;
            case 'home':
                primaryPhoneType = 'homeNumber';
                break;
            case 'mobile':
                primaryPhoneType = 'cellNumber';
                break;
            default:
                primaryPhoneType = primaryPhone;
        }

        if (accountHolderVM.subtype.value === 'Company' && primaryPhoneType === 'cellNumber') {
            primaryPhoneType = 'homeNumber';
        }
        setAccountDetailsVM(accountHolderVM);
        setPrimaryPhone(primaryPhoneType);
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        '@field': {
            showOptional: true
        },
        homePhone: {
            path: primaryPhone
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.editContactDetailsModal}>
            <ModalHeader
                title={title}
                onClose={onReject}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountDetailsVM}
                    overrideProps={overrideProps}
                    classNameMap={styles}
                    onValueChange={writeValue}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave} disabled={!isComponentValid}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

EditAccountDetailsPopUp.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired,
        clone: PropTypes.func.isRequired,
    }).isRequired,
    accountData: PropTypes.shape({
        accountNumber: PropTypes.string.isRequired,
        accountHolder: PropTypes.shape({
            primaryAddress: PropTypes.shape({}).isRequired,
            emailAddress1: PropTypes.shape({}).isRequired,
            subtype: PropTypes.shape({}).isRequired,
            cellNumber: PropTypes.string.isRequired,
            homeNumber: PropTypes.string.isRequired,
            workNumber: PropTypes.string.isRequired,
            primaryPhoneType: PropTypes.string
        }).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.shape({}).isRequired,
    cancelBtnLabel: PropTypes.shape({}).isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    ...validationPropTypes,
    ...validationDefaultProps
};

export default withValidation(EditAccountDetailsPopUp);
