import { values, flow } from 'lodash';
import EventTemplate from '../event-template/index';

function eventComparator({ timestamp: timestampA }, { timestamp: timestampB }) {
    return +new Date(timestampB) - +new Date(timestampA);
}

const aggregateCoverageEvents = (originalEventName, aggregatedEventName) => (events) => {
    const { res, coverageEvents } = events.reduce((memoObject, eventObject) => {
        const memo = memoObject;
        const event = eventObject;
        if (event.eventName !== originalEventName) {
            memo.res.push(event);
            return memo;
        }
        const { coverage, jobId } = event;
        const { covTerms } = event;

        if (!memo.coverageEvents[jobId]) {
            delete event.covTerms;
            delete event.coverage;
            event.eventName = aggregatedEventName;
            event.coverages = [{ coverage, covTerms }];
            memo.coverageEvents[jobId] = event;
            return memo;
        }
        memo.coverageEvents[jobId].coverages.push({ coverage, covTerms });
        return memo;
    }, { res: [], coverageEvents: {} });
    return res.concat(values(coverageEvents));
};

const isEventAllowed = ({ eventName }) => {
    if (EventTemplate(eventName)) {
        return true;
    }
    return false;
};

const transformEvents = (events) => {
    if (!Array.isArray(events)) {
        throw new Error('Response should contain an events array');
    }

    const aggregate = flow(
        aggregateCoverageEvents('policyChange-addedCoverage', 'policyChange-addedCoverages'),
        aggregateCoverageEvents('policyChange-removedCoverage', 'policyChange-removedCoverages'),
        aggregateCoverageEvents('policyChange-changedCoverage', 'policyChange-changedCoverages')
    );
    return aggregate(events)
        .sort(eventComparator)
        .filter(isEventAllowed);
};

export default transformEvents;
