import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withProducerContext } from 'gw-gateway-common-react';
import styles from './AdvancedFilter.module.scss';
import ProductFilterCard from './ProductFilterCard';
import CategoryFilterCard from './CategoryFilterCard';
import PolicyLandingUtil from './PolicyLandingUtil';

const policiesID = ['recentlyViewedTile', 'recentlyIssuedTile', 'delinquentTile'];
class AdvancedFilter extends Component {
    static propTypes = {
        producerCode: PropTypes.string,
        policies: PropTypes.shape([]).isRequired,
        accounts: PropTypes.shape([]).isRequired,
        onValueChange: PropTypes.func.isRequired,
        clickedTile: PropTypes.func.isRequired
    };

    static defaultProps = {
        producerCode: undefined
    };

    util = null;

    state = {
        selectedCategory: undefined
    };

    componentDidMount() {
        const {
            policies, accounts, clickedTile
        } = this.props;
        this.util = new PolicyLandingUtil();
        this.util.initJobs(accounts);
        const [customFilters, policyLineFilter] = this.util.getPolicySummaries(
            policies,
            clickedTile
        );
        let getDisplayValueForAdvFilter;
        const checkPolicyIDAvailability = policiesID.includes(clickedTile);
        if (checkPolicyIDAvailability) {
            getDisplayValueForAdvFilter = customFilters[0].displayValue;
        } else if (clickedTile === 'quotes') {
            getDisplayValueForAdvFilter = customFilters[1].displayValue;
        } else if (clickedTile === 'renewal') {
            getDisplayValueForAdvFilter = customFilters[2].displayValue;
        } else if (clickedTile === 'change') {
            getDisplayValueForAdvFilter = customFilters[3].displayValue;
        } else if (clickedTile === 'cancellation') {
            getDisplayValueForAdvFilter = customFilters[4].displayValue;
        }
        this.setState(
            {
                policies,
                customFilters,
                policyLineFilter,
                selectedCategory: getDisplayValueForAdvFilter
            },
            () => {
                this.filterCallback();
            }
        );
    }

    componentWillReceiveProps(nextProps) {
        const { selectedCategory, policies } = this.state;
        const { producerCode, onValueChange } = this.props;
        const { producerCode: newProducerCode } = nextProps;
        if (producerCode !== newProducerCode) {
            const filteredJobs = this.util.filterJobs(selectedCategory, newProducerCode);
            const filterAllPolicies = this.util.filterAllPolicies(policies, newProducerCode);
            const filteredPolicies = (selectedCategory === 'Policies') ? filterAllPolicies : filteredJobs;
            const policy = {
                currentView: selectedCategory,
                data: filteredPolicies
            };
            onValueChange(policy);
        }
    }

    onStatusValueChange = (category) => {
        this.setState({ selectedCategory: category }, () => {
            this.filterCallback();
        });
    };

    onProductFilterChange = (value) => {
        this.setState({ policyLineFilter: value }, () => {
            this.filterCallback();
        });
    };

    filterCallback = () => {
        const { selectedCategory, policies } = this.state;
        const { producerCode } = this.props;
        const filteredPolicies = this.util.callFiltersForCurrentFilterOptions(
            producerCode,
            selectedCategory,
            policies
        );
        const { onValueChange } = this.props;
        const policy = {
            currentView: selectedCategory,
            data: filteredPolicies
        };
        onValueChange(policy);
    };

    render() {
        const { selectedCategory, customFilters, policyLineFilter } = this.state;
        if (!customFilters || !policyLineFilter || !selectedCategory) {
            return null;
        }
        return (
            <div className={styles.gw_advanced_filter}>
                <CategoryFilterCard
                    selectedCategory={selectedCategory}
                    customFilter={customFilters}
                    onValueChange={this.onStatusValueChange}
                    countInformation={this.util.policyCountInformation}
                />
                <ProductFilterCard
                    productProperties={policyLineFilter}
                    onValueChange={this.onProductFilterChange}
                />
            </div>
        );
    }
}

export default withProducerContext(AdvancedFilter);
