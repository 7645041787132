import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './CoveragesTermsIterableComponent.metadata.json5';
import styles from '../../Policies/Summary/Summary.module.scss';

function CoveragesTermsIterableComponent(props) {
    const { data: termsData } = props;

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={termsData}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

CoveragesTermsIterableComponent.propTypes = {
    data: PropTypes.shape({}).isRequired
};

export default CoveragesTermsIterableComponent;
