import { defineMessages } from 'react-intl';

export default defineMessages({
    compensationSummary: {
        id: 'quoteandbind.wc7.views.confirmation.Statutory Workers Compensation Summary',
        defaultMessage: 'Statutory Workers\' Compensation Summary'
    },
    address: {
        id: 'quoteandbind.wc7.views.confirmation.Address',
        defaultMessage: 'Address'
    },
    summaryByState: {
        id: 'quoteandbind.wc7.views.confirmation.Summary by State',
        defaultMessage: 'Summary by State'
    },
    state: {
        id: 'quoteandbind.wc7.views.confirmation.State',
        defaultMessage: 'State'
    },
    policyInformationHeader: {
        id: 'quoteandbind.wc7.views.confirmation.Policy Information',
        defaultMessage: 'Policy Information'
    },
    premium: {
        id: 'quoteandbind.wc7.views.confirmation.Premium',
        defaultMessage: 'Premium'
    },
    taxesAndSurcharges: {
        id: 'quoteandbind.wc7.views.confirmation.Taxes and Surcharges',
        defaultMessage: 'Taxes and Surcharges'
    },
    totalCost: {
        id: 'quoteandbind.wc7.views.confirmation.Total Cost',
        defaultMessage: 'Total Cost'
    },
    policyStartDate: {
        id: 'quoteandbind.directives.wc7.QuoteDetailsBox.Policy Start Date',
        defaultMessage: 'Policy Start Date'
    },
    policyEndDate: {
        id: 'quoteandbind.directives.wc7.QuoteDetailsBox.Policy End Date',
        defaultMessage: 'Policy End Date'
    },
    startDate: {
        id: 'quoteandbind.directives.wc7.QuoteDetailsBox.Start Date',
        defaultMessage: 'Start Date'
    },
    endDate: {
        id: 'quoteandbind.directives.wc7.QuoteDetailsBox.End Date',
        defaultMessage: 'End Date'
    }
});
